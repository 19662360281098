import React, { Component } from 'react';

import {AppProvider, Page ,Frame ,FormLayout ,Card, TextField, Select, TextStyle, Tabs, ChoiceList, Button , Thumbnail ,DropZone ,  
  Layout, Stack, Banner, Spinner, List, Link, Toast, Avatar,Collapsible,ContextualSaveBar,TextContainer,Modal ,Icon} from '@shopify/polaris';
import {
  SettingsMinor,CancelSmallMinor
} from '@shopify/polaris-icons';
// import SheetExample from '../../../../../src/components/ContactUsSheetExample/SheetExample';
import DiscountCodePreview from '../../../../../src/components/DiscountCodePreview/index';
import LoadingGif from '../../../../components/LoadingGif';

import { reqGetAutomatedUserUrlName,reqInsertAutomatedCartRecoveryImage,reqInsertAutomatedCartRecovery } from '../../../../api';

import './index.css';
import OnPageSEOTabs from '../../Tabs';

import {API_DOMAIN} from '../../../../utils/constants';

class dashboard extends Component {

  state = {
    
    //弹窗
    showToast: false,  //弹窗显示
    showToastText:'',  //弹窗文字
    showToastIserror:false, //弹窗是否错误
    
    selected: 0,

    //多选框
    Multipleselected:[],

    //页面动作
    loading: true,
    loadingPage: false, 
    //输入框变量
    sendingTo:'All subscribers',
    Title:'',
    TitleError:'',
    Message:'',
    MessageError:'',
    URL:'',
    URLError:'',
    
    //下拉框数据
    httpSelectValue: 'https://',

    //图片1
    files: '',
    fileUpload:'',
    uploadedFiles:'',
    setUpdateImageWarning:'',
    setUpdateImageBackground:'',
    loadingLogo: false,
    //图片删除按钮
    imgButton:true,
    //图片2
    filesHero: '',
    fileUploadHero:'',
    uploadedFilesHero:'',
    setUpdateImageHeroWarning:'',
    setUpdateImageHeroBackground:'',
    //图片删除按钮
    imgButtonHero:true,


    //按钮1
    httpSelectValueButton1: 'https://',
    button1Title:'',
    button1TitleError:'',
    button1Url:'',
    button1UrlError:'',

    //按钮2
    httpSelectValueButton2: 'https://',
    button2Title:'',
    button2TitleError:'',
    button2Url:'',
    button2UrlError:'',

    //用户链接
    UserUrlName: '',

    //显示警告窗口
    criticalWindow:'',

    //优惠卷框
    DiscountCode:'',
    DiscountCodeError:'',
    DiscountCodeSelect:'2',
    DiscountCodeActive:false,

    DiscountCode_Title: "Get 10% off today’s order!",
    DiscountCode_Message: "Complete your cart with code below before the discount gets expired.",
    DiscountCode_Button: "Copy discount code",
    DiscountCode_Position: '3',

    // 预览窗口的高度设置
    previewHeight: 0,


    //保存按钮动作
    saveButton:false,

    // 页面详情所有内容 (用户选择还原数据 discard 时使用)
    initPageData:[],

    // 监听用户内容变化变量 (在用户未保存离开是做提示用)
    dataHasChange: false,
    leavingModalActive: false,

    // 用户还原回到 未编辑时内容版本 ( 弹窗提示 )
    discardModalActive: false,

    // 显示顶部保存栏
    showTopSaveBar: false,
    
  };
  callback=()=>{
    this.setState({})
  }
   //进入页面调用接口
  componentDidMount () {
    //开始调用获取网站
    this.GetCreateCampaign();

    // 挂载滚动监听
    // window.addEventListener('scroll', this.handleScroll)

  }

  componentWillUnmount() {
    // 移除滚动监听
    // window.removeEventListener('scroll', this.handleScroll);
  }


  handleScroll(event) {
    if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      
      // 滚动的高度
      // const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);
      // 视窗高度
      // const clientHeight = (event.srcElement && event.srcElement.documentElement.clientHeight) || document.body.clientHeight;
      
      // 页面高度
      // const scrollHeight = (event.srcElement && event.srcElement.documentElement.scrollHeight) || document.body.scrollHeight;
      // 距离页面底部的高度
      // const height = scrollHeight - scrollTop - clientHeight;
      
      // var previewObj = document.getElementById('Preview-Auto');
      
      // if(scrollTop > 133){
      //   var fixedHeight = '';
      //   if(scrollTop > 2800){
          
      //     fixedHeight = 2800 + -130 + 'px';
      //   }else{
          
      //     fixedHeight = scrollTop + -130 + 'px';
      //   }

      //   previewObj.style.marginTop = fixedHeight;

      // } else{

      //   previewObj.style.marginTop = 'unset';
      // }
    }
    
  }

  // 保存数据时，报错滚动到页面错误提示顶部
  ScrollToTop(){
    if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {

      var event = document.getElementById('errorContent');

      // 滚动的高度
      const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);
      // 视窗高度
      // const clientHeight = (event.srcElement && event.srcElement.documentElement.clientHeight) || document.body.clientHeight;
      // 页面高度
      // const scrollHeight = (event.srcElement && event.srcElement.documentElement.scrollHeight) || document.body.scrollHeight;

      if(scrollTop > 128){
        window.scrollTo(0,128);
      }
    }
  }

  // 更新数据
  updatePageDetail(isDiscard){
    let getData = this.state.initPageData;

    this.setState({ 
      Title:getData.Title,  //标题
      Message:getData.Message, //内容
      httpSelectValue:getData.httpSelectValue, //https下拉框
      URL:getData.URL, //链接
      setUpdateImageBackground:getData.setUpdateImageBackground, //logo图
      setUpdateImageHeroBackground:getData.setUpdateImageHeroBackground, //大图
      Multipleselected:getData.Multipleselected,  //所选中的按钮
      button1Title:getData.button1Title,  //按钮1的标题
      httpSelectValueButton1:getData.httpSelectValueButton1, //按钮1https下拉框
      button1Url:getData.button1Url,  //按钮1的链接
      button2Title:getData.button2Title,  //按钮2的标题
      httpSelectValueButton2:getData.httpSelectValueButton2, //按钮2https下拉框
      button2Url:getData.button2Url,  //按钮2的链接
      DiscountCode:getData.DiscountCode, //优惠卷

      DiscountCodeSelect: String(getData.DiscountCodeSelect),
      DiscountCodeActive: (getData.DiscountCodeSelect===1 || getData.DiscountCodeSelect==='1') ? true : false,  // Closed
      DiscountCode_Title: getData.DiscountCode_Title,
      DiscountCode_Message: getData.DiscountCode_Message,
      DiscountCode_Button: getData.DiscountCode_Button,
      DiscountCode_Position: getData.DiscountCode_Position,

      //更新状态
      loadingPage : true,
      loading : false,
      UserUrlName:getData.userUrl,
    });

    this.setUpdateImage(0,'',getData.setUpdateImageBackground);
    this.setUpdateImageHero(0,'',getData.setUpdateImageHeroBackground);

    if(isDiscard){
      this.setState({
        TitleError: '',
        MessageError: '',
        URLError: '',
        button1TitleError: '',
        button1UrlError: '',
        button2TitleError: '',
        button2UrlError: '',
        criticalWindow:'',
        loadingLogo: false,
      });
    }
  }


  //获取数据
  GetCreateCampaign = async () => {

    const params = {type:this.props.match.params.id};
    const responseData = await reqGetAutomatedUserUrlName(params)
    if (responseData.code==='200') {
      var initData = responseData.data;
      this.setState({ 
        initPageData:initData,
      })

      // 渲染数据
      this.updatePageDetail(false);

    } else {
      this.toggleToast(responseData.msg,true);
    }
    
  }

  //提示弹窗
  toggleToast = (showToastText,showToastIserror) => {
    this.setState({
    showToastText:showToastText,
    showToastIserror:showToastIserror
    });
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

  // input 输入框变化
  handleChangeInput = (value,name) =>{

    // diacount 开关
    if(name==="DiscountCodeSelect"){
      var isOpen = (value==='1')?true:false;
      this.setState({ 
        DiscountCodeActive: isOpen,
        criticalWindow:'',
      });
    }

    if(name==="DiscountCode_Title" && value===''){
      value = 'Get 10% off today’s order!';
    }
    if(name==="DiscountCode_Message" && value===''){
      value = 'Complete your cart with code below before the discount gets expired.';
    }
    if(name==="DiscountCode_Button" && value===''){
      value = 'Copy discount code';
    }

    this.setState({ 
      [name]: value
    });

    // 显示顶部保存栏
    // if(!this.state.showTopSaveBar){
    //   this.TopSaveBarAction();
    // }
  }

  //输入框输入
  handleChange = (field,value) => {
    return (value) => this.setState({
      [field]: value,
      TitleError: '',
      MessageError: '',
      URLError: '',
      button1TitleError: '',
      button1UrlError: '',
      button2TitleError: '',
      button2UrlError: '',
      criticalWindow:'',
      DiscountCodeError:'',
      dataHasChange: true,

    },()=>{
      // 显示顶部保存栏
      // if(!this.state.showTopSaveBar){
      //   this.TopSaveBarAction();
      // }
    });
  };
  //URL输入框
  URLHandleChange = (Value,id) => {
    this.setState({
      TitleError: '',
      MessageError: '',
      URLError: '',
      button1TitleError: '',
      button1UrlError: '',
      button2TitleError: '',
      button2UrlError: '',
      criticalWindow:'',
      DiscountCodeError:''
    });
    // 过滤 http https
    Value = Value.replace("http://",'');
    Value = Value.replace("https://",'');

    var reg=/[\w-]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/;
    
    if(id === "button1Url"){
      var button1UrlError = '';
      if(!reg.test(Value)){
        if(Value){
          button1UrlError = 'Enter a valid website URL. eg: google.com'; 
        }
      }
      this.setState({
        [id] : Value,
        button1UrlError:button1UrlError,
      });
    }else if(id === "button2Url"){
      var button2UrlError = '';
      if(!reg.test(Value)){
        if(Value){
          button2UrlError = 'Enter a valid website URL. eg: google.com'; 
        }
      }
      this.setState({
        [id] : Value,
        button2UrlError:button2UrlError
      });
    }else{
      var URLError = ''; 
      if(!reg.test(Value)){
        if(Value){
          URLError = 'Enter a valid website URL. eg: google.com'; 
        }
      }
      this.setState({
        [id] : Value,
        URLError:URLError,
      });
    }

    this.setState({
      dataHasChange: true,
    });
    // 显示顶部保存栏
    // if(!this.state.showTopSaveBar){
    //   this.TopSaveBarAction();
    // }

  };

  //多选框
  handleChangeMultipl = (value) => {
    this.setState({Multipleselected: value});
  };

  //下拉框替换
  handleSelectChange = (httpSelectValue) => {
    this.setState({httpSelectValue});
  };
  handleSelectChangebutton1 = (httpSelectValueButton1) => {
    this.setState({httpSelectValueButton1});
  };
  handleSelectChangebutton2 = (httpSelectValueButton2) => {
    this.setState({httpSelectValueButton2});
  };
  //切换卡片
  handleTabChange = (selectedTabIndex) => {
    this.setState({selected: selectedTabIndex});
  };
  
  //时间选择
  handleDayChange = (day) => {
    if(day){
      this.setState({ Date: day.toLocaleDateString() });
    }else{
      this.setState({ Date: ''});
    }
  }


  //上传图片
  updateImage = (acceptedFiles) => {
    if(!this.state.loadingLogo){
      this.setState({
        files: acceptedFiles[0],
        loadingLogo: true,
      });
      this.setUpdateImage(1,acceptedFiles[0]);
    } else {
      this.toggleToast('Image is uploading, please try again later.',false);
    }
  };
  
  
  //设置图片
  setUpdateImage = async (type,acceptedFiles,img) => {
    var fileUpload = '';
    var uploadedFiles = '';
    var setUpdateImageWarning = '';
    var setUpdateImageBackground = API_DOMAIN+"/logo.jpg";
    if(img){
      fileUpload = !img && <DropZone.FileUpload />;
      uploadedFiles = img && (
        <div className="addImage">
         <Thumbnail
            size="small"
            alt=''
            source={img}
          />
        </div>
      );
      this.setState({
        imgButton: false,
        fileUpload: fileUpload,
        uploadedFiles: uploadedFiles
      });
    }else if(acceptedFiles){
      const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
      const filesImg = acceptedFiles
      if(validImageTypes.indexOf(filesImg.type) >= 0){
        fileUpload = !filesImg && <DropZone.FileUpload />;
        
        //开启FileReader
        var reader  = new FileReader();
        reader.readAsDataURL(filesImg); //读取文件保存在result中
        reader.onload = (e) =>  {
          var getRes = e.target.result;//读取的结果在result中
          const params = {img:getRes,type:filesImg.type};
          reqInsertAutomatedCartRecoveryImage(params).then(response => {
            if (response.code==='200') {
              uploadedFiles = filesImg && (
                <div className="addImage">
                 <Thumbnail
                    size="small"
                    alt={filesImg.name}
                    source={response.data}
                  />
                </div>
              );
              setUpdateImageBackground = response.data;
            }else{
              fileUpload = <DropZone.FileUpload />;
              uploadedFiles = '';
            }
            
            this.setState({
              imgButton: false,
              fileUpload: fileUpload,
              uploadedFiles: uploadedFiles,
              setUpdateImageBackground:setUpdateImageBackground,
              loadingLogo: false,
            });

            if(uploadedFiles){
              this.setState({
                dataHasChange: true,
              });
              // 显示顶部保存栏
              // if(!this.state.showTopSaveBar){
              //   this.TopSaveBarAction();
              // }
            }
          })
        }
      }else{
        fileUpload = <DropZone.FileUpload />;
        uploadedFiles = ''
        setUpdateImageWarning = <Banner title="The following images couldn’t be uploaded:" status="warning">
          <List>
            <List.Item>
              File type must be .jpg .jpeg .png.
            </List.Item>
          </List>
          <p></p>
        </Banner>
        this.setState({
          fileUpload: fileUpload,
          uploadedFiles: uploadedFiles,
          setUpdateImageWarning: setUpdateImageWarning,
          setUpdateImageBackground:setUpdateImageBackground
        });
      }
    }else{
      fileUpload = <DropZone.FileUpload />;
      uploadedFiles = ''
      //如果提交的文件不是图片 就提醒报错
      if(type === 1){
        setUpdateImageWarning = <Banner title="The following images couldn’t be uploaded:" status="warning">
          <List>
            <List.Item>
              File type must be .jpg .jpeg .png.
            </List.Item>
          </List>
        </Banner>
      }

      this.setState({
        fileUpload: fileUpload,
        uploadedFiles: uploadedFiles,
        setUpdateImageWarning: setUpdateImageWarning,
        setUpdateImageBackground:setUpdateImageBackground
      });
    }
  }

  //删除图片
  deleteImage = () => {
    this.setState({
      files: '',
      imgButton: true,
      dataHasChange: true,
    });
    // 显示顶部保存栏
    // if(!this.state.showTopSaveBar){
    //   this.TopSaveBarAction();
    // }
    this.setUpdateImage();
  }

  //上传图片
  updateImageHero = (acceptedFiles) => {
    this.setState({
      filesHero: acceptedFiles[0],
    });
    this.setUpdateImageHero(1,acceptedFiles[0]);
  };

  //设置图片
  setUpdateImageHero = (type,acceptedFiles,img) => {
    var fileUploadHero = '';
    var uploadedFilesHero = '';
    var setUpdateImageHeroWarning = "";
    var setUpdateImageHeroBackground = API_DOMAIN+"/Untitled.jpg";
    if(img){
      fileUploadHero = !img && <DropZone.FileUpload />;
      uploadedFilesHero = img && (
        <div className="addHerpImage">
         <Thumbnail
            size="small"
            alt=''
            source={img}
          />
        </div>
      );
      this.setState({
        imgButtonHero: false,
        fileUploadHero: fileUploadHero,
        uploadedFilesHero: uploadedFilesHero
      });
    }else if(acceptedFiles){
      const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
      const filesImg = acceptedFiles
      if(validImageTypes.indexOf(filesImg.type) >= 0){
        fileUploadHero = !filesImg && <DropZone.FileUpload />;


        //开启FileReader
        var reader  = new FileReader();
        reader.readAsDataURL(filesImg); //读取文件保存在result中
        reader.onload = (e) =>  {
          var getRes = e.target.result;//读取的结果在result中
          const params = {img:getRes,type:filesImg.type};
          reqInsertAutomatedCartRecoveryImage(params).then(response => {
            if (response.code==='200') {
              uploadedFilesHero = filesImg && (
                <div className="addHerpImage">
                 <Thumbnail
                    size="small"
                    alt={filesImg.name}
                    source={response.data}
                  />
                </div>
              );
              setUpdateImageHeroBackground = response.data;
            }else{
              fileUploadHero = <DropZone.FileUpload />;
              uploadedFilesHero = '';
            }
            this.setState({
              imgButtonHero: false,
              fileUploadHero: fileUploadHero,
              uploadedFilesHero: uploadedFilesHero,
              setUpdateImageHeroBackground:setUpdateImageHeroBackground,
              
            });

            if(uploadedFilesHero){
              this.setState({
                dataHasChange: true,
              })
              // 显示顶部保存栏
              // if(!this.state.showTopSaveBar){
              //   this.TopSaveBarAction();
              // }
            }
          })
        }

      }else{
        fileUploadHero = <DropZone.FileUpload />;
        uploadedFilesHero = ''
        setUpdateImageHeroWarning = <Banner title="The following images couldn’t be uploaded:" status="warning">
          <List>
            <List.Item>
              File type must be .jpg .jpeg .png.
            </List.Item>
          </List>
        </Banner>
        this.setState({
          fileUploadHero: fileUploadHero,
          uploadedFilesHero: uploadedFilesHero,
          setUpdateImageHeroWarning: setUpdateImageHeroWarning,
          setUpdateImageHeroBackground:setUpdateImageHeroBackground
        });
      }
    }else{
      fileUploadHero = <DropZone.FileUpload />;
      uploadedFilesHero = ''
      if(type === 1){
        setUpdateImageHeroWarning = <Banner title="The following images couldn’t be uploaded:" status="warning">
          <List>
            <List.Item>
              File type must be .jpg .jpeg .png.
            </List.Item>
          </List>
        </Banner>
      }
        
      this.setState({
        fileUploadHero: fileUploadHero,
        uploadedFilesHero: uploadedFilesHero,
        setUpdateImageHeroWarning: setUpdateImageHeroWarning,
        setUpdateImageHeroBackground:setUpdateImageHeroBackground
      });
    }
  }

  //删除图片
  deleteImageHero = () => {
    this.setState({
      filesHero: '',
      imgButtonHero: true,
      dataHasChange: true,
    });
    // 显示顶部保存栏
    // if(!this.state.showTopSaveBar){
    //   this.TopSaveBarAction();
    // }
    this.setUpdateImageHero();
  }

  //保存
  insertCreateCampaign = async () => {
    //判断是否允许提交
    var isAllow = 1;
    //错误字符提示 以及 个数
    var criticalCharacter1 = '';
    var criticalCharacter2 = '';
    var criticalCharacter3 = '';
    var criticalCharacter4 = '';
    var criticalCharacter5 = '';
    var criticalCharacter6 = '';
    var criticalCharacter7 = '';
    var criticalCharacter8 = '';
    var criticalCharacter9 = '';
    var criticalCharacter10 = '';
    var criticalCharacter11 = '';
    var criticalCount = 0;
    
    //判断必填项
    if(this.state.Title === ''){
      criticalCharacter1 = <List.Item>Content title is required</List.Item>;
      criticalCount = criticalCount + 1;
      isAllow = 0;
      this.setState({TitleError: 'Required'});
    }
    if(this.state.Message === ''){
      criticalCharacter2 = <List.Item>Content body is required</List.Item>
      criticalCount = criticalCount + 1;
      isAllow = 0;
      this.setState({MessageError: 'Required'});
    }
    if(this.state.URL === ''){
      criticalCharacter3 = <List.Item>Content url is required</List.Item>
      criticalCount = criticalCount + 1;
      isAllow = 0;
      this.setState({URLError: 'Required'});
    }else{
      if(this.state.URLError !== ''){
        criticalCharacter4 = <List.Item>Content url: Enter a valid website URL. eg: google.com</List.Item>
        criticalCount = criticalCount + 1;
        isAllow = 0;
      }
    }

    //按钮1
    if(this.state.Multipleselected.indexOf('button1') >= 0){
      if(this.state.button1Title === ''){
        criticalCharacter5 = <List.Item>Content action buttons 1 title is required</List.Item>
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({button1TitleError: 'Required'});
      }
      if(this.state.button1Url === ''){
        criticalCharacter6 = <List.Item>Content action buttons 1 url is required</List.Item>
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({button1UrlError: 'Required'});
      }else{
        if(this.state.button1UrlError !== ''){
          criticalCharacter7 = <List.Item>Content action buttons 1 url: Enter a valid website URL. eg: google.com</List.Item>
          criticalCount = criticalCount + 1;
          isAllow = 0;
        }
      }
    }

    //按钮2
    if(this.state.Multipleselected.indexOf('button2') >= 0){
      if(this.state.button2Title === ''){
        criticalCharacter8 = <List.Item>Content action buttons 2 title is required</List.Item>
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({button2TitleError: 'Required'});
      }
      if(this.state.button2Url === ''){
        criticalCharacter9 = <List.Item>Content action buttons 2 url is required</List.Item>
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({button2UrlError: 'Required'});
      }else{
        if(this.state.button2UrlError !== ''){
          criticalCharacter10 = <List.Item>Content action buttons 2 url: Enter a valid website URL. eg: google.com</List.Item>
          criticalCount = criticalCount + 1;
          isAllow = 0;
        }
      }
    }

    // 开启优惠券开关后需要填写优惠码
    if(this.state.DiscountCodeSelect==='1'){
      if(this.state.DiscountCode ===''){
        criticalCharacter11 = <List.Item>Do you want to offer a Discount Code ?</List.Item>
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({DiscountCodeError: 'Required'});
      }
    }

    //判断是否允许提交
    if(isAllow === 1){
      this.setState({
        saveButton:true
      });
      const params = {
        Title:this.state.Title,  //标题
        Message:this.state.Message, //内容
        URL:this.state.URL, //链接
        httpSelectValue:this.state.httpSelectValue, //https下拉框
        Multipleselected:this.state.Multipleselected,  //所选中的按钮
        button1Title:this.state.button1Title,  //按钮1的标题
        button1Url:this.state.button1Url,  //按钮1的链接
        httpSelectValueButton1:this.state.httpSelectValueButton1, //按钮1https下拉框
        button2Title:this.state.button2Title,  //按钮2的标题
        button2Url:this.state.button2Url,  //按钮2的链接
        httpSelectValueButton2:this.state.httpSelectValueButton2, //按钮2https下拉框
        setUpdateImageBackground:this.state.setUpdateImageBackground, //logo图
        setUpdateImageHeroBackground:this.state.setUpdateImageHeroBackground, //大图
        DiscountCode:this.state.DiscountCode,  //优惠码
        type:this.props.match.params.id,  //修改类型

        DiscountCodeSelect: this.state.DiscountCodeSelect,  // 优惠码开关
        DiscountCode_Title: this.state.DiscountCode_Title,
        DiscountCode_Message: this.state.DiscountCode_Message,
        DiscountCode_Button: this.state.DiscountCode_Button,
        DiscountCode_Position: this.state.DiscountCode_Position,
      };
      
      const responseData = await reqInsertAutomatedCartRecovery(params)
      
      if (responseData.code==='200') {
        this.setState({ 
          saveButton:false,
          dataHasChange: false,
          showTopSaveBar: false,
          criticalWindow:'',
        })
        this.toggleToast(responseData.msg,false);
      }else{
        this.setState({ 
          saveButton:false
        })
        this.toggleToast(responseData.msg,true);
      }
    }else{
      var criticalWindow = <div style={{ marginBottom:"2rem"}}>
        <Banner title={`To add this message, `+criticalCount+` fields need changes`} status="critical">
          <List>
            {criticalCharacter1}
            {criticalCharacter2}
            {criticalCharacter3}
            {criticalCharacter4}
            {criticalCharacter5}
            {criticalCharacter6}
            {criticalCharacter7}
            {criticalCharacter8}
            {criticalCharacter9}
            {criticalCharacter10}
            {criticalCharacter11}
          </List>
        </Banner>
      </div>

      this.ScrollToTop();

      this.setState({
        criticalWindow: criticalWindow
      });
    }
   
  }

  //获取周几
  GetDate(){
    var todays = new Date(),
    date = todays.getFullYear() + '-' + (todays.getMonth() + 1) + '-' + todays.getDate();
    const day = new Date(Date.parse(date.replace(/-/g, '/')));
    const today = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return today[day.getDay()]
  }

  //获取时间
  formatDate() {
    var todays = new Date(),
    date = todays.getFullYear() + '-' + (todays.getMonth() + 1) + '-' + todays.getDate() + " " + todays.getHours() + ":" + todays.getMinutes(); 

    var d = new Date(date);
    var hh = d.getHours();
    var m = d.getMinutes();
    var dd = "AM";
    var h = hh;
    if (h > 12) {
      h = hh - 12;
      dd = "PM";
    }
    
    if (h === 0) {
      h = 12;
    }
    m = m < 10 ? "0" + m : m;
    h = h<10?"0"+h:h;
  
    
    return h+":"+m+" "+dd;

  }

  //获取日期
  GetTime(){
    var todays = new Date(),
    date = todays.getFullYear() + '-' + (todays.getMonth() + 1) + '-' + todays.getDate() + " " + todays.getHours() + ":" + todays.getMinutes(); 

    var dd = new Date(date);
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = dd.getDate()
    d = d < 10 ? "0" + d : d;
    date = y + '-' + (m) + '-' + d;
    return date
  }

  // 用户编辑内容 变化后，显示全局顶部保存导航
  TopSaveBarAction = () =>{
    this.setState(({showTopSaveBar}) => ({showTopSaveBar: !showTopSaveBar}));
  }

  // 监听用户返回 导航方法
  isLeave = () =>{
    if(this.state.dataHasChange){
      this.ControlUserLeavingModal();
      return false;
    } else{
      window.location.href = '/admin/abandoned-cart-recovery/index';  // 直接跳转
    }
  }

  // 用户未保存编辑内容，直接确认离开
  UserLeavesWithoutSave = () => {
    window.location.href = '/admin/abandoned-cart-recovery/index';  // 直接跳转
  }

  // UserLeavesListening modal 开关控制
  ControlUserLeavingModal = async () =>{
    this.setState(({leavingModalActive}) => ({leavingModalActive: !leavingModalActive}));
  }

  // DiscardModal 开关控制
  ControlDiscardModal = async () =>{
    this.setState(({discardModalActive}) => ({discardModalActive: !discardModalActive}));
  }

  // Discard 返回页面原始数据
  DiscardInitData = () => {
    // 恢复原始数据
    this.setState({
      dataHasChange: false,  // 数据编辑参数
      showTopSaveBar: false,  // 顶部保存导航 
    },()=>{
      this.updatePageDetail(true);
    });
    
    // 关闭弹窗 隐藏顶部保存栏
    this.setState(({discardModalActive}) => ({discardModalActive: !discardModalActive}));
  }
  

  render() {
    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={this.toggleToast} error={this.state.showToastIserror} /> : null;
    const loadingMarkup = this.state.loading ? <div className="loadingCenter"><Spinner size="large" color="teal" /></div> : '';
    const {

      state: { fileUpload,uploadedFiles,
        // fileUploadHero,uploadedFilesHero,
        sendingTo,Title,Message,URL,Multipleselected,button1Title,button1Url,button2Title,button2Url,httpSelectValue,httpSelectValueButton1,httpSelectValueButton2,selected,setUpdateImageWarning,
        // setUpdateImageHeroWarning,
        setUpdateImageBackground,setUpdateImageHeroBackground,TitleError,MessageError,URLError,button1TitleError,button1UrlError,button2TitleError,button2UrlError,DiscountCode,criticalWindow,DiscountCodeError},
      handleChange,
      updateImage,
      deleteImage,
      // updateImageHero,
      // deleteImageHero,
      // handleChangeInput,DiscountCode_Title,DiscountCode_Message,DiscountCode_Button,DiscountCode_Position
    } = this;
    var button1Content = '';
    //判断按钮1
    if(Multipleselected.indexOf('button1') >= 0){
      button1Content = <div>
       <FormLayout>
        <TextField
          label="Title"
          onChange={handleChange('button1Title')}
          value={button1Title}
          maxLength={50}
          showCharacterCount
          helpText="Perfect length is 15 characters"
          error={button1TitleError}
        /> 
        <TextField
          label="Url"
          id="button1Url"
          onChange={this.URLHandleChange}
          value={button1Url}
          error={button1UrlError}
          prefix={httpSelectValueButton1}
          // connectedLeft={
          //   <Select 
          //     value={httpSelectValueButton1}
          //     label="Weight unit"
          //     onChange={this.handleSelectChangebutton1}
          //     labelHidden
          //     options={['https://', 'http://']}
          //   />
          // }
        />  
        </FormLayout>
    </div>;
    }
    
    var button2Content = '';
    //判断按钮2
    if(Multipleselected.indexOf('button2') >= 0){
       button2Content = <div>
        <FormLayout>
          <TextField
            label="Title"
            onChange={handleChange('button2Title')}
            value={button2Title}
            maxLength={50}
            showCharacterCount
            helpText="Perfect length is 15 characters"
            error={button2TitleError}
          /> 
          <TextField
            label="Url"
            id="button2Url"
            onChange={this.URLHandleChange}
            error={button2UrlError}
            value={button2Url}
            prefix={httpSelectValueButton2}
            // connectedLeft={
            //   <Select 
            //     value={httpSelectValueButton2}
            //     label="Weight unit"
            //     onChange={this.handleSelectChangebutton2}
            //     labelHidden
            //     options={['https://', 'http://']}
            //   />
            // }
          />  
        </FormLayout>
    </div>;
    }

    const tabs = [
      {
        id: '0',
        content: 'Windows',
        panelID: 'Windows',
      },
      {
        id: '1',
        content: 'Android',
        panelID: 'Android',
      },
      {
        id: '2',
        content: 'Mac',
        panelID: 'Mac',
      },
    ];

    //Preview 切换卡片
    var tabsContent = '';
    if(this.state.loadingPage === true){

      if(selected === 0){
      //设置按钮显示
        var buttonShow = '';

        if(Multipleselected.indexOf('button1') >= 0 || Multipleselected.indexOf('button2') >= 0){
          if(Multipleselected.indexOf('button1') >= 0){
            var button1Show = '';
            if(button1Title){
              button1Show = <span>{button1Title}</span>
            }
          }
          if(Multipleselected.indexOf('button2') >= 0){
            var button2Show = '';
            if(button2Title){
              button2Show = <span>{button2Title}</span>
            }
          }
          buttonShow = <section className="Android_Preview_Actions">{button1Show}{button2Show}</section>
        } 

        tabsContent = <Card.Section>
          <div className=""  style={{ background:"url('/winback.jpg')  no-repeat",height:"580px",width: "100%",overflow: "hidden",backgroundSize: "cover",backgroundPosition: "right bottom",position: "relative" }}>
            
            <div className="ht-preview--windows__time"><div>{this.formatDate()}</div><div>{this.GetTime()}</div></div>
            <section className="" style={{ width:"85%",maxWidth:"400px",position:"absolute",right:"10px",bottom:"60px",backgroundColor:"white" }}>
              <Card>
                <Card.Section>
                  <Stack  vertical={true}>
                    <Stack vertical={true}>

                      <Stack wrap={false}>
                        <Stack.Item fill>
                            <div className="Webpush_Url_Icon">
                                <Stack wrap={false}>
                                    <Avatar source='/Google_Chrome_Icon.svg' />
                                    <div style={{paddingBottom:'.5rem'}}>{this.state.UserUrlName}</div>
                                </Stack>
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                        <div className="testWebPush_Win_Icon">
                                <Stack>
                                    <Icon source={SettingsMinor} />
                                    <Icon source={CancelSmallMinor} />
                                </Stack>
                            </div>
                        </Stack.Item>
                      </Stack>

                      <Stack distribution="fill" wrap={false}>
                        <Stack vertical={true}>
                          <section className="Message_Title" style={{marginTop:'-1.6rem'}}><span className="">{this.state.Title?this.state.Title:'Title'}</span></section>
                          <section className="Message_Content">
                            <div className="Polaris-Stack__Itemclass">
                              <Stack vertical={true}>
                                <span>{this.state.Message?this.state.Message:'Message'}</span>
                              </Stack>
                            </div>
                          </section>
                        </Stack>
                        <section style={{float:'right',marginTop:'-1.6rem'}}>
                        <Thumbnail
                            source={setUpdateImageBackground}
                            size="small"
                            alt="Black choker necklace"
                          />
                       </section>
                      </Stack>
                      <div style={{clear:'both'}}></div>
                    </Stack>
                  </Stack>
                  
                </Card.Section>
                <div className="Win_Preview_Image">
                  <img src={setUpdateImageHeroBackground} style={{maxWidth:'100%',height:'auto'}} alt="Abandoned Cart Web Push" />
                </div>
                {buttonShow}
              </Card>
            </section>
          </div>
        </Card.Section>
      }else if(selected === 1){
        //   //设置按钮显示
          // var b2buttonShow = '';
          // if(Multipleselected.indexOf('button1') >= 0 || Multipleselected.indexOf('button2') >= 0){
          //   if(Multipleselected.indexOf('button1') >= 0){
          //     var b2button1Show = '';
          //     if(button1Title){
          //       b2button1Show = <Link>{button1Title}</Link>
          //     }
          //   }
          //   if(Multipleselected.indexOf('button2') >= 0){
          //     var b2button2Show = '';
          //     if(button2Title){
          //       b2button2Show = <Link>{button2Title}</Link>
          //     }
          //   }
          //   b2buttonShow = <div style={{ marginLeft:"70px" }}><Stack>
          //     {b2button1Show}
              
          //     {b2button2Show}
          //   </Stack></div>
          // }
          tabsContent = <Card.Section>
          <div className="design-mobile4" style={{background:"url('/mobile5.png')  no-repeat top",backgroundSize:"100% auto"}}>
            <div className="Mobile__Time">{this.formatDate()}</div>
            <section className="Preview">
              <Card>
                <Stack  vertical={true}>
                
                  <Stack distribution="fill" wrap={false}>
                   
                      <Stack vertical={true}>
                        <section className="Message_Title">
                          <div className="">{this.state.Title?this.state.Title:'Title'}</div>
                        </section>
                        <section className="Message_Content" style={{paddingRight:'1rem'}}>
                          <div className="Polaris-Stack__Itemclass">
                            <Stack vertical={true}>
                              <div>{this.state.Message?this.state.Message:'Message'}</div>
                            </Stack>
                          </div>
                        </section>
                      </Stack>

                      <section style={{float:'right'}}>
                        <Thumbnail
                          source={setUpdateImageBackground}
                          size="small"
                          alt="Black choker necklace"
                        />
                      </section>

                    </Stack>
                    <div style={{clear:'both'}}></div>
                    {/* <div className="Preview_Image" style={{ backgroundImage:"url("+setUpdateImageHeroBackground+")"}}></div> */}
                    <div style={{marginTop:'-2rem'}}>
                      <img src={setUpdateImageHeroBackground} style={{maxWidth:'100%',height:'auto'}} alt="Abandoned Cart Web Push" />
                    </div>
                    
                    {/* {b2buttonShow} */}
                </Stack>
              </Card>
            </section>
          </div>
        </Card.Section>;
      }else if(selected === 2){
          tabsContent = 
          <Card.Section>
          <div className=""  style={{ background:"url('/macback.jpg')  no-repeat",height:"415px",width: "100%",overflow: "hidden",backgroundSize: "cover",backgroundPosition: "right top",position: "relative" }}>
            <div className="ht-preview--mac__time">{this.GetDate()} {this.formatDate()}</div>
            <section className="MacPreview" style={{ width:"94%",margin:"10% auto" }}>
              <section className="MacPreview_Card">
                <Card>
                  <Stack alignment="center">
                    <section style={{marginLeft:"2rem"}}>
                      <Avatar customer name="Farrah" source="/mac_chrome.iso.f3b6c54c.svg"/>
                    </section>
                    <Stack.Item fill>
                      <Stack vertical={true} spacing="extraTight">
                        <div className="Mac_Message_Title">{this.state.Title?this.state.Title.substring(0,30):'Title'}{(this.state.Title.length>31)?'...':''}</div>
                        <span>{this.state.UserUrlName}</span>
                        <div className="Mac_Message_Content">{this.state.Message?this.state.Message.substring(0,34):'Message'}{(this.state.Message.length>35)?'...':''}</div>
                      </Stack>
                    </Stack.Item>
                    <Card>
                      <Card.Section>
                        Close
                      </Card.Section>
                      <Card.Section>
                        Settings
                      </Card.Section>
                    </Card>
                  </Stack>
                </Card>
              </section>
            </section>
          </div>
        </Card.Section>;
      }
   }

    const {leavingModalActive,discardModalActive} = this.state;

    const floatTopSaveBar = false ? <div>
      <ContextualSaveBar
        alignContentFlush
        message="Unsaved changes"
        saveAction={{
          onAction: this.insertCreateCampaign,
          loading: this.state.saveButton
        }}
        discardAction={{
          onAction: this.ControlDiscardModal,
        }}
      />
      </div> : '';

    const UserLeavingModal = this.state.leavingModalActive ? <div>
    <Modal
      open={leavingModalActive}
      onClose={this.ControlUserLeavingModal}
      title="You have unsaved changes"
      primaryAction={{
        content: 'Leave edit',
        onAction: this.UserLeavesWithoutSave,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: this.ControlUserLeavingModal,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            If you leave this page, all unsaved changes will be lost.
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
    </div> : '';

    const UserDiscardModal = this.state.discardModalActive ? <div>
    <Modal
      open={discardModalActive}
      onClose={this.ControlDiscardModal}
      title="Discard all unsaved changes"
      primaryAction={{
        content: 'Discard changes',
        onAction: this.DiscardInitData,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: 'Continue editing',
          onAction: this.ControlDiscardModal,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            If you discard changes, you’ll delete any edits you made since you last saved.
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
    </div> : '';


    return (
      <div className="frame_page_padding">
      <AppProvider>
        <Frame topBar={ <OnPageSEOTabs
            loading={this.state.saveButton}
            discardButton={this.state.dataHasChange && this.ControlDiscardModal}
            saveData = {this.insertCreateCampaign}
            saveDataParams=''
            callback={this.callback}
        /> } >
          <Page 
            breadcrumbs={[{content: 'Abandoned Cart Recovery', onAction:this.isLeave}]}
            title="Update web push"
          >

            {floatTopSaveBar}

            <div id="errorContent"></div>

            {criticalWindow}
            
            <Layout>
              <Layout.Section secondary>
                <Card title="CONTENT">
                  <Card.Section>
                    <FormLayout>
                      <TextField
                          disabled
                          label="Sending to"
                          onChange={handleChange('sendingTo')}
                          value={sendingTo}
                        />  
                        <TextField
                          label="Title"
                          onChange={handleChange('Title')}
                          value={Title}
                          maxLength={50}
                          showCharacterCount
                          helpText="Perfect length is 21 characters"
                          error={TitleError}
                        />  
                        <TextField
                          label="Message"
                          onChange={handleChange('Message')}
                          value={Message}
                          maxLength={100}
                          showCharacterCount
                          helpText="Perfect length is 23 characters"
                          error={MessageError}
                        /> 
                        <TextField
                          label="URL"
                          id="URL"
                          onChange={this.URLHandleChange}
                          value={URL}
                          helpText="Your customers will see this web page when they tap on the content or hero image."
                          error={URLError}
                          prefix={httpSelectValue}
                          // connectedLeft={
                          //   <Select 
                          //     value={httpSelectValue}
                          //     label="Weight unit"
                          //     onChange={this.handleSelectChange}
                          //     labelHidden
                          //     options={['https://', 'http://']}
                          //   />
                          // }
                        />  
                       
                    </FormLayout>
                  </Card.Section>   
                  <Card.Section title="BRAND LOGO">
                    <Stack vertical={true}  >
                      <TextStyle variation="subdued">64px x 64px; Less than 1MB; Accepts .jpg, .jpeg, .png.</TextStyle>
                      {setUpdateImageWarning}
                      <Stack spacing="loose" distribution="center">
                        <div style={{width: 134, height: 134,margin:'0 auto'}}>
                        <DropZone
                         accept="image/*"
                          type="image"
                          onDrop={(files, acceptedFiles, rejectedFiles) => {
                            updateImage(acceptedFiles)
                          }}
                        >
                          <div className="Webpush_UploadImage">
                          {uploadedFiles}
                          {fileUpload}
                          
                          <div style={{textAlign:'center',margin: '-10px 0 10px'}}> {this.state.loadingLogo ? <LoadingGif /> : ''}</div>
                          </div>
                        </DropZone>
                        </div>

                        <div style={{display:this.state.imgButton?'none':'block'}}>
                          <Button disabled={this.state.imgButton} onClick={() => deleteImage() }>Remove</Button>
                        </div>
                      </Stack>
                    </Stack>
                  </Card.Section>
                </Card>
                {/* <Card title="HERO IMAGE" sectioned>
                  <Stack vertical={true} >
                    <TextStyle variation="subdued">450px x 200px; Less than 2MB; Accepts .jpg, .jpeg, .png.</TextStyle>
                    {setUpdateImageHeroWarning}
                    <DropZone
                      accept="image/*"
                      type="image"
                      onDrop={(files, acceptedFiles, rejectedFiles) => {
                        updateImageHero(acceptedFiles)
                      }}
                    >
                      {uploadedFilesHero}
                      {fileUploadHero}
                    </DropZone>
                    <div style={{textAlign:"center"}}>
                      <Button disabled={this.state.imgButtonHero} onClick={() => deleteImageHero() }>Remove</Button>
                    </div>
                  </Stack>
                </Card> */}
                
                <Card>
                  <Card.Section title="ACTION BUTTONS">
                    <FormLayout> 
                      <ChoiceList
                        allowMultiple
                        choices={[
                          {
                            label: '1st action button',
                            value: 'button1',
                            helpText : button1Content
                          }
                        ]}
                        selected={Multipleselected}
                        onChange={this.handleChangeMultipl}
                      />
                      <ChoiceList
                        allowMultiple
                        choices={[
                          {
                            label: '2nd action button',
                            value: 'button2',
                            helpText : button2Content
                          },
                        ]}
                        selected={Multipleselected}
                        onChange={this.handleChangeMultipl}
                      />
                    </FormLayout> 
                  </Card.Section>   
                </Card>
                
              </Layout.Section>
              <Layout.Section secondary>
                <div id="Preview-Auto">
                  <Card title="">
                    <Tabs
                      tabs={tabs}
                      selected={selected}
                      onSelect={this.handleTabChange}
                      fitted
                    >
                      {loadingMarkup}
                      {tabsContent}
                        
                    </Tabs>
                  </Card>
                </div>
              </Layout.Section>
            </Layout>

            <div className="space"></div>
            <Layout>
              <Layout.Section secondary>
                <Card>
                  <Card.Section title="DISCOUNT">
                    <Stack vertical>
                      <FormLayout>
                        <FormLayout.Group>
                          <Select
                            label="Offer a Discount Code"
                            id='DiscountCodeSelect'
                            value={this.state.DiscountCodeSelect}
                            ariaControls="basic-collapsible-third"
                            onChange={this.handleChangeInput}
                            options={[{label:'Yes',value:'1'}, {label:'No',value:'2'}]}
                          />
                        </FormLayout.Group>
                      </FormLayout>
                      
                      <Collapsible open={this.state.DiscountCodeActive} id="basic-collapsible-third">
                        
                        {/* <p style={{fontSize:'1.4rem',color:'#a2a2a2',marginBottom:'2.5rem'}}> *If offer a discount, we recommend you choose a Discount template to increase the web push CTR. </p> */}

                        <FormLayout>
                          <FormLayout.Group>
                            <TextField
                              label="Enter Discount Code"
                              onChange={handleChange('DiscountCode')}
                              value={DiscountCode}
                              error={DiscountCodeError}
                              // helpText="Please also include your discount info (e.g. 10%) in your Web Push Title or Message to increase Click-Through Rate (CTR)."
                            /> 
                          </FormLayout.Group>
                          
                          <div style={{fontSize:'1.4rem'}}>
                            <Link url={`https://`+this.state.UserUrlName+"/admin/discounts"} external={true}>Create discount code</Link>
                          </div>

                          <TextField 
                            label="Title"
                            id="DiscountCode_Title"
                            value={this.state.DiscountCode_Title}
                            onChange={this.handleChangeInput}
                            maxLength={30}
                            showCharacterCount
                          />
                          <TextField
                            label="Message"
                            id="DiscountCode_Message"
                            value={this.state.DiscountCode_Message}
                            onChange={this.handleChangeInput}
                            maxLength={80}
                            showCharacterCount
                          />
                          <TextField 
                            label="Button"
                            id="DiscountCode_Button"
                            value={this.state.DiscountCode_Button}
                            onChange={this.handleChangeInput}
                            maxLength={30}
                            showCharacterCount
                          />
                          <Select
                            label="Position"
                            id="DiscountCode_Position"
                            value={this.state.DiscountCode_Position}
                            onChange={this.handleChangeInput}
                            options={[{label:'Left',value:'1'}, {label:'Center',value:'2'}, {label:'Right',value:'3'}]}
                          />
                        </FormLayout>

                      </Collapsible>
                    </Stack>

                  </Card.Section>
                </Card>
              </Layout.Section>

              <Layout.Section secondary>
                {/* 开关控制显示 隐藏 */}
                <div style={{display:this.state.DiscountCodeActive?'block':'none'}}>
                  <Card sectioned>
                    <DiscountCodePreview 
                      code={DiscountCode} 
                      title={this.state.DiscountCode_Title} 
                      message={this.state.DiscountCode_Message} 
                      button={this.state.DiscountCode_Button}
                      position={this.state.DiscountCode_Position}
                      getDateFunction={this.formatDate()} 
                      getTimeFunction={this.GetTime()}
                      />
                  </Card>
                </div>
              </Layout.Section>
            </Layout>

            {toastMarkup}

            {UserLeavingModal}
            {UserDiscardModal}

            <div className="space"></div>
            {/* <SheetExample /> */}
          </Page>
        </Frame>
      </AppProvider>
      </div>
    );
  }
}

export default dashboard;
