import React, { Component } from 'react';

import {AppProvider,  Avatar, Page ,Frame , Layout, Card, Button, Stack, Heading, TextField, TextStyle, Toast, Spinner, Link, DisplayText} from '@shopify/polaris';

// import SheetExample from '../../../../../src/components/ContactUsSheetExample/SheetExample';

import './index.css';
import OnPageSEOTabs from '../../Tabs';

import { reqSetUserStartupType,reqGetAutomatedCartRecovery,requpdateAutomatedCartRecoveryTime,requpdateAutomatedCartRecoveryCode } from '../../../../api';
import LanguageSwitch from "../../../../components/LanguageSwitch";
class dashboard extends Component {

  state = {
    //变量框1
    Title1:'',
    Message1:'',
    URL1:'',
    httpSelectValue1:'',
    DiscountCode1:'',
    Mins:'',
    //变量框2
    Title2:'',
    Message2:'',
    URL2:'',
    httpSelectValue2:'',
    DiscountCode2:'',
    Hours:'',
    //变量框3
    Title3:'',
    Message3:'',
    URL3:'',
    httpSelectValue3:'',
    DiscountCode3:'',
    Day:'',
    
    //用户链接
    UserUrlName: '',


    //弹窗
    showToast: false,  //弹窗显示
    showToastText:'',  //弹窗文字
    showToastIserror:false, //弹窗是否错误

    //页面动作
    loading: true,
    loadingPage: false,

    //按钮动作
    ButtonLoading:false,
    //启动状态
    ButtonState:0,
    //时间按钮状态
    ButtonTypeLoading:false,
    //输入框状态
    TextFieldDisabled:false,
  };

  //进入页面调用接口
  componentDidMount () {
    this.GetAutomatedCartRecovery()
  }
  callback=() =>{
    this.setState({})
  }

  
  
  //获取数据
  GetAutomatedCartRecovery = async () => {
    const params = {};
    const responseData = await reqGetAutomatedCartRecovery(params)
    if (responseData.code==='200') {
      this.setState({ 
        Title1:responseData.data.type1.Title1,
        Message1:responseData.data.type1.Message1,
        URL1:responseData.data.type1.URL1,
        httpSelectValue1:responseData.data.type1.httpSelectValue1,
        DiscountCode1:responseData.data.type1.DiscountCode1,
        Mins:responseData.data.type1.Time1,

        Title2:responseData.data.type2.Title2,
        Message2:responseData.data.type2.Message2,
        URL2:responseData.data.type2.URL2,
        httpSelectValue2:responseData.data.type2.httpSelectValue2,
        DiscountCode2:responseData.data.type2.DiscountCode2,
        Hours:responseData.data.type2.Time2,

        Title3:responseData.data.type3.Title3,
        Message3:responseData.data.type3.Message3,
        URL3:responseData.data.type3.URL3,
        httpSelectValue3:responseData.data.type3.httpSelectValue3,
        DiscountCode3:responseData.data.type3.DiscountCode3,
        Day:responseData.data.type3.Time3,
        //启动状态
        ButtonState:responseData.data.type,
        loadingPage : true,
        loading : false,
        UserUrlName:responseData.data.userUrl,
      })
    }
  }

  


  //提示弹窗
  toggleToast = (showToastText,showToastIserror) => {
    this.setState({
    showToastText:showToastText,
    showToastIserror:showToastIserror
    });
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

  //请求开关
  SetUserStartupType = async () => {
    this.setState({ 
      ButtonLoading:true
    })
    const params = {type:"automated"};
    const responseData = await reqSetUserStartupType(params)
    if (responseData.code==='200') {
      this.setState({ 
        //开关状态
        ButtonState:responseData.data,
        ButtonLoading:false
      })
      this.toggleToast(responseData.msg,false);
    }
  }


  handleChange = (field) => (value) => {

    if(field === 'Mins'){
      if(value<5 || value>20){
        value = '';
      }
    }else if(field === 'Hours'){
      if(value<2 || value>20){
        value = '';
      }

    }else if(field === 'Day'){
      if(value<1 || value>3){
        value = '';
      }
    }

    this.setState({[field]: value});
  };

  //修改时间
  updateAutomatedCartRecoveryTime = async (type) => {
    this.setState({ 
      ButtonTypeLoading:true
    })
    //判断类型取对应时间
    var time = 0;
    if(type === 1){
      time = this.state.Mins;
    }else if(type === 2){
      time = this.state.Hours;
    }else if(type === 3){
      time = this.state.Day;
    }
    const params = {type:type,time:time};
    const responseData = await requpdateAutomatedCartRecoveryTime(params)
    if (responseData.code==='200') {
      this.setState({ 
        ButtonTypeLoading:false
      })
      this.toggleToast(responseData.msg,false);
    }else{
      this.toggleToast(responseData.msg,false);
    }
  }

  
  //修改优惠卷
  updateAutomatedCartRecoveryCode = async (type) => {
    this.setState({ 
      TextFieldDisabled:true
    })
    //判断类型取对应时间
    var code = '';
    if(type === 1){
      code = this.state.DiscountCode1;
    }else if(type === 2){
      code = this.state.DiscountCode2;
    }else if(type === 3){
      code = this.state.DiscountCode3;
    }
    const params = {type:type,code:code};
    const responseData = await requpdateAutomatedCartRecoveryCode(params)
    if (responseData.code==='200') {
      this.setState({ 
        TextFieldDisabled:false
      })
      this.toggleToast(responseData.msg,false);
    }else{
      this.toggleToast(responseData.msg,false);
    }
  }


  render() {
    const {
      Mins, Hours, Day, ButtonState, Title1, Message1, URL1, httpSelectValue1, DiscountCode1, Title2, Message2, URL2, httpSelectValue2, DiscountCode2, Title3, Message3, URL3, httpSelectValue3, DiscountCode3
    } = this.state;

    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={this.toggleToast} error={this.state.showToastIserror} /> : null;
    const loadingMarkup = this.state.loading ? <div className="loadingCenter"><Spinner size="large" color="teal" /></div> : '';

    //判断开关1
    var button = '';
    if(ButtonState === 1){
      button = <Button loading={this.state.ButtonLoading} onClick={() => this.SetUserStartupType() }>Disable</Button>;
    }else{
      button = <Button loading={this.state.ButtonLoading} primary onClick={() => this.SetUserStartupType() }>Enable</Button>;
    }

    const cardTop = this.state.loadingPage && 
    <section className="MacPreview">
        <Stack alignment="center">
          <Stack.Item fill>
            <Stack vertical={true} spacing="extraTight">
            <p>Let us send automated cart recovery notifications for you at a set time (this function will be disabled when “Abandoned Cart Recovery” is on).</p>
            <p>If you want to recall the scheduled web push, please disable this function.</p>
            </Stack>
          </Stack.Item>
          {button}
        </Stack>
    </section>

  // 底下内容根据开关展示
  var content = '';
  if(ButtonState === 1){
      content = <Layout.Section>
      
    <div className="Time-line-title">TimeLine <br />
    <div style={{fontSize:"15px",paddingTop:"2rem"}} className="total-tip">(Save either one of the web pushes will activate all three sequential web pushes.)</div> </div>
    
    <div className="m-scrollable m-scroller">
      <div className="m-timeline-2">
        <div className="m-timeline-2__items  m--padding-top-25 m--padding-bottom-30">
          <div className="m-timeline-2__item">
            <div className="m-timeline-2__item-cricle">1</div>
            <div className="m-timeline-2__item-text  m--padding-top-5">
              <div style={{ marginBottom:"30px" }}>
                <DisplayText size="large">First Web Push</DisplayText>
              </div>
              <Card primaryFooterAction={{content: 'Edit',url:"/admin/automated-cart-recovery/insert/1"}}>
                  <Card.Section>
                    <Stack wrap={false}>
                      <Avatar customer name="Farrah" />
                      <Stack vertical={true}>
                        <div className="Recovery-Cart-title">
                          <Heading>{Title1}</Heading>
                        </div>
                        <div className="Recovery-Cart-subtitle">
                          <Heading>{Message1}</Heading>
                        </div>
                        <div className="Recovery-Cart-url">
                          <TextStyle variation="subdued">{httpSelectValue1}{URL1}</TextStyle>
                        </div>
                      </Stack>
                    </Stack>
                  </Card.Section>
              </Card>
              <Card sectioned>
                <Stack>
                  <div className="Prefix-text">
                    <Heading><LanguageSwitch Words="Send" /></Heading>
                  </div>
                  <div className="Input-number">
                    <TextField
                      label=""
                      type="number"
                      value={Mins}
                      max="40"
                      min="5"
                      placeholder="5~20"
                      onChange={this.handleChange('Mins')}
                    />
                  </div>
                  <div className="Suffix-text">
                    <Heading><LanguageSwitch Words="Mins after a cart is abandoned" /></Heading>
                  </div>
                  <Stack.Item fill>
                  </Stack.Item>
                  <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(1)}>Save</Button>
                </Stack>
              </Card>
              <Card sectioned>
                <Stack  alignment="center">
                    <Heading><LanguageSwitch Words="Enter discount code" /></Heading>
                    <TextField
                      onChange={this.handleChange('DiscountCode1')}
                      value={DiscountCode1}
                      disabled={this.state.TextFieldDisabled}
                      onBlur={ () => this.updateAutomatedCartRecoveryCode(1)}
                    /> 
                    <Heading><Link url={`https://`+this.state.UserUrlName+"/admin/discounts"} external={true}>Create discount code</Link></Heading>
                </Stack>
              </Card>
              </div>
          </div>
          <div className="bigspace"></div>
          <div className="m-timeline-2__item">
            <div className="m-timeline-2__item-cricle">2</div>
            <div className="m-timeline-2__item-text  m--padding-top-5">
              <div style={{ marginBottom:"30px" }}>
                 <DisplayText size="large"><LanguageSwitch Words="Second Web Push" /></DisplayText>
              </div>
              <Card primaryFooterAction={{content: 'Edit',url:"/admin/automated-cart-recovery/insert/2"}}>
                <Card.Section>
                  <Stack wrap={false}>
                    <Avatar customer name="Farrah" />
                    <Stack vertical={true}>
                      <div className="Recovery-Cart-title">
                        <Heading>{Title2}</Heading>
                      </div>
                      <div className="Recovery-Cart-subtitle">
                        <Heading>{Message2}</Heading>
                      </div>
                      <div className="Recovery-Cart-url">
                        <TextStyle variation="subdued">{httpSelectValue2}{URL2}</TextStyle>
                      </div>
                    </Stack>
                  </Stack>
                </Card.Section>
              </Card>
              <Card sectioned>
                <Stack>
                  <div className="Prefix-text">
                    <Heading>Send</Heading>
                  </div>
                  <div className="Input-number">
                    <TextField
                      label=""
                      type="number"
                      value={Hours}
                      max="20"
                      min="2"
                      placeholder="2~20"
                      onChange={this.handleChange('Hours')}
                    />
                  </div>
                  <div className="Suffix-text">
                    <Heading>Hours after a cart is abandoned</Heading>
                  </div>
                  <Stack.Item fill>
                  </Stack.Item>
                  <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(2)}>Save</Button>
                </Stack>
              </Card>
              <Card sectioned>
                <Stack  alignment="center">
                  <Heading>Enter discount code</Heading>
                  <TextField
                    onChange={this.handleChange('DiscountCode2')}
                    value={DiscountCode2}
                    disabled={this.state.TextFieldDisabled}
                    onBlur={ () => this.updateAutomatedCartRecoveryCode(2)}
                  /> 
                  <Heading><Link url={`https://`+this.state.UserUrlName+"/admin/discounts"} external={true}>Create discount code</Link></Heading>
                </Stack>
              </Card>
            </div>
          </div>

          <div className="bigspace"></div>
          <div className="m-timeline-2__item">
            <div className="m-timeline-2__item-cricle">3</div>
            <div className="m-timeline-2__item-text  m--padding-top-5">
              <div style={{ marginBottom:"30px" }}>
                <DisplayText size="large">Third Web Push</DisplayText>
              </div>
              <Card primaryFooterAction={{content: 'Edit',url:"/admin/automated-cart-recovery/insert/3"}}>
                <Card.Section>
                  <Stack wrap={false}>
                    <Avatar customer name="Farrah" />
                    <Stack vertical={true}>
                      <div className="Recovery-Cart-title">
                        <Heading>{Title3}</Heading>
                      </div>
                      <div className="Recovery-Cart-subtitle">
                        <Heading>{Message3}</Heading>
                      </div>
                      <div className="Recovery-Cart-url">
                        <TextStyle variation="subdued">{httpSelectValue3}{URL3}</TextStyle>
                      </div>
                    </Stack>
                  </Stack>
                </Card.Section>
              </Card>
              <Card sectioned>
                <Stack>
                  <div className="Prefix-text">
                      <Heading>Send</Heading>
                  </div>
                  <div className="Input-number">
                    <TextField
                      label=""
                      type="number"
                      value={Day}
                      max="2"
                      min="1"yinliao
                      placeholder="1~2"
                      onChange={this.handleChange('Day')}
                    />
                  </div>
                  <div className="Suffix-text">
                    <Heading>Day after a cart is abandoned</Heading>
                  </div>
                  <Stack.Item fill>
                  </Stack.Item>
                  <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(3) }>Save</Button>
                </Stack>
              </Card>
              <Card sectioned>
              <Stack  alignment="center">
                <Heading>Enter discount code</Heading>
                <TextField
                  onChange={this.handleChange('DiscountCode3')}
                  value={DiscountCode3}
                  disabled={this.state.TextFieldDisabled}
                  onBlur={ () => this.updateAutomatedCartRecoveryCode(3)}
                /> 
                <Heading><Link url={`https://`+this.state.UserUrlName+"/admin/discounts"} external={true}>Create discount code</Link></Heading>
              </Stack>
            </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout.Section>
    }else{
      content = '';
    }


    return (
      <div>
      <AppProvider>
        <Frame topBar={<OnPageSEOTabs
          callback={this.callback}
        /> } >
          <Page
            breadcrumbs={[{content: 'Dashboard', url: '/admin/dashboard'}]}
            title="Automated Cart Recovery"
            // primaryAction={{content: 'Add new web push',url:"/admin/automated-cart-recovery/insert"}}
          >
            <Card sectioned>
              {loadingMarkup}
              {cardTop}
            </Card>
            
            <Layout>
              {content}
              <Layout.Section secondary>

              </Layout.Section>

            </Layout>

            {toastMarkup}

            <div className="space"></div>
            {/* <SheetExample /> */}
          </Page>
        </Frame>
      </AppProvider>
      </div>
    );
  }
}

export default dashboard;
