import React, { Component } from "react";

import {
  DisplayText,
  AppProvider,
  SkeletonDisplayText,
  SkeletonBodyText,
  Page,
  Frame,
  FormLayout,
  Card,
  Button,
  Layout,
  Stack,
  TextContainer,
  Toast
} from "@shopify/polaris";

import Switch from "react-switch";
// import {
//   SettingsMinor,CancelSmallMinor
// } from '@shopify/polaris-icons';
// import SheetExample from '../../../../../src/components/ContactUsSheetExample/SheetExample';

import WebPushBox from '../../../../../src/components/WebPushBox/index';

import {
  reqGetShipping,
  reqSetShippingState,
  reqSetSimpleShippingState
} from "../../../../api";

import "./index.css";
import OnPageSEOTabs from "../../Tabs";

import "rc-time-picker/assets/index.css";

import EditDetailModal from './modal';

import { API_DOMAIN } from "../../../../utils/constants";
import LanguageSwitch from "../../../../components/LanguageSwitch";

class dashboard extends Component {
  state = {
    productImageUrl: '/template_Product_Image.jpg',  // sent test product image
    isWindows:true,
    //弹窗
    showToast: false, //弹窗显示
    showToastText: "", //弹窗文字
    showToastIserror: false, //弹窗是否错误

    selected: 0,
    //头部数据
    clicks: 0,
    sent: 0,

    //页面动作
    loading: true,
    loadingPage: false,
    setUpdateImageBackground: "",

    setUpdateImageHeroBackground: "",
    //用户链接
    UserUrlName: "",

    //提交状态
    SubState: false,

    //保存按钮动作
    saveButton: false,

    // 页面详情所有内容 (用户选择还原数据 discard 时使用)
    initPageData: [],

    // 显示顶部保存栏
    showTopSaveBar: false,

    //总开关
    status: 0,
    Button2Loading: false,

    //变量框1
    Title1:'',
    Message1:'',
    URL1:'',
    httpSelectValue1:'',
    Button1_Title_1:'',
    Button1_Title_2:'',
    //变量框2
    Title2:'',
    Message2:'',
    URL2:'',
    httpSelectValue2:'',
    Button2_Title_1:'',
    Button2_Title_2:'',
    //变量框3
    Title3:'',
    Message3:'',
    URL3:'',
    httpSelectValue3:'',
    Button3_Title_1:'',
    Button3_Title_2:'',
    //变量框4
    Title4:'',
    Message4:'',
    URL4:'',
    httpSelectValue4:'',
    Button4_Title_1:'',
    Button4_Title_2:'',

    // 3个web push单独开关
    firstWebPushSwitch: true,
    secondWebPushSwitch: true,
    thirdWebPushSwitch: true,
    fourWebPushSwitch: true,
  };

  callback=() => {
    this.setState({})
  }
  //进入页面调用接口
  componentDidMount() {
    this.GetCreateCampaign();
  }

  componentWillUnmount() {}

  // 用户 编辑完成功保存后，将数据更新到父级页面
    // 用户 编辑完成功保存后，将数据更新到父级页面
    callBackRefresh = (type,title,message,buttonTitle1,buttonTitle2) =>{

      if(type === 1){
        this.setState({ 
          Title1: title,
          Message1: message,
          Button1_Title_1: buttonTitle1,
          Button1_Title_2: buttonTitle2,
        });
  
      } else if(type === 2) {
        this.setState({ 
          Title2: title,
          Message2: message,
          Button2_Title_1: buttonTitle1,
          Button2_Title_2: buttonTitle2,
        });
  
      } else if(type === 3) {
        this.setState({ 
          Title3: title,
          Message3: message,
          Button3_Title_1: buttonTitle1,
          Button3_Title_2: buttonTitle2,
        });
      }else if(type === 4) {
        this.setState({ 
          Title4: title,
          Message4: message,
          Button4_Title_1: buttonTitle1,
          Button4_Title_2: buttonTitle2,
        });
      }  
    }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror) => {
    this.setState({
      showToastText: showToastText,
      showToastIserror: showToastIserror
    });
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };
  // input 输入框变化
  handleChangeInput = (value, name) => {
    this.setState({
      [name]: value
    });
  };

  // 更新数据
  updatePageDetail() {
    let getData = this.state.initPageData;
    this.setState({
      status: getData.status,
      Title: getData.Title, //标题
      Message: getData.Message, //内容
      URL: getData.URL, //链接
      button1Title: getData.button1Title, //按钮1的标题
      button2Title: getData.button2Title, //按钮2的标题
      setUpdateImageBackground: getData.setUpdateImageBackground, //logo图
      setUpdateImageHeroBackground: getData.setUpdateImageHeroBackground, //大图
      sent: getData.totalSent,
      clicks: getData.totalClicks,

      //变量1
      Title1:getData.type1.Title,
      Message1:getData.type1.Message,
      URL1:getData.type1.URL,
      httpSelectValue1:getData.type1.httpSelectValue,
      Button1_Title_1:getData.type1.button1Title?getData.type1.button1Title:"",
      Button1_Title_2:getData.type1.button2Title?getData.type1.button2Title:"",
      //变量框2
      Title2:getData.type2.Title,
      Message2:getData.type2.Message,
      URL2:getData.type2.URL,
      httpSelectValue2:getData.type2.httpSelectValue,
      Button2_Title_1:getData.type2.button1Title?getData.type2.button1Title:"",
      Button2_Title_2:getData.type2.button2Title?getData.type2.button2Title:"",
      //变量框3
      Title3:getData.type3.Title,
      Message3:getData.type3.Message,
      URL3:getData.type3.URL,
      httpSelectValue3:getData.type3.httpSelectValue,
      Button3_Title_1:getData.type3.button1Title?getData.type3.button1Title:"",
      Button3_Title_2:getData.type3.button2Title?getData.type3.button2Title:"",
      //变量框4
      Title4:getData.type4.Title,
      Message4:getData.type4.Message,
      URL4:getData.type4.URL,
      httpSelectValue4:getData.type4.httpSelectValue,
      Button4_Title_1:getData.type4.button1Title?getData.type4.button1Title:"",
      Button4_Title_2:getData.type4.button2Title?getData.type4.button2Title:"",

      //更新状态
      loadingPage: true,
      loading: false,
      UserUrlName: getData.userUrl,

      // 3个web push单独开关
      firstWebPushSwitch: getData.type1.type_status?true:false,
      secondWebPushSwitch: getData.type2.type_status?true:false,
      thirdWebPushSwitch: getData.type3.type_status?true:false,
      fourWebPushSwitch: getData.type4.type_status?true:false,
    });
  }

  //获取数据
  GetCreateCampaign = async () => {
    const params = {};
    const responseData = await reqGetShipping(params);
    if (responseData.code === "200") {
      var initData = responseData.data;
      this.setState({
        initPageData: initData
      });
      this.updatePageDetail();
    }
  };

  //输入框输入
  handleChange = (field, value) => {
      return value =>
      this.setState({
        [field]: value
      }); 
  };


  //通知开关切换
  SetWidgetState = async type => {
    const params = {};
    if (type === 2) {
      this.setState({
        Button2Loading: true
      });
      const responseData = await reqSetShippingState(params);
      if (responseData.code === "200") {
        this.setState({
          //开关状态
          status: responseData.data,
          Button2Loading: false
        });
        this.toggleToast(responseData.msg, false);
      }
    }
  };

    // 单个web push 控制按钮
    handleCheckChange = (name,value)=> {
      this.setState({ 
        [name]:value,
      },()=>{
        if(value){
          this.toggleToast("Activated",false);
        }else{
          this.toggleToast("Inactivated",false);
        }
      });
  
      // 更新请求
      var updateTpye = '';
      if(name === 'firstWebPushSwitch'){
        updateTpye = '1';
      } else if(name === 'secondWebPushSwitch'){
        updateTpye = '2';
      } else if(name === 'thirdWebPushSwitch'){
        updateTpye = '3';
      }else{
        updateTpye = '4';
      }
      this.SetWebPushControl(updateTpye);
    }

    // 单个shipping开关
    SetWebPushControl = async (updateType) => {
      const params = {type:updateType};
      const responseData = await reqSetSimpleShippingState(params)
      if (responseData.code==='200') {
        if(responseData.data !== ''){
          this.toggleToast(responseData.msg,false);
        }
      } else {
        this.toggleToast(responseData.msg,true);
      }
    }


  // See Example
  WebPushSeeExample =()=>{
    this.setState({
      showExample: '',
      WebPushExample:false,
    },()=>{
      this.setState({
        showExample:  this.props.isWindows ? 'testWebPush_Show' : 'testPush_Mac_Show',
        WebPushExample: true,
        sendTestTitle: this.state.Title,
        sendTestMessage: this.state.Message,
        sendTestUrl: this.state.UserUrlName,
      });
    });

    // 显示 5秒后 自动关闭
    setTimeout(this.CloseWebPushExample,5000);
  }

  // closed web push test
  CloseWebPushExample =()=>{
    this.setState({
      showExample:'',
      WebPushExample:false,
    });
  }

  render() {
    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={this.toggleToast}
        error={this.state.showToastIserror}
      />
    ) : null;
    const {
      state: {
        firstWebPushSwitch,Title1, Message1, 
        Button1_Title_1,Button1_Title_2,
        secondWebPushSwitch,Title2, Message2, 
        Button2_Title_1,Button2_Title_2,
        thirdWebPushSwitch,Title3, Message3,
        Button3_Title_1,Button3_Title_2,
        fourWebPushSwitch,Title4, Message4,
        Button4_Title_1,Button4_Title_2,

        status,
        setUpdateImageBackground,
      },
      SetWidgetState,
    } = this;

    //判断开关3
    var button2 = "";
    if (status === 1) {
      button2 = (
        <Button
          loading={this.state.Button2Loading}
          onClick={() => SetWidgetState(2)}
          disabled={!this.state.loadingPage}
        >
          Disable
        </Button>
      );
    } else {
      button2 = (
        <Button
          loading={this.state.Button2Loading}
          primary
          onClick={() => SetWidgetState(2)}
          disabled={!this.state.loadingPage}
        >
          Enable
        </Button>
      );
    }

    const cardHeader = this.state.loading ? (
      <Stack>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
      </Stack>
    ) : (
      <FormLayout>
        <FormLayout.Group condensed>

          <div style={{padding:'2rem',boxShadow: '0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)'}}>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.sent}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="IMPRESSIONS" /></div>
              </TextContainer>
            </div>
          </div>

          <div style={{padding:'2rem',boxShadow: '0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)'}}>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.clicks}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="CLICKS" /></div>
              </TextContainer>
            </div>
          </div>

          {/* <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <Heading>{this.state.sent}</Heading>
                <Heading>IMPRESSIONS</Heading>
              </TextContainer>
            </div>
          </Card>
          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <Heading>{this.state.clicks}</Heading>
                <Heading>CLICKS</Heading>
              </TextContainer>
            </div>
          </Card> */}
        </FormLayout.Group>
      </FormLayout>
    );

    const middleContent = this.state.loadingPage ? (
       <Layout.Section>
        <div className="Time-line-title">
          <LanguageSwitch Words="Order Events" />
        </div>
        <div className="m-scrollable m-scroller">
          <div className="m-timeline-2">
            <div className="m-timeline-2__items  m--padding-top-25 m--padding-bottom-30">
              <div className="m-timeline-2__item">
                <div className="m-timeline-2__item-cricle">1</div>
                <div className="m-timeline-2__item-text  m--padding-top-5">
                  <div style={{ marginBottom:"10px" }}>
                    <Stack>
                      <Stack.Item fill>
                        <div className="webPush__Title"><LanguageSwitch Words="Payment accepted" /></div>
                      </Stack.Item>
                      <Stack.Item>
                        {/* <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(1)}>disabled</Button> */}
                        <div style={{marginTop:'10px'}}>
                          <Switch onChange={()=>this.handleCheckChange('firstWebPushSwitch',!firstWebPushSwitch)} checked={firstWebPushSwitch} height={20} width={40} uncheckedIcon={true} checkedIcon={true} onColor="#6371c7"/>
                        </div>
                      </Stack.Item>
                    </Stack>
                  </div>
                  {/* 开关控制显示 隐藏 */}
                  <div style={{display: firstWebPushSwitch?'block':'none'}}>
                  <Card>
                    <section className="webPush__SendTime" style={{padding:'2rem 2rem 0'}}>
                      <Stack>
                        <Stack.Item fill>
                        </Stack.Item>
                        <EditDetailModal callBackFunc={this.callBackRefresh} sendTestImageUrl={this.state.productImageUrl} isWindows={this.state.isWindows} editType={1} />
                        {/* <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(1)}>Edit</Button> */}
                      </Stack>
                    </section>

                    <Card.Section>
                      <WebPushBox 
                          productImageUrl={API_DOMAIN + '/Untitled.jpg?v=123'} 
                          logo={setUpdateImageBackground} 
                          title={Title1} 
                          message={Message1} 
                          url={this.state.UserUrlName} 
                          button_title_1={Button1_Title_1} 
                          button_title_2={Button1_Title_2} 
                          type={true}
                          sendTest={false}
                          noBackImg={true}
                      />
                    </Card.Section>
                  </Card>
                  </div>
                </div>
              </div>
              <div className="space"></div>
              <div className="m-timeline-2__item">
                <div className="m-timeline-2__item-cricle">2</div>
                <div className="m-timeline-2__item-text  m--padding-top-5">
                  <div style={{ marginBottom:"10px" }}>
                    <Stack>
                      <Stack.Item fill>
                        <div className="webPush__Title"><LanguageSwitch Words="Order dispatched" /></div>
                      </Stack.Item>
                      <Stack.Item>
                        <div style={{marginTop:'10px'}}>
                          <Switch onChange={()=>this.handleCheckChange('secondWebPushSwitch',!secondWebPushSwitch)} checked={secondWebPushSwitch} height={20} width={40} uncheckedIcon={true} checkedIcon={true} onColor="#6371c7"/>
                        </div>
                      </Stack.Item>
                    </Stack>
                  </div>

                  {/* 开关控制显示 隐藏 */}
                  <div style={{display: secondWebPushSwitch?'block':'none'}}>
                  <Card>
                    <section className="webPush__SendTime" style={{padding:'2rem 2rem 0'}}>
                      <Stack>
                        <Stack.Item fill>
                        </Stack.Item>
                        <EditDetailModal callBackFunc={this.callBackRefresh} sendTestImageUrl={this.state.productImageUrl} isWindows={this.state.isWindows} editType={2} />
                        {/* <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(2)}>Save</Button> */}
                      </Stack>
                    </section>

                    <Card.Section>
                      <WebPushBox 
                            productImageUrl={API_DOMAIN + '/Untitled.jpg?v=123'} 
                            logo={setUpdateImageBackground} 
                            title={Title2} 
                            message={Message2} 
                            url={this.state.UserUrlName} 
                            button_title_1={Button2_Title_1} 
                            button_title_2={Button2_Title_2} 
                            type={true}
                            sendTest={false}
                            noBackImg={true}
                        />
                    </Card.Section>
                  </Card>
                  </div>

                </div>
              </div>

              <div className="space"></div>
              <div className="m-timeline-2__item">
                <div className="m-timeline-2__item-cricle">3</div>
                <div className="m-timeline-2__item-text  m--padding-top-5">
                  <div style={{ marginBottom:"10px" }}>
                    <Stack>
                      <Stack.Item fill>
                        <div className="webPush__Title"><LanguageSwitch Words="Tracking number" /></div>
                      </Stack.Item>
                      <Stack.Item>
                        <div style={{marginTop:'10px'}}>
                          <Switch onChange={()=>this.handleCheckChange('thirdWebPushSwitch',!thirdWebPushSwitch)} checked={thirdWebPushSwitch} height={20} width={40} uncheckedIcon={true} checkedIcon={true} onColor="#6371c7"/>
                        </div>
                      </Stack.Item>
                    </Stack>
                  </div>

                  {/* 开关控制显示 隐藏 */}
                  <div style={{display: thirdWebPushSwitch?'block':'none'}}>
                  <Card>
                    <section className="webPush__SendTime" style={{padding:'2rem 2rem 0'}}>
                      <Stack>
                        <Stack.Item fill>
                        </Stack.Item>
                        <EditDetailModal callBackFunc={this.callBackRefresh} sendTestImageUrl={this.state.productImageUrl} isWindows={this.state.isWindows} editType={3} />
                        {/* <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(3)}>Save</Button> */}
                      </Stack>
                    </section>

                    <Card.Section>
                      <WebPushBox 
                          productImageUrl={API_DOMAIN + '/Untitled.jpg?v=123'} 
                          logo={setUpdateImageBackground} 
                          title={Title3} 
                          message={Message3} 
                          url={this.state.UserUrlName} 
                          button_title_1={Button3_Title_1} 
                          button_title_2={Button3_Title_2} 
                          type={true}
                          sendTest={false}
                          noBackImg={true}
                      />
                    </Card.Section>
                  </Card>
                  </div>

                </div>
              </div>
              <div className="space"></div>
              <div className="m-timeline-2__item">
                <div className="m-timeline-2__item-cricle">4</div>
                <div className="m-timeline-2__item-text  m--padding-top-5">
                  <div style={{ marginBottom:"10px" }}>
                    <Stack>
                      <Stack.Item fill>
                        <div className="webPush__Title"><LanguageSwitch Words="Refund" /></div>
                      </Stack.Item>
                      <Stack.Item>
                        <div style={{marginTop:'10px'}}>
                          <Switch onChange={()=>this.handleCheckChange('fourWebPushSwitch',!fourWebPushSwitch)} checked={fourWebPushSwitch} height={20} width={40} uncheckedIcon={true} checkedIcon={true} onColor="#6371c7"/>
                        </div>
                      </Stack.Item>
                    </Stack>
                  </div>

                  {/* 开关控制显示 隐藏 */}
                  <div style={{display: fourWebPushSwitch?'block':'none'}}>
                  <Card>
                    <section className="webPush__SendTime" style={{padding:'2rem 2rem 0'}}>
                      <Stack>
                        <Stack.Item fill>
                        </Stack.Item>
                        <EditDetailModal callBackFunc={this.callBackRefresh} sendTestImageUrl={this.state.productImageUrl} isWindows={this.state.isWindows} editType={4} />
                        {/* <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(3)}>Save</Button> */}
                      </Stack>
                    </section>

                    <Card.Section>
                      <WebPushBox 
                          productImageUrl={API_DOMAIN + '/Untitled.jpg?v=123'} 
                          logo={setUpdateImageBackground} 
                          title={Title4} 
                          message={Message4} 
                          url={this.state.UserUrlName} 
                          button_title_1={Button4_Title_1} 
                          button_title_2={Button4_Title_2} 
                          type={true}
                          sendTest={false}
                          noBackImg={true}
                      />
                    </Card.Section>
                  </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bigspace"></div>
      </Layout.Section>
    ) : (
      <div>
        <div className="space"></div>
        <Card>
          <Layout>
            <Layout.Section secondary>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
            </Layout.Section>
          </Layout>
        </Card>
      </div>
    );

    const pageContent = (
      <Page>
        <div className="space"></div>
        <Layout>
          <Layout.Section>
            <DisplayText size="Large"><LanguageSwitch Words="Shipping Notification" /></DisplayText>
            <DisplayText size="small">
              <span className="hello_sub" style={{fontSize:'1.8rem'}}>
                <LanguageSwitch Words="Notifies subscriber when the status of their fulfillment is updated" />
              </span>
            </DisplayText>
            <div className="space"></div>
          </Layout.Section>
        </Layout>

        <div className="WebPush__Control__Button">
          {button2}
        </div>
        
        {cardHeader}
        {middleContent}
      </Page>
    );
    return (
      <div className="frame_page_padding">
        <AppProvider>
          <Frame
            topBar={
              <OnPageSEOTabs
                loading={this.state.saveButton}
                saveDataParams=""
                callback={this.callback}
              />
            }
          >
            {pageContent}
            {toastMarkup}
          </Frame>
        </AppProvider>
      </div>
    );
  }
}

export default dashboard;
