import React, { Component } from "react";
import { Banner } from '@shopify/polaris';

/*
 App 公告通知
 */
class AppNotification extends Component {
  state = {
    show: this.props.show
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      show: nextProps.show
    });
  }

  componentDidMount () {}

  componentWillUnmount(){}

  handleChange = () =>{
    this.setState(({ show }) => ({ show: !show }));
  }

  render() {
    const NotificationContent = this.state.show ? (
      <div style={{paddingBottom:'1.5rem'}}>
        <Banner
          title="We are on holiday 💗"
          status="info"
          onDismiss={this.handleChange}
        >
          <p style={{marginBottom:'1rem'}}>
            From <span style={{color:"#ff6262"}}>1st October - 8th October</span>, the customer service response will be a bit delayed, and some of our engineers will also become a member of the customer service.
          </p>
          <p>We apologize for any inconvenience this may have caused.</p>
        </Banner> 
      </div>
    ) : null;

    return (
      <div>
        {NotificationContent}
      </div>
    );
  }
}

export default AppNotification;