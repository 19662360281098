import React, { Component } from "react";
import {
  FormLayout,
  Select,
  Icon,
  Button,
  TextField,
  DisplayText,
} from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons";
// import { ajax } from "../../api/ajax";
import "./index.css";
import LanguageSwitch from "../LanguageSwitch";

class SegmentRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //操作框显示状态
      showTwo: true,
      showThree: true,
      componentID: props.componentID,
      fields: props.fields,
      operate: props.operate,
      value: props.value,
      country: props.country,
      timezone: props.timezone,
      remainFieldsOptions: props.remainFieldsOptions,
      operateOptions: [],
      valueOptions: [],
      isEdit: props.isEdit,
      caseSixShowSuffix:false,
      placeholder:"",
      showHelpText:false,

    };
  }

  componentDidMount() {
    this.init(this.props.fields, this.props.operate);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      componentID: nextProps.componentID,
      fileds: nextProps.fields,
      operate: nextProps.operate,
      value: nextProps.value,
      remainFieldsOptions: nextProps.remainFieldsOptions,
    });
  }

  //初始化以及切换
  init(paramFields, paramOperate) {
    switch (paramFields) {
      case "1": //device
        this.setState(
          {
            operateOptions: [
              { label: "equals", value: "1" },
              { label: "does not equal", value: "2" },
              // { label: "includes", value: "3" },
              // { label: "does no include", value: "4" },
            ],
            valueOptions: [
              { label: "Desktop", value: "Desktop" },
              { label: "Mobile", value: "Mobile" },
              { label: "Tablet", value: "Tablet" },
            ],
            showTwo: true,
            caseSixShowSuffix:false,
          },
          () => {
            switch (paramOperate) {
              case "1":
              case "2":
                //switch
                this.setState({ showThree: true });
                break;
              case "3":
              case "4":
                //input
                this.setState({ showThree: false });
                break;
              default:
                break;
            }
          }
        );
        break;
      case "2": //operation
        this.setState(
          {
            operateOptions: [
              { label: "equals", value: "1" },
              { label: "does not equal", value: "2" },
              { label: "includes", value: "3" },
              { label: "does no include", value: "4" },
            ],
            valueOptions: [
              { label: "Android", value: "Android" },
              { label: "iOS", value: "iOS" },
              { label: "iPadOS", value: "iPadOS" },
              { label: "Windows", value: "Windows" },
              { label: "ChromeOS", value: "ChromeOS" },
              { label: "Other", value: "Other" },
            ],
            showTwo: true,
            caseSixShowSuffix:false,
          },
          () => {
            switch (paramOperate) {
              case "1":
              case "2":
                //switch
                this.setState({ showThree: true });
                break;
              case "3":
              case "4":
                //input
                this.setState({ showThree: false });
                break;
              default:
                break;
            }
          }
        );
        break;
      case "3": //country
        this.setState(
          {
            operateOptions: [
              { label: "equals", value: "1" },
              { label: "does not equal", value: "2" },
              { label: "includes", value: "3" },
              { label: "does no include", value: "4" },
            ],
            valueOptions: this.props.country,
            caseSixShowSuffix:false,
            showTwo: true,
          },
          () => {
            switch (paramOperate) {
              case "1":
              case "2":
                //switch
                this.setState({ showThree: true });
                break;
              case "3":
              case "4":
                //input
                this.setState({ showThree: false });
                break;
              default:
                break;
            }
          }
        );
        break;
      case "4": //city(暂时还没有数据 使用假数据)
        this.setState(
          {
            operateOptions: [
              { label: "equals", value: "1" },
              { label: "does not equal", value: "2" },
              { label: "includes", value: "3" },
              { label: "does no include", value: "4" },
            ],
            valueOptions: [
              { label: "beijing", value: "beijing" },
              { label: "shanghai", value: "shanghai" },
              { label: "shenzhen", value: "shenzhen" },
              { label: "guangzhou", value: "guangzhou" },
            ],
            showTwo: true,
            caseSixShowSuffix:false,
          },
          () => {
            //
            switch (paramOperate) {
              case "1":
              case "2":
                //switch
                this.setState({ showThree: true });
                break;
              case "3":
              case "4":
                //input
                this.setState({ showThree: false });
                break;
              default:
                break;
            }
          }
        );
        break;
      case "5": //timezone
        this.setState(
          {
            operateOptions: [
              { label: "equals", value: "1" },
              { label: "does not equal", value: "2" },
              { label: "includes", value: "3" },
              { label: "does no include", value: "4" },
            ],
            valueOptions: this.props.timezone,
            showTwo: true,
            caseSixShowSuffix:false,
          },
          () => {
            switch (paramOperate) {
              case "1":
              case "2":
                //switch
                this.setState({ showThree: true });
                break;
              case "3":
              case "4":
                //input
                this.setState({ showThree: false });
                break;
              default:
                break;
            }
          }
        );
        break;
      case "6": //last order
        this.setState(
          { operate: "1", showTwo: false, showThree: false,caseSixShowSuffix:true, },
          () => {
            this.changeConponent(); //特例 需要多调用一次
          }
        );
        break;
      case "7": //average order value
        this.setState({
          operateOptions: [
            { label: "is greater than", value: "5" },
            { label: "is less than", value: "6" },
          ],
          showTwo: true,
          showThree: false,
          caseSixShowSuffix:false,
        });
        break;
      case "8": //number of orders
        this.setState({
          operateOptions: [
            { label: "equals", value: "1" },
            { label: "is greater than", value: "5" },
            { label: "is less than", value: "6" },
          ],
          showTwo: true,
          showThree: false,
          caseSixShowSuffix:false,
        });
        break;
      case "9": //latest order had coupon
        this.setState({
          showTwo: false,
          operateOptions: [
            { label: "true", value: true },
            { label: "false", value: false },
          ],
          valueOptions: [
            { label: "true", value: true },
            { label: "false", value: false },
          ],
          caseSixShowSuffix:false,
          showThree: true,
        });
        break;
      default:
        break;
    }
  }

  //输入框输入
  handleChange = (value, field) => {
    this.setState(
      {
        [field]: value,
      },
      () => {
        if (field === "fields") {
          this.init(value, this.state.operate);
          //切换之后 对应的value 换为 对应的第一项的值
          switch (value) {
            case "1":
              this.setState({ value: "Desktop",placeholder: "",showHelpText:false}, () => {
                this.changeConponent();
              });
              break;
            case "2":
              this.setState({ value: "Android",placeholder: "eg:Android,iOS,iPadOS",showHelpText:true}, () => {
                this.changeConponent();
              });
              break;
            case "3":
              this.setState({ value: this.props.country[0].value,placeholder: "eg:United States,China,Russia",showHelpText:true}, () => {
                this.changeConponent();
              });
              break;
            case "4":
              this.setState({ value: "beijing",placeholder: "eg:Beijing,Shanghai",showHelpText:true}, () => {
                this.changeConponent();
              });
              break;
            case "5":
              this.setState({ value: this.props.timezone[0].value,placeholder: "eg:Africa/Abidjan,Africa/Addis_Ababa",showHelpText:true}, () => {
                this.changeConponent();
              });
              break;
            case "6":
              this.setState({ value: 1,placeholder: "",showHelpText:false}, () => {
                this.changeConponent();
              });
              break;
            case "7":
              this.setState({ value: 0,placeholder: "",showHelpText:false}, () => {
                this.changeConponent();
              });
              break;
            case "8":
              this.setState({ value: 0,placeholder: "",showHelpText:false}, () => {
                this.changeConponent();
              });
              break;
            case "9":
              this.setState({ value: true,placeholder: "",showHelpText:false}, () => {
                this.changeConponent();
              });
              break;
            default:
              break;
          }
        }
        if (field === "operate") {
          this.init(this.state.fields, value);
          //根据字段以及操作方式更换placeholder提示
          switch (this.state.fields) {
            case "2":
              this.setState({ placeholder: "eg:Android,iOS,iPadOS",showHelpText:true});
              //修改内容
              this.changeConponent();
              break;
            case "3":
              this.setState({ placeholder: "eg:United States,China,Russia",showHelpText:true});
              //修改内容
              this.changeConponent();
              break;
            case "4":
              this.setState({ placeholder: "eg:Beijing,Shanghai",showHelpText:true});
              //修改内容
              this.changeConponent();
              break;
            case "5":
              this.setState({ placeholder: "eg:Africa/Abidjan,Africa/Addis_Ababa",showHelpText:true});
              //修改内容
              this.changeConponent();
              break;
              default:
                this.setState({ placeholder: "",showHelpText:false});
              break;
            }
        }
      }
    );
  };

  saveThisData = () => {
    this.props.deleteComponent(this.state.componentID);
  };

  //传回给组件
  changeConponent = async () => {
    this.props.changeComponent({
      id: this.props.componentID,
      fields: this.state.fields,
      operate: this.state.operate,
      value: this.state.value,
    });
  };

  render() {
    const {
      state: { operate, value, remainFieldsOptions },
    } = this;
    const selectOne = (
      <Select
        disabled={this.props.isEdit}
        label={<LanguageSwitch Words="Select a Option" />}
        id="fields"
        value={this.state.fields}
        onChange={this.handleChange}
        options={remainFieldsOptions}
      />
    );
    const selectTwo = this.state.showTwo ? (
      <Select
        disabled={this.props.isEdit}
        label={<LanguageSwitch Words="Select a Operation" />}
        id="operate"
        value={operate}
        onChange={this.handleChange}
        options={this.state.operateOptions}
      />
    ) : (
      <div style={{ textAlign: "center", paddingTop: "2rem" }}>
        <DisplayText size="medium">Equal</DisplayText>
      </div>
    );
    const selectThree = this.state.showThree ? (
      <Select
        disabled={this.props.isEdit}
        label={<LanguageSwitch Words="Options value" />}
        id="value"
        value={value}
        onChange={this.handleChange}
        options={this.state.valueOptions}
      />
    ) : (
          <TextField
            disabled={this.props.isEdit}
            id="value"
            label="Options value"
            onChange={this.handleChange}
            value={value}
            suffix={this.state.caseSixShowSuffix ? "days ago" : ""}
            placeholder={this.state.placeholder?this.state.placeholder:""}
            helpText={this.state.showHelpText?'Separated Options by ","':""}
          />
    );
    const deleteIcon = this.props.isEdit ? (
      ""
    ) : (
      <div style={{ paddingTop: "2.3rem" }} >
        <Button onClick={this.saveThisData}>
          <Icon source={DeleteMinor} color="inkLighter" />
        </Button>
      </div>
    );
    return (
      <FormLayout.Group condensed>
        {selectOne}
        {selectTwo}
        {selectThree}
        {deleteIcon}
      </FormLayout.Group>
    );
  }
}

export default SegmentRule;
