import React, { Component } from 'react';
import { Card, Stack, Thumbnail, Avatar } from '@shopify/polaris';
// import { SettingsMinor ,CancelSmallMinor } from '@shopify/polaris-icons';

import './index.css';
/*
 WebPush 推送弹窗 预览模板
 */

class WebPushBox extends Component {

    constructor(props){
        super(props);
        
        this.state = {
            Webpush_Logo: props.logo,
            Webpush_Title: props.title,
            Webpush_Message: props.message,
            Webpush_Url: props.url,
            Webpush_Button_Title1: props.button_title_1,
            Webpush_Button_Title2: props.button_title_2,
            Webpush_ProductImageUrl: props.productImageUrl,
            
            isWindows: props.type,
            sendTest: props.sendTest,

            // 不需要显示大背景图，设置为true，默认为false
            noBackImg:props.noBackImg?props.noBackImg:false,
        }
    }

    //进入页面调用接口 (传入组件数据发生变化时 ，接受新数据重新渲染)
    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            Webpush_Title: nextProps.title,
            Webpush_Message: nextProps.message,
            Webpush_Url: nextProps.url,
            Webpush_Button_Title1: nextProps.button_title_1,
            Webpush_Button_Title2: nextProps.button_title_2,
            Webpush_ProductImageUrl: nextProps.productImageUrl,
        });
    }
    
    render() {

        const { Webpush_Logo, Webpush_Title ,Webpush_Message ,Webpush_Url, Webpush_Button_Title1, Webpush_Button_Title2, Webpush_ProductImageUrl , isWindows, sendTest,noBackImg} = this.state;

        // 弹窗按钮判断显示
        var buttonShowBool = false;
        var button1ShowBool = false;
        var button2ShowBool = false;
        var buttonShow = '';

        if(Webpush_Button_Title1 !== undefined || Webpush_Button_Title2 !== undefined){

            if(Webpush_Button_Title1 !== '' && Webpush_Button_Title2 !==''){
                button1ShowBool = true;
                button2ShowBool = true;

            } else if(Webpush_Button_Title1 !== '' && Webpush_Button_Title2 ===''){
            
                buttonShowBool = true;
                button1ShowBool = true;
            } else if(Webpush_Button_Title1 === '' && Webpush_Button_Title2 !==''){
                buttonShowBool = true;
                button2ShowBool = true;
            }

            if(button1ShowBool && button2ShowBool){
                buttonShow = <section style={{display:'flex',textAlign:'center',marginTop:'-1.5rem',paddingLeft:'1rem'}} >
                <div style={{backgroundColor:'#6b6b6b',marginRight:'5px',width:'50%',padding:'4px 5px',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'clip'}}>{Webpush_Button_Title1}</div>
                <div style={{backgroundColor:'#6b6b6b',width:'50%',padding:'4px 5px',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'clip'}}>{Webpush_Button_Title2}</div>
            </section>
            }
            if(buttonShowBool && button1ShowBool){
                buttonShow = <section style={{display:'flex',textAlign:'center',marginTop:'-1.5rem',paddingLeft:'1rem'}} >
                <div style={{backgroundColor:'#6b6b6b',width:'100%',padding:'4px 5px',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'clip'}}>{Webpush_Button_Title1}</div>
                </section>
            }

            if(buttonShowBool && button2ShowBool){
                buttonShow = <section style={{display:'flex',textAlign:'center',marginTop:'-1.5rem',paddingLeft:'1rem'}} >
                <div style={{backgroundColor:'#6b6b6b',width:'100%',padding:'4px 5px',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'clip'}}>{Webpush_Button_Title2}</div>
                </section>
            }
        }

        // 产品图显示判断，sendTest 显示真实产品图，反之显示默认图
        var productImageShow = '';
        if(!noBackImg){
            if(sendTest){
                productImageShow = <div style={{textAlign:'center'}}>
                    <img src={Webpush_ProductImageUrl} style={{maxWidth:'100%',maxHeight:'150px'}} alt="Abandoned Cart Web Push" />
                    <div className="testPush_Win_Image_Help_Text" >
                        Hero Image is generated automatically.
                    </div>
                </div>
    
            } else {
                productImageShow = <div style={{textAlign:'center',margin:'-1rem',background:'url('+Webpush_ProductImageUrl+') center top / 100% no-repeat',backgroundSize:"100% auto",height:'175px'}}>
                    <div style={{color:'#000',top:'70%',position:'relative',fontSize:'18px',userSelect:'none'}}>Hero Image is generated automatically.</div>
                </div>
            }
        }

        if(isWindows){
            return (
                <section className="testPush_Win_Preview" style={{ display:'block',maxWidth:"370px",backgroundColor:"white"}}>
                    <Card>
                        <div style={{padding:'1rem',backgroundColor:'#333',color:'#fff'}}>
                            <Stack  vertical={true}>
                                {/* <Stack wrap={false}>
                                    <Stack.Item fill>
                                        <div className="Webpush_Url_Icon">
                                            <Stack wrap={false}>
                                                <Avatar source='/Google_Chrome_Icon.svg' />
                                                <div style={{paddingBottom:'.5rem'}}>{Webpush_Url}</div>
                                            </Stack>
                                        </div>
                                    </Stack.Item>
                                    <Stack.Item>
                                    <div className="testWebPush_Win_Icon">
                                            <Stack>
                                                <Icon source={SettingsMinor} />
                                                <Icon source={CancelSmallMinor} />
                                            </Stack>
                                        </div>
                                    </Stack.Item>
                                </Stack> */}

                                {productImageShow}
                                
                                <section style={{padding:'1rem 1rem'}}>
                                    <Stack wrap={false}>
                                        <section style={{paddingTop:'.5rem'}}>
                                            <Thumbnail
                                                source={Webpush_Logo}
                                                size="small"
                                                alt="Black choker necklace"
                                            />
                                        </section>
                                        <Stack vertical={true}>
                                        <section className="Message_Title" style={{paddingRight:'6rem'}}><span className="">{Webpush_Title}</span></section>
                                        <section className="Message_Content" style={{paddingRight:'7rem',paddingTop:'.5px',marginTop:'-5px',lineHeight:'1.5'}}>
                                            <div className="Polaris-Stack__Itemclass">
                                                <Stack vertical={true}>
                                                    <span>{Webpush_Message}</span>
                                                </Stack>
                                            </div>
                                            <div className="Polaris-Stack__Itemclass">
                                                <div style={{fontSize:'13px',color:'#999'}}> {'Google Chrome · '+Webpush_Url}</div>
                                            </div>
                                        </section>
                                        </Stack>
                                    </Stack>
                                </section>

                                {buttonShow}

                            </Stack>
                            
                        </div>
                    </Card>
                </section>
            );

        } else {  // mac

            return (
                <section className="testPush_Mac_Preview" style={{ display:'block',right:"15px",top:"30px" }}>
                    <section className="testPush_Mac_Preview_Card">
                    <Card>
                        <Stack alignment="center">
                        <section style={{marginLeft:"1rem"}}>
                            <Avatar customer name="Farrah" source="/mac_chrome.iso.f3b6c54c.svg"/>
                        </section>
                        <Stack.Item fill>
                            <Stack vertical={true} spacing="extraTight">
                            <div className="testPush_Mac__Message_Title">
                                { Webpush_Title
                                .substring(0,26)
                                }{(Webpush_Title.length>26)?'...':''}
                            </div>
                            <span className="testPush_Mac__Message_Content">{ Webpush_Url }</span>
                            <div className="testPush_Mac__Message_Content">
                                { Webpush_Message
                                .substring(0,26)
                                }{(Webpush_Message.length>26)?'...':''}
                            </div>
                            </Stack>
                        </Stack.Item>

                        <Thumbnail
                            source={Webpush_Logo}
                            alt="Abandoned Cart Recovery"
                        />

                        <div className="testPush_Mac_Icon">
                        <Card>
                            <Card.Section>
                               Close
                            </Card.Section>
                            <Card.Section>
                               Settings
                            </Card.Section>
                        </Card>
                        </div>
                        </Stack>
                    </Card>
                    </section>
                </section>
            );

        }

    }


}

export default WebPushBox;