import React, { Component } from 'react';

import {AppProvider , TextContainer , Layout , Page , Heading , Frame} from '@shopify/polaris';

import './index.css';



class PrivacyPolicy extends Component {

  state = {
  };

  render() {

    return (
      <div className="privacypolicypage">
      <AppProvider>
        <Frame >
          <Page
            // breadcrumbs={[{content: 'Dashboard', url: '/admin/dashboard'}]}
            title="Abandoned Cart Web Push- Privacy Policy"
          >
            <div className="space"></div>
            <Layout>
              <Layout.Section>
                <div className="privacypolicy">
                <TextContainer>
                  <p>
                  Abandoned Cart Web Push "the App” provides web push notification Services "the Service" to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected and used when you install or use the App in connection with your Shopify-supported store.
                </p>
                </TextContainer>
                <div className="space"></div>
                <TextContainer>
                 <Heading>Merchants Information the App Collects</Heading>
                  <p>
                  When you install the App, we are automatically able to access the following personal information from your Shopify account once you have installed the App: Information about you and others who may access the App on behalf of your store, such as your name, location, email address, phone number, and billing information. Additionally, we will collect Information, such as products, product listings and collections on your Online Store, script tags in your Online Store and theme.
                  </p>
                </TextContainer>

                <div className="space"></div>
                <TextContainer>
                 <Heading>How Do We Use Merchants Information?</Heading>
                  <p>
                  We use the personal information we collect from you in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information relating to our services. We use the product and other information to provide merchants the services state above.
                   </p>
                </TextContainer>


                <div className="space"></div>
                <TextContainer>
                 <Heading>For Merchants in Europe</Heading>
                  <p>
                  Your Rights If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                  </p>
                  <p>
                  Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you, or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.

                  </p>
                  <p>
                  Data Retention When you install the App, we will maintain your Information for our records unless and until you uninstall the App, a request will be sent to the App to delete this data.
                  </p>
                  <p>
                  Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                  </p>
                  <p>
                  Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@giraffly.com
                  </p>
                </TextContainer>

                </div>
              </Layout.Section>
            </Layout>
      
            <div className="bigspace"></div>

          </Page>
        </Frame>
      </AppProvider>
      </div>
    );
  }
}

export default PrivacyPolicy;
