import {ajax ,ajaxWithoutAuth} from './ajax';
import {API_DOMAIN} from '../utils/constants';

/** 登录模块 */

export const ckeditorConfig = {
      heading: {
          options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
              { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
              // { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
              // { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
          ]
      },
      ckfinder: {
          uploadUrl: API_DOMAIN + '/api/v1/ckUpload'
      }
  };

export const InstallAppUrl = 'https://apps.shopify.com/seo-master-1?surface_detail=seo&surface_inter_position=2&surface_intra_position=9&surface_type=search';  // 安装app界面

// 登录授权路由接口
export const reqLogin = (params) => ajax(API_DOMAIN + '/login', params, 'POST');

export const reqGetPricingPlans = (params) => ajax(API_DOMAIN + '/api/v1/getPricingPlans', params, 'GET'); // 获取用户当前套餐，以及所有套餐
export const reqChangePlans = (params) => ajax(API_DOMAIN + '/api/v1/changePlans', params, 'POST'); // 获取用户当前套餐，以及所有套餐
export const reqPostManualUninstall = (params) => ajax(API_DOMAIN + '/api/v1/manualUninstall', params, 'POST'); // 用户手动卸载APP

export const reqSaveSearchConsoleAuthData = (params) => ajax(API_DOMAIN + '/api/v1/save-search-console-auth-data', params, 'POST'); // 保存Google 授权成功的数据
export const reqGetSearchConsoleSiteData = (params) => ajax(API_DOMAIN + '/api/v1/get-search-console-site-data', params, 'GET'); // 保存Google 授权成功的数据
export const reqGoogleSearchConsoleAuth = (params) => ajaxWithoutAuth('https://accounts.google.com/o/oauth2/token', params, 'POST'); // Google Search Consoe 授权请求

/** Dashboard 模块 */
export const reqgetAppDashboardChartData = (params) => ajax(API_DOMAIN + '/api/v1/getAppDashboardChartData', params, 'GET'); // 获取 APP 首页chart数据
export const reqGetAppDashboardData = (params) => ajax(API_DOMAIN + '/api/v1/getAppDashboardData', '', 'GET'); // 获取 APP 首页数据
export const reqUpdateOnBoardingStatus = (params) => ajax(API_DOMAIN + '/api/v1/updateOnBoardingStatus', params, 'POST'); // 更新 onboard 客户指引状态
export const reqGetProductImage = (params) => ajax(API_DOMAIN + '/api/v1/getProductImage', '', 'GET'); // 获取 用户一张产品图片，用于send test展示


/** Abandoned Cart Recovery模块 */
export const reqGetAbandonedCartRecovery = (params) => ajax(API_DOMAIN + '/api/v1/GetAbandonedCartRecovery', '', 'GET'); // 获取当前用户的购物车弹窗数据（所有）

export const reqSetUserStartupType = (params) => ajax(API_DOMAIN + '/api/v1/SetUserStartupType', params, 'POST'); // 开关是否订阅请求
export const reqGetAbandonedUserUrlName = (params) => ajax(API_DOMAIN + '/api/v1/GetAbandonedUserUrlName/'+params.type, '', 'GET'); // 获取购物车定义链接
export const reqInsertAbandonedCartRecovery = (params) => ajax(API_DOMAIN + '/api/v1/InsertAbandonedCartRecovery', params, 'POST'); // 添加购物车挽留弹窗
export const reqInsertAbandonedCartRecoveryImage = (params) => ajax(API_DOMAIN + '/api/v1/InsertAbandonedCartRecoveryImage', params, 'POST'); // 购物车挽留窗口添加图片

// Abandoned Cart Recovery V2 模块 (完成之后再移除其他无用的)
export const reqSetWebPushControl = (params) => ajax(API_DOMAIN + '/api/v1/setWebPushControl', params, 'POST'); // 推送弹窗 开启关闭
export const reqGetAbandonedCartRecoveryTemplate = (params) => ajax(API_DOMAIN + '/api/v1/GetAbandonedCartRecoveryTemplate/'+params.type, '', 'GET'); // 获取当前用户的购物车弹窗数据（所有）

export const reqSaveTemplateData = (params) => ajax(API_DOMAIN + '/api/v1/saveTemplateData', params, 'POST'); // 用户选择推送模板 并点击保存 将模板数据保存到当前编辑的 web push 中
export const reqUpdateAllWebPush = (params) => ajax(API_DOMAIN + '/api/v1/updateAllWebPush', params, 'POST'); // 顶部导航栏 保存 [ 同时保存 3 个webPush的数据 ]



/** Automated Cart Recovery 模块 */
export const reqGetAutomatedCartRecovery = (params) => ajax(API_DOMAIN + '/api/v1/GetAutomatedCartRecovery', '', 'GET'); // 获取当前用户的购物车弹窗数据（所有）(自动)
export const reqGetAutomatedUserUrlName = (params) => ajax(API_DOMAIN + '/api/v1/GetAutomatedUserUrlName/'+params.type, '', 'GET'); // 获取购物车定义链接 (自动)
export const reqInsertAutomatedCartRecovery = (params) => ajax(API_DOMAIN + '/api/v1/InsertAutomatedCartRecovery', params, 'POST'); // 添加购物车挽留弹窗 (自动)
export const reqInsertAutomatedCartRecoveryImage = (params) => ajax(API_DOMAIN + '/api/v1/InsertAutomatedCartRecoveryImage', params, 'POST'); // 购物车挽留窗口添加图片 (自动)
export const requpdateAutomatedCartRecoveryTime = (params) => ajax(API_DOMAIN + '/api/v1/updateAutomatedCartRecoveryTime', params, 'POST'); // 单个修改时间
export const requpdateAutomatedCartRecoveryCode = (params) => ajax(API_DOMAIN + '/api/v1/updateAutomatedCartRecoveryCode', params, 'POST'); // 单个修改优惠卷


/** Create Campaign 模块 */
export const reqGetCreateCampaign = (params) => ajax(API_DOMAIN + '/api/v1/GetCreateCampaign/'+params.type, '', 'GET'); // 获取当前用户的自定义弹窗数据（所有）
export const reqGetUserUrlName = (params) => ajax(API_DOMAIN + '/api/v1/GetUserUrlName/'+params.id, '', 'GET'); // 获取自定义用户链接
export const reqInsertCreateCampaign = (params) => ajax(API_DOMAIN + '/api/v1/InsertCreateCampaign', params, 'POST'); // 添加自定义弹窗
export const reqDeleteCreateCampaign = (params) => ajax(API_DOMAIN + '/api/v1/DeleteCreateCampaign', params, 'POST'); // 删除自定义弹窗
export const reqInsertCreateCampaignImage = (params) => ajax(API_DOMAIN + '/api/v1/InsertCreateCampaignImage', params, 'POST'); // 自定义窗口添加图片


/* Automation 模块 */
// Automation 首页 
export const reqGetFunctionalStatus = (params) => ajax(API_DOMAIN + '/api/v1/getFunctionalStatus', params, 'GET'); //获取 app 各个功能模块开启关闭状态

//welcome
export const reqSetWelcomeState = (params) => ajax(API_DOMAIN + '/api/v1/setWelcomeState', params, 'POST'); // 修改WelcomeNotification 功能开关
export const reqGetWelcomeNotification = (params) => ajax(API_DOMAIN + '/api/v1/getWelcomeNotification', params, 'GET'); //获取WelcomeNotification
export const reqUpdateWelcomeNotification = (params) => ajax(API_DOMAIN + '/api/v1/updateWelcomeNotification', params, 'POST'); //修改WelcomeNotification数据

//stock in push
export const reqGetStockSubscribeProduct = (params) => ajax(API_DOMAIN + '/api/v1/getStockSubscribeProduct', params, 'GET'); //获取StockPush待补充库存产品
export const reqGetStockPush = (params) => ajax(API_DOMAIN + '/api/v1/getStockPush', params, 'GET'); //获取StockPush
export const reqUpdateStockPush = (params) => ajax(API_DOMAIN + '/api/v1/updateStockPush', params, 'POST'); //修改StockPush数据
export const reqSetStockState = (params) => ajax(API_DOMAIN + '/api/v1/setStockState', params, 'POST'); //修改StockPush总开关
export const reqSetStockWidgetState = (params) => ajax(API_DOMAIN + '/api/v1/setStockWidgetState', params, 'POST'); //修改StockPushwidget开关数据
export const reqSetStockWidgetContent = (params) => ajax(API_DOMAIN + '/api/v1/setStockWidgetContent', params, 'POST'); //修改StockPush数据

//dropalert
export const reqGetPriceSubscribeProduct = (params) => ajax(API_DOMAIN + '/api/v1/getPriceSubscribeProduct', params, 'GET'); //获取PriceDrop待降价产品
export const reqGetPriceDrop = (params) => ajax(API_DOMAIN + '/api/v1/getPriceDrop', params, 'GET'); //获取PriceDrop编辑页面数据
export const reqUpdatePriceDrop = (params) => ajax(API_DOMAIN + '/api/v1/updatePriceDrop', params, 'POST'); //修改PriceDrop编辑页面数据
export const reqSetPriceState = (params) => ajax(API_DOMAIN + '/api/v1/setPriceState', params, 'POST'); //修改PriceDrop总开关
export const reqSetPriceWidgetState = (params) => ajax(API_DOMAIN + '/api/v1/setPriceWidgetState', params, 'POST'); //修改PriceDropwidget开关数据
export const reqSetPriceWidgetContent = (params) => ajax(API_DOMAIN + '/api/v1/setPriceWidgetContent', params, 'POST'); //修改PriceDropWidget数据

//shipping
export const reqGetShipping = (params) => ajax(API_DOMAIN + '/api/v1/getShipping', params, 'GET'); //获取Shipping首页数据
export const reqGetSimpleShipping = (params) => ajax(API_DOMAIN + '/api/v1/getSimpleShipping', params, 'GET'); //获取单个Shipping弹窗数据
export const reqSetupdateShipping = (params) => ajax(API_DOMAIN + '/api/v1/updateShipping', params, 'POST'); //修改单个Shipping弹窗数据
export const reqSetShippingState = (params) => ajax(API_DOMAIN + '/api/v1/setShippingState', params, 'POST'); //修改Shipping总开关
export const reqSetSimpleShippingState = (params) => ajax(API_DOMAIN + '/api/v1/setSimpleShippingState', params, 'POST'); //修改单个Shipping开关


/** Segements 模块 */
export const reqGetAllSegements = (params) => ajax(API_DOMAIN + '/api/v1/getAllSegements', '', 'GET'); // 获取当前用户的自定义segments（所有）
export const reqGetSegement = (params) => ajax(API_DOMAIN + '/api/v1/getSegement',params,'GET'); // 获取当前用户的自定义segment（单个）
export const reqUpdateSegement = (params) => ajax(API_DOMAIN + '/api/v1/updateSegement', params, 'POST'); // 添加/修改自定义segment
export const reqDeleteSegement = (params) => ajax(API_DOMAIN + '/api/v1/deleteSegement', params, 'POST'); // 删除自定义segment
export const reqAutoGetSubscriber = (params) => ajax(API_DOMAIN + '/api/v1/autoGetSubscriber', params, 'POST'); // 动态获取订阅人数


/** Settings 模块 */
export const reqGetUserSettings = (params) => ajax(API_DOMAIN + '/api/v1/GetUserSettings', '', 'GET'); // 获取当前用户设置信息
export const reqSetSecond = (params) => ajax(API_DOMAIN + '/api/v1/SetSecond', params, 'POST'); // 保存设置的秒速
export const reqSetSubscribeState = (params) => ajax(API_DOMAIN + '/api/v1/SetSubscribeState', params, 'POST'); // 开关是否订阅请求
export const reqSetStartState = (params) => ajax(API_DOMAIN + '/api/v1/SetStartState', params, 'POST'); // 开关是否启动请求
export const reqSetWidgetState = (params) => ajax(API_DOMAIN + '/api/v1/SetWidgetState', params, 'POST'); // 通知开关
export const reqSetWidgetContent = (params) => ajax(API_DOMAIN + '/api/v1/SetWidgetContent', params, 'POST'); // 通知数据

export const reqSetContent = (params) => ajax(API_DOMAIN + '/api/v1/SetContent', params, 'POST'); // 保存数据



/* ContactUs 联系我们模板 */

export const reqGetThisUserEmail = (params) => ajax(API_DOMAIN + '/api/v1/getThisUserEmail', params, 'GET'); //获取Settings数据
export const reqInsertContactUs = (params) => ajax(API_DOMAIN + '/api/v1/insertContactUs', params, 'POST'); //修改Settings数据
export const reqGetSettingInfo = (params) => ajax(API_DOMAIN + '/api/v1/getSettingInfo', params, 'GET'); //获取用户信息


// 翻译模块
export const reqGetSelectLanguage = (params) => ajax(API_DOMAIN + '/api/v1/getSelectLanguage', '', 'GET'); // 获取当前选择语言
export const reqUpdateLanguage = (params) => ajax(API_DOMAIN + '/api/v1/updateLanguage', params, 'POST'); // 获取当前选择语言

