import React, { Component } from 'react';
import './index.css'
/*
 DiscountCode 推送弹窗 预览(带背景的)
 */

class DiscountCodePreview extends Component {

    constructor(props){
        super(props);

        this.state = {
            Discount_Code: props.code,
            Discount_Title: props.title,
            Discount_Message: props.message,
            Discount_Button: props.button,
            Discount_Position: props.position,

            ShowDiscountCode: 'none',
            ShowButton: 'inline-block',
        }
    }

    //进入页面调用接口 (传入组件数据发生变化时 ，接受新数据重新渲染)
    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            Discount_Code: nextProps.code,
            Discount_Title: nextProps.title,
            Discount_Message: nextProps.message,
            Discount_Button: nextProps.button,
            Discount_Position: nextProps.position,
            ShowDiscountCode: 'none',
            ShowButton: 'inline-block',
        });
    }
    
    // 控制显示 box / discount code
    showHandleChange = (type)=>{
        if(type==='box'){
            var boxObject = document.getElementById('mydiscode');
            if(boxObject.style.display === 'block'){
                boxObject.style.display = 'none';
            }else{
                boxObject.style.display = 'block';
            }

        }else{
            if(this.state.Discount_Code !== ''){
                this.setState({
                    ShowDiscountCode: 'block',
                    ShowButton: 'none',
                },()=>{
                    this.copyText();
                });
            }else{
                return false;
            }
        }
    }

    copyText() {
        let textArea = document.createElement('textArea');
        textArea.innerHTML = this.state.Discount_Code;
        document.body.appendChild(textArea);

        try {
            if (document.execCommand('Copy')) {
                // alert("Copy success!");
                this.showCopyStatus("Copy success!", true);
                
            } else {
                // alert("Copy failed!");
                this.showCopyStatus("Please copy manually!", false);
            }
        } catch (err) {
            // alert("Copy failed!");
            this.showCopyStatus("Please copy manually!", false);
        }

        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    showCopyStatus(text,isSuccess) {
        var copyTextDiv = document.getElementById("Giraffly-Copy-Success");
        copyTextDiv.innerHTML = text;
        if(isSuccess){
            copyTextDiv.style.color = "#50b83c";
        }else{
            copyTextDiv.style.color = "#de3618";
        }
        copyTextDiv.style.display = "block";
        
        setTimeout(function(){
            copyTextDiv.style.display = "none";
        },3000);
    }


    render() {

        const { Discount_Code ,Discount_Title ,Discount_Message ,Discount_Button ,Discount_Position , ShowDiscountCode,ShowButton} = this.state;
        var PositionClass = '';
        if(Discount_Position === '1'){
            PositionClass = 'Position_Left';
        }else if(Discount_Position === '2'){
            PositionClass = 'Position_Center';
        } else{
            PositionClass = 'Position_Right';
        }

        return (
            <section className={"Discount_Preview " + PositionClass}>
                <div className="Discount_Preview__Box">
                                
                    <div style={{background: '#343434',display:'flex',borderTopLeftRadius: '5px',borderTopRightRadius: '5px',cursor: 'pointer'}}>
                        <div id="mydiscodeaction" style={{margin: '8px',float:'left',height: '22px',width: '25px',background: '#fff',position: 'absolute',borderRadius:'3px'}}>
                            <div style={{color:'#000',position:'absolute',marginLeft:'5px',marginTop:'10px',width:'15px',height:'3px',backgroundColor:'currentColor'}}></div>
                        </div>
                        <div style={{color: '#fff',fontSize: '1.5rem',margin:'9px',float:'left',paddingLeft:'30px'}}>
                            {Discount_Title}
                        </div>
                        <div style={{clear:'both'}}></div>
                    </div>

                    <div id="mydiscode" style={{textAlign: 'center',padding: '3rem 1rem 1rem',display:'block'}}>
                        

                        {/* <div style={{background:'#666',width:'8rem',height:'8rem',margin: '0 auto 10px',borderRadius:'5rem'}}>
                            <div style={{background: '#666',width: '6rem',height: '6rem',borderRadius: '4rem',position: 'relative',left: '1.1rem',top: '1rem',border: '2px dashed #fff'}}>
                                <div style={{color: '#fff',fontSize: '32px',display: 'inline-block',lineHeight: '5rem',paddingLeft:'.3rem'}}>
                                %
                                </div>
                                <div style={{fontSize: '10px !important',display: 'inline-block',color: '#fff',lineHeight: '6rem'}}>
                                OFF
                                </div>
                            </div>
                        </div> */}

                        <div>
                            <img src='/Discount-Code-Sale.png?v=20191129' alt="Discount-Code" style={{maxWidth:'100%',maxHeight:'115px'}} />
                        </div>


                        <div style={{margin:'12px 10px 18px 10px',fontSize:'14px'}}>
                            { Discount_Message }
                        </div>

                        <div style={{cursor: 'pointer',padding: '0.4rem',border: '2px dashed #666',margin: '1rem',display: ShowDiscountCode}}>
                            {Discount_Code}
                        </div>

                        <div style={{
                        background: 'rgb(219, 29, 29)',
                        marginBottom: '1rem',
                        fontWeight: 'normal',
                        fontSize: '15px',
                        borderRadius: '5px',
                        padding: '6px 26px',
                        // display:'inline-block',
                        whiteSpace:'nowrap',
                        color:'#fff',
                        cursor: 'pointer',
                        display: ShowButton
                        }} onClick={this.showHandleChange.bind(this,'code')} >
                        {Discount_Button}
                        </div>

                        <div id="Giraffly-Copy-Success" style={{display: 'none', fontSize: '13px'}}></div>

                    </div>
                </div>
                <div className="Discount-preview--windows__time"><div>{this.props.getDateFunction}</div><div>{this.props.getTimeFunction}</div></div>
            </section>
            
        );
    }

}

export default DiscountCodePreview;