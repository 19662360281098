import React, { Component } from "react";

import {
  AppProvider,
  Card,
  Layout,
  Select,
  Page,
  Heading,
  Button,
  Frame,
  Stack,
  // Link,
  TextField,
  FormLayout,
  Toast,
  Badge,
  Thumbnail,
  SkeletonDisplayText,
  SkeletonBodyText,
  TextContainer
} from "@shopify/polaris";

// import SheetExample from "../../../../src/components/ContactUsSheetExample/SheetExample";
import "./index.css";
import OnPageSEOTabs from "../Tabs";

import {
  reqGetUserSettings,
  reqSetSecond,
  reqSetSubscribeState,
  reqSetStartState,
  reqSetWidgetState,
  reqSetContent,
  reqSetWidgetContent
} from "../../../api";

import { API_DOMAIN } from "../../../utils/constants";
import LanguageSwitch from "../../../components/LanguageSwitch";
class dashboard extends Component {
  state = {
    //弹窗
    showToast: false, //弹窗显示
    showToastText: "", //弹窗文字
    showToastIserror: false, //弹窗是否错误

    //页面动作
    loading: true,
    loadingPage: false,

    //开关状态
    subscribeState: 0,
    //启动状态
    startState: 0,
    //通知状态
    widgetState: 0,

    //下拉框
    selected: "0",
    //下拉框状态
    selectedState: false,

    setUpdateImageHeroBackground: API_DOMAIN + "/Imageimg.png",

    //输入框
    Title: "",
    Description: "",
    AcceptButton: "",
    DeclineButton: "",
    Message: "",
    WidgetAcceptButton: "",

    //按钮 滚动
    Button1Loading: false,
    Button2Loading: false,
    Button3Loading: false,
    SaveLoading: false,
    SaveWidgetLoading: false,

    // New Widgets Setting
    shopLogo: '',
    new_widgetDelayTime: '10',
    new_widgetTitle: 'One more important step before you keep browsing',
    new_widgetDescription: 'Get push notifications about the limited time special offers and discount code.',
    new_widgetAcceptButton: 'Allow',
    new_widgetDeclineButton: 'No',
  };

  //进入页面调用接口
  componentDidMount() {
    //开始调用获取数据
    this.GetUserSettings();
  }

  callback=() => {
    this.setState({})
  }
  //获取数据
  GetUserSettings = async () => {
    const params = {};
    const responseData = await reqGetUserSettings(params);
    if (responseData.code === "200") {
      this.setState({
        loadingPage: true,
        loading: false,
        //开关状态
        subscribeState: responseData.data.subscribe_state,
        //启动状态
        startState: responseData.data.start_state,
        //通知状态
        widgetState: responseData.data.widget_state,
        //下拉框
        selected: responseData.data.set_second,
        //输入框
        Title: responseData.data.title,
        Description: responseData.data.description,
        AcceptButton: responseData.data.accept_button,
        DeclineButton: responseData.data.decline_button,
        Message: responseData.data.message,
        WidgetAcceptButton: responseData.data.widget_accept_button,
        shopLogo: responseData.data.shopLogo,
        new_widgetDelayTime: responseData.data.delayTime,
        new_widgetTitle: responseData.data.widgetTitle,
        new_widgetDescription: responseData.data.widgetDescription,
        new_widgetAcceptButton: responseData.data.widgetAcceptButton,
        new_widgetDeclineButton: responseData.data.widgetDeclineButton,
      });
    }
  };

  //提示弹窗
  toggleToast = (showToastText, showToastIserror) => {
    this.setState({
      showToastText: showToastText,
      showToastIserror: showToastIserror
    });
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

  //下拉框
  selectHandleChange = async newValue => {
    this.setState({ selectedState: true });
    const params = { selected: newValue };
    const responseData = await reqSetSecond(params);
    if (responseData.code === "200") {
      this.setState({
        selected: newValue,
        selectedState: false
      });
      this.toggleToast(responseData.msg, false);
    }
  };


  //输入框输入
  handleChange = (field, value) => {
    return value => this.setState({ [field]: value });
  };

  //请求开关
  SetSubscribeState = async () => {
    this.setState({
      Button1Loading: true
    });
    const params = {};
    const responseData = await reqSetSubscribeState(params);
    if (responseData.code === "200") {
      this.setState({
        //开关状态
        subscribeState: responseData.data,
        Button1Loading: false
      });
      this.toggleToast(responseData.msg, false);
    }
  };

  //启动开关
  SetStartState = async () => {
    this.setState({
      Button2Loading: true
    });
    const params = {};
    const responseData = await reqSetStartState(params);
    if (responseData.code === "200") {
      this.setState({
        //开关状态
        startState: responseData.data,
        Button2Loading: false
      });
      this.toggleToast(responseData.msg, false);
    }
  };

  //通知开关切换
  SetWidgetState = async () => {
    this.setState({
      Button3Loading: true
    });
    const params = {
      Message: this.state.Message,
      WidgetAcceptButton: this.state.WidgetAcceptButton
    };
    const responseData = await reqSetWidgetState(params);
    if (responseData.code === "200") {
      this.setState({
        //开关状态
        widgetState: responseData.data,
        Button3Loading: false
      });
      this.toggleToast(responseData.msg, false);
    }
  };

  //通知数据保存
  SetWidgetContent = async () => {
    this.setState({
      SaveWidgetLoading: true
    });
    const params = {
      Message: this.state.Message,
      WidgetAcceptButton: this.state.WidgetAcceptButton
    };
    const responseData = await reqSetWidgetContent(params);
    if (responseData.code === "200") {
      this.setState({
        //开关状态
        SaveWidgetLoading: false
      });
      this.toggleToast(responseData.msg, false);
    }
  };

  // save new Widget 保存数据
  saveNewWidgetContent = async () => {
    this.setState({
      SaveWidgetLoading: true
    });
    const params = {
      isNew: 1,
      new_widgetDelayTime: this.state.new_widgetDelayTime,
      new_widgetTitle: this.state.new_widgetTitle,
      new_widgetDescription: this.state.new_widgetDescription,
      new_widgetAcceptButton: this.state.new_widgetAcceptButton,
      new_widgetDeclineButton: this.state.new_widgetDeclineButton,
    };
    const responseData = await reqSetWidgetContent(params);
    if (responseData.code === "200") {
      this.setState({
        //开关状态
        SaveWidgetLoading: false
      });
      this.toggleToast(responseData.msg, false);
    }
  };

  //保存数据
  SetContent = async () => {
    this.setState({
      SaveLoading: true
    });
    const params = {
      Title: this.state.Title,
      Description: this.state.Description,
      AcceptButton: this.state.AcceptButton,
      DeclineButton: this.state.DeclineButton
    };
    const responseData = await reqSetContent(params);
    if (responseData.code === "200") {
      this.setState({
        SaveLoading: false
      });
      this.toggleToast(responseData.msg, false);
    }
  };

  render() {
    const {
      state: {
        widgetState,


        shopLogo,
        new_widgetDelayTime,
        new_widgetTitle,
        new_widgetDescription,
        new_widgetAcceptButton,
        new_widgetDeclineButton,
      },
      handleChange,
      SetWidgetState,

      saveNewWidgetContent
    } = this;


    //判断开关3
    var button3 = "";
    if (widgetState === 1) {
      button3 = (
        <Button
          loading={this.state.Button3Loading}
          onClick={() => SetWidgetState()}
        >
          Disable
        </Button>
      );
    } else {
      button3 = (
        <Button
          loading={this.state.Button3Loading}
          primary
          onClick={() => SetWidgetState()}
        >
          Enable
        </Button>
      );
    }

    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={this.toggleToast}
        error={this.state.showToastIserror}
      />
    ) : null;

    const loadingMarkup = this.state.loading ? (
      <div>
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </div>
    ) : (
      ""
    );

    // New Widget Setting
    const NewWidgetSetting = this.state.loadingPage && (
      <div>
        <Card.Section>
          <Stack distribution="equalSpacing">
            <Heading>Widget</Heading>
            {button3}
          </Stack>
        </Card.Section>
        <Card.Section>
          <Layout>
            <div style={{padding:'2rem 2rem 3rem'}}>
              <p style={{lineHeight:'2.2rem'}}>
                <LanguageSwitch Words="Widget" />
                <Badge status="info"><LanguageSwitch Words="Step 1" /></Badge> <LanguageSwitch Words="displays with some delay time will allow users to have a glance at your website first before the Auto Prompt" />
                <Badge status="info"><LanguageSwitch Words="Step 2" /></Badge> <LanguageSwitch Words="shows up. Using 2 steps opt-in will give your visitors enough context for them to easily accept the permission." />
              </p>
            </div>
          </Layout>

          <Layout>
            <Layout.Section secondary>
              <FormLayout>
                <Select
                  label={<LanguageSwitch Words="Widget Delay" />}
                  options={[
                    { label: "5 seconds", value: "5" },
                    { label: "8 seconds", value: "8" },
                    { label: "10 seconds", value: "10" },
                    { label: "15 seconds", value: "15" },
                    { label: "30 seconds", value: "30" }
                  ]}
                  onChange={handleChange("new_widgetDelayTime")}
                  value={new_widgetDelayTime}
                  helpText={<LanguageSwitch Words="When trigger the widget to show" />}
                />
                <TextField
                  label={<LanguageSwitch Words="Title" />}
                  onChange={handleChange("new_widgetTitle")}
                  value={new_widgetTitle}
                />
                <TextField
                  label={<LanguageSwitch Words="Description" />}
                  onChange={handleChange("new_widgetDescription")}
                  value={new_widgetDescription}
                />
                <TextField
                  label={<LanguageSwitch Words="Accept Button" />}
                  onChange={handleChange("new_widgetAcceptButton")}
                  value={new_widgetAcceptButton}
                />
                <TextField
                  label={<LanguageSwitch Words="Decline Button" />}
                  onChange={handleChange("new_widgetDeclineButton")}
                  value={new_widgetDeclineButton}
                />
                
                <div style={{ float: "right", marginBottom: "2rem" }}>
                  <Button
                    primary
                    loading={this.state.SaveWidgetLoading}
                    onClick={() => saveNewWidgetContent()}
                  >
                    <LanguageSwitch Words="Save" />
                  </Button>
                </div>
              </FormLayout>
            </Layout.Section>

            <Layout.Section secondary>
              <section>
                <div style={{marginBottom:'1rem'}}>
                  <Badge status="info"><LanguageSwitch Words="Step 1" /></Badge>
                </div>
                <Card sectioned>

                <Stack wrap={false}>
                    <section style={{paddingTop:'1.5rem'}}>
                        <Thumbnail
                            source={shopLogo}
                            size="small"
                            alt="Black choker necklace"
                        />
                    </section>
                    <Stack vertical={true}>
                      <section className="Message_Title" style={{paddingRight:'6rem'}}>
                        <Heading>{new_widgetTitle}</Heading>
                      </section>
                      <section style={{color:'#637381',paddingRight:'7rem',paddingTop:'.5px',marginTop:'-5px',lineHeight:'1.5'}}>
                        <p>{new_widgetDescription}</p>
                      </section>
                       
                    </Stack>
                </Stack>

                <div style={{ marginTop: "1rem" }}>
                   <Stack>
                      <Stack.Item fill>
                      </Stack.Item>
                      <Stack.Item>
                      <div className="Preview_Decline">
                        <Button>{new_widgetDeclineButton}</Button>
                      </div>
                      </Stack.Item>
                      <Stack.Item>
                      <div className="Preview_Accept">
                        <Button primary>{new_widgetAcceptButton}</Button>
                      </div>
                      </Stack.Item>
                    </Stack>
                  </div>
                </Card>
                
                <div style={{marginTop:'2rem',marginBottom:'1rem'}}>
                  <Badge status="info"><LanguageSwitch Words="Step 2" /></Badge>
                </div>

                <div style={{ marginBottom: "0" }}>
                  <div
                    className="Preview_Image_a"
                    style={{
                      backgroundImage:
                        "url(" + this.state.setUpdateImageHeroBackground + ")"
                    }}
                  ></div>
                </div>
              </section>
            </Layout.Section>

          </Layout>
        </Card.Section>
      </div>
    );

    const pagetitle = this.state.loading ? (
      <TextContainer>
        <SkeletonDisplayText size="small" />
      </TextContainer>
    ):(
        <LanguageSwitch Words="Widgets" />
    ) ; 

    return (
      <div>
        <AppProvider>
          <Frame topBar={<OnPageSEOTabs
            callback={this.callback}
          />}>
            <Page
              // breadcrumbs={[{ content: "Dashboard", url: "/admin/dashboard" }]}
              title={pagetitle}
            >
              <Layout>
                <Layout.Section>
                  {/* <Card>
                    {loadingMarkup}
                    {pageTop}
                  </Card> */}
                  {/* <Card>
                    {loadingMarkup}
                    {pageMiddle}
                  </Card> */}
                  {/* <Card>
                    {loadingMarkup}
                    {pageBottom}
                  </Card> */}

                  <Card>
                    {loadingMarkup}
                    { NewWidgetSetting }
                  </Card>
                </Layout.Section>
              </Layout>
              {toastMarkup}
              <div className="space"></div>
              {/* <SheetExample /> */}
            </Page>
          </Frame>
        </AppProvider>
      </div>
    );
  }
}

export default dashboard;
