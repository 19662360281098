import { Component } from 'react';
import WordsArray from "./WordsArray";
class LanguageSwitch extends Component {
    state = {
        Language: 'en',
        //文字
        Words: this.props.Words,
    };

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            Language : localStorage.getItem('SELECT_LANGUAGE')
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        try {
            let language = localStorage.getItem('SELECT_LANGUAGE');
            if(WordsArray[language][this.state.Words] !== WordsArray[prevState.Language][this.state.Words] || this.state.Language!==prevState.Language || this.state.Words!==prevState.Words) {
                this.setState({
                    Language: localStorage.getItem('SELECT_LANGUAGE')
                })
            }
        }catch (e) {
            // console.log(Words)
            return this.state.Words;
        }
        // 会无限调用接口
        // a = setTimeout(()=>{
        //     this.setState({
        //         Language: localStorage.getItem('SELECT_LANGUAGE')
        //     })
        // },100)
        // 延迟获取,防止更新请求更新缓存动作还未结束就开始获取
        // setTimeout(()=> {
        //     let language = localStorage.getItem('SELECT_LANGUAGE');
        //     if(WordsArray[language][this.state.Words] !== WordsArray[prevState.Language][this.state.Words] || this.state.Language!==prevState.Language || this.state.Words!==prevState.Words) {
        //         this.setState({
        //             Language: localStorage.getItem('SELECT_LANGUAGE')
        //         })
        //     }
        // }, 100)

    }
    render() {
        let { Words,Language } = this.state;
        if(Words) {
            try {
                // return WordsArray[Language][Words] ? WordsArray[Language][Words] : [console.log(Words),Words];
                return WordsArray[Language][Words] ? WordsArray[Language][Words] : Words;
            }catch (e){
                // 不存在的字词就会报错
                // console.log(Words)
                return Words;
            }

        }
    }
}


export default LanguageSwitch;