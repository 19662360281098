import React, { Component } from 'react';

import {TextContainer ,  Modal  , TextStyle  , List} from '@shopify/polaris';


import './index.css';


class UpgradeModel extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            upgradeModalShow:false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        
    }

    openModal = ()=>{
        this.setState({
             upgradeModalShow:true
        });
     }

    closeModal = ()=>{
       this.setState({
            upgradeModalShow:false
       });
    }
    
    render() {
        const { show,button,button1 } = this.props;
        const lastButton  = show?<div onClick={this.openModal}>{button1}</div>:button;

        return (
        <div>
            {lastButton}
            <Modal
            open={this.state.upgradeModalShow}
            onClose={this.closeModal}
            title="This feature in only enabled on a advanced plan"
            primaryAction={{
                content: 'Upgrade Now',
                // onAction: this.closeModal,
                url:'/admin/pricing'
            }}
            // secondaryActions={[
            //     {
            //     content: 'Not Now',
            //     onAction: this.closeModal,
            //     },
            // ]}
            >
            <Modal.Section>
                <TextContainer>
                    <div className="UpgradeModalContainer">
                        {/* <div className="UpgradeModalHeader">
                            <DisplayText size="medium" element="h1">This feature is only enabled on the premium plan</DisplayText>
                        </div> */}
                        <div className="UpgradeModalContainerhalfleft">
                            <img style={{width:"100%"}} src="/list-is-empty-3-300x225.png" alt="" />
                        </div>
                        <div className="UpgradeModalContainerhalfright">
                            <div className="space"></div>
                            <p style={{'fontWeight': 'bold','paddingBottom':'1.5rem'}}>Advanced features include:</p>
                           <p>
                              <span className="allfeatures">✔</span>
                              <span >All Basic Features</span>
                          </p>
                          <List type="bullet">
                              {/* <List.Item>Unlimited Subscribers</List.Item>
                              <List.Item>Abandoned Cart Automation</List.Item>
                              <List.Item>Hero Image inside the push</List.Item>
                              <List.Item>Discount code widget</List.Item>
                              <List.Item>Desktop + Mobile Web Push</List.Item>
                              <List.Item>Promotion Campaign</List.Item>
                              <List.Item>Web Push Report</List.Item>
                              <List.Item>Widgets</List.Item> */}
                              <List.Item>Send push at subscriber’s local time </List.Item>
                          </List>
                        </div>
                        <div className="UpgradeModalText">
                            <TextStyle variation="subdued" >Upgrade to a advanced version of Abandoned Cart Web Push to have more monthly impression limit and to send push at subscriber's local time.</TextStyle>
                        </div>
                    </div>
                </TextContainer>
            </Modal.Section>
          </Modal>
        </div>
        );
    }
}


export default UpgradeModel;