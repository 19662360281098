import React, { Component } from 'react';

import {AppProvider,  Layout , Page, Button } from '@shopify/polaris';

// import OnPageSEOTabs from '../Tabs';
// https://cdn.shopifycloud.com/web/assets/6933fb5939cd87752c68648f5db4d540.svg
import notFoundImg from './images/6933fb5939cd87752c68648f5db4d540.svg';
import './index.css';

class PageNotFound extends Component {

  render() {

    const pageContent = <div>
        <Layout>
          <Layout.Section oneThird>

              <div className="page-not-found">
                  <div className="not-found-title">
                      The page you’re looking for couldn’t be found
                  </div>
                  <div className="not-found-msg">
                      Check the web address and try again, or try navigating to the page from Abandoned Cart Web Push Home.
                  </div>
                  <Button primary size="large" url="/admin/dashboard">Go to Abandoned Cart Web Push Home</Button>
              </div>

          </Layout.Section>

          <Layout.Section oneThird>
              <div className="not-img">
                <img src={notFoundImg} alt="page not found" className="not-found-img"></img>
              </div>
          </Layout.Section>
        </Layout></div>;
    
    return (
      <AppProvider>
          <Page>
            { pageContent }
          </Page>
      </AppProvider>
    );
  }
}

export default PageNotFound;
