import React from 'react'
import './index.css'
/*

item 样式组件

 */
export default function InlineItem(props) {
    const name = props.name;
    const value = props.value;
    const isheader = props.isheader;
    const licon = props.licon;
    const ricon = props.ricon;
    console.log();

    if(isheader>0){
      return <div className="inlineitem ">
        <span className="inline left isheader itemicon">
          {licon}
        </span>
        <span className="inline left isheader">
            {name}
        </span>
        <span className="inline right isheader">
            {value}
        </span>
        <span className="inline right isheader itemicon">
          {ricon}
        </span>
      </div>
    }else{
      return <div className="inlineitem">
        <span className="inline left itemicon">
          {licon}
        </span>
        <span className="inline left">
            <b>{value}</b>{name}
        </span>
        <span className="inline right">
            {/* {value} */}
        </span>
        <span className="inline right itemicon">
          {ricon}
        </span>
      </div>
    }

    

    
}



