import React, { Component } from 'react';

import {AppProvider,  Layout , Page, Button } from '@shopify/polaris';

// import OnPageSEOTabs from '../Tabs';
import errorImg from './images/500-internal-server-error.png';
import './index.css';

class Error extends Component {

  render() {

    const pageContent = <div>
        <Layout>

          <Layout.Section oneThird>
              <div className="not-img">
                <img src={errorImg} alt="page not found" className="error-img"></img>
              </div>
          </Layout.Section>

          <Layout.Section oneThird>
              <div className="page-error">
                  <div className="not-found-title">
                      Sorry, Internal Server Error
                  </div>
                  <div className="not-found-msg">
                      Please try navigating to the page from Abandoned Cart Web Push Home and try again.
                  </div>
                  <Button primary size="large" url="/admin/dashboard">Go to Abandoned Cart Web Push Home</Button>
              </div>

          </Layout.Section>
        </Layout></div>;
    
    return (
      <AppProvider>
          <Page>
            { pageContent }
          </Page>
      </AppProvider>
    );
  }
}

export default Error;
