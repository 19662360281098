import React, { Component } from 'react';

import { Layout ,   FooterHelp , Link ,  Button,Sheet,Heading,FormLayout,TextField,Scrollable,Spinner,Toast ,TextStyle} from '@shopify/polaris';
import { MobileCancelMajorMonotone } from '@shopify/polaris-icons';

import { reqGetThisUserEmail,reqInsertContactUs } from '../../api';

import utils from '../../utils/utils'

class SheetExample extends Component {
    state = {
        sheetActive: false,
        name:'',
        email: '',
        title: '',
        description: "",
        
        //弹窗
        showToast: false,  //弹窗显示
        showToastText:'',  //弹窗文字
        showToastIserror:false, //弹窗是否错误

        //页面动作
        loading: true,
        loadingPage: false,

        Buttonloading:false
        
    };

    
  //进入页面调用接口
  componentDidMount () {
    if(this.props.onRef){ // 判断是否onref 属性来调用子组件的方法
        this.props.onRef(this)
    }
    this.getEamilList()
  }

  //获取邮箱
  getEamilList = async () => {
    const params = {};
    const responseData = await reqGetThisUserEmail(params)
    if (responseData.code==='200') {
        this.setState({ 
          email:responseData.data.email,
          loadingPage : true,
          loading : false,
        })
    }
    this.setState({
        name:utils.getStore('S_SHOP_OWNER')
    });
  }

    
    handleOpenSheet = () => {
        this.setState({sheetActive: true});
    };

    handleCloseSheet = () => {
        this.setState({sheetActive: false});
    };
    
  //点击保存
    handleCloseSheetInsert = () => {
        if(this.state.email === ''){
            this.toggleToast("Email should not be empty",true);
            return ''
        }else if(this.state.name === ''){
            this.toggleToast("Name should not be empty",true);
            return ''
        }else if(this.state.title === ''){
            this.toggleToast("Title should not be empty",true);
            return ''
        }else if(this.state.description === ''){
            this.toggleToast("Description should not be empty",true);
            return ''
        }
        //调用修改数据
        this.updateEamilList()
    };

    //修改邮箱按钮
    updateEamilList = async () => {
        this.setState({ 
            Buttonloading:true,
        })
        const params = {name:this.state.name,email:this.state.email,title:this.state.title,description:this.state.description};
        const responseData = await reqInsertContactUs(params)
        if (responseData.code==='200') {
            this.toggleToast(responseData.msg,false);
        }else{
            this.toggleToast(responseData.msg,true);
        }
        this.setState({ 
            Buttonloading:false,
            sheetActive: false
        })
        
    }

    
    //提示弹窗
    toggleToast = (showToastText,showToastIserror) => {
        this.setState({
        showToastText:showToastText,
        showToastIserror:showToastIserror
        });
        this.setState(({ showToast }) => ({ showToast: !showToast }));
    };



    handleToggleSheet = () => {
        const {
        state: {sheetActive},
        handleCloseSheet,
        handleOpenSheet,
        } = this;

        sheetActive ? handleCloseSheet() : handleOpenSheet();
    };

    handleChange = (field) => {
        return (value) => this.setState({[field]: value});
    };
    
  render() {
    const loadingMarkup = this.state.loading ? <div className="loadingCenter"><Spinner size="large" color="teal" /></div> : '';

    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={this.toggleToast} error={this.state.showToastIserror} /> : null;

    const {
      state: { sheetActive,name,title,description,email },
      handleCloseSheet,
      handleToggleSheet,
      handleChange,
      handleCloseSheetInsert
    } = this;

    const pageContent = this.state.loadingPage && <div style={{ display: 'flex', flexDirection: 'column',height: '100%'}} >
            <div style={{ alignItems: 'center', borderBottom: '1px solid #DFE3E8', display: 'flex', justifyContent: 'space-between',padding: '1.6rem',width: '100%'}}>
            <Heading>Contact Us</Heading>
            <Button
                accessibilityLabel="Cancel"
                icon={MobileCancelMajorMonotone}
                onClick={handleCloseSheet}
                plain
            />
            </div>
            <Scrollable style={{padding: '1.6rem', height: '100%'}}>
                <FormLayout>
                    <TextField
                        label="Name"
                        onChange={handleChange('name')}
                        value={name}
                    />  
                    <TextField
                        label="Email"
                        onChange={handleChange('email')}
                        value={email}
                    />  
                    <TextField
                        label="Title"
                        onChange={handleChange('title')}
                        value={title}
                    />    
                    <TextField
                        label="Message"
                        onChange={handleChange('description')}
                        value={description}
                        multiline
                    />
                </FormLayout>
            </Scrollable>
            <div style={{padding: '1.6rem 1.6rem 0.5rem 1.6rem'}}>
             <TextStyle variation="subdued">Thanks for reaching out. We will reply you within 1 business day.</TextStyle>
            </div>
            <div style={{ alignItems: 'center', borderTop: '1px solid #DFE3E8',display: 'flex',  padding: '1.6rem',justifyContent: 'space-between',width: '100%'}}>
            <Button onClick={handleCloseSheet}>Cancel</Button>
            <Button primary loading={this.state.Buttonloading} onClick={handleCloseSheetInsert}>
                Send
            </Button>
            </div>
        </div>

    return (
      <div>
        <Sheet open={sheetActive} onClose={handleCloseSheet}>
            { loadingMarkup }
            { pageContent }
        </Sheet>
         <Layout>
            <Layout.Section>
              <FooterHelp>Need help ? <Link onClick={handleToggleSheet}>Contact Us</Link>.</FooterHelp>
            </Layout.Section>
          </Layout>
          { toastMarkup }
      </div>
    );
  }
}


export default SheetExample;