import React, { useState,} from 'react';
import {
  Card, Thumbnail,Button
} from "@shopify/polaris";

import './index.css';
import LanguageSwitch from "../LanguageSwitch";
export default function AppsItem(props) {

  // const itemArray = props.items;

  // 当前 APP (跳转安装链接需要用到)
  const currentApp = 'web-push-by-giraffly';

  // 设置默认 显示3个 show less
  const initShowNumber = 4;
  const [value] = useState(true);
  // const handleChange = useCallback((newValue) => setValue(newValue), []);

  const seoMaster_IMG = '/app_SEOMaster.png';
  const brokenLink_IMG = '/app_broken_link.png';
  // const webPush_IMG = '/app_webPush.png';
  const stickyCart_IMG = '/app_sticky_cart.png';
  // const trustMe_IMG = '/app_trust_me.png';
  const pageSpeed_IMG = '/app_pageSpeed.png';
  // const gdpr_IMG = '/app_gdpr.png';
  // const localSEO_IMG = '/app_local_seo.png';

  const itemList = [
    {
      id: 1,
      url: 'https://apps.shopify.com/seo-master?surface_detail='+currentApp,
      title: 'SEO Master ‑ Image Alt Text',
      pricing: '7-day free trial',
      description: 'Meta Edit + Broken Links + On-Page SEO + JSON-LD',
      icon: seoMaster_IMG,
      show: true
    },
    {
      id: 2,
      url: 'https://apps.shopify.com/broken-link-manager?surface_detail='+currentApp,
      title: 'Broken Link Master‑404Redirect',
      pricing: 'Free plan available',
      description: 'Bulk Edit 404 Errors + Automated 301 Redirect + Suggest URL',
      icon: brokenLink_IMG,
      show: true
    },
    {
      id: 3,
      url: 'https://apps.shopify.com/page-speed-boost?surface_detail='+currentApp,
      title: 'Page Speed + SEO Image Optimizer',
      pricing: 'Free plan available',
      description: 'Image Compression,Image Alt text, PNG to JPG. Page Speed Boost',
      icon: pageSpeed_IMG,
      show: true
    },
    // {
    //   id: 4,
    //   url: 'https://apps.shopify.com/abandoned-cart-web-push?surface_detail='+currentApp,
    //   title: 'Smart Web Push Notifications',
    //   pricing: 'Free plan available',
    //   description: 'Abandoned Cart Recovery - Win Your Customers Back',
    //   icon: webPush_IMG,
    //   show: true
    // },
    {
      id: 5,
      url: 'https://apps.shopify.com/sticky-cart-and-sticky-add-to-cart-button?surface_detail='+currentApp,
      title: 'Sticky Add to Cart + Animation',
      pricing: 'Free plan available',
      description: 'Sticky Cart to Boost Sales+Quick Buy, Time Urgency & Mini Cart',
      icon: stickyCart_IMG,
      show: true
    },
    // {
    //   id: 6,
    //   url: 'https://apps.shopify.com/trust-badge-by-giraffly?surface_detail='+currentApp,
    //   title: 'Trust Me ‑ Free Trust Badges',
    //   pricing: 'Free',
    //   description: 'FREE! Colour Badges + Mobile Responsive + No Code Needed',
    //   icon: trustMe_IMG,
    //   show: true
    // },
    // {
    //   id: 7,
    //   url: 'https://apps.shopify.com/gdpr-bar?surface_detail='+currentApp,
    //   title: 'EU Cookie Bar ‑ GDPR Consent',
    //   pricing: 'Free',
    //   description: 'Easy GDPR Cookie, Free EU Cookie Banner, Cookie Consent',
    //   icon: gdpr_IMG,
    //   show: true
    // },
    // {
    //   id: 8,
    //   url: 'https://apps.shopify.com/local-seo?surface_detail='+currentApp,
    //   title: 'Local SEO ‑ Free',
    //   pricing: 'Free',
    //   description: "Boost your local business with rich snippet on search result",
    //   icon: localSEO_IMG,
    //   show: true
    // },
    
  ];


  // show more / less 处理
  let needShowItem = [];
  if(value){
    let count = 0;
    for(let i=0; i < itemList.length; i++){
      if(count === initShowNumber){
        break;
      }
      if(itemList[i].show === true){
        needShowItem.push(itemList[i]);
        count ++;
      }
    }
  } else{
    needShowItem = itemList;
  }

  const listItems = needShowItem.map((item) =>{
      if(item.show){
        let li_Item = '';
        li_Item = <li className="App__item" key={item.id}>
            <a href={item.url} className="App__action" aria-disabled="false" target="_blank" rel="noopener noreferrer">
              <div className="App__media">
                <Thumbnail
                  source={item.icon}
                  alt={item.title}
                />
              </div>
              <div>
                <p className="App__title">
                  {item.title}
                </p>
                {/* <p className="App__pricing">
                  {item.pricing}
                </p> */}
                <p className="App__description">
                  {item.description}
                </p>
              </div>
          </a>
          <div className="viewButton" style={{margin:"auto"}}>
              <Button url={item.url} external>View</Button>
          </div>
        </li>
        return [
          li_Item
        ];

      }else{
        return ''
      }
    }
  );

  return (
    <section>
    <div>
      {/* <div className="App-collection">
        <Stack>
          <Stack.Item fill>
            <div className="App-collection__heading">
              <Heading>Checkout our other awesome apps</Heading>
            </div>
          </Stack.Item>
          
          <Stack.Item>
            <div className="App-collection__more-link">
              <Link onClick={()=>handleChange(!value)} >{value ? "Show more" : "Show less"}</Link>
            </div>
          </Stack.Item>
        </Stack>
      </div> */}
{/* 
      <Card title="">
        <div className="App_Item_Box">
          <nav>
            <ul className="App_area">
                {listItems}
            </ul>
          </nav>
        </div>
      </Card> */}

      <Card title={<LanguageSwitch Words="Try one of these top recommended apps to help your business" />}>
        <Card.Section>
          {listItems}
        </Card.Section>
      </Card>
    </div>
    </section>
  );
}