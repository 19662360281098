// const WordsArray = {
//     "en":{
//         'Top Countries': 'en_word',
//         'More Web Push Automation': 'en_word',
//         'More push notifications options for your subscrbers, to increase your conversion rate and get more sales.': 'en_word',
//         'Web Push Impression Limit': 'en_word',
//         'If you want to get more impression limits, please upgrade to a more advanced plan.': 'en_word',
//         'One has to subscribe your website before you can send push notifications to them. The opt-in process is simple as follow:': 'en_word',
//         '1. Once a user clicks "allow" on the browser prompt. He/She will be subscribed to your web push notifications and receive push notifications from your site afterward.': 'en_word',
//         '2. In Abandoned Cart Web Push, you can either choose to enable the "Abandoned Cart Recovery" feature and let the App does the rest for you or craft your own web push title and message and set the "time-to-send" yourself.': 'en_word',
//         '3. Your subscribers do not need to be on your website to receive your push. As long as they open their browsers, they will receive your push in real-time.': 'en_word',
//         '4. Once they click on the push notification, they will be taken to either your cart or checkout page to complete the order.': 'en_word',
//         'Total Subscribers': 'en_word',
//         'Total Campaigns': 'en_word',
//         'Total Revenue': 'en_word',
//         'Recent Compains': 'en_word',
//         'Hi': 'en_word',
//         'Welcome': 'en_word',
//         'Here’s what’s happening with your web push notifications.': 'en_word',
//         'What is Smart Web Push Notifications?': 'en_word',
//         'Smart Web push notifications are notifications that delivered on a user’s desktop or mobile screen anytime they have their browser open - regardless of whether or not the user is on the website.': 'en_word',
//         'According to a study, there is a': 'en_word',
//         '15% lift in revenue': 'en_word',
//         'from': 'en_word',
//         'Abandoned Cart Web Push Notifications.': 'en_word',
//         'Easy Opt-In for Users': 'en_word',
//         'No Email Address Required': 'en_word',
//         'Higher Opt-In Rate': 'en_word',
//         'Less Opt-Out Rate': 'en_word',
//         'Higher CTR': 'en_word',
//         'Higher Conversion Rate': 'en_word',
//         'How Abandoned Cart Web Push works?': 'en_word',
//         '1. Users subscribe to your web push notifications via browser prompt.': 'en_word',
//         '2. Your send out the push notifications.': 'en_word',
//         '3. Subscribers receive push notifications on their browser.': 'en_word',
//         '4. Subscribers click on your push notifications and land on your website.': 'en_word',
//         'The appearance of the web push notification varies from the operating system and browser to browser in which the message is displayed.': 'en_word',
//         'Logo': 'en_word',
//         '64px x 64px; Less than 1MB; Accepts .jpg, .jpeg, .png.': 'en_word',
//         'Previous': 'en_word',
//         'Continue': 'en_word',
//         'See Example': 'en_word',
//         'Done': 'en_word',
//         'No data to show': 'en_word',
//         'Active': 'en_word',
//         'Inactive': 'en_word',
//         'CAMPAIGNS': 'en_word',
//         'IN QUEUE': 'en_word',
//         'IMPRESSION': 'en_word',
//         'Duplicate': 'en_word',
//         'Completed': 'en_word',
//         'Scheduled': 'en_word',
//         'sent': 'en_word',
//         'clicks': 'en_word',
//         'CTR': 'en_word',
//         'Campaign': 'en_word',
//         'Create Campaign': 'en_word',
//         'Content action buttons 1 url: Enter a valid website URL. eg: yourstore.com': 'en_word',
//         'Content action buttons 2 url: Enter a valid website URL. eg: yourstore.com': 'en_word',
//         'Chrome': 'en_word',
//         'Subscribers’ local time': 'en_word',
//         '450px x 200px; Less than 2MB; Accepts jpg, jpeg, png.': 'en_word',
//         'Send now': 'en_word',
//         'At subscribed time': 'en_word',
//         'Abandoned Cart Web Push': 'en_word',
//         'Back In Stock Push': 'en_word',
//         'Total Impressions': 'en_word',
//         'Total Clicks': 'en_word',
//         'A notification is sent to the new subscribers': 'en_word',
//         'Welcome Notification': 'en_word',
//         'First Web Push title is required': 'en_word',
//         'First Web Push message is required': 'en_word',
//         'First Web Push URL is required': 'en_word',
//         'First Web Push send time is required': 'en_word',
//         'First Web Push send time is required, time range : 5~59': 'en_word',
//         'First Web Push Discount Code is required': 'en_word',
//         'Second Web Push title is required': 'en_word',
//         'Second Web Push message is required': 'en_word',
//         'Second Web Push URL is required': 'en_word',
//         'Second Web Push send time is required': 'en_word',
//         'Second Web Push Discount Code is required': 'en_word',
//         'Third Web Push title is required': 'en_word',
//         'Third Web Push message is required': 'en_word',
//         'Third Web Push URL is required': 'en_word',
//         'Third Web Push send time is required': 'en_word',
//         'Third Web Push Discount Code is required': 'en_word',
//         'RECOVERED': 'en_word',
//         'Timeline': 'en_word',
//         '(How it works?)': 'en_word',
//         'First Web Push': 'en_word',
//         'Second Web Push': 'en_word',
//         'Hours': 'en_word',
//         'Third Web Push': 'en_word',
//         'Send': 'en_word',
//         'set Time': 'en_word',
//         'Day': 'en_word',
//         ' after a cart is abandoned': 'en_word',
//         'Select a Template': 'en_word',
//         'All': 'en_word',
//         'Regular': 'en_word',
//         'Discount': 'en_word',
//         'Select a category': 'en_word',
//         'First Web Push - Reminder': 'en_word',
//         'Kindly remind your subscribers of their abandoned carts.': 'en_word',
//         'Second Web Push - Discount with urgency': 'en_word',
//         'Remind your subscribers of their abandoned carts again but with urgency, e.g. offer a discount. Let them know the offer only valid for a limited time or the abandoned items are selling out fast.': 'en_word',
//         'Third Web Push - Deadline reminder': 'en_word',
//         'Send the third web push with a discount with deadline to induce your subscribers to complete the purchase, in order to improve conversion rate.': 'en_word',
//         'If customer completes the purchase in one of the web pushes, the next push will not be activated.': 'en_word',
//         'If you offer discount to your subscribers, please mention it in web push to increase CTR.': 'en_word',
//         'Abandoned Cart Recovery': 'en_word',
//         'A series of notifications to remind the subscriber about the items they forgot in their cart': 'en_word',
//         'Notifies subscribers when the price of a product is dropped': 'en_word',
//         'Price Drop Alert': 'en_word',
//         'File type must be .jpg .jpeg .png.': 'en_word',
//         'The following images couldn’t be uploaded:': 'en_word',
//         'Content title is required': 'en_word',
//         'Content body is required': 'en_word',
//         'Content url is required': 'en_word',
//         'Content url: Enter a valid website URL. eg: yourstore.com': 'en_word',
//         'Content action buttons 1 title is required': 'en_word',
//         'Content action buttons 1 url is required': 'en_word',
//         'Content action buttons 1 url: Enter a valid website URL. eg:': 'en_word',
//         'Content action buttons 2 title is required': 'en_word',
//         'Content action buttons 2 url is required': 'en_word',
//         'Content action buttons 2 url: Enter a valid website URL. eg:': 'en_word',
//         'Do you want to offer a Discount Code ?': 'en_word',
//         'Perfect length is 15 characters': 'en_word',
//         'Url': 'en_word',
//         'Windows': 'en_word',
//         'Android': 'en_word',
//         'Mac': 'en_word',
//         'Hero Image is generated automatically.': 'en_word',
//         'Close': 'en_word',
//         'Settings': 'en_word',
//         'Leave edit': 'en_word',
//         'You have unsaved changes': 'en_word',
//         'If you leave this page, all unsaved changes will be lost.': 'en_word',
//         'Discard all unsaved changes': 'en_word',
//         'Discard changes': 'en_word',
//         'Continue editing': 'en_word',
//         'If you discard changes, you’ll delete any edits you made since you last saved.': 'en_word',
//         'REVENUE': 'en_word',
//         'SUBSCRIBERS': 'en_word',
//         'Subscription Product List': 'en_word',
//         'Notifies subscribers when an out-of-stock product is made available again': 'en_word',
//         'Back in Stock Push': 'en_word',
//         'Sending to': 'en_word',
//         'Perfect length is 21 characters': 'en_word',
//         'Perfect length is 23 characters': 'en_word',
//         'URL': 'en_word',
//         'Your customers will see this web page when they tap on the content or hero image.': 'en_word',
//         '64px x 64px; Less than 1MB; Accepts jpg, jpeg, png.': 'en_word',
//         'BRAND LOGO': 'en_word',
//         'Remove': 'en_word',
//         'ACTION BUTTONS': 'en_word',
//         '1st action button': 'en_word',
//         '2nd action button': 'en_word',
//         'Now that you’ve set your web push message. After users see and click on your web push, they will be taken to your your website Homepage or Cart. The discount widget will be at the bottom of the page. After they click \'': 'en_word',
//         'Copy discount code': 'en_word',
//         '\', they will get your code and usethem instantly.': 'en_word',
//         'Create discount code': 'en_word',
//         'Message': 'en_word',
//         'Button': 'en_word',
//         'Position': 'en_word',
//         'Message (After Subscription)': 'en_word',
//         'IMPRESSIONS': 'en_word',
//         'CLICKS': 'en_word',
//         'Payment accepted': 'en_word',
//         'Order dispatched': 'en_word',
//         'Tracking number': 'en_word',
//         'Refund': 'en_word',
//         'Shipping Notification': 'en_word',
//         'Notifies subscriber when the status of their fulfillment is updated': 'en_word',
//         'Add Rule': 'en_word',
//         'SEGEMENT RULES': 'en_word',
//         'CONTENT': 'en_word',
//         'Estimated Subscribers': 'en_word',
//         'Cancel': 'en_word',
//         'Group your Subscribers by demographic & behavioral info': 'en_word',
//         'Create Segement': 'en_word',
//         'Edit': 'en_word',
//         'Delete': 'en_word',
//         'Subscribers': 'en_word',
//         'Name': 'en_word',
//         'Action': 'en_word',
//         'Widget': 'en_word',
//         'displays with some delay time will allow users to have a glance at your website first before the Auto Prompt': 'en_word',
//         'shows up. Using 2 steps opt-in will give your visitors enough context for them to easily accept the permission.': 'en_word',
//         'Widget Delay': 'en_word',
//         'When trigger the widget to show': 'en_word',
//         'Title': 'en_word',
//         'Description': 'en_word',
//         'Accept Button': 'en_word',
//         'Decline Button': 'en_word',
//         'Save': 'en_word',
//         'Step 2': 'en_word',
//         'Step 1': 'en_word',
//         'Widgets': 'en_word',
//         'UPGRADE': 'en_word',
//         'DOWNGRADE': 'en_word',
//         'CURRENT PLAN': 'en_word',
//         'Impressions Per Month': 'en_word',
//         'Forever Free': 'en_word',
//         'CUSTOM IMPRESSIONS': 'en_word',
//         'Contact us to discuss a custom pricing plan which suits your needs.': 'en_word',
//         'Enterprise': 'en_word',
//         'GET IN TOUCH': 'en_word',
//         'Unlimited Subscribers': 'en_word',
//         'Promotion Campaign': 'en_word',
//         'Abandoned Cart Automation': 'en_word',
//         'Welcome Notification Automation': 'en_word',
//         'Price Drop Alert Automation': 'en_word',
//         'Back In Stock Alert Automation': 'en_word',
//         'Shipping Notification Automation': 'en_word',
//         'Group subscribers': 'en_word',
//         'Hero Image inside the push': 'en_word',
//         'Discount code widget': 'en_word',
//         'Custom Widgets': 'en_word',
//         'Desktop + Mobile Web Push': 'en_word',
//         'Web Push Report': 'en_word',
//         'Send Campaign at subscriber’s local time': 'en_word',
//         'Pricing': 'en_word',
//         'Edit Segment': 'en_word',
//         'Create Segment': 'en_word',
//         'Segments': 'en_word',
//         'Select a Option': 'en_word',
//         'Select a Operation': 'en_word',
//         'Options value': 'en_word',
//         'HERO IMAGE': 'en_word',
//         'SCHEDULE': 'en_word',
//         'DISCOUNT': 'en_word',
//         'Offer a Discount': 'en_word',
//         'Order Events': 'en_word',
//         'Mins after a cart is abandoned': 'en_word',
//         'Enter discount code': 'en_word',
//         'Mins': 'en_word',
//         'Devices': 'en_word',
//         'Try one of these top recommended apps to help your business': 'en_word',
//         'Dashboard': 'en_word',
//         'Automation': 'en_word',
//     },
//     "fr":{
//         'Top Countries': 'fr_word',
//         'More Web Push Automation': 'fr_word',
//         'More push notifications options for your subscrbers, to increase your conversion rate and get more sales.': 'fr_word',
//         'Web Push Impression Limit': 'fr_word',
//         'If you want to get more impression limits, please upgrade to a more advanced plan.': 'fr_word',
//         'One has to subscribe your website before you can send push notifications to them. The opt-in process is simple as follow:': 'fr_word',
//         '1. Once a user clicks "allow" on the browser prompt. He/She will be subscribed to your web push notifications and receive push notifications from your site afterward.': 'fr_word',
//         '2. In Abandoned Cart Web Push, you can either choose to enable the "Abandoned Cart Recovery" feature and let the App does the rest for you or craft your own web push title and message and set the "time-to-send" yourself.': 'fr_word',
//         '3. Your subscribers do not need to be on your website to receive your push. As long as they open their browsers, they will receive your push in real-time.': 'fr_word',
//         '4. Once they click on the push notification, they will be taken to either your cart or checkout page to complete the order.': 'fr_word',
//         'Total Subscribers': 'fr_word',
//         'Total Campaigns': 'fr_word',
//         'Total Revenue': 'fr_word',
//         'Recent Compains': 'fr_word',
//         'Hi': 'fr_word',
//         'Welcome': 'fr_word',
//         'Here’s what’s happening with your web push notifications.': 'fr_word',
//         'What is Smart Web Push Notifications?': 'fr_word',
//         'Smart Web push notifications are notifications that delivered on a user’s desktop or mobile screen anytime they have their browser open - regardless of whether or not the user is on the website.': 'fr_word',
//         'According to a study, there is a': 'fr_word',
//         '15% lift in revenue': 'fr_word',
//         'from': 'fr_word',
//         'Abandoned Cart Web Push Notifications.': 'fr_word',
//         'Easy Opt-In for Users': 'fr_word',
//         'No Email Address Required': 'fr_word',
//         'Higher Opt-In Rate': 'fr_word',
//         'Less Opt-Out Rate': 'fr_word',
//         'Higher CTR': 'fr_word',
//         'Higher Conversion Rate': 'fr_word',
//         'How Abandoned Cart Web Push works?': 'fr_word',
//         '1. Users subscribe to your web push notifications via browser prompt.': 'fr_word',
//         '2. Your send out the push notifications.': 'fr_word',
//         '3. Subscribers receive push notifications on their browser.': 'fr_word',
//         '4. Subscribers click on your push notifications and land on your website.': 'fr_word',
//         'The appearance of the web push notification varies from the operating system and browser to browser in which the message is displayed.': 'fr_word',
//         'Logo': 'fr_word',
//         '64px x 64px; Less than 1MB; Accepts .jpg, .jpeg, .png.': 'fr_word',
//         'Previous': 'fr_word',
//         'Continue': 'fr_word',
//         'See Example': 'fr_word',
//         'Done': 'fr_word',
//         'No data to show': 'fr_word',
//         'Active': 'fr_word',
//         'Inactive': 'fr_word',
//         'CAMPAIGNS': 'fr_word',
//         'IN QUEUE': 'fr_word',
//         'IMPRESSION': 'fr_word',
//         'Duplicate': 'fr_word',
//         'Completed': 'fr_word',
//         'Scheduled': 'fr_word',
//         'sent': 'fr_word',
//         'clicks': 'fr_word',
//         'CTR': 'fr_word',
//         'Campaign': 'fr_word',
//         'Create Campaign': 'fr_word',
//         'Content action buttons 1 url: Enter a valid website URL. eg: yourstore.com': 'fr_word',
//         'Content action buttons 2 url: Enter a valid website URL. eg: yourstore.com': 'fr_word',
//         'Chrome': 'fr_word',
//         'Subscribers’ local time': 'fr_word',
//         '450px x 200px; Less than 2MB; Accepts jpg, jpeg, png.': 'fr_word',
//         'Send now': 'fr_word',
//         'At subscribed time': 'fr_word',
//         'Abandoned Cart Web Push': 'fr_word',
//         'Back In Stock Push': 'fr_word',
//         'Total Impressions': 'fr_word',
//         'Total Clicks': 'fr_word',
//         'A notification is sent to the new subscribers': 'fr_word',
//         'Welcome Notification': 'fr_word',
//         'First Web Push title is required': 'fr_word',
//         'First Web Push message is required': 'fr_word',
//         'First Web Push URL is required': 'fr_word',
//         'First Web Push send time is required': 'fr_word',
//         'First Web Push send time is required, time range : 5~59': 'fr_word',
//         'First Web Push Discount Code is required': 'fr_word',
//         'Second Web Push title is required': 'fr_word',
//         'Second Web Push message is required': 'fr_word',
//         'Second Web Push URL is required': 'fr_word',
//         'Second Web Push send time is required': 'fr_word',
//         'Second Web Push Discount Code is required': 'fr_word',
//         'Third Web Push title is required': 'fr_word',
//         'Third Web Push message is required': 'fr_word',
//         'Third Web Push URL is required': 'fr_word',
//         'Third Web Push send time is required': 'fr_word',
//         'Third Web Push Discount Code is required': 'fr_word',
//         'RECOVERED': 'fr_word',
//         'Timeline': 'fr_word',
//         '(How it works?)': 'fr_word',
//         'First Web Push': 'fr_word',
//         'Second Web Push': 'fr_word',
//         'Hours': 'fr_word',
//         'Third Web Push': 'fr_word',
//         'Send': 'fr_word',
//         'set Time': 'fr_word',
//         'Day': 'fr_word',
//         ' after a cart is abandoned': 'fr_word',
//         'Select a Template': 'fr_word',
//         'All': 'fr_word',
//         'Regular': 'fr_word',
//         'Discount': 'fr_word',
//         'Select a category': 'fr_word',
//         'First Web Push - Reminder': 'fr_word',
//         'Kindly remind your subscribers of their abandoned carts.': 'fr_word',
//         'Second Web Push - Discount with urgency': 'fr_word',
//         'Remind your subscribers of their abandoned carts again but with urgency, e.g. offer a discount. Let them know the offer only valid for a limited time or the abandoned items are selling out fast.': 'fr_word',
//         'Third Web Push - Deadline reminder': 'fr_word',
//         'Send the third web push with a discount with deadline to induce your subscribers to complete the purchase, in order to improve conversion rate.': 'fr_word',
//         'If customer completes the purchase in one of the web pushes, the next push will not be activated.': 'fr_word',
//         'If you offer discount to your subscribers, please mention it in web push to increase CTR.': 'fr_word',
//         'Abandoned Cart Recovery': 'fr_word',
//         'A series of notifications to remind the subscriber about the items they forgot in their cart': 'fr_word',
//         'Notifies subscribers when the price of a product is dropped': 'fr_word',
//         'Price Drop Alert': 'fr_word',
//         'File type must be .jpg .jpeg .png.': 'fr_word',
//         'The following images couldn’t be uploaded:': 'fr_word',
//         'Content title is required': 'fr_word',
//         'Content body is required': 'fr_word',
//         'Content url is required': 'fr_word',
//         'Content url: Enter a valid website URL. eg: yourstore.com': 'fr_word',
//         'Content action buttons 1 title is required': 'fr_word',
//         'Content action buttons 1 url is required': 'fr_word',
//         'Content action buttons 1 url: Enter a valid website URL. eg:': 'fr_word',
//         'Content action buttons 2 title is required': 'fr_word',
//         'Content action buttons 2 url is required': 'fr_word',
//         'Content action buttons 2 url: Enter a valid website URL. eg:': 'fr_word',
//         'Do you want to offer a Discount Code ?': 'fr_word',
//         'Perfect length is 15 characters': 'fr_word',
//         'Url': 'fr_word',
//         'Windows': 'fr_word',
//         'Android': 'fr_word',
//         'Mac': 'fr_word',
//         'Hero Image is generated automatically.': 'fr_word',
//         'Close': 'fr_word',
//         'Settings': 'fr_word',
//         'Leave edit': 'fr_word',
//         'You have unsaved changes': 'fr_word',
//         'If you leave this page, all unsaved changes will be lost.': 'fr_word',
//         'Discard all unsaved changes': 'fr_word',
//         'Discard changes': 'fr_word',
//         'Continue editing': 'fr_word',
//         'If you discard changes, you’ll delete any edits you made since you last saved.': 'fr_word',
//         'REVENUE': 'fr_word',
//         'SUBSCRIBERS': 'fr_word',
//         'Subscription Product List': 'fr_word',
//         'Notifies subscribers when an out-of-stock product is made available again': 'fr_word',
//         'Back in Stock Push': 'fr_word',
//         'Sending to': 'fr_word',
//         'Perfect length is 21 characters': 'fr_word',
//         'Perfect length is 23 characters': 'fr_word',
//         'URL': 'fr_word',
//         'Your customers will see this web page when they tap on the content or hero image.': 'fr_word',
//         '64px x 64px; Less than 1MB; Accepts jpg, jpeg, png.': 'fr_word',
//         'BRAND LOGO': 'fr_word',
//         'Remove': 'fr_word',
//         'ACTION BUTTONS': 'fr_word',
//         '1st action button': 'fr_word',
//         '2nd action button': 'fr_word',
//         'Now that you’ve set your web push message. After users see and click on your web push, they will be taken to your your website Homepage or Cart. The discount widget will be at the bottom of the page. After they click \'': 'fr_word',
//         'Copy discount code': 'fr_word',
//         '\', they will get your code and usethem instantly.': 'fr_word',
//         'Create discount code': 'fr_word',
//         'Message': 'fr_word',
//         'Button': 'fr_word',
//         'Position': 'fr_word',
//         'Message (After Subscription)': 'fr_word',
//         'IMPRESSIONS': 'fr_word',
//         'CLICKS': 'fr_word',
//         'Payment accepted': 'fr_word',
//         'Order dispatched': 'fr_word',
//         'Tracking number': 'fr_word',
//         'Refund': 'fr_word',
//         'Shipping Notification': 'fr_word',
//         'Notifies subscriber when the status of their fulfillment is updated': 'fr_word',
//         'Add Rule': 'fr_word',
//         'SEGEMENT RULES': 'fr_word',
//         'CONTENT': 'fr_word',
//         'Estimated Subscribers': 'fr_word',
//         'Cancel': 'fr_word',
//         'Group your Subscribers by demographic & behavioral info': 'fr_word',
//         'Create Segement': 'fr_word',
//         'Edit': 'fr_word',
//         'Delete': 'fr_word',
//         'Subscribers': 'fr_word',
//         'Name': 'fr_word',
//         'Action': 'fr_word',
//         'Widget': 'fr_word',
//         'displays with some delay time will allow users to have a glance at your website first before the Auto Prompt': 'fr_word',
//         'shows up. Using 2 steps opt-in will give your visitors enough context for them to easily accept the permission.': 'fr_word',
//         'Widget Delay': 'fr_word',
//         'When trigger the widget to show': 'fr_word',
//         'Title': 'fr_word',
//         'Description': 'fr_word',
//         'Accept Button': 'fr_word',
//         'Decline Button': 'fr_word',
//         'Save': 'fr_word',
//         'Step 2': 'fr_word',
//         'Step 1': 'fr_word',
//         'Widgets': 'fr_word',
//         'UPGRADE': 'fr_word',
//         'DOWNGRADE': 'fr_word',
//         'CURRENT PLAN': 'fr_word',
//         'Impressions Per Month': 'fr_word',
//         'Forever Free': 'fr_word',
//         'CUSTOM IMPRESSIONS': 'fr_word',
//         'Contact us to discuss a custom pricing plan which suits your needs.': 'fr_word',
//         'Enterprise': 'fr_word',
//         'GET IN TOUCH': 'fr_word',
//         'Unlimited Subscribers': 'fr_word',
//         'Promotion Campaign': 'fr_word',
//         'Abandoned Cart Automation': 'fr_word',
//         'Welcome Notification Automation': 'fr_word',
//         'Price Drop Alert Automation': 'fr_word',
//         'Back In Stock Alert Automation': 'fr_word',
//         'Shipping Notification Automation': 'fr_word',
//         'Group subscribers': 'fr_word',
//         'Hero Image inside the push': 'fr_word',
//         'Discount code widget': 'fr_word',
//         'Custom Widgets': 'fr_word',
//         'Desktop + Mobile Web Push': 'fr_word',
//         'Web Push Report': 'fr_word',
//         'Send Campaign at subscriber’s local time': 'fr_word',
//         'Pricing': 'fr_word',
//         'Edit Segment': 'fr_word',
//         'Create Segment': 'fr_word',
//         'Segments': 'fr_word',
//         'Select a Option': 'fr_word',
//         'Select a Operation': 'fr_word',
//         'Options value': 'fr_word',
//         'HERO IMAGE': 'fr_word',
//         'SCHEDULE': 'fr_word',
//         'DISCOUNT': 'fr_word',
//         'Offer a Discount': 'fr_word',
//         'Order Events': 'fr_word',
//         'Mins after a cart is abandoned': 'fr_word',
//         'Enter discount code': 'fr_word',
//         'Mins': 'fr_word',
//         'Devices': 'fr_word',
//         'Try one of these top recommended apps to help your business': 'fr_word',
//         'Dashboard': 'fr_word',
//         'Automation': 'fr_word',
//     },
//     "ge": {},
//     "sp": {},
//     "pt": {}
// }

export default {};
// export default WordsArray;