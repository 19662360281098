import axios from 'axios';
import qs from 'qs';
import utils from '../utils/utils';

export  function ajax(url, data={}, type='GET') {

  type = type.toUpperCase();

  return new Promise((resolve, reject) => {
    let promise;
    let timeStr = new Date().getTime();
    let header = {
      'usertoken' : utils.getStore('S_USER_TOKEN'),
      'shopdomain': utils.getStore('S_SHOP_DOMAIN'),
      'timestr'   : timeStr,
      'sign'      : utils.getSign(utils.getStore('S_SHOP_DOMAIN'),timeStr),
    }
    if(type === 'GET') {
      promise = axios.get(url, {
        params: data,
        headers:header
      })
    }

    // POST 请求
    else if(type === 'POST') {
      promise = axios.post(url, qs.stringify(data),{
        headers:header
      })
    }

    // 请求成功
    promise.then(response => {
      resolve(response.data)
    })
  })
}

export  function ajaxWithoutAuth(url, data={}, type='GET') {

  type = type.toUpperCase();

  return new Promise((resolve, reject) => {
    let promise;    
    if(type === 'GET') {
      promise = axios.get(url, {
        params: data
      })
    }

    // POST 请求
    else if(type === 'POST') {
      promise = axios.post(url, qs.stringify(data))
    }

    // 请求成功
    promise.then(response => {
      resolve(response.data)
    })
  })
}