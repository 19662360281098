import React, { Component } from "react";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from "recharts";

import {
  AppProvider,
  SkeletonBodyText,
  SkeletonDisplayText,
  Card,
  Layout,
  DisplayText,
  Page,
  Icon,
  CalloutCard,
  Frame,
  Button,
  DataTable,
  ExceptionList,
  ResourceList,
  Badge,
  Toast,
  Modal,
  TextContainer,
  Stack,
  ButtonGroup,
  FormLayout,
  TextField,
  Thumbnail,
  Avatar,
  DropZone,
  TextStyle,
  List,
  Banner,
  Heading,
  EmptyState
} from "@shopify/polaris";
import {
  ChevronRightMinor,
  AbandonedCartMajorMonotone,
  BehaviorMajorMonotone,
  DesktopMajorMonotone,
  QuestionMarkMajorMonotone,
  CalendarMinor
} from "@shopify/polaris-icons";

import InlineItem from "./components/InlineItem/index";
// import SheetExample from '../../../../src/components/ContactUsSheetExample/SheetExample';
// import RecommandedAppsNew from "../../../../src/components/RecommandedAppsNew";
// import ThirdPartyADS from '../../../../src/components/ThirdPartyADS';
import RecommandedAppsItem from "../../../../src/components/RecommandedAppsItem";
import AppNotification from "../../../../src/components/AppNotification";
import "./index.css";
import OnPageSEOTabs from "../Tabs";
import utils from "../../../utils/utils";
import LoadingGif from "../../../components/LoadingGif";
// import { SettingsMinor ,CancelSmallMinor } from '@shopify/polaris-icons';
import MyRangeDate from "../../../components/RangeDate/index";
import WebPushBox from "../../../components/WebPushBox/index";

import {
  reqGetAppDashboardData,
  reqgetAppDashboardChartData,
  reqUpdateOnBoardingStatus,
  reqInsertAutomatedCartRecoveryImage,
  reqGetProductImage
} from "../../../api";

import { API_DOMAIN } from "../../../utils/constants";
import LanguageSwitch from "../../../components/LanguageSwitch";
class dashboard extends Component {
  state = {
    AppNotificationShow: false,
    username: "", //用户名称
    userDomian: "", // domain
    productImageUrl: "/template_Product_Image.jpg", // sent test product image

    // 顶部数据汇总
    subscribersCount: 0,
    impressionsCount: 0,
    activeLiveSubscribersCount: "",

    // 左边统计 数据
    leftCountItem: "",

    // 右边统计 订单列表 数据
    rightListTableItem: "",
    rightListTableItemCountry: "",
    orderTotalPrice: 0,

    //页面动作
    loading: true,
    loadingPage: false,

    //弹窗
    showToast: false, //弹窗显示
    showToastText: "", //弹窗文字
    showToastIserror: false, //弹窗是否错误

    // 客户指引弹窗显示
    onBoarding: false,
    boardingModalTitle: "Welcome to Smart Web Push Notifications",

    boardingStep: 1,
    boardingStepOne: "block",
    boardingStepTwo: "none",
    boardingStepThree: "none",
    boardingStepFour: "none",
    boardingStepFive: "none",

    PreviousButton: "none",
    ContinueButton: "block",
    SeeExampleButton: "none",
    FinishedButton: "none",

    testPushTitle: "We’re saved your cart for you.",
    testPushMessage: "Buy them now before they get out of stock.",

    testPush_Win_Class: "",
    testPush_Mac_Class: "",

    // logo 上传
    files: "",
    fileUpload: "",
    uploadedFiles: "",
    setUpdateImageWarning: "",
    setUpdateImageBackground: "",
    loadingLogo: false,
    //图片删除按钮
    imgButton: true,

    // 账户额度不足提醒的banner
    LimitBannerStatus: false,
    HowItWorkModalActive: false,

    //新版本start
    //列表数据
    items: [],
    webpushSimple: "",
    chartOneData: [],
    leftLoading: true,
    leftHasData: false,
    totalCreateCampaignMSG:""
    //新版本end
  };

  saveTimeData = async (from, to) => {
    console.log(from);
    console.log(to);
    //重新调用查询数据
    this.getDashboardChartData(from, to);
  };

  //进入页面调用接口
  componentDidMount() {
    this.initData();
    this.getDashboardChartData();
    this.getDashboardData();
  }
  callback=()=>{
    this.setState({})
  }
  initData = () => {
    let username = utils.getStore("S_SHOP_OWNER");
    let userDomian = utils.getStore("S_SHOP_DOMAIN");
    if (userDomian === undefined || userDomian === "") {
      userDomian = "giraffly.com";
    }
    this.setState({ username: username, userDomian: userDomian });
  };

  //提示弹窗
  toggleToast = (showToastText, showToastIserror) => {
    this.setState({
      showToastText: showToastText,
      showToastIserror: showToastIserror
    });
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

  //获取首页表格数据
  getDashboardChartData = async (from = null, to = null) => {
    const params = { from, to };
    const response = await reqgetAppDashboardChartData(params);
    if (response.code === "200") {
      var chartOneData = response.data.map((item, key) => {
        return {
          date: item.date,
          subscribe: item.subscribe
        };
      });
      this.setState({
        leftHasData: true,
        leftLoading: false,
        chartOneData: chartOneData

      });
    } else {
      this.setState({
        leftHasData: false,
        leftLoading: false
      });
    }
  };

  // 获取首页数据
  getDashboardData = async () => {
    // 请求前旋转
    const loadingMarkupaa = (
      <div>
        <SkeletonDisplayText size="small" />
        <div className="space"></div>
        <SkeletonBodyText lines={4} />
        <div className="space"></div>
        <SkeletonBodyText lines={4} />
        <div className="space"></div>
        <SkeletonBodyText lines={4} />
      </div>
    );
    const rightloadingMarkupaa = (
      <Card>
        <Card.Section>
          <SkeletonDisplayText size="small" />
        </Card.Section>
        <Card.Section>
          <SkeletonBodyText lines={3} />
          <div className="space"></div>
          <SkeletonBodyText lines={3} />
        </Card.Section>
      </Card>
    );
    const itemWait = (
      <Card title="" sectioned>
        {loadingMarkupaa}
      </Card>
    );

    this.setState({
      leftCountItem: itemWait,
      rightListTableItem: rightloadingMarkupaa,
      rightListTableItemCountry: rightloadingMarkupaa,
      loadingPage: true
    });

    const params = {};
    const response = await reqGetAppDashboardData(params);
    if (response.code === "200") {
      var IntercomData = response.IntercomData
      if(IntercomData) {
        var IntercomArr = {
          'user_id' : 'webpush_'+IntercomData.user_id,
          'email' : IntercomData.email,
          'username' : IntercomData.shopname,
          'review' : IntercomData.review,
          'createdat' : IntercomData.created_at,
          'Level' : IntercomData.level,
          'name' : IntercomData.owner,
          'Plan' : IntercomData.shopify_level,
        };
        utils.setStore('S_SHOP_USERID',IntercomData.user_id);
        utils.setStore('S_SHOP_EMAIL',IntercomData.email);
        utils.setStore('S_SHOP_NAME',IntercomData.shopname);
        utils.setStore('S_SHOP_REVIEW',IntercomData.review);
        utils.setStore('S_SHOP_CREATED_AT',IntercomData.created_at);
        utils.setStore('S_SHOP_LEVEL',IntercomData.level);
        utils.setStore('S_SHOP_OWNER',IntercomData.owner);
        utils.setStore('S_SHOP_SHOPIFY_LEVEL',IntercomData.shopify_level);
        window.Intercom("update",IntercomArr)
      }

      var result = response.data;
      var topCount = result.topCount;
      var devices = result.devices;
      var country = result.country;
      
      var tableRowDevices = devices.map((item, key) => {
        return [item.name, item.per + "%"];
      });

      var tableRowCountry = country.map((item, key) => {
        return [item.name, item.per + "%"];
      });

      const rightListTableDevicesContent = tableRowDevices.length!==0?(
        <Card title={<LanguageSwitch Words="Devices" />}>
          <div className="padding_table_08">
            <DataTable
              columnContentTypes={["text", "numeric"]}
              headings={
                [
                  // '',
                  // '',
                ]
              }
              rows={tableRowDevices}
            />
          </div>
        </Card>
      ):(
        <Card title={<LanguageSwitch Words="Devices" />}>
            <div className="space"></div>
            <div style={{textAlign:"center"}}><DisplayText size="small"><LanguageSwitch Words="No data to show" /></DisplayText></div>
            <div className="space"></div>
            <div className="space"></div>
        </Card>
      );

      const rightListTableCountryContent = tableRowCountry.length!==0?(
        <Card title={<LanguageSwitch Words="Top Countries" />}>
          <div className="padding_table_08">
            <DataTable
              columnContentTypes={["text", "numeric"]}
              headings={
                [
                  // '',
                  // '',
                ]
              }
              rows={tableRowCountry}
            />
          </div>
        </Card>
      ):(
        <Card title="Top Countries">
            <div className="space"></div>
            <div style={{textAlign:"center"}}><DisplayText size="small"><LanguageSwitch Words="No data to show" /></DisplayText></div>
            <div className="space"></div>
            <div className="space"></div>
        </Card>
      );

      // 判断增加的订阅者数量
      var addSubscribersCountString = "";
      if (topCount.activeLiveSubscribersCount !== 0) {
        addSubscribersCountString = (
          <sup style={{ color: "#9a9a9a" }}>
            +{topCount.activeLiveSubscribersCount}{" "}
          </sup>
        );
      }

      var webpushSimple =
        response.data.data.length === 0 ? (
          <div className="Polaris-Card Create_Campaign_Button">
            <CalloutCard
              title={<LanguageSwitch Words="More Web Push Automation" />}
              // illustration=""
              primaryAction={{
                content: "Create Campaign",
                url: "/admin/create-campaign/all"
              }}
            >
              <p>
                <LanguageSwitch Words="More push notifications options for your subscrbers, to increase your conversion rate and get more sales." />
              </p>
            </CalloutCard>
          </div>
        ) : (
          response.data.data.map(item => (
            <div
              key={item.id}
              style={{ position: "fixed", display: "none", zIndex: "99" }}
              className={"webPushBox_" + item.id}
            >
              <WebPushBox
                productImageUrl={item.img}
                logo={item.logo}
                title={item.title}
                message={item.messages}
                url={item.url}
                button_title_1={item.button_title_1}
                button_title_2={item.button_title_2}
                type={true}
                sendTest={true}
                noBackImg={item.img===""?true:false}
              />
            </div>
          ))
        );

      //更新状态
      this.setState({
        onBoarding: result.onBoarding,
        setUpdateImageBackground: result.logoUrl, //logo 图
        subscribersCount: topCount.subscribersCount,
        impressionsCount: topCount.impressionsCount,
        activeLiveSubscribersCount: addSubscribersCountString,
        rightListTableItem: rightListTableDevicesContent,
        rightListTableItemCountry: rightListTableCountryContent,
        orderTotalPrice: result.orderTotalPrice,
        loading: false,
        loadingPage: true,
        LimitBannerStatus: response.data.precent > 90 ? true : false,
        impressions_use: response.data.impressions_use,
        impressions_all: response.data.impressions_all,
        items: response.data.data,
        webpushSimple: webpushSimple,
        totalCreateCampaignMSG:response.data.totalCreateCampaignMSG,
        AppNotificationShow: (response.data.appNotification === 1) ? true : false
      });

      if (result.onBoarding) {
        this.setUpdateImage(0, "", "");
        // get product image
        this.getProdcutImage();
      }
    } else {
      // error

      const leftCountItemContentEnd = (
        <Card title="" sectioned>
          <InlineItem
            licon={<Icon source={DesktopMajorMonotone} />}
            ricon={<Icon source={ChevronRightMinor} />}
            name=" abandoned web push impression"
            value="0"
          />
          <InlineItem
            licon={<Icon source={BehaviorMajorMonotone} />}
            ricon={<Icon source={ChevronRightMinor} />}
            name=" abandoned web push clicked"
            value="0"
          />
          <InlineItem
            licon={<Icon source={AbandonedCartMajorMonotone} />}
            ricon={<Icon source={ChevronRightMinor} />}
            name=" abandoned cart recovered"
            value="0"
          />
        </Card>
      );

      var orderListItemEnd = [];

      var tableRowEnd = orderListItemEnd.map((item, key) => {
        return ["", ""];
      });

      const rightListTableContentEnd = (
        <DataTable
          columnContentTypes={["text", "numeric"]}
          headings={
            [
              // '',
              // '',
            ]
          }
          rows={tableRowEnd}
        />
      );

      //更新状态
      this.setState({
        leftCountItem: leftCountItemContentEnd,
        rightListTableItem: rightListTableContentEnd,
        rightListTableItemCountry: rightListTableContentEnd,
        loading: false,
        loadingPage: false,
        AppNotificationShow: (response.data.appNotification === 1) ? true : false
      });

      this.toggleToast(response.msg, true);
    }
  };

  // onBoarding 指引控制 (下一步)
  onBoardingContinue = step => {
    if (step === 1) {
      this.setState({
        boardingStep: 2,
        boardingModalTitle: "Welcome to Smart Web Push Notifications",
        boardingStepOne: "none",
        boardingStepTwo: "block",
        PreviousButton: "block"
      });
    } else if (step === 2) {
      this.setState({
        boardingStep: 3,
        boardingModalTitle: "Benefits of Smart Web Push Notifications",
        boardingStepTwo: "none",
        boardingStepThree: "block"
      });
    } else if (step === 3) {
      this.setState({
        boardingStep: 4,
        boardingModalTitle: "Welcome to Smart Web Push Notifications",
        boardingStepThree: "none",
        boardingStepFour: "block"
      });
    } else if (step === 4) {
      this.setState({
        boardingStep: 5,
        boardingModalTitle: "Try a test push",
        boardingStepFour: "none",
        ContinueButton: "none",
        SeeExampleButton: "block",
        boardingStepFive: "block"
      });
    }
  };

  // 客户指引 (上一步)
  onBoardingPrevious = step => {
    if (step === 5) {
      this.setState({
        boardingStep: 4,
        boardingModalTitle: "Benefits of Abandoned Cart Web Push",
        boardingStepFive: "none",
        boardingStepFour: "block",
        ContinueButton: "block",
        SeeExampleButton: "none",
        FinishedButton: "none"
      });
    } else if (step === 4) {
      this.setState({
        boardingStep: 3,
        boardingModalTitle: "Welcome to Abandoned Cart Web Push",
        boardingStepFour: "none",
        boardingStepThree: "block"
      });
    } else if (step === 3) {
      this.setState({
        boardingStep: 2,
        boardingModalTitle: "Welcome to Abandoned Cart Web Push",
        boardingStepTwo: "block",
        boardingStepThree: "none"
      });
    } else if (step === 2) {
      this.setState({
        boardingStep: 1,
        boardingModalTitle: "Welcome to Abandoned Cart Web Push",
        boardingStepOne: "block",
        boardingStepTwo: "none",
        PreviousButton: "none"
      });
    }
  };
  // test push 弹窗文本内容 修改
  handleInputChange = (value, name) => {
    if (value === "") {
      if (name === "testPushTitle") {
        value = "We’re saved your cart for you.";
      } else {
        value = "Buy them now before they get out of stock.";
      }
    }
    this.setState({
      [name]: value
    });
  };

  // see example ,测试弹窗之后
  onBoardingSeeExample = async () => {
    this.setState({
      testPush_Mac_Class: "testPush_Mac_Show",
      testPush_Win_Class: "testPush_Win_Show",
      SeeExampleButton: "block",
      FinishedButton: "block"
    });

    setTimeout(this.closedTestPush, 5000);
    // var is_Mac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  };

  // 结束 客户指引按钮 finished  请求修改 onboarding 状态
  onBoardingFinished = async () => {
    this.closedTestPush();
    this.setState(({ onBoarding }) => ({ onBoarding: !onBoarding }));

    const params = {
      title: this.state.testPushTitle,
      message: this.state.testPushMessage
    };
    const response = await reqUpdateOnBoardingStatus(params);

    if (response.code !== "200") {
      this.toggleToast("Oops... please refresh the network", true);
    }
  };

  // 测试弹窗 关闭
  closedTestPush = () => {
    this.setState({
      testPush_Win_Class: "",
      testPush_Mac_Class: ""
    });
  };

  //上传图片
  updateImage = acceptedFiles => {
    if (!this.state.loadingLogo) {
      this.setState({
        files: acceptedFiles[0],
        loadingLogo: true
      });
      this.setUpdateImage(1, acceptedFiles[0]);
    } else {
      this.toggleToast("Image is uploading, please try again later.", false);
    }
  };

  //设置图片
  setUpdateImage = async (type, acceptedFiles, img) => {
    var fileUpload = "";
    var uploadedFiles = "";
    var setUpdateImageWarning = "";
    var setUpdateImageBackground = API_DOMAIN + "/logo.jpg";
    if (img) {
      fileUpload = !img && <DropZone.FileUpload />;
      uploadedFiles = img && (
        <div className="addImage">
          <Thumbnail size="small" alt="" source={img} />
        </div>
      );
      this.setState({
        imgButton: false,
        fileUpload: fileUpload,
        uploadedFiles: uploadedFiles
      });
    } else if (acceptedFiles) {
      const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
      const filesImg = acceptedFiles;
      if (validImageTypes.indexOf(filesImg.type) >= 0) {
        fileUpload = !filesImg && <DropZone.FileUpload />;

        //开启FileReader
        var reader = new FileReader();
        reader.readAsDataURL(filesImg); //读取文件保存在result中
        reader.onload = e => {
          var getRes = e.target.result; //读取的结果在result中
          const params = {
            img: getRes,
            type: filesImg.type,
            action: "dashboard"
          };
          reqInsertAutomatedCartRecoveryImage(params).then(response => {
            if (response.code === "200") {
              uploadedFiles = filesImg && (
                <div className="addImage">
                  <Thumbnail
                    size="small"
                    alt={filesImg.name}
                    source={response.data}
                  />
                </div>
              );
              setUpdateImageBackground = response.data;
            } else {
              fileUpload = <DropZone.FileUpload />;
              uploadedFiles = "";
            }

            this.setState({
              imgButton: false,
              fileUpload: fileUpload,
              uploadedFiles: uploadedFiles,
              setUpdateImageBackground: setUpdateImageBackground,
              loadingLogo: false
            });
          });
        };
      } else {
        fileUpload = <DropZone.FileUpload />;
        uploadedFiles = "";
        setUpdateImageWarning = (
          <Banner
            title={<LanguageSwitch Words="The following images couldn’t be uploaded:" />}
            status="warning"
          >
            <List>
              <List.Item><LanguageSwitch Words="File type must be .jpg .jpeg .png." /></List.Item>
            </List>
            <p></p>
          </Banner>
        );
        this.setState({
          fileUpload: fileUpload,
          uploadedFiles: uploadedFiles,
          setUpdateImageWarning: setUpdateImageWarning,
          setUpdateImageBackground: setUpdateImageBackground
        });
      }
    } else {
      fileUpload = <DropZone.FileUpload />;
      uploadedFiles = "";
      //如果提交的文件不是图片 就提醒报错
      if (type === 1) {
        setUpdateImageWarning = (
          <Banner
            title={<LanguageSwitch Words="The following images couldn’t be uploaded:" />}
            status="warning"
          >
            <List>
              <List.Item><LanguageSwitch Words="File type must be .jpg .jpeg .png." /></List.Item>
            </List>
          </Banner>
        );
      }

      this.setState({
        fileUpload: fileUpload,
        uploadedFiles: uploadedFiles,
        setUpdateImageWarning: setUpdateImageWarning,
        setUpdateImageBackground: setUpdateImageBackground
      });
    }
  };

  //删除图片
  deleteImage = () => {
    this.setState({
      files: "",
      imgButton: true
    });
    this.setUpdateImage();
  };

  // get product imgae use in send test
  getProdcutImage = async () => {
    const params = {};
    const response = await reqGetProductImage(params);
    if (response.code === "200") {
      this.setState({
        productImageUrl: response.data.productImageUrl
      });
    }
  };

  // how it work modal
  howItWorkModalChange = closed => {
    this.setState(({ HowItWorkModalActive }) => ({
      HowItWorkModalActive: !HowItWorkModalActive
    }));
  };

  showBigPic = (id, e) => {
    var className = "webPushBox_" + id.id + "";

    var div = document.getElementsByClassName(className)[0];
    // if(same_path != id){  //避免鼠标在图片上移动时重复加载图片
    //     //将文件路径传给img大图
    //     document.getElementById('bigPic').src = id;
    // }
    // //获取大图div是否存在
    // var div = document.getElementById("bigPicView");
    if (!div) {
      return;
    }
    // //如果存在则展示
    div.style.display = "block";

    var intX = e.clientX;
    var intY = e.clientY-120;

    div.style.left = intX + "px";
    div.style.top = intY + "px";
  };

  //隐藏预览图
  closeimg = id => {
    var className = "webPushBox_" + id.id + "";
    var div = document.getElementsByClassName(className)[0];
    div.style.display = "none";
  };

  render() {
    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={this.toggleToast}
        error={this.state.showToastIserror}
      />
    ) : null;

    const showSubscribersCount = !this.state.loading ? this.state.subscribersCount : 0
    const showImpressionsCount = !this.state.loading ? this.state.impressionsCount : 0
    const showOrderTotalPrice = !this.state.loading ? this.state.orderTotalPrice : 0

    const {
      onBoarding,
      setUpdateImageWarning,
      uploadedFiles,
      fileUpload,
      setUpdateImageBackground,
      activeLiveSubscribersCount
    } = this.state;

    const macPushContent = (
      <section
        className={"testPush_Mac_Preview  " + this.state.testPush_Mac_Class}
        style={{ position: "fixed", right: "15px", top: "15px", zIndex: "520" }}
      >
        <div
          style={{ color: "#fff", fontSize: "2.8rem", paddingBottom: "1.5rem" }}
        >
          <LanguageSwitch Words="Mac" />
        </div>
        <section className="testPush_Mac_Preview_Card">
          <Card>
            <Stack alignment="center">
              <section style={{ marginLeft: "1rem" }}>
                <Avatar
                  customer
                  name="Farrah"
                  source="/mac_chrome.iso.f3b6c54c.svg"
                />
              </section>
              <Stack.Item fill>
                <Stack vertical={true} spacing="extraTight">
                  <div className="testPush_Mac__Message_Title">
                    {this.state.testPushTitle.substring(0, 26)}
                    {this.state.testPushTitle.length > 26 ? "..." : ""}
                  </div>
                  <span className="testPush_Mac__Message_Content">
                    {this.state.userDomian}
                  </span>
                  <div className="testPush_Mac__Message_Content">
                    {this.state.testPushMessage.substring(0, 26)}
                    {this.state.testPushMessage.length > 26 ? "..." : ""}
                  </div>
                </Stack>
              </Stack.Item>

              <Thumbnail
                source={setUpdateImageBackground}
                alt="Abandoned Cart Recovery"
              />

              <div className="testPush_Mac_Icon">
                <Card>
                  <Card.Section>
                    <div
                      onClick={this.closedTestPush}
                      style={{ cursor: "pointer" }}
                    >
                      <LanguageSwitch Words="Close" />
                    </div>
                  </Card.Section>
                  <Card.Section><LanguageSwitch Words="Settings" /></Card.Section>
                </Card>
              </div>
            </Stack>
          </Card>
        </section>
      </section>
    );

    const windPushContent = (
      <section
        className={"testPush_Win_Preview  " + this.state.testPush_Win_Class}
        style={{
          width: "85%",
          maxWidth: "370px",
          position: "fixed",
          right: "15px",
          bottom: "20px",
          zIndex: "520"
        }}
      >
        <div style={{ color: "#fff", fontSize: "2.8rem", padding: "1.5rem 0" }}>
          <LanguageSwitch Words="Windows" />
        </div>
        <Card>
          <div
            style={{ padding: "1rem", backgroundColor: "#333", color: "#fff" }}
          >
            <Stack vertical={true}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={this.state.productImageUrl}
                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                  alt="Abandoned Cart Web Push"
                />
                <div className="testPush_Win_Image_Help_Text">
                  <LanguageSwitch Words="Hero Image is generated automatically." />
                </div>
              </div>

              <section style={{ padding: "1rem 1rem" }}>
                <Stack wrap={false}>
                  <section style={{ paddingTop: ".5rem" }}>
                    <Thumbnail
                      source={setUpdateImageBackground}
                      size="small"
                      alt="Black choker necklace"
                    />
                  </section>
                  <Stack vertical={true}>
                    <section
                      className="Message_Title"
                      style={{ paddingRight: "6rem" }}
                    >
                      <span className="">{this.state.testPushTitle}</span>
                    </section>
                    <section
                      className="Message_Content"
                      style={{
                        paddingRight: "7rem",
                        paddingTop: ".5px",
                        marginTop: "-5px",
                        lineHeight: "1.5"
                      }}
                    >
                      <div className="Polaris-Stack__Itemclass">
                        <Stack vertical={true}>
                          <span>{this.state.testPushMessage}</span>
                        </Stack>
                      </div>
                      <div className="Polaris-Stack__Itemclass">
                        <div style={{ fontSize: "13px", color: "#999" }}>
                          {" "}
                          {"Google Chrome · " + this.state.userDomian}
                        </div>
                      </div>
                    </section>
                  </Stack>
                </Stack>
              </section>
            </Stack>
          </div>
        </Card>
      </section>
    );

    const LimitBanner = this.state.LimitBannerStatus ? (
      <Banner
        title="Web Push Impression Limit Warning"
        action={{ content: "Upgrade", url: "/admin/pricing" }}
        status="warning"
        onDismiss={() => {
          this.setState({ LimitBannerStatus: false });
        }}
      >
        <p>
          <LanguageSwitch Words="Web Push Impression Limit" /> ({this.state.impressions_use}/
          {this.state.impressions_all}). <LanguageSwitch Words="If you want to get more impression limits, please upgrade to a more advanced plan." />

        </p>
      </Banner>
    ) : (
      ""
    );

    const HowItWorkModal = (
      <Modal
        open={this.state.HowItWorkModalActive}
        onClose={this.howItWorkModalChange.bind(this, true)}
        title={<LanguageSwitch Words="How Abandoned Cart Web Push works?" />}
        secondaryActions={[
          {
            content: <LanguageSwitch Words="Close" />,
            onAction: this.howItWorkModalChange.bind(this, true)
          }
        ]}
      >
        <Modal.Section>
          <div style={{ padding: "0 20px" }}>
            <TextContainer>
              <p style={{ fontSize: "1.6rem", marginTop: "1.2rem" }}>
                <LanguageSwitch Words="One has to subscribe your website before you can send push notifications to them. The opt-in process is simple as follow:" />
              </p>

              <p style={{ fontSize: "1.6rem", marginTop: "1.2rem" }}>
                <LanguageSwitch Words='1. Once a user clicks "allow" on the browser prompt. He/She will be subscribed to your web push notifications and receive push notifications from your site afterward.' />
              </p>
              <p style={{ fontSize: "1.6rem", marginTop: "1.2rem" }}>
                <LanguageSwitch Words='2. In Abandoned Cart Web Push, you can either choose to enable the "Abandoned Cart Recovery" feature and let the App does the rest for you or craft your own web push title and message and set the "time-to-send" yourself.' />
              </p>
              <p style={{ fontSize: "1.6rem", marginTop: "1.2rem" }}>
                <LanguageSwitch Words="3. Your subscribers do not need to be on your website to receive your push. As long as they open their browsers, they will receive your push in real-time." />
              </p>
              <p style={{ fontSize: "1.6rem", marginTop: "1.2rem" }}>
                <LanguageSwitch Words="4. Once they click on the push notification, they will be taken to either your cart or checkout page to complete the order." />
              </p>

              <p
                style={{
                  fontSize: "1.3rem",
                  color: "rgb(142, 142, 142)",
                  marginTop: "1.2rem"
                }}
              >
                * <LanguageSwitch Words="The appearance of the web push notification varies from the operating system and browser to browser in which the message is displayed." />
              </p>

              <div className="onBording-Img">
                <img
                  src="/guide-to-website-push-notifications.gif"
                  alt="Abandoned Cart Web Push"
                  style={{ width: "450px" }}
                />
              </div>
            </TextContainer>
          </div>
        </Modal.Section>
      </Modal>
    );

    const cardHeader = this.state.loading ? (
      <Stack>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
      </Stack>
    ) : (
      <FormLayout>
        <FormLayout.Group condensed>
          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">
                  {showSubscribersCount} {activeLiveSubscribersCount}
                </div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="Total Subscribers" /></div>
              </TextContainer>
            </div>
          </Card>
          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">
                  {showImpressionsCount}
                </div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="Total Impressions" /></div>
              </TextContainer>
            </div>
          </Card>

          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">
                  {this.state.totalCreateCampaignMSG}
                </div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="Total Campaigns" /></div>
              </TextContainer>
            </div>
          </Card>

          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">
                  {showOrderTotalPrice}
                </div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="Total Revenue" /></div>
              </TextContainer>
            </div>
          </Card>
        </FormLayout.Group>
      </FormLayout>
    );

    const cardContent = this.state.loading ? (
      <Card>
        <Card.Section>
          <TextContainer>
            <SkeletonDisplayText size="small" />
          </TextContainer>
        </Card.Section>
        <Card.Section>
          <SkeletonBodyText lines={2} />
        </Card.Section>
        <Card.Section>
          <SkeletonBodyText lines={2} />
        </Card.Section>
        <Card.Section>
          <SkeletonBodyText lines={2} />
        </Card.Section>
      </Card>
    ) : this.state.items.length === 0 ? (
      ""
    ) : (
      <div>
        <Card>
          <Card.Section>
            <Heading><LanguageSwitch Words="Recent Compains" /></Heading>
          </Card.Section>
          <Card.Section>
            <ResourceList
              items={this.state.items}
              renderItem={item => {
                const {
                  title,
                  messages,
                  send_time,
                  sent,
                  clicks,
                  CTR,
                  id
                } = item;

                const BadgeState = <Badge progress="complete"><LanguageSwitch Words="Completed" /></Badge>;

                return (
                  <ResourceList.Item>
                    <div
                      onMouseOver={event => {
                        this.showBigPic({ id }, event);
                      }}
                      onMouseOut={() => {
                        this.closeimg({ id });
                      }}
                    >
                      <div className="MessageListItem__Main">
                        <div className="MessageListItem__Title">
                          <Heading>{title}</Heading>
                        </div>
                        <div className="MessageListItem__statistics">
                          <div>{sent} <LanguageSwitch Words="sent" /></div>
                          <div>{clicks} <LanguageSwitch Words="clicks" /></div>
                          <div>{CTR} <LanguageSwitch Words="CTR" /></div>
                        </div>
                        <div className="MessageListItem__status">
                          {BadgeState}
                        </div>
                      </div>

                      <div className="MessageListItem__Body">
                        <div className="Polaris-Stack__Item">{messages}</div>
                        <ExceptionList
                          items={[
                            {
                              icon: CalendarMinor,
                              description: send_time
                            }
                          ]}
                        />
                      </div>
                    </div>
                  </ResourceList.Item>
                );
              }}
            />
          </Card.Section>
        </Card>
      </div>
    );

    // const RecommandedApp = this.state.loading ? "" : <RecommandedAppsNew />;

    const welcome = this.state.loading ? (
      <Layout.Section>
        <SkeletonDisplayText size="small" />
        <div className="space"></div>
        <SkeletonBodyText lines={2} />
        <div className="space"></div>
      </Layout.Section>
    ) : (
      <Layout.Section>
        {LimitBanner}
        <div className="hello">
          <DisplayText size="Large">
            <LanguageSwitch Words="Hi" /> {this.state.username}, <LanguageSwitch Words="Welcome" />.
          </DisplayText>
          <div className="hello_sub">
            <span style={{ display: "inline-block" }}>
              <LanguageSwitch Words="Here’s what’s happening with your web push notifications." />
            </span>
            <div
              style={{ display: "inline-block", cursor: "pointer" }}
              onClick={this.howItWorkModalChange.bind(this, false)}
            >
              <Icon source={QuestionMarkMajorMonotone} backdrop={true} />
            </div>
          </div>
        </div>
        <AppNotification show={this.state.AppNotificationShow} />
      </Layout.Section>
    );

    const newleftItemChart = this.state.leftHasData ? (
      <div className="dashboard_charts">
      <ResponsiveContainer aspect={1.6}>
        <LineChart
          width={130}
          height={250}
          data={this.state.chartOneData}
          margin={{ top: 50, right: 40, left: -15, bottom: 0 }}
        >
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="subscribe" stroke="#9C6ADE" />
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </LineChart>
      </ResponsiveContainer>
      </div>
    ) : (
      <div className="emptyWithImg">
        <EmptyState image="/empty.svg">
          <p><LanguageSwitch Words="No data to show" /></p>
        </EmptyState>
      </div>
    );

    const newleftItem = this.state.leftLoading ? (
      <div>
        <Card title="" sectioned>
          <div>
            <SkeletonDisplayText size="small" />
            <div className="space"></div>
            <SkeletonBodyText lines={4} />
            <div className="space"></div>
            <SkeletonBodyText lines={4} />
            <div className="space"></div>
            <SkeletonBodyText lines={4} />
          </div>
        </Card>
      </div>
    ) : (
      <Card>
        <Card.Header title={<LanguageSwitch Words="Subscribers" />}>
          <Stack>
            <Stack.Item>
              <div className="myRangeDate">
                <MyRangeDate saveTimeData={this.saveTimeData} />
              </div>
            </Stack.Item>
          </Stack>
        </Card.Header>
        {newleftItemChart}
        <div style={{padding:'0.7rem'}}></div>
      </Card>
    );

    return (
      <div>
        <AppProvider>
          <Frame topBar={<OnPageSEOTabs
            callback={this.callback}
          />}>
            <Page>
              <Layout>
                {welcome}
                <Layout.Section>
                  {cardHeader}
                  <div className="space"></div>
                </Layout.Section>
              </Layout>
              <Layout>
                <Layout.Section>{newleftItem}</Layout.Section>
                <Layout.Section secondary>
                  {this.state.rightListTableItemCountry}
                  {this.state.rightListTableItem}
                </Layout.Section>
              </Layout>

              <Layout>
                <Layout.Section>
                  <div className="space"></div>
                  {cardContent}
                  {this.state.webpushSimple}
                  {/* {RecommandedApp} */}
                  <div className="space"></div>
                  <RecommandedAppsItem />
                </Layout.Section>
              </Layout>

              {/* //end */}

              {toastMarkup}
              {/* { onBoardingModal onBoarding } */}
              <Modal
                open={onBoarding}
                onClose={() => {
                  this.setState(({ onBoarding }) => ({
                    onBoarding: !onBoarding
                  }));
                }}
                size="Medium"
              >
                <div className="Polaris-Modal-Header">
                  <div
                    id="modal-header1"
                    className="Polaris-Modal-Header__Title"
                  >
                    <h2 className="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
                      {this.state.boardingModalTitle}
                    </h2>
                  </div>
                </div>

                <Modal.Section>
                  <div
                    className="onBording__Step"
                    style={{ display: this.state.boardingStepOne }}
                  >
                    <TextContainer>
                      <Heading><LanguageSwitch Words="What is Smart Web Push Notifications?" /></Heading>

                      <p style={{ fontSize: "1.6rem" }}>
                        <LanguageSwitch Words="Smart Web push notifications are notifications that delivered on a user’s desktop or mobile screen anytime they have their browser open - regardless of whether or not the user is on the website." />
                      </p>

                      <div className="onBording-Img">
                        <img
                          src="/guide-to-website-push-notifications.png"
                          alt="Abandoned Cart Web Push"
                        />
                      </div>
                    </TextContainer>
                  </div>

                  <div
                    className="onBording__Step"
                    style={{ display: this.state.boardingStepTwo }}
                  >
                    <TextContainer>
                      <p style={{ fontSize: "1.6rem" }}>
                        <LanguageSwitch Words="According to a study, there is a" />{" "}
                        <span style={{ fontWeight: "500" }}>
                          <LanguageSwitch Words="15% lift in revenue" />
                        </span>{" "}
                        <LanguageSwitch Words="from" />{" "}
                        <span style={{ fontWeight: "500" }}>
                          <LanguageSwitch Words="Abandoned Cart Web Push Notifications." />
                        </span>
                      </p>

                      <div className="onBording-Img">
                        <img
                          src="/Benefits-Abandoned-Cart.png"
                          alt="Abandoned Cart Web Push"
                        />
                      </div>
                    </TextContainer>
                  </div>

                  <div
                    className="onBording__Step"
                    style={{ display: this.state.boardingStepThree }}
                  >
                    <TextContainer>
                      <Stack alignment="center" distribution="center">
                        <div
                          className="Benefit__Content"
                          style={{ fontSize: "1.6rem", fontWeight: "500" }}
                        >
                          <ul>
                            <li><LanguageSwitch Words="Easy Opt-In for Users" /></li>
                            <li><LanguageSwitch Words="No Email Address Required" /></li>
                            <li><LanguageSwitch Words="Higher Opt-In Rate" /></li>
                            <li><LanguageSwitch Words="Less Opt-Out Rate" /></li>
                            <li><LanguageSwitch Words="Higher CTR" /></li>
                            <li><LanguageSwitch Words="Higher Conversion Rate" /></li>
                          </ul>
                        </div>

                        <div className="onBording-Img Benefit__Img">
                          <img
                            src="/Abandoned-Cart.png"
                            alt="Abandoned Cart Web Push"
                          />
                        </div>
                      </Stack>
                    </TextContainer>
                  </div>

                  <div
                    className="onBording__Step"
                    style={{ display: this.state.boardingStepFour }}
                  >
                    <TextContainer>
                      <Heading><LanguageSwitch Words="How Abandoned Cart Web Push works?" /></Heading>

                      <p style={{ fontSize: "1.6rem", marginTop: "1.2rem" }}>
                        <LanguageSwitch Words="1. Users subscribe to your web push notifications via browser prompt." />
                      </p>
                      <p style={{ fontSize: "1.6rem", marginTop: "1.2rem" }}>
                        <LanguageSwitch Words="2. Your send out the push notifications." />
                      </p>
                      <p style={{ fontSize: "1.6rem", marginTop: "1.2rem" }}>
                        <LanguageSwitch Words="3. Subscribers receive push notifications on their browser." />
                      </p>
                      <p style={{ fontSize: "1.6rem", marginTop: "1.2rem" }}>
                        <LanguageSwitch Words="4. Subscribers click on your push notifications and land on your website." />
                      </p>

                      <p
                        style={{
                          fontSize: "1.3rem",
                          color: "rgb(142, 142, 142)",
                          marginTop: "1.2rem"
                        }}
                      >
                        * <LanguageSwitch Words="The appearance of the web push notification varies from the operating system and browser to browser in which the message is displayed." />
                      </p>

                      <div className="onBording-Img">
                        <img
                          src="/guide-to-website-push-notifications.gif"
                          alt="Abandoned Cart Web Push"
                          style={{ width: "450px" }}
                        />
                      </div>
                    </TextContainer>
                  </div>

                  <div
                    className="onBording__Step"
                    style={{ display: this.state.boardingStepFive }}
                  >
                    <FormLayout>
                      <div className="Polaris-Labelled__LabelWrapper">
                        <div className="Polaris-Label">
                          <label className="Polaris-Label__Text"><LanguageSwitch Words="Logo" /></label>
                        </div>
                      </div>

                      {/* <Thumbnail
                      source="/logo.jpg"
                      alt="logo"
                    /> */}
                      <Stack vertical={true}>
                        {setUpdateImageWarning}
                        <Stack spacing="loose">
                          <div
                            style={{ width: 95, height: 90, margin: "0 auto" }}
                          >
                            <DropZone
                              accept="image/*"
                              type="image"
                              onDrop={(files, acceptedFiles, rejectedFiles) => {
                                this.updateImage(acceptedFiles);
                              }}
                            >
                              <div className="Webpush_UploadImage">
                                {uploadedFiles}
                                {fileUpload}

                                <div
                                  style={{
                                    textAlign: "center",
                                    marginTop: "-1rem",
                                    paddingBottom: "5px",
                                    color: "#006fbb",
                                    display: this.state.imgButton
                                      ? "block"
                                      : "none"
                                  }}
                                >
                                  <span>
                                    {" "}
                                    {this.state.loadingLogo ? (
                                      <LoadingGif />
                                    ) : (
                                      "Upload Logo"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </DropZone>
                          </div>

                          <div
                            style={{
                              display: this.state.imgButton ? "none" : "block"
                            }}
                          >
                            <Button
                              size="slim"
                              disabled={this.state.imgButton}
                              onClick={this.deleteImage}
                            >
                              <LanguageSwitch Words="Remove" />
                            </Button>
                          </div>
                        </Stack>
                        <TextStyle variation="subdued">
                          <LanguageSwitch Words="64px x 64px; Less than 1MB; Accepts .jpg, .jpeg, .png." />
                        </TextStyle>
                      </Stack>

                      <div className="testPush__Input">
                        <TextField
                          label={<LanguageSwitch Words="Title" />}
                          id="testPushTitle"
                          value={this.state.testPushTitle}
                          onChange={this.handleInputChange}
                          maxLength={40}
                          showCharacterCount
                        />
                      </div>

                      <div className="testPush__Input">
                        <TextField
                          label={<LanguageSwitch Words="Message" />}
                          id="testPushMessage"
                          value={this.state.testPushMessage}
                          onChange={this.handleInputChange}
                          multiline
                          maxLength={50}
                          showCharacterCount
                        />
                      </div>
                    </FormLayout>
                  </div>
                </Modal.Section>

                <div className="Polaris-Modal-Footer">
                  <div className="Polaris-Modal-Footer__FooterContent">
                    <Stack alignment="center">
                      <Stack.Item fill></Stack.Item>
                      <Stack.Item>
                        <ButtonGroup>
                          <div style={{ display: this.state.PreviousButton }}>
                            <Button
                              plain
                              monochrome
                              onClick={this.onBoardingPrevious.bind(
                                this,
                                this.state.boardingStep
                              )}
                            >
                              <LanguageSwitch Words="Previous" />
                            </Button>
                          </div>
                          <div style={{ display: this.state.ContinueButton }}>
                            <Button
                              primary
                              onClick={this.onBoardingContinue.bind(
                                this,
                                this.state.boardingStep
                              )}
                            >
                              <LanguageSwitch Words="Continue" />
                            </Button>
                          </div>
                          <div style={{ display: this.state.SeeExampleButton }}>
                            <Button primary onClick={this.onBoardingSeeExample}>
                              <LanguageSwitch Words="See Example" />
                            </Button>
                          </div>
                          <div style={{ display: this.state.FinishedButton }}>
                            <Button onClick={this.onBoardingFinished}>
                              <LanguageSwitch Words="Done" />
                            </Button>
                          </div>
                        </ButtonGroup>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>
              </Modal>

              {HowItWorkModal}

              {macPushContent}
              {windPushContent}

              <div className="bigspace"></div>
              {/* <ThirdPartyADS /> */}
              {/* <SheetExample /> */}
            </Page>
          </Frame>
        </AppProvider>
      </div>
    );
  }
}

export default dashboard;
