import React, { Component } from "react";

import {
  AppProvider,
  Page,
  Frame,
  Card,
  TextContainer,
  ExceptionList,
  Link,
  SkeletonDisplayText,
  SkeletonBodyText,
  Layout,
  FormLayout,
  EmptyState,
  DataTable,
  Modal,
  TextField,
  DisplayText,
  Button,
  Stack,
  Toast,
} from "@shopify/polaris";
import { CalendarMinor } from "@shopify/polaris-icons";
import LoadingGif from "../../../../../src/components/LoadingGif";
// import SheetExample from '../../../../../src/components/ContactUsSheetExample/SheetExample';

import {
  reqGetAllSegements,
  reqGetSegement,
  reqUpdateSegement,
  reqDeleteSegement,
  reqAutoGetSubscriber,
} from "../../../../api";

import "./index.css";
import OnPageSEOTabs from "../../Tabs";
import SegmentRule from "../../../../components/SegmentRule";
import LanguageSwitch from "../../../../components/LanguageSwitch";

class dashboard extends Component {
  state = {
    //弹窗
    showToast: false, //弹窗显示
    showToastText: "", //弹窗文字
    showToastIserror: false, //弹窗是否错误

    //是否为编辑
    isEdit: true,
    //列表数据
    items: [],
    tableData: "",
    country: [],
    timezone: [],
    //弹窗数据
    id: "",
    fields: "",
    name: "",
    operate: "",
    value: "",
    estimated_subscriber: 0,
    segmentsContent: "",

    //规则数量
    segmentsNum: 0,
    segments: [],

    //字段默认值对应
    defaultsegment: [
      "Desktop",
      "Windows",
      "China",
      "beijing",
      "America/Los_Angeles",
      1,
      0,
      1,
      true,
    ],

    //剩余可选择的选项
    remainFieldsOptions: [
      { label: "Device", value: "1", disabled: false },
      { label: "OS", value: "2", disabled: false },
      { label: "Country", value: "3", disabled: false },
      // { label: "City", value: "4", disabled: false },
      { label: "TimeZone", value: "5", disabled: false },
      // { label: "Latest order", value: "6", disabled: false },
      // { label: "Average order value", value: "7", disabled: false },
      // { label: "Number of orders", value: "8", disabled: false },
      // { label: "Latest order had coupon", value: "9", disabled: false },
    ],
    //总选项
    fieldsOptions: [
      { label: "Device", value: "1", disabled: false },
      { label: "OS", value: "2", disabled: false },
      { label: "Country", value: "3", disabled: false },
      // { label: "City", value: "4", disabled: false },
      { label: "TimeZone", value: "5", disabled: false },
      // { label: "Latest order", value: "6", disabled: false },
      // { label: "Average order value", value: "7", disabled: false },
      // { label: "Number of orders", value: "8", disabled: false },
      // { label: "Latest order had coupon", value: "9", disabled: false },
    ],

    operateOptions: [
      { label: "equals", value: "1" },
      { label: "does not equal", value: "2" },
      { label: "includes", value: "3" },
      { label: "does no include", value: "4" },
      { label: "is greater than", value: "5" },
      { label: "is less than", value: "6" },
    ],
    //弹窗
    editDetailActive: false,
    saveButton: false,
    //页面动作
    loading: true,
    loadingPage: false,
    loadingSubscriber: false,
  };

  //提示弹窗
  toggleToast = (showToastText, showToastIserror) => {
    this.setState({
      showToastText: showToastText,
      showToastIserror: showToastIserror,
    });
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };
  callback = () => {
    this.setState({})
  }

  //进入页面调用接口
  componentDidMount() {
    this.GetCreateCampaign();
  }

  //获取数据
  GetCreateCampaign = async (open) => {
    if (open) {
      this.setState({
        loading: true,
        loadingPage: false,
      });
    }

    const params = {};
    const responseData = await reqGetAllSegements(params);
    if (responseData.code === "200") {
      var getData = responseData.data;
      var tableRow = getData.map((item) => {
        var nameDiv = (
          <div className="MessageListItem__Body sonDiv">
            <div className="Polaris-Stack__Item">{item.name}</div>
            <ExceptionList
              items={[
                {
                  icon: CalendarMinor,
                  description: item.created_at,
                },
              ]}
            />
          </div>
        );
        var EditSpan = (
          <Link onClick={() => this.editSegement(item.id)}><LanguageSwitch Words="Edit" /></Link>
        );
        var DeleteSpan = (
          <Link onClick={() => this.deleteSegement(item.id)}><LanguageSwitch Words="Delete" /></Link>
        );

        return [nameDiv, item.subscriber, EditSpan, DeleteSpan];
      });

      const table =
        tableRow.length === 0 ? (
          <div className="emptyWithImg">
            <EmptyState image="/empty.svg">
              <p>You can create a segment!</p>
            </EmptyState>
          </div>
        ) : (
          <div className="segmentTable">
            <DataTable
              columnContentTypes={["text", "text", "text", "text"]}
              headings={[<LanguageSwitch Words="Name" />, <LanguageSwitch Words="Subscribers" />, <LanguageSwitch Words="Action" />, ""]}
              rows={tableRow}
              verticalAlign="middle"
            />
          </div>
        );

      this.setState({
        loadingPage: true,
        loading: false,
        tableData: table,
        country: responseData.country,
        timezone: responseData.timezoneArr,
      });
    }
  };

  //删除数据
  deleteSegement = async (id) => {
    const params = { id: id };
    const responseData = await reqDeleteSegement(params);
    if (responseData.code === "200") {
      this.GetCreateCampaign(false);
      this.toggleToast(responseData.msg, false);
    }
  };

  //编辑数据
  editSegement = async (id) => {
    const params = { id: id };
    const responseData = await reqGetSegement(params);
    if (responseData.code === "200") {
      var haveOptions = []; //已经存在
      var allFieldsOptions = this.state.fieldsOptions; //所有可选项
      haveOptions = responseData.data.segments.map((item, key) => {
        //对已经存在的进行处理数据
        return item.fields;
      });
      allFieldsOptions.forEach((item, key) => {
        if (haveOptions.indexOf(item.value) !== -1) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
      });

      this.setState(
        {
          id: responseData.data.id,
          name: responseData.data.name,
          segments: responseData.data.segments,
          segmentsNum: responseData.data.segments.length,
          remainFieldsOptions: allFieldsOptions,
          isEdit: true,
        },
        () => {
          this.ControlEditDetailModal();
          this.autoGetSubscriber();
        }
      );
    }
  };

  //新增/修改component
  insertCreateCampaign = async () => {
    if (this.state.segments.length ===0) {
      this.toggleToast("Please add a rule at least!", false);
      return false;
    }

    const params = {
      id: this.state.id,
      name: this.state.name,
      segments: this.state.segments,
    };
    const responseData = await reqUpdateSegement(params);
    if (responseData.code === "200") {
      this.ControlEditDetailModal();
      this.GetCreateCampaign(false);
      this.toggleToast(responseData.msg, false);
    }else if(responseData.code === "501"){
      this.toggleToast(responseData.msg, false);
    }else if(responseData.code === "201"){
      this.toggleToast(responseData.msg, false);
    }
  };

  //打开创建segment模态框
  openCreateModal = () => {
    var initArr = [
      { label: "Device", value: "1", disabled: false },
      { label: "OS", value: "2", disabled: false },
      { label: "Country", value: "3", disabled: false },
      // { label: "City", value: "4", disabled: false },
      { label: "TimeZone", value: "5", disabled: false },
      // { label: "Latest order", value: "6", disabled: false },
      // { label: "Average order value", value: "7", disabled: false },
      // { label: "Number of orders", value: "8", disabled: false },
      // { label: "Latest order had coupon", value: "9", disabled: false },
    ];
    this.setState(
      {
        id: "",
        name: "",
        segments: [],
        segmentsNum: 0,
        isEdit: false,
        estimated_subscriber:0,
        remainFieldsOptions:initArr
      },
      () => {
        this.ControlEditDetailModal();
      }
    );
  };
  //弹窗控制开关
  ControlEditDetailModal = () => {
    this.setState(({ editDetailActive }) => ({
      editDetailActive: !editDetailActive,
    }),()=>{
      this.showSegment();
    });
  };

  handleChange = (field) => {
    return (value) => this.setState({ [field]: value });
  };

  //动态更新segment组件数量
  showSegment = () => {
    var segments = this.state.segments.map((item, index) => {
      return (
        <SegmentRule
          componentID={item.id}
          key={index}
          fields={item.fields}
          operate={item.operate}
          value={item.value}
          country={this.state.country}
          timezone={this.state.timezone}
          remainFieldsOptions={this.state.remainFieldsOptions}
          deleteComponent={this.deleteSegementComponent}
          changeComponent={this.changeSegementComponent}
          isEdit={this.state.isEdit}
        />
      );
    });
    this.setState({
      segmentsContent: segments,
    });
  };
  //删除组件
  deleteSegementComponent = (ID) => {
    var remain = [];
    remain = this.state.segments.filter((item, key) => {
      return item.id !== ID;
    });
    // 重新map
    this.setState({ segments: remain }, () => {
      var haveOptions = []; //已经存在
      var allFieldsOptions = this.state.fieldsOptions; //所有可选项
      haveOptions = remain.map((item, key) => {
        //对已经存在的进行处理数据
        return item.fields;
      });

      allFieldsOptions.forEach((item, key) => {
        if (haveOptions.indexOf(item.value) !== -1) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
      });

      this.setState({
        remainFieldsOptions: allFieldsOptions,
      },()=>{
        this.autoGetSubscriber();
        this.showSegment();
      });
    });
  };

  //动态添加组件
  addSegementComponent = () => {
    if (this.state.segments.length > this.state.fieldsOptions.length-1) {
      this.toggleToast("Can not add more rules!", false);
      return false;
    }
    var arrSegments = this.state.segments;
    var id = parseInt(this.state.segmentsNum) + 1;
    var newSegments = [];
    for (let i in this.state.remainFieldsOptions) {
      if (this.state.remainFieldsOptions[i].disabled === false) {
        newSegments = {
          id: id,
          fields: this.state.remainFieldsOptions[i].value,
          operate: "1",
          value: this.state.defaultsegment[
            parseInt(this.state.remainFieldsOptions[i].value) - 1
          ],
        };
        break;
      }
    }

    arrSegments.push(newSegments);
    this.setState({ segments: arrSegments, segmentsNum: id + "" }, () => {
      this.showSegment();
      var haveOptions = []; //已经存在
      var allFieldsOptions = this.state.fieldsOptions; //所有可选项
      haveOptions = arrSegments.map((item, key) => {
        //对已经存在的进行处理数据
        return item.fields;
      });
      allFieldsOptions.forEach((item, key) => {
        if (haveOptions.indexOf(item.value) !== -1) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
      });
      this.setState({
        remainFieldsOptions: allFieldsOptions,
      });
      this.autoGetSubscriber();
    });
  };

  //子组件内容修改
  changeSegementComponent = (result) => {
    var newarr = [];
    this.state.segments.forEach((item, key) => {
      if (item.id === result.id) {
        newarr[key] = result;
      } else {
        newarr[key] = item;
      }
    });

    this.setState(
      {
        segments: newarr,
      },
      () => {
        this.autoGetSubscriber(); //自动获取订阅者
        //更新disabled的状态
        var haveOptions = []; //已经存在
        var allFieldsOptions = this.state.fieldsOptions; //所有可选项
        haveOptions = newarr.map((item, key) => {
          //对已经存在的进行处理数据
          return item.fields;
        });

        allFieldsOptions.forEach((item, key) => {
          if (haveOptions.indexOf(item.value) !== -1) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        });

        this.setState({
          remainFieldsOptions: allFieldsOptions,
        });
      }
    );
  };

  //动态获取订阅人数
  autoGetSubscriber = async () => {
    this.setState({
      loadingSubscriber: true,
    });
    const params = {
      segments: this.state.segments,
    };
    const responseData = await reqAutoGetSubscriber(params);
    if (responseData.code === "200") {
      this.setState({
        loadingSubscriber: false,
        estimated_subscriber: responseData.subscriber,
      });
    }
  };

  render() {
    const {
      state: { name, editDetailActive },
      handleChange,
    } = this;

    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={this.toggleToast}
        error={this.state.showToastIserror}
      />
    ) : null;

    const subscribeDiv = this.state.loadingSubscriber ? (
      <LoadingGif />
    ) : (
      this.state.estimated_subscriber
    );

    const addRule = this.state.isEdit ? (
      ""
    ) : (
      <Button primary onClick={this.addSegementComponent}>
        +<LanguageSwitch Words="Add Rule" />
      </Button>
    );
    const ModalContent = (
      <div>
        <Card title={<LanguageSwitch Words="CONTENT" />}>
          <Card.Section>
            <FormLayout>
              <TextField
                label={<LanguageSwitch Words="Name" />}
                onChange={handleChange("name")}
                value={name}
                maxLength={50}
                showCharacterCount
              />
            </FormLayout>
          </Card.Section>
          <div className="space"></div>
        </Card>
        <Card title={<LanguageSwitch Words="SEGEMENT RULES" />}>
          <Card.Section>
            <Stack>
              <Stack.Item fill></Stack.Item>
              <Stack.Item>
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#F9F9F9",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    marginTop: "-5rem",
                  }}
                >
                  <div className="space"></div>
                  <LanguageSwitch Words="Estimated Subscribers" />
                  <DisplayText size="medium">{subscribeDiv}</DisplayText>
                  <div className="space"></div>
                </div>
              </Stack.Item>
            </Stack>
            <FormLayout>
              {addRule}
              {this.state.segmentsContent}
            </FormLayout>
          </Card.Section>
        </Card>
      </div>
    );

    const modalTitle = this.state.id ? <LanguageSwitch Words="Edit Segment" /> : <LanguageSwitch Words="Create Segment" />;

    const modal = (
      <Modal
        open={editDetailActive}
        onClose={this.ControlEditDetailModal}
        title={modalTitle}
        primaryAction={{
          content: <LanguageSwitch Words="Save" />,
          onAction: this.insertCreateCampaign,
          loading: this.state.saveButton,
        }}
        secondaryActions={[
          {
            content: <LanguageSwitch Words="Cancel" />,
            onAction: this.ControlEditDetailModal,
          },
        ]}
        large={true}
      >
        <Modal.Section>{ModalContent}</Modal.Section>
      </Modal>
    );

    const cardContent = this.state.loadingPage ? (
      this.state.tableData
    ) : (
      <div>
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </div>
    );

    const title = this.state.loading ? (
      <div>
        <SkeletonDisplayText size="small" />
      </div>
    ) : 
      (<div><TextContainer>
        <LanguageSwitch Words="Segments" />
      </TextContainer>
        <span style={{fontSize:"1.8rem",fontWeight:'400'}}>
          <LanguageSwitch Words="Group your Subscribers by demographic & behavioral info" />
        </span>
      </div>)
    ;

    const primarybutton = this.state.loading
      ? ""
      : {
          content: <LanguageSwitch Words="Create Segement" />,
          onAction: this.openCreateModal,
        };

    return (
      <div>
        <AppProvider>
          <Frame topBar={<OnPageSEOTabs
            callback={this.callback}
          />}>
            <Page title={title} primaryAction={primarybutton}>
              <div className="space"></div>
              <Card>
                {/* {loadingMarkup} */}
                <div className="CampaignList__Content">{cardContent}</div>
              </Card>
              {modal}
              <div className="space"></div>
              {/* <SheetExample /> */}
              {toastMarkup}
            </Page>
          </Frame>
        </AppProvider>
      </div>
    );
  }
}

export default dashboard;
