import React, { Component } from "react";

import {
  AppProvider,
  Stack,
  Page,
  Frame,
  Card,
  TextContainer,
  Heading,
  Tabs,
  ResourceList,
  Badge,
  ExceptionList,
  Link,
  Button,
  Popover,
  ActionList,
  SkeletonDisplayText,
  SkeletonBodyText,
  Layout,
  FormLayout,
  EmptyState
} from "@shopify/polaris";
import {
  CalendarMinor,
  MobileHorizontalDotsMajorMonotone
} from "@shopify/polaris-icons";
// import SheetExample from '../../../../../src/components/ContactUsSheetExample/SheetExample';

import { reqGetCreateCampaign, reqDeleteCreateCampaign } from "../../../../api";

import "./index.css";
import OnPageSEOTabs from "../../Tabs";
import LanguageSwitch from "../../../../components/LanguageSwitch";
class dashboard extends Component {
  state = {
    active: {},
    selected: 0,

    //单独4个数
    CAMPAIGNS: 0,
    INQUEUE: 0,
    SENT: 0,
    CLICKS: 0,
    //列表数据
    items: [],

    //页面动作
    loading: true,
    loadingPage: false
  };

  //进入页面调用接口
  componentDidMount() {
    this.GetCreateCampaign();
  }
  callback = () =>{
    this.setState({})
  }
  //获取数据
  GetCreateCampaign = async () => {
    this.setState({
      loading: true,
      loadingPage: false
    });
    const params = { type: "2" };
    const responseData = await reqGetCreateCampaign(params);
    if (responseData.code === "200") {
      this.setState({
        CAMPAIGNS: responseData.data.CAMPAIGNS,
        INQUEUE: responseData.data.INQUEUE,
        SENT: responseData.data.SENT,
        CLICKS: responseData.data.CLICKS,
        items: responseData.data.data,
        active: responseData.data.deleteArray,
        loadingPage: true,
        loading: false
      });
    }
  };

  //点击触发删除
  togglePopover = (id, active) => {
    var activeRes = this.state.active;

    activeRes[id] = !activeRes[id];

    this.setState({
      active: activeRes
    });
  };

  //删除数据
  DeleteCreateCampaign = async id => {
    const params = { id: id };
    const responseData = await reqDeleteCreateCampaign(params);
    if (responseData.code === "200") {
      this.GetCreateCampaign();
    }
  };

  render() {
    const tabs = [
      {
        id: "0",
        content: <LanguageSwitch Words="All" />,
        accessibilityLabel: "All",
        panelID: "All",
        url: "/admin/create-campaign/all"
      },
      {
        id: "1",
        content: <LanguageSwitch Words="Active" />,
        panelID: "Active",
        url: "/admin/create-campaign/active"
      },
      {
        id: "2",
        content: <LanguageSwitch Words="Inactive" />,
        panelID: "Inactive",
        url: "/admin/create-campaign/inactive"
      }
    ];

    const cardHeader = this.state.loadingPage ? (
      <FormLayout>
        <FormLayout.Group condensed>
          <Card sectioned>
            {/* <Card.Section> */}
              <div style={{ textAlign: "center" }}>
                <TextContainer>
                  <Heading><LanguageSwitch Words="CAMPAIGNS" /></Heading>
                  <Heading>{this.state.CAMPAIGNS}</Heading>
                </TextContainer>
              </div>
            {/* </Card.Section> */}
          </Card>
     
          <Card sectioned>
          {/* <Card.Section> */}
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <Heading><LanguageSwitch Words="IN QUEUE" /></Heading>
                <Heading>{this.state.INQUEUE}</Heading>
              </TextContainer>
            </div>
            {/* </Card.Section> */}
          </Card>

          <Card sectioned>
          {/* <Card.Section> */}
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <Heading><LanguageSwitch Words="IMPRESSION" /></Heading>
                <Heading>{this.state.SENT}</Heading>
              </TextContainer>
            </div>
            {/* </Card.Section> */}
          </Card>

          <Card sectioned>
          {/* <Card.Section> */}
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <Heading><LanguageSwitch Words="CLICKS" /></Heading>
                <Heading>{this.state.CLICKS}</Heading>
              </TextContainer>
            </div>
            {/* </Card.Section> */}
          </Card>
          </FormLayout.Group>
      </FormLayout>
    ) : (
      <Stack>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
      </Stack>
    );

    const cardContent = this.state.loadingPage ? (
      this.state.items.length===0?(
        <Tabs tabs={tabs} selected={this.state.selected}>
        <Card>
          <div className="emptyWithImg">
            <EmptyState image="/empty.svg">
              <p>No data to show</p>
            </EmptyState>
          </div>
        </Card>
        </Tabs>
      ):(
      <Tabs tabs={tabs} selected={this.state.selected}>
        <Card>
          <ResourceList
            items={this.state.items}
            renderItem={item => {
              const {
                title,
                messages,
                send_time,
                sent,
                clicks,
                CTR,
                typeState,
                id
              } = item;

              const activator = (
                <Button
                  icon={MobileHorizontalDotsMajorMonotone}
                  onClick={() => this.togglePopover(id, this.state.active[id])}
                ></Button>
              );

              const DuplicateLink = typeState ? (
                <span style={{ paddingLeft: "10px" }}>
                  <Link url={"/admin/create-campaign/insert/c" + id}>
                    <LanguageSwitch Words="Duplicate" />
                  </Link>
                </span>
              ) : (
                ""
              );
              const BadgeState = typeState ? (
                <Badge progress="complete"><LanguageSwitch Words="Completed" /></Badge>
              ) : (
                <Popover
                  active={this.state.active[id]}
                  activator={activator}
                  onClose={() => this.togglePopover(id, this.state.active[id])}
                >
                  <ActionList
                    items={[
                      {
                        content: <LanguageSwitch Words="Edit" />,
                        url: "/admin/create-campaign/insert/" + id
                      },
                      {
                        content: <LanguageSwitch Words="Duplicate" />,
                        url: "/admin/create-campaign/insert/c" + id
                      },
                      {
                        content: <LanguageSwitch Words="Delete" />,
                        onAction: () => {
                          this.DeleteCreateCampaign(id);
                        }
                      }
                    ]}
                  />
                </Popover>
              );
              return (
                <ResourceList.Item>
                  <div className="MessageListItem__Main">
                    <div className="MessageListItem__Title">
                      <Heading>{title}</Heading>
                    </div>
                    <div className="MessageListItem__statistics">
                      <div>{sent} <LanguageSwitch Words="sent" /></div>
                      <div>{clicks} <LanguageSwitch Words="clicks" /></div>
                      <div>{CTR} <LanguageSwitch Words="CTR" /></div>
                    </div>
                    <div className="MessageListItem__status">
                      {BadgeState}
                      {DuplicateLink}
                    </div>
                  </div>

                  <div className="MessageListItem__Body">
                    <div className="Polaris-Stack__Item">{messages}</div>
                    <ExceptionList
                      items={[
                        {
                          icon: CalendarMinor,
                          description: send_time
                        }
                      ]}
                    />
                  </div>
                </ResourceList.Item>
              );
            }}
          />
        </Card>
      </Tabs>)
    ):(
      <div>
         <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </div>
    );

    const title = this.state.loading?(
      <div><SkeletonDisplayText size="small" /></div>
    ):(<LanguageSwitch Words="Campaign" />);

    const primarybutton = this.state.loading ? (""):({
      content: <LanguageSwitch Words="Create Campaign" />,
      url: "/admin/create-campaign/insert/0"
    });
    return (
      <div>
        <AppProvider>
          <Frame topBar={<OnPageSEOTabs
            callback={this.callback}
          />}>
            <Page
              // breadcrumbs={[{content: 'Dashboard', url: '/admin/dashboard'}]}
              title={title}
              primaryAction={primarybutton}
            >
              {cardHeader}
              <div className="space"></div>
              <Card>
                {/* {loadingMarkup} */}
                <div className="CampaignList__Content">{cardContent}</div>
              </Card>

              <div className="space"></div>
              {/* <SheetExample /> */}
            </Page>
          </Frame>
        </AppProvider>
      </div>
    );
  }
}

export default dashboard;
