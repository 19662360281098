import React, { Component } from 'react';

import {Icon} from '@shopify/polaris';
import {
    LockMajorMonotone
  } from '@shopify/polaris-icons';

import './index.css';


class UpgradeIcon extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            upgradeModalShow:false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        
    }

    
    
    render() {
        

        return (
            <span>
                <span  className="upgradeicon"><Icon source={LockMajorMonotone} backdrop={true} color="inkLight"  /></span>
                <span style={{position: 'relative',top: '-12px',fontSize: '14px',color: '#454f5b',fontWeight: '600'}}>
                    Pro
                </span>
            </span>
        );
    }
}


export default UpgradeIcon;