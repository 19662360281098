import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  // Link,
  Switch,
  Redirect
} from 'react-router-dom';

import utils from './utils/utils';
import ErrorBoundary from './components/ErrorBoundary';
import CookieError from './pages/Admin/Error/cookieError';

import dashboard from './pages/Admin/Dashboard/dashboard';

//Abandoned Cart Recovery
// import AbandonedCartRecoveryAll from './pages/Admin/AbandonedCartRecovery/all';
// import AbandonedCartRecoveryInsert from './pages/Admin/AbandonedCartRecovery/insert';
// import AbandonedCartRecoveryTemplate from './pages/Admin/AbandonedCartRecovery/template';
// import ChatPlatform from "./components/ChatPlatform";


//Automated Cart Recovery
import AutomatedCartRecovery from './pages/Admin/AutomatedCartRecovery/index';
import AutomatedCartRecoveryInsert from './pages/Admin/AutomatedCartRecovery/insert';

//Abandoned Cart Recovery V2
import AbandonedCartRecoveryV2 from './pages/Admin/AbandonedCartRecoveryV2/index';
import AbandonedCartRecoveryInsertV2 from './pages/Admin/AbandonedCartRecoveryV2/insert';


//Create Campaign
import CreateCampaignAll from './pages/Admin/CreateCampaign/all';
import CreateCampaignActive from './pages/Admin/CreateCampaign/active';
import CreateCampaignInactive from './pages/Admin/CreateCampaign/inactive';
import CreateCampaignInsert from './pages/Admin/CreateCampaign/insert';

//Automation
import AutomationAll from './pages/Admin/Automation/all';
import Notification from './pages/Admin/Automation/notification';
import StockPush from './pages/Admin/Automation/stockpush';
import PriceDrop from './pages/Admin/Automation/pricedrop';
import Shipping from './pages/Admin/Automation/shipping';

//Settings
import Settings from './pages/Admin/Settings/index';

//Segments
import Segments from './pages/Admin/Segments/all';

//价格页面
import Pricing from './pages/Admin/Pricing/index/index'

// Error 404 / 500
import NotFound from './pages/Admin/Error/pageNotFound'
import ErrorPage from './pages/Admin/Error/error'

import PrivacyPolicy from './pages/Admin/PrivacyPolicy/index'
import { reqGetSelectLanguage } from "./api";

class BasicRoute  extends Component {
  UNSAFE_componentWillMount  () {
    let select_language = window.localStorage.getItem('SELECT_LANGUAGE');
    if(!select_language) {
      window.localStorage.setItem('SELECT_LANGUAGE', 'en');
      setTimeout(()=>{
        this.getSelectLanguage()
      },500)
    }
  }
  getSelectLanguage = async () => {
    const params = {};
    const response = await reqGetSelectLanguage(params);
    if(response.code === 200) {
      window.localStorage.setItem('SELECT_LANGUAGE', response.language);
    } else{
      window.localStorage.setItem('SELECT_LANGUAGE', 'en');
    }
  }
  requireAuth = (Layout, props)=>{ // 登录验证

    if(navigator.cookieEnabled===false){
      window.location.href = '/admin/cookieError';
      return false;
    }

    const params = new URLSearchParams(props.location.search);
    let domain    = params.get("domain");
    let usertoken = params.get("usertoken");
    let shopOwner = params.get("shop_owner");

  
    if(shopOwner){
      utils.setStore('S_SHOP_OWNER',shopOwner);
    }

    if(domain && usertoken){
      utils.setStore('S_SHOP_DOMAIN',domain);
      utils.setStore('S_USER_TOKEN',usertoken);
    }

    domain    = utils.getStore('S_SHOP_DOMAIN');
    usertoken = utils.getStore('S_USER_TOKEN');
	
    // console.log(this.store);
    let sign = '';
    if(domain && usertoken){
      sign = utils.getSign(domain,new Date().getTime());
    }

    if(!sign){
      return <p>Please try again later</p>
    }else{
      return <Layout {...props} />
    }
  }

  render() {

    return (
      <BrowserRouter>
        {/* <ChatPlatform App="Web Push" /> */}
        <div> 
        <ErrorBoundary>
          <Switch>
            <Route exact path="/" render={() => <Redirect to='/admin/dashboard'></Redirect>} />
            <Route exact path="/admin" render={() => <Redirect to='/admin/dashboard'></Redirect>} />
            <Route path="/admin/dashboard"  component={props => this.requireAuth(dashboard, props)} />
            
            {/* Abandoned Cart Recovery */}
            {/* <Route path="/admin/abandoned-cartecovery/all" component={props => this.requireAuth(AbandonedCartRecoveryAll, props)} />
            <Route path="/admin/abandoned-cartecovery/insert/:id" component={props => this.requireAuth(AbandonedCartRecoveryInsert, props)} />
            <Route path="/admin/abandoned-cartecovery/template" component={props => this.requireAuth(AbandonedCartRecoveryTemplate, props)} /> */}
            
            {/* Automated Cart Recovery */}
            <Route path="/admin/automated-cart-recovery/index" component={props => this.requireAuth(AutomatedCartRecovery, props)} />
            <Route path="/admin/automated-cart-recovery/insert/:id" component={props => this.requireAuth(AutomatedCartRecoveryInsert, props)} />

            {/* Abandoned Cart Recovery V2 */}
            <Route path="/admin/abandoned-cart-recovery/index" component={props => this.requireAuth(AbandonedCartRecoveryV2, props)} />
            <Route path="/admin/abandoned-cart-recovery-v2/insert/:id" component={props => this.requireAuth(AbandonedCartRecoveryInsertV2, props)} />
            
            {/* Create Campaign */}
            <Route path="/admin/create-campaign/all" component={props => this.requireAuth(CreateCampaignAll, props)} />
            <Route path="/admin/create-campaign/active" component={props => this.requireAuth(CreateCampaignActive, props)} />
            <Route path="/admin/create-campaign/inactive" component={props => this.requireAuth(CreateCampaignInactive, props)} />
            <Route path="/admin/create-campaign/insert/:id" component={props => this.requireAuth(CreateCampaignInsert, props)} />


            {/* Automation */}
            <Route path="/admin/automation/all" component={props => this.requireAuth(AutomationAll, props)} />
            <Route path="/admin/automation/notification" component={props => this.requireAuth(Notification, props)} />
            <Route path="/admin/automation/stockpush" component={props => this.requireAuth(StockPush, props)} />
            <Route path="/admin/automation/pricedrop" component={props => this.requireAuth(PriceDrop, props)} />
            <Route path="/admin/automation/shipping" component={props => this.requireAuth(Shipping, props)} />

            {/* Setting */}
            <Route path="/admin/advanced-settings/index" component={props => this.requireAuth(Settings, props)} />

            {/* segments */}
            <Route path="/admin/segments/all" component={props => this.requireAuth(Segments, props)} />


            
            {/* Pricing */}
            <Route onEnter={()=>this.checkAuth} path="/admin/pricing" component={props => this.requireAuth(Pricing, props)} />

            {/* <Redirect to="/"></Redirect> */}

            <Route path="/privacy-policy" component={ PrivacyPolicy } />

            {/* 异常处理 500 */}
            <Route path="/admin/error" component={ ErrorPage } />
            <Route path="/admin/cookieError" component={ CookieError } />
            {/* 异常处理 404 */}
            <Route path="/404" component={ NotFound } />
            <Redirect to="/404"></Redirect>
          </Switch>
        </ErrorBoundary>
        </div>
      </BrowserRouter>
    );
  }
}

export default BasicRoute ;
