/*
包含一些常量值的模块
 */

// export const API_DOMAIN = 'http://project8.cn' // api base url
export const API_DOMAIN = 'https://api-webpush.giraffly.com' // api base url  线上
// export const API_DOMAIN = 'https://test-api-webpush.giraffly.com' // api base url  测试服务器线上


export const REDIRECT_URI = 'https://seomaster-v2.channelwill.com/auth/googleSearchConsoleAuth'
export const CLLIENT_ID   = '175486626803-21stcuu686e6amc6mfue1rcssjnlir0d.apps.googleusercontent.com'
export const CLIENT_SECRET = '3_Yr2XHYvfXBkCkD6kg8NohI'
export const GOOGLLE_SEARCH_CONSOLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/auth?redirect_uri='+REDIRECT_URI+'&response_type=code&client_id='+CLLIENT_ID+'&scope=https://www.googleapis.com/auth/webmasters+https://www.googleapis.com/auth/webmasters.readonly+https://www.googleapis.com/auth/userinfo.email&approval_prompt=force&access_type=offline'; // google Search Console 授权请求地址