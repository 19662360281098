import React, { Component } from 'react';
import './index.css'
/*
 DiscountCode 推送弹窗 预览模板(不带背景)
 */

class DiscountCodeBox extends Component {

    constructor(props){
        super(props);

        this.state = {
            Discount_Code: props.code,
            Discount_Title: props.title,
            Discount_Message: props.message,
            Discount_Button: props.button,

            ShowDiscountCode: 'none',
            ShowButton: 'inline-block',
        }
    }

    //进入页面调用接口 (传入组件数据发生变化时 ，接受新数据重新渲染)
    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            Discount_Code: nextProps.code,
            Discount_Title: nextProps.title,
            Discount_Message: nextProps.message,
            Discount_Button: nextProps.button,
            ShowDiscountCode: 'none',
            ShowButton: 'inline-block',
        });
    }
    
    // 显示 discount code
    showHandleChange = ()=>{
        if(this.state.Discount_Code !== ''){
            this.setState({
                ShowDiscountCode: 'block',
                ShowButton: 'none',
            })
        }else{
            return false;
        }
    }

    render() {

        const { Discount_Code ,Discount_Title ,Discount_Message ,Discount_Button , ShowDiscountCode,ShowButton} = this.state;

        return (
            
            <div style={{width: '270px',border: '1px solid #343434',textAlign: 'center',borderRadius: '5px'}}>
                            
                <div style={{background: '#343434',display:'flex',borderTopLeftRadius: '5px',borderTopRightRadius: '5px',cursor: 'pointer'}}>
                    <div id="mydiscodeaction" style={{margin: '8px',float:'left',height: '22px',width: '25px',background: '#fff',position: 'absolute',borderRadius:'3px'}}>
                        <div style={{color:'#000',position:'absolute',marginLeft:'5px',marginTop:'10px',width:'15px',height:'3px',backgroundColor:'currentColor'}}></div>
                    </div>
                    <div style={{color: '#fff',fontSize: '1.5rem',margin:'9px',float:'left',paddingLeft:'40px'}}>
                        {Discount_Title}
                    </div>
                    <div style={{clear:'both'}}></div>
                </div>

                <div id="mydiscode" style={{textAlign: 'center',padding: '4rem 1rem 1rem',backgroundColor:'#fff'}}>
                    
                    {/* <div style={{background:'#666',width:'8rem',height:'8rem',margin: '0 auto 10px',borderRadius:'5rem'}}>
                        <div style={{background: '#666',width: '6rem',height: '6rem',borderRadius: '4rem',position: 'relative',left: '1.1rem',top: '1rem',border: '2px dashed #fff'}}>
                            <div style={{color: '#fff',fontSize: '35px',display: 'inline-block',lineHeight: '5rem',paddingLeft:'.3rem'}}>
                            %
                            </div>
                            <div style={{fontSize: '10px !important',display: 'inline-block',color: '#fff',lineHeight: '6rem'}}>
                            OFF
                            </div>
                        </div>
                    </div> */}

                    <div>
                        <img src='/Discount-Code-Sale.png?v=20191129' alt="Discount-Code" style={{maxWidth:'100%',maxHeight:'115px'}} />
                    </div>

                    <div style={{margin:'12px 10px 18px 10px',fontSize:'14px'}}>
                        { Discount_Message }
                    </div>

                    <div style={{cursor: 'pointer',padding: '0.4rem',border: '2px dashed #666',margin: '1rem',display: ShowDiscountCode}}>
                        {Discount_Code}
                    </div>

                    <div style={{
                    background: 'rgb(219, 29, 29)',
                    marginBottom: '1rem',
                    fontWeight: 'normal',
                    fontSize: '15px',
                    borderRadius: '5px',
                    padding: '6px 26px',
                    // display:'inline-block',
                    whiteSpace:'nowrap',
                    color:'#fff',
                    cursor: 'pointer',
                    display: ShowButton
                    }} onClick={this.showHandleChange} >
                    {Discount_Button}
                    </div>

                </div>

            </div>
        );
    }

}

export default DiscountCodeBox;