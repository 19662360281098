import React, { Component } from 'react';
import Switch from "react-switch";

import {SkeletonDisplayText,SkeletonBodyText,AppProvider, Page ,Frame , Layout, Card, Button, Stack, Heading, TextField, Toast,Banner, Spinner, FormLayout,TextContainer,Thumbnail,
  Select,Modal,ContextualSaveBar ,List ,Tooltip} from '@shopify/polaris';

// import SheetExample from '../../../../../src/components/ContactUsSheetExample/SheetExample';
import WebPushBox from '../../../../../src/components/WebPushBox/index';
import DiscountCodeBox from '../../../../../src/components/DiscountCodeBox/index';
import EditDetailModal from './editDetailModal';

import './index.css';
import OnPageSEOTabs from '../../Tabs';

import { reqSetWebPushControl,reqGetAutomatedCartRecovery,requpdateAutomatedCartRecoveryTime,requpdateAutomatedCartRecoveryCode,
  reqGetAbandonedCartRecoveryTemplate , reqSaveTemplateData ,reqUpdateAllWebPush , reqGetProductImage
} from '../../../../api';
import {API_DOMAIN} from '../../../../utils/constants';
import LanguageSwitch from "../../../../components/LanguageSwitch";
class dashboard extends Component {

  state = {
    // 顶部数据汇总
    SENT:0,
    CLICKS:0,
    CARTRECOVERED:0,
    REVENUE:0,
    UserLogoUrl:'',  // logo 图
    productImageUrl: '/template_Product_Image.jpg',  // sent test product image
    bannerTips: false,

    //变量框1
    Title1:'',
    Message1:'',
    URL1:'',
    httpSelectValue1:'',
    DiscountCode1:'',
    Mins:'',
    Button1_Title_1:'',
    Button1_Title_2:'',
    firstWebPushDiscountCode: false,  // Closed
    firstWebPushDiscountCodeSelect: '2',  // No
    DiscountCode1_Title: "Get 10% off today’s order!",
    DiscountCode1_Message: "Complete your cart with code below before the discount gets expired.",
    DiscountCode1_Button: "Copy discount code",
    DiscountCode1_Position: '3',
    //变量框2
    Title2:'',
    Message2:'',
    URL2:'',
    httpSelectValue2:'',
    DiscountCode2:'',
    Hours:'',
    Button2_Title_1:'',
    Button2_Title_2:'',
    secondWebPushDiscountCode: false,  // Closed
    secondWebPushDiscountCodeSelect: '2',  // No
    DiscountCode2_Title: "Get 10% off today’s order!",
    DiscountCode2_Message: "Complete your cart with code below before the discount gets expired.",
    DiscountCode2_Button: "Copy discount code",
    DiscountCode2_Position: '3',
    //变量框3
    Title3:'',
    Message3:'',
    URL3:'',
    httpSelectValue3:'',
    DiscountCode3:'',
    Day:'',
    Button3_Title_1:'',
    Button3_Title_2:'',
    thirdWebPushDiscountCode: true,  // Open
    thirdWebPushDiscountCodeSelect: '1',  // Yes
    DiscountCode3_Title: "Get 10% off today’s order!",
    DiscountCode3_Message: "Complete your cart with code below before the discount gets expired.",
    DiscountCode3_Button: "Copy discount code",
    DiscountCode3_Position: '3',
    //用户链接
    UserUrlName: '',

    //弹窗
    showToast: false,  //弹窗显示
    showToastText:'',  //弹窗文字
    showToastIserror:false, //弹窗是否错误

    //页面动作
    loading: true,
    loadingPage: false,

    //按钮动作
    ButtonLoading:false,
    ButtonState:0,
    
    //时间按钮状态
    ButtonTypeLoading:false,
    //输入框状态
    TextFieldDisabled:false,

    // 3个web push单独开关
    firstWebPushSwitch: true,
    secondWebPushSwitch: true,
    thirdWebPushSwitch: true,

    // 选择模板弹窗 模块
    templateActive: false,
    loadingPageSelect: false,
    templateType: 'All',
    // 推送弹窗模板列表
    templateData: '',

    // 当前正在选择模板的webPush 编号：1,2,3
    webPushNumber: 0,

    // 用户待选定的模板数据 点击 save 后进行替换
    Selected_TemplateId: '',
    Selected_TemplateTitle: '',
    Selected_TemplateMessage: '',

    // 顶部保存
    showTopSaveBar: false,
    topSaveButtonLoading: false,

    // 页面详情所有内容 (用户选择还原数据 discard 时使用)
    initPageData:[],
    // 监听用户内容变化变量 (在用户未保存离开是做提示用)
    dataHasChange: false,
    leavingModalActive: false,
    // 用户还原回到 未编辑时内容版本 ( 弹窗提示 )
    discardModalActive: false,

    // 错误信息提示
    criticalWindow :'',
    Title1ErrorMsg:'',
    Message1ErrorMsg:'',
    URL1ErrorMsg:'',
    DiscountCode1ErrorMsg:'',
    MinsErrorMsg:'',

    Title2ErrorMsg:'',
    Message2ErrorMsg:'',
    URL2ErrorMsg:'',
    DiscountCode2ErrorMsg:'',
    HoursErrorMsg:'',

    Title3ErrorMsg:'',
    Message3ErrorMsg:'',
    URL3ErrorMsg:'',
    DiscountCode3ErrorMsg:'',
    DayErrorMsg:'',

    // send test / see example
    showExample: false,

    WebPushExample:false,
    sendTestTitle: '',
    sendTestMessage: '',
    sendTestUrl: '',

    DiscountCodeExample:false,
    seeExampleCode: '',
    seeExampleTitle: '',
    seeExampleMessage: '',
    seeExampleButton: '',

    isWindows: true,  // 当前设备

    // 自动显示 不同提示
    Title1HelpText: '',
    Message1HelpText: '',
    Title2HelpText: '',
    Message2HelpText: '',
    Title3HelpText: '',
    Message3HelpText: '',

    // 用户 选择(取消)填写 Discount Code 时，自动填充相对应的模板数据
    // 3套 普通模板[当前使用discount code ,切换 不提供时]
    tempWebpushTitle1: 'Your item is selling out fast',
    tempWebpushMessage1: 'Buy them now before they are out of stock.',

    tempWebpushTitle2: 'We’ve saved your cart for you',
    tempWebpushMessage2: 'Buy them now before they get out of stock.',

    tempWebpushTitle3: 'Don’t be the one who missed out!',
    tempWebpushMessage3: 'Buy the [item_name] in your cart before you lose it!',

    // 3套 discount code 模板 [当前使用普通 ,切换 填写discount code时]
    tempInDiscountCodeTitle1: 'You get [value]% off now for your items in cart',
    tempInDiscountCodeMessage1: 'Buy them now, before the discount gets expired.',

    tempInDiscountCodeTitle2: 'Look what Fell!',
    tempInDiscountCodeMessage2: 'Buy the [item_name] at [value]% less!',

    tempInDiscountCodeTitle3: 'Here is a [value]% discount on your cart!',
    tempInDiscountCodeMessage3: 'Discount code [code] with out compliments.',
  };

  //进入页面调用接口
  componentDidMount () {
    this.GetAutomatedCartRecovery();

    // 检测用户当前设备是否为mac
    var is_Mac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    if(is_Mac){
        this.setState({
            isWindows: false,
        });
    }
    
  }

  callback = ()=>{
    this.setState({})
  }

  componentWillUnmount() {
    
  }

  // 用户 编辑完成功保存后，将数据更新到父级页面
  callBackRefresh = (type,title,message,buttonTitle1,buttonTitle2) =>{

    if(type === 1){
      this.setState({ 
        Title1: title,
        Message1: message,
        Button1_Title_1: buttonTitle1,
        Button1_Title_2: buttonTitle2,
      });

    } else if(type === 2) {
      this.setState({ 
        Title2: title,
        Message2: message,
        Button2_Title_1: buttonTitle1,
        Button2_Title_2: buttonTitle2,
      });

    } else if(type === 3) {
      this.setState({ 
        Title3: title,
        Message3: message,
        Button3_Title_1: buttonTitle1,
        Button3_Title_2: buttonTitle2,
      });
    }

  }


  // 检测弹窗内容，返回不同提示 \n 
  autoReturnHelpText(content){
    if(content === '' || content === undefined){
      return '';
    }

    var tips = '';
    if(content.indexOf("[item_name]") !== -1){
      tips += '[item_name] will replaced by actual abandoned product name automatically. \n';
    }
    if(content.indexOf("[store_name]") !== -1){
      tips += '[store_name] will replaced by your store name automatically. \n';
    }
    if(content.indexOf("[price]") !== -1){
      tips += '[price] will replaced by actual abandoned product price automatically. \n';
    }
    if(content.indexOf("[code]") !== -1){
      tips += '[code] will replaced by the discount code you enter below automatically. \n';
    }
    if(content.indexOf("[value]") !== -1){
      tips += 'Replace [value] with discount. e.g. [value] -> 10 \n';
    }
    if(content.indexOf("[time]") !== -1){
      tips += 'Replace [time] with number for your limited-time offer. e.g. [time] -> 1';
    }

    return tips;
  }

  // helptext 提示赋值
  SetHelpText = (type,title,message) =>{
    if(type===1){
      this.setState({
        Title1HelpText: this.autoReturnHelpText(title),
        Message1HelpText: this.autoReturnHelpText(message),
      });

    } else if(type===2){
      this.setState({
        
        Title2HelpText: this.autoReturnHelpText(title),
        Message2HelpText: this.autoReturnHelpText(message),
      });

    }else{
      this.setState({
        Title3HelpText: this.autoReturnHelpText(title),
        Message3HelpText: this.autoReturnHelpText(message),
      });

    }
  }

  // get product imgae use in send test
  getProdcutImage = async () =>{
    const params = {};
    const response = await reqGetProductImage(params)
    if (response.code==='200') {
        this.setState({
          productImageUrl: response.data.productImageUrl,
        });
    }
  }

  // 更新数据
  updatePageDetail(isDiscard){
    let getData = this.state.initPageData;

    this.setState({ 
      Title1:getData.type1.Title1,
      Message1:getData.type1.Message1,
      URL1:getData.type1.URL1,
      httpSelectValue1:getData.type1.httpSelectValue1,
      DiscountCode1:getData.type1.DiscountCode1,
      Mins:getData.type1.Time1,
      Button1_Title_1: getData.type1.button1Title,
      Button1_Title_2: getData.type1.button2Title,
      DiscountCode1_Title: getData.type1.DiscountCode1_Title,
      DiscountCode1_Message: getData.type1.DiscountCode1_Message,
      DiscountCode1_Button: getData.type1.DiscountCode1_Button,
      DiscountCode1_Position: getData.type1.DiscountCode1_Position,
      firstWebPushDiscountCodeSelect: String(getData.type1.DiscountCode1_Select),  // No or yes
      firstWebPushDiscountCode: (getData.type1.DiscountCode1_Select===1 || getData.type1.DiscountCode1_Select==='1') ? true : false,  // Closed

      Title2:getData.type2.Title2,
      Message2:getData.type2.Message2,
      URL2:getData.type2.URL2,
      httpSelectValue2:getData.type2.httpSelectValue2,
      DiscountCode2:getData.type2.DiscountCode2,
      Hours:getData.type2.Time2,
      Button2_Title_1: getData.type2.button1Title,
      Button2_Title_2: getData.type2.button2Title,
      DiscountCode2_Title: getData.type2.DiscountCode2_Title,
      DiscountCode2_Message: getData.type2.DiscountCode2_Message,
      DiscountCode2_Button: getData.type2.DiscountCode2_Button,
      DiscountCode2_Position: getData.type2.DiscountCode2_Position,
      secondWebPushDiscountCodeSelect: String(getData.type2.DiscountCode2_Select),  // No or yes
      secondWebPushDiscountCode: (getData.type2.DiscountCode2_Select===1 || getData.type2.DiscountCode2_Select==='1') ? true : false,  // Closed

      Title3:getData.type3.Title3,
      Message3:getData.type3.Message3,
      URL3:getData.type3.URL3,
      httpSelectValue3:getData.type3.httpSelectValue3,
      DiscountCode3:getData.type3.DiscountCode3,
      Day:getData.type3.Time3,
      Button3_Title_1: getData.type3.button1Title,
      Button3_Title_2: getData.type3.button2Title,
      DiscountCode3_Title: getData.type3.DiscountCode3_Title,
      DiscountCode3_Message: getData.type3.DiscountCode3_Message,
      DiscountCode3_Button: getData.type3.DiscountCode3_Button,
      DiscountCode3_Position: getData.type3.DiscountCode3_Position,
      thirdWebPushDiscountCodeSelect: String(getData.type3.DiscountCode3_Select),  // No or yes
      thirdWebPushDiscountCode: (getData.type3.DiscountCode3_Select===1 || getData.type3.DiscountCode3_Select==='1') ? true : false,  // Closed
      
      loadingPage : true,
      loading : false,
      UserUrlName:getData.userUrl,
      // 总开关
      ButtonState : getData.type,
      // 3个web push单独开关
      firstWebPushSwitch: getData.firstWebPushSwitch,
      secondWebPushSwitch: getData.secondWebPushSwitch,
      thirdWebPushSwitch: getData.thirdWebPushSwitch,
      // 顶部统计
      SENT:getData.SENT,
      CLICKS:getData.CLICKS,
      CARTRECOVERED:getData.CARTRECOVERED,
      REVENUE:getData.REVENUE,
      UserLogoUrl: getData.logo_url,
    });

    this.SetHelpText(1,getData.type1.Title1,getData.type1.Message1);
    this.SetHelpText(2,getData.type2.Title2,getData.type2.Message2);
    this.SetHelpText(3,getData.type3.Title3,getData.type3.Message3);

    if(isDiscard){
      // removeErrorMsg
      if(this.state.criticalWindow !== ''){
        this.removeErrorMsg();
      }
    }
  }


  //获取数据
  GetAutomatedCartRecovery = async () => {
    const params = {};
    const responseData = await reqGetAutomatedCartRecovery(params)
    if (responseData.code==='200') {
      var initData = responseData.data;
      this.setState({ 
        initPageData:initData,
      })

      // 渲染数据
      this.updatePageDetail(false);

      this.getProdcutImage();
    }else{
      this.toggleToast(responseData.msg,true);
    }
  }

  // 推送 web push 请求开关
  SetWebPushControl = async (updateType) => {
    // 更新总开关
    if(updateType === 'webPush'){
      this.setState({ 
        ButtonLoading:true
      })
    }
    const params = {type:updateType};
    const responseData = await reqSetWebPushControl(params)
    if (responseData.code==='200') {
      // 更新总开关
      if(responseData.data !== ''){

        this.setState({ 
          ButtonState:responseData.data,
          ButtonLoading:false,
        })

        this.toggleToast(responseData.msg,false);
      }
      
    } else {
      this.toggleToast(responseData.msg,true);
    }
  }

  
  //提示弹窗
  toggleToast = (showToastText,showToastIserror) => {
    this.setState({
    showToastText:showToastText,
    showToastIserror:showToastIserror
    });
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

  // 设置弹窗时间 优惠码等
  handleChange = (field) => (value) => {

    if(field ==="Mins" || field ==="Hours" ||field ==="Day"){  // 判断是否是输入时间的
      
      if(field ==="Mins"){
        if(value>=60){
          value = 5;
        }
      } else if(field ==="Hours"){
        if(value<1 || value>24){
          value = 1;
        }
      } else if(field ==="Day"){
        if(value<1 || value>7){
          value = 1;
        }
      }
      
    }
    
    this.setState({[field]: value,dataHasChange: true});

    // removeErrorMsg
    if(this.state.criticalWindow !== ''){
      this.removeErrorMsg();
    }

    // 显示顶部保存栏
    // if(!this.state.showTopSaveBar){
    //   this.TopSaveBarAction();
    // }
  };

  //修改时间
  updateAutomatedCartRecoveryTime = async (type) => {
    this.setState({ 
      ButtonTypeLoading:true
    })
    //判断类型取对应时间
    var time = 0;
    if(type === 1){
      time = this.state.Mins;
    }else if(type === 2){
      time = this.state.Hours;
    }else if(type === 3){
      time = this.state.Day;
    }

    if(!time){
      this.toggleToast('Please set a time',true);
      return false;
    }

    const params = {type:type,time:time};
    const responseData = await requpdateAutomatedCartRecoveryTime(params)
    if (responseData.code==='200') {
      this.setState({ 
        ButtonTypeLoading:false
      })
      this.toggleToast(responseData.msg,false);
    }else{
      this.toggleToast(responseData.msg,true);
    }
  }

  
  //修改优惠卷
  updateAutomatedCartRecoveryCode = async (type) => {
    this.setState({ 
      TextFieldDisabled:true
    })
    //判断类型取对应时间
    var code = '';
    if(type === 1){
      code = this.state.DiscountCode1;
    }else if(type === 2){
      code = this.state.DiscountCode2;
    }else if(type === 3){
      code = this.state.DiscountCode3;
    }
    const params = {type:type,code:code};
    const responseData = await requpdateAutomatedCartRecoveryCode(params)
    if (responseData.code==='200') {
      this.setState({ 
        TextFieldDisabled:false
      })
      this.toggleToast(responseData.msg,false);
    }else{
      this.toggleToast(responseData.msg,false);
    }
  }

  // input 输入框变化
  handleChangeInput = (value,name) =>{

    // 如果是输入地址 过滤 https:// http:// 判断合法性等
    if(name==="URL1" || name==="URL2" || name==="URL3"){  
      value = value.replace("http://",'');
      value = value.replace("https://",'');

      var reg=/[\w-]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/;

      var UrlError = '';
      if(!reg.test(value)){
        if(value){
          UrlError = 'Enter a valid website URL. eg: google.com'; 
        }
      }
      if(name==="URL1"){
        this.setState({ 
          [name]: value,
          URL1ErrorMsg: UrlError,
          dataHasChange: true
        });

      } else if(name==="URL2"){
        this.setState({ 
          [name]: value,
          URL2ErrorMsg: UrlError,
          dataHasChange: true
        });

      } else {
        this.setState({ 
          [name]: value,
          URL3ErrorMsg: UrlError,
          dataHasChange: true
        });
      }

    } else {
      if(name==="DiscountCode1_Title" || name==="DiscountCode2_Title" || name==="DiscountCode3_Title"){
        if(value === ''){
          value = 'Get 10% off today’s order!';
        }
      } else if(name==="DiscountCode1_Message" || name==="DiscountCode2_Message" || name==="DiscountCode3_Message"){
        if(value === ''){
          value = 'Complete your cart with code below before the discount gets expired.';
        }
      } else if(name==="DiscountCode1_Button" || name==="DiscountCode2_Button" || name==="DiscountCode3_Button"){
        if(value === ''){
          value = 'Copy discount code';
        }
      }

      this.setState({ 
        [name]: value,
        dataHasChange: true
      });

      if(name==="Title1"){
        this.SetHelpText(1,value,this.state.Message1)

      } else if(name==='Message1'){
        this.SetHelpText(1,this.state.Title1,value)

      } else if(name==="Title2"){
        this.SetHelpText(2,value,this.state.Message2)

      } else if(name==='Message2'){
        this.SetHelpText(2,this.state.Title2,value)

      } else if(name==="Title3"){
        this.SetHelpText(3,value,this.state.Message3)

      } else if(name==='Message3'){
        this.SetHelpText(3,this.state.Title3,value)
      }
    }

    // removeErrorMsg
    if(this.state.criticalWindow !== ''){
      this.removeErrorMsg();
    }

    // 显示顶部保存栏
    // if(!this.state.showTopSaveBar){
    //   this.TopSaveBarAction();
    // }
    
  }

  // 单个web push 控制按钮
  handleCheckChange = (name,value)=> {
    this.setState({ 
      [name]:value,
    },()=>{
      if(value){
        this.toggleToast("Activated",false);
      }else{
        this.toggleToast("Inactivated",false);
      }
    });

    // removeErrorMsg
    if(this.state.criticalWindow !== ''){
      this.removeErrorMsg();
    }

    // 更新请求
    var updateTpye = '';
    if(name === 'firstWebPushSwitch'){
      updateTpye = 'firstWebPush';
    } else if(name === 'secondWebPushSwitch'){
      updateTpye = 'secondWebPush';
    } else if(name === 'thirdWebPushSwitch'){
      updateTpye = 'thirdWebPush';
    }
    this.SetWebPushControl(updateTpye);
  }

  // 是否有 优惠码选择 (自动切换模板)
  handleSelectChange = (value,name) => {
    var setBool = (value === '1') ? true:false;

    this.setState({ 
      [name]: value,
      dataHasChange: true
    });

    var getData = this.state.initPageData;
    var ReplaceTitle = '';
    var ReplaceMessage = '';
    if(name === 'firstWebPushDiscountCodeSelect'){
      // 上次保存的状态 1-yes  2-no
      let initSelect1 = parseInt(getData.type1.DiscountCode1_Select);
      
      // 模板更换
      if(setBool){  // 开启
        if(initSelect1 === 1){
          ReplaceTitle = getData.type1.Title1;
          ReplaceMessage = getData.type1.Message1;
        }else{
          ReplaceTitle = this.state.tempInDiscountCodeTitle1;
          ReplaceMessage = this.state.tempInDiscountCodeMessage1;
        }

      } else {  // 取消
        if(initSelect1 === 2){
          ReplaceTitle = getData.type1.Title1;
          ReplaceMessage = getData.type1.Message1;
        }else{
          ReplaceTitle = this.state.tempWebpushTitle1;
          ReplaceMessage = this.state.tempWebpushMessage1;
        }
      }

      this.setState({ 
        Title1: ReplaceTitle,
        Message1: ReplaceMessage,
        firstWebPushDiscountCode: setBool,
      });

      this.SetHelpText(1,ReplaceTitle,ReplaceMessage)

    } else if(name === 'secondWebPushDiscountCodeSelect'){
      // 上次保存的状态 1-yes  2-no
      let initSelect2 = parseInt(getData.type2.DiscountCode2_Select);
      
      // 模板更换
      if(setBool){  // 开启
        if(initSelect2 === 1){
          ReplaceTitle = getData.type2.Title2;
          ReplaceMessage = getData.type2.Message2;
        }else{
          ReplaceTitle = this.state.tempInDiscountCodeTitle2;
          ReplaceMessage = this.state.tempInDiscountCodeMessage2;
        }

      } else {  // 取消
        if(initSelect2 === 2){
          ReplaceTitle = getData.type2.Title2;
          ReplaceMessage = getData.type2.Message2;
        }else{
          ReplaceTitle = this.state.tempWebpushTitle2;
          ReplaceMessage = this.state.tempWebpushMessage2;
        }
      }

      this.setState({ 
        Title2: ReplaceTitle,
        Message2: ReplaceMessage,
        secondWebPushDiscountCode: setBool
      });
      this.SetHelpText(2,ReplaceTitle,ReplaceMessage)

    } else if(name === 'thirdWebPushDiscountCodeSelect'){
      // 上次保存的状态 1-yes  2-no
      let initSelect3 = parseInt(getData.type3.DiscountCode3_Select);
      
      // 模板更换
      if(setBool){  // 开启
        if(initSelect3 === 1){
          ReplaceTitle = getData.type3.Title3;
          ReplaceMessage = getData.type3.Message3;
        }else{
          ReplaceTitle = this.state.tempInDiscountCodeTitle3;
          ReplaceMessage = this.state.tempInDiscountCodeMessage3;
        }

      } else {  // 取消
        if(initSelect3 === 2){
          ReplaceTitle = getData.type3.Title3;
          ReplaceMessage = getData.type3.Message3;
        }else{
          ReplaceTitle = this.state.tempWebpushTitle3;
          ReplaceMessage = this.state.tempWebpushMessage3;
        }
      }

      this.setState({ 
        Title3: ReplaceTitle,
        Message3: ReplaceMessage,
        thirdWebPushDiscountCode: setBool
      });
      this.SetHelpText(3,ReplaceTitle,ReplaceMessage)
    }

    // removeErrorMsg
    if(this.state.criticalWindow !== ''){
      this.removeErrorMsg();
    }

    // 显示顶部保存栏
    // if(!this.state.showTopSaveBar){
    //   this.TopSaveBarAction();
    // }
  };


  // 获取弹窗模板列表
  getWebPushTemplateList = async(templateType) =>{

    this.setState({ 
      //启动状态
      loadingPageSelect: false,
    })

    if(templateType === "All"){
      templateType = 2;
    }else if(templateType === "Discount"){
      templateType = 1;
    }else if(templateType === "General"){
      templateType = 0;
    }

    const params = {type:templateType};
    const responseData = await reqGetAbandonedCartRecoveryTemplate(params)
    if (responseData.code==='200') {
      const products  = responseData.data.data.map((item , key)=>{
        
        var templateId = item.id;
        var templateTitle = item.title;
        var templateMessage = item.messages;

        var layoutContent = '';
        if(item !== ''){
          layoutContent = <Layout.Section oneHalf key={key}>
            <div className="webPush_Template_Card" id={"webPush_"+item.id} onClick={this.selectTemplate.bind(this,templateId,templateTitle,templateMessage)}>
              <Card sectioned>
                  <Stack wrap={false}>
                    <Thumbnail
                      source={this.state.UserLogoUrl}
                      size="small"
                      alt="Abandoned Cart Recovery"
                    />

                    <Stack alignment="center">
                      <section className="Template__Content">
                        <div className="Template__Title">
                          { templateTitle }
                        </div>
                        <div className="Template__Message">
                          { templateMessage }
                        </div>
                      </section>
                    </Stack>
                  </Stack>
                  <Stack wrap={false}>
                    <div style={{width:'4rem'}}></div>

                    <Stack alignment="center">
                      <div className="Template__Button_1">{item.button_title_1}</div>
                    </Stack>
                    <Stack alignment="center">
                      <div className="Template__Button_2">{item.button_title_2}</div>
                    </Stack>
                  </Stack>

              </Card>
            </div>
          </Layout.Section>
        }else{
          layoutContent = <Layout.Section oneHalf key={key}>
          </Layout.Section>
        }

        return ([
          layoutContent
        ]);
      });

      this.setState({ 
        templateData:products,
        loadingPageSelect : true,
      })

    } else {
      var templateDataEnd = <div style={{padding:'2rem'}}>Sorry... No Results Found</div>;

      this.setState({ 
        templateData: templateDataEnd,
        loadingPageSelect : true,
      });

      this.toggleToast(responseData.msg,true);

    }

  }

  // 模板类型切换，默认为 all (之前选中的模板数据 置空)
  handleSelectTemplateType = (SelectACategory) =>{
    this.getWebPushTemplateList(SelectACategory);
    this.setState({
      templateType:SelectACategory,
      Selected_TemplateId: '',
      Selected_TemplateTitle: '',
      Selected_TemplateMessage: ''
    });

  }


  // 模板弹窗开关 处理
  handleTemplateModal = (isOpen,webPushNumber) =>{
    
    if(isOpen){  
      this.setState({
        Selected_TemplateId: '',
        Selected_TemplateTitle: '',
        Selected_TemplateMessage: '',
        webPushNumber: webPushNumber, // 当前正在选择模板的 webPush 编号
      });
      // 获取模板列表
      this.getWebPushTemplateList(this.state.templateType);
    }else{
      this.setState({
        webPushNumber: 0, // 当前正在选择模板的 webPush 编号 设置回默认值
      });
    }

    // removeErrorMsg
    if(this.state.criticalWindow !== ''){
      this.removeErrorMsg();
    }
    
    this.setState(({templateActive}) => ({templateActive: !templateActive}));
  }

  // selectTemplate (鼠标选中状态修改)
  selectTemplate = (id,title,message,e) =>{
    
    if(this.state.Selected_TemplateId !== ''){  // 先移除 之前选中的模板样式
      this.selectedClassControl(this.state.Selected_TemplateId,false);
    }

    this.setState({
      Selected_TemplateId: id,
      Selected_TemplateTitle: title,
      Selected_TemplateMessage: message
    },()=>{

      // 添加选中样式
      this.selectedClassControl(id,true);
    });

  }

  // 添加/ 移除 已选中的 class
  selectedClassControl(id,isAdd){
    if(id){
      var obj = document.getElementById("webPush_"+id);
      var targetClass = 'Template_Card_Selected';
      var hasClass = !!obj.className.match(new RegExp("(\\s|^)" + targetClass + "(\\s|$)")); 

      if(isAdd){  // 添加 class
        if(!hasClass){
          obj.className += " " + targetClass;
        }
      }else{
        if(hasClass){
          obj.className = obj.className.replace(new RegExp("(\\s|^)" + targetClass + "(\\s|$)"), " "); 
        }
      }
    }else{
      return false;
    }

  }

  // edit Selected Template  将模板数据（id）跳转到编辑页面
  editSelectedTemplate = () =>{

    // <Button url="/admin/abandoned-cart-recovery-v2/insert/1">Edit</Button>

    if(this.state.Selected_TemplateId === ''){
      this.toggleToast('Please select a template',true);
      return false;
    }

    // 关闭弹窗
    this.handleTemplateModal(false);

    // 传递当前编辑的webpush 编号 和 模板对应的ID
    let fixedId = this.state.webPushNumber + "" + this.state.Selected_TemplateId;

    window.location.href="/admin/abandoned-cart-recovery-v2/insert/" + fixedId;

  }

  // save Selected Template ，将模板数据应用到当前的 web Push 内容中
  saveSelectedTemplate = () =>{

    if(this.state.Selected_TemplateId === ''){
      this.toggleToast('Please select a template',true);
      return false;
    }

    // 数据替换 ，并关闭模板弹窗
    let NowEditPush = this.state.webPushNumber;  // 当前正在编辑的 web push 编号

    // 数据处理下，有换行符
    let setTitle = this.state.Selected_TemplateTitle;
    let setMessage = this.state.Selected_TemplateMessage;

    setTitle = setTitle.replace(/[\r\n]/g, " "); 
    setTitle = setTitle.replace(/\s+/g," "); 
    setMessage = setMessage.replace(/[\r\n]/g, " "); 
    setMessage = setMessage.replace(/\s+/g," "); 

    if(NowEditPush === 1){
      this.setState({ 
        Title1: setTitle,
        Message1: setMessage,
      });

      this.SetHelpText(1,setTitle,setMessage)

    } else if(NowEditPush === 2){
      this.setState({ 
        Title2: setTitle,
        Message2: setMessage,
      });
      this.SetHelpText(2,setTitle,setMessage)

    } else if(NowEditPush === 3){
      this.setState({ 
        Title3: setTitle,
        Message3: setMessage,
      });
      this.SetHelpText(3,setTitle,setMessage)

    } else{  // === 0
      this.toggleToast('Something wrong, please refresh the page',true);
      return false;
    }

    // removeErrorMsg
    if(this.state.criticalWindow !== ''){
      this.removeErrorMsg();
    }
    
    // 关闭弹窗
    this.handleTemplateModal(false);

    // 调用接口将模板数据 保存到当前 web push
    this.saveTemplateData(NowEditPush,this.state.Selected_TemplateId);

  }

  // 用户选择模板 并点击保存按钮，将模板数据保存到当前的web push中
  saveTemplateData = async(webPushNumber,selectedTemplateId) =>{

    const params = {type:webPushNumber,templateId:selectedTemplateId};
    const responseData = await reqSaveTemplateData(params)
    
    if (responseData.code==='200') {
      this.toggleToast(responseData.msg,false);
    } else{
      this.toggleToast(responseData.msg,true);
    }

  }

  // 用户编辑内容 变化后，显示全局顶部保存导航
  TopSaveBarAction = () =>{
    this.setState(({showTopSaveBar}) => ({showTopSaveBar: !showTopSaveBar}));
  }

  // 用户点击保存显示错误信息，重新编辑动作之后，移除 提示错误信息
  removeErrorMsg =()=>{

    this.setState({
      criticalWindow :'',

      Title1ErrorMsg:'',
      Message1ErrorMsg:'',
      URL1ErrorMsg:'',
      DiscountCode1ErrorMsg:'',
      MinsErrorMsg:'',

      Title2ErrorMsg:'',
      Message2ErrorMsg:'',
      URL2ErrorMsg:'',
      DiscountCode2ErrorMsg:'',
      HoursErrorMsg:'',

      Title3ErrorMsg:'',
      Message3ErrorMsg:'',
      URL3ErrorMsg:'',
      DiscountCode3ErrorMsg:'',
      DayErrorMsg:'',
    });
  }


  // 保存用户页面所有内容
  updateToAll = async()=>{
    //判断是否允许提交
    var isAllow = 1;
    //错误字符提示 以及 个数
    var criticalCharacter1 = '';
    var criticalCharacter2 = '';
    var criticalCharacter3 = '';
    var criticalCharacter4 = '';
    var criticalCharacter5 = '';
    var criticalCharacter6 = '';
    var criticalCharacter7 = '';
    var criticalCharacter8 = '';
    var criticalCharacter9 = '';
    var criticalCharacter10 = '';
    var criticalCharacter11 = '';
    var criticalCharacter12 = '';
    var criticalCharacter13 = '';
    var criticalCharacter14 = '';
    var criticalCharacter15 = '';
    var criticalCount = 0;

    // first web push
    if(this.state.firstWebPushSwitch){  // open
      if(this.state.Title1 === ''){
        criticalCharacter1 = <List.Item><LanguageSwitch Words="First Web Push title is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({Title1ErrorMsg: 'Required'});
      }
      if(this.state.Message1 === ''){
        criticalCharacter2 = <List.Item><LanguageSwitch Words="First Web Push message is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({Message1ErrorMsg: 'Required'});
      }
      if(this.state.URL1 === ''){
        criticalCharacter3 = <List.Item><LanguageSwitch Words="First Web Push URL is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({URL1ErrorMsg: 'Required'});

      }
      if(this.state.Mins === ''){
        criticalCharacter4 = <List.Item><LanguageSwitch Words="First Web Push send time is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({MinsErrorMsg: 'Required. Time range : 5~59'});
      }else if(this.state.Mins < 5){
        criticalCharacter4 = <List.Item><LanguageSwitch Words="First Web Push send time is required, time range : 5~59" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({MinsErrorMsg: 'Required. Time range : 5~59'});
      }

      if(this.state.firstWebPushDiscountCodeSelect === '1' || this.state.firstWebPushDiscountCodeSelect === 1){
        if(this.state.DiscountCode1 === ''){
          criticalCharacter5 = <List.Item><LanguageSwitch Words="First Web Push Discount Code is required" /></List.Item>;
          criticalCount = criticalCount + 1;
          isAllow = 0;
          this.setState({DiscountCode1ErrorMsg: 'Required'});
        }
      }
      
    }

    // second web push
    if(this.state.secondWebPushSwitch){  // open
      if(this.state.Title2 === ''){
        criticalCharacter6 = <List.Item><LanguageSwitch Words="Second Web Push title is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({Title2ErrorMsg: 'Required'});
      }
      if(this.state.Message2 === ''){
        criticalCharacter7 = <List.Item><LanguageSwitch Words="Second Web Push message is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({Message2ErrorMsg: 'Required'});
      }
      if(this.state.URL2 === ''){
        criticalCharacter8 = <List.Item><LanguageSwitch Words="Second Web Push URL is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({URL2ErrorMsg: 'Required'});

      }
      if(this.state.Hours === ''){
        criticalCharacter9 = <List.Item><LanguageSwitch Words="Second Web Push send time is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({HoursErrorMsg: 'Required. Time range : 1~24'});
      }
      
      if(this.state.secondWebPushDiscountCodeSelect === '1' || this.state.secondWebPushDiscountCodeSelect === 1){
        if(this.state.DiscountCode2 === ''){
          criticalCharacter10 = <List.Item><LanguageSwitch Words="Second Web Push Discount Code is required" /></List.Item>;
          criticalCount = criticalCount + 1;
          isAllow = 0;
          this.setState({DiscountCode2ErrorMsg: 'Required'});
        }
      }

    }


    // third web push
    if(this.state.thirdWebPushSwitch){  // open
      if(this.state.Title3 === ''){
        criticalCharacter11 = <List.Item><LanguageSwitch Words="Third Web Push title is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({Title3ErrorMsg: 'Required'});
      }
      if(this.state.Message3 === ''){
        criticalCharacter12 = <List.Item><LanguageSwitch Words="Third Web Push message is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({Message3ErrorMsg: 'Required'});
      }
      if(this.state.URL3 === ''){
        criticalCharacter13 = <List.Item><LanguageSwitch Words="Third Web Push URL is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({URL3ErrorMsg: 'Required'});

      }
      if(this.state.Day === ''){
        criticalCharacter14 = <List.Item><LanguageSwitch Words="Third Web Push send time is required" /></List.Item>;
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({DayErrorMsg: 'Required. Time range : 1~7'});
      }

      if(this.state.thirdWebPushDiscountCodeSelect==='1' || this.state.thirdWebPushDiscountCodeSelect===1){
        if(this.state.DiscountCode3 === ''){
          criticalCharacter15 = <List.Item><LanguageSwitch Words="Third Web Push Discount Code is required" /></List.Item>;
          criticalCount = criticalCount + 1;
          isAllow = 0;
          this.setState({DiscountCode3ErrorMsg: 'Required'});
        }
      }

    }

    if(isAllow ===1){
      this.setState({ 
        topSaveButtonLoading: true,
      });
      const params = {
        Title1:this.state.Title1,
        Message1:this.state.Message1, //内容
        URL1:this.state.URL1, //链接
        httpSelectValue1:this.state.httpSelectValue1, //https下拉框
        Mins: this.state.Mins,
        firstWebPushDiscountCodeSelect: this.state.firstWebPushDiscountCodeSelect,  // 优惠码开关
        DiscountCode1:this.state.DiscountCode1,  //优惠码
        DiscountCode1_Title: this.state.DiscountCode1_Title,
        DiscountCode1_Message: this.state.DiscountCode1_Message,
        DiscountCode1_Button: this.state.DiscountCode1_Button,
        DiscountCode1_Position: this.state.DiscountCode1_Position,
  
        Title2:this.state.Title2,
        Message2:this.state.Message2, //内容
        URL2:this.state.URL2, //链接
        httpSelectValue2:this.state.httpSelectValue2, //https下拉框
        Hours: this.state.Hours,
        secondWebPushDiscountCodeSelect: this.state.secondWebPushDiscountCodeSelect,  // 优惠码开关
        DiscountCode2:this.state.DiscountCode2,  //优惠码
        DiscountCode2_Title: this.state.DiscountCode2_Title,
        DiscountCode2_Message: this.state.DiscountCode2_Message,
        DiscountCode2_Button: this.state.DiscountCode2_Button,
        DiscountCode2_Position: this.state.DiscountCode2_Position,
  
        Title3:this.state.Title3,
        Message3:this.state.Message3, //内容
        URL3:this.state.URL3, //链接
        httpSelectValue3:this.state.httpSelectValue3, //https下拉框
        Day: this.state.Day,
        thirdWebPushDiscountCodeSelect: this.state.thirdWebPushDiscountCodeSelect,  // 优惠码开关
        DiscountCode3:this.state.DiscountCode3,  //优惠码
        DiscountCode3_Title: this.state.DiscountCode3_Title,
        DiscountCode3_Message: this.state.DiscountCode3_Message,
        DiscountCode3_Button: this.state.DiscountCode3_Button,
        DiscountCode3_Position: this.state.DiscountCode3_Position,
      };
  
      const responseData = await reqUpdateAllWebPush(params)
      if (responseData.code==='200') {
        this.toggleToast(responseData.msg,false);
      }else{
        this.toggleToast(responseData.msg,false);
      }
  
      // 更新状态
      this.setState({ 
        dataHasChange: true,
        showTopSaveBar: false,
        topSaveButtonLoading: false
      });

    } else {  // input data something wrong 

      var criticalWindow = <div style={{ marginBottom:"2rem"}}>
        <Banner title={`To add this message, `+criticalCount+` fields need changes`} status="critical">
          <List>
            {criticalCharacter1}
            {criticalCharacter2}
            {criticalCharacter3}
            {criticalCharacter4}
            {criticalCharacter5}
            {criticalCharacter6}
            {criticalCharacter7}
            {criticalCharacter8}
            {criticalCharacter9}
            {criticalCharacter10}
            {criticalCharacter11}
            {criticalCharacter12}
            {criticalCharacter13}
            {criticalCharacter14}
            {criticalCharacter15}
          </List>
        </Banner>
      </div>

      this.ScrollToTop();

      this.setState({
        criticalWindow: criticalWindow
      });
    }
    
  }

  // 保存数据时，报错滚动到页面错误提示顶部
  ScrollToTop(){
    if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {

      var event = document.getElementById('errorMsg');
      // 滚动的高度
      const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);
      // 视窗高度
      // const clientHeight = (event.srcElement && event.srcElement.documentElement.clientHeight) || document.body.clientHeight;
      // 页面高度
      // const scrollHeight = (event.srcElement && event.srcElement.documentElement.scrollHeight) || document.body.scrollHeight;

      if(scrollTop > 190){
        window.scrollTo(0,190);
      }
    }
  }

  // See Example
  WebPushSeeExample =(type,number,e)=>{
    this.setState({
      showExample: false,
      WebPushExample:false,
      DiscountCodeExample: false
    },()=>{
      if(type==='webpush'){
        if(number===1){
          this.setState({
            showExample:true,
            WebPushExample: true,
            sendTestTitle: this.state.Title1,
            sendTestMessage: this.state.Message1,
            sendTestUrl: this.state.UserUrlName,
          });
        } else if(number===2){
          this.setState({
            showExample:true,
            WebPushExample: true,
            sendTestTitle: this.state.Title2,
            sendTestMessage: this.state.Message2,
            sendTestUrl: this.state.UserUrlName,
          });
        }else{
          this.setState({
            showExample:true,
            WebPushExample: true,
            sendTestTitle: this.state.Title3,
            sendTestMessage: this.state.Message3,
            sendTestUrl: this.state.UserUrlName,
          });
        }

      } else {  // discount code
        if(number===1){
          this.setState({
            showExample:true,
            DiscountCodeExample: true,
            seeExampleCode: this.state.DiscountCode1,
            seeExampleTitle: this.state.DiscountCode1_Title,
            seeExampleMessage: this.state.DiscountCode1_Message,
            seeExampleButton: this.state.DiscountCode1_Button,
          });
        } else if(number===2){
          this.setState({
            showExample:true,
            DiscountCodeExample: true,
            seeExampleCode: this.state.DiscountCode2,
            seeExampleTitle: this.state.DiscountCode2_Title,
            seeExampleMessage: this.state.DiscountCode2_Message,
            seeExampleButton: this.state.DiscountCode2_Button,
          });
        }else{
          this.setState({
            showExample:true,
            DiscountCodeExample: true,
            seeExampleCode: this.state.DiscountCode3,
            seeExampleTitle: this.state.DiscountCode3_Title,
            seeExampleMessage: this.state.DiscountCode3_Message,
            seeExampleButton: this.state.DiscountCode3_Button,
          });
        }
      }
      // 显示 5秒后 自动关闭
      setTimeout(this.CloseWebPushExample,5000);
    });
  }

  // closed web push test
  CloseWebPushExample =()=>{
    this.setState({
      showExample:false,
      WebPushExample:false,
      DiscountCodeExample: false
    });
  }

  // 监听用户返回 导航方法
  isLeave = () =>{
    if(this.state.dataHasChange){
      this.ControlUserLeavingModal();
      return false;
    } else{
      window.location.href = '/admin/dashboard';  // 直接跳转
    }
  }

  // 用户未保存编辑内容，直接确认离开
  UserLeavesWithoutSave = () => {
    window.location.href = '/admin/dashboard';
  }

  // UserLeavesListening modal 开关控制
  ControlUserLeavingModal = async () =>{
    this.setState(({leavingModalActive}) => ({leavingModalActive: !leavingModalActive}));
  }

  // DiscardModal 开关控制
  ControlDiscardModal = async () =>{
    this.setState(({discardModalActive}) => ({discardModalActive: !discardModalActive}));
  }

  // Discard 返回页面原始数据
  DiscardInitData = () => {
    // 恢复原始数据
    this.setState({
      dataHasChange: false,  // 数据编辑参数
      showTopSaveBar: false,  // 顶部保存导航 
    },()=>{
      this.updatePageDetail(true);
    });
    
    // 关闭弹窗 隐藏顶部保存栏
    this.setState(({discardModalActive}) => ({discardModalActive: !discardModalActive}));
  }

  // 开启关闭webpush 介绍
  ControlShowBannerTip = async () =>{
    this.setState(({bannerTips}) => ({bannerTips: !bannerTips}));
  }

  render() {
    const {
      firstWebPushSwitch, Mins, Title1, Message1, 
      // URL1 ,httpSelectValue1, DiscountCode1,firstWebPushDiscountCode,firstWebPushDiscountCodeSelect,
      // DiscountCode1_Title,DiscountCode1_Message,DiscountCode1_Button,DiscountCode1_Position,
      Button1_Title_1,Button1_Title_2,
      secondWebPushSwitch,Hours, Title2, Message2, 
      // URL2, httpSelectValue2, DiscountCode2,secondWebPushDiscountCode,secondWebPushDiscountCodeSelect,
      // DiscountCode2_Title,DiscountCode2_Message,DiscountCode2_Button,DiscountCode2_Position,
      Button2_Title_1,Button2_Title_2,
      thirdWebPushSwitch,Day, Title3, Message3,
      //  URL3, httpSelectValue3, DiscountCode3,thirdWebPushDiscountCode,thirdWebPushDiscountCodeSelect,
      // DiscountCode3_Title,DiscountCode3_Message,DiscountCode3_Button,DiscountCode3_Position,
      Button3_Title_1,Button3_Title_2,
      ButtonState,MinsErrorMsg,HoursErrorMsg,DayErrorMsg,
      // Title1ErrorMsg,Message1ErrorMsg,URL1ErrorMsg,DiscountCode1ErrorMsg,
      // Title2ErrorMsg,Message2ErrorMsg, URL2ErrorMsg,DiscountCode2ErrorMsg,
      // Title3ErrorMsg,Message3ErrorMsg,URL3ErrorMsg,DiscountCode3ErrorMsg,
      // Title1HelpText,Title2HelpText,Title3HelpText,Message1HelpText,Message2HelpText,Message3HelpText
    } = this.state;

    const toastMarkup = this.state.showToast ? <Toast content={this.state.showToastText} onDismiss={this.toggleToast} error={this.state.showToastIserror} /> : null;

    //判断 推送弹窗总开关
    var button = '';
    if(this.state.loadingPage){
      if(ButtonState === 1){
        button = <Button loading={this.state.ButtonLoading} onClick={() => this.SetWebPushControl('webPush') }>Disable</Button>;
      }else{
        button = <Button loading={this.state.ButtonLoading} primary onClick={() => this.SetWebPushControl('webPush') }>Enable</Button>;
      }
    }else{
      button = <Button primary disabled>Enable</Button>;
    }
    
    const cardHeader = this.state.loading ? (
      <Stack>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
      </Stack>
    ) : (
      <FormLayout>
        <FormLayout.Group condensed>
          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.REVENUE}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="REVENUE" /></div>
              </TextContainer>
            </div>
          </Card>
          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.CARTRECOVERED}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="RECOVERED" /></div>
              </TextContainer>
            </div>
          </Card>
          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.SENT}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="IMPRESSIONS" /></div>
              </TextContainer>
            </div>
          </Card>
          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.CLICKS}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="CLICKS" /></div>
              </TextContainer>
            </div>
          </Card>
        </FormLayout.Group>
        <div className="space"></div>
      </FormLayout>
    );

    // 内容
    var content = ''
    if(this.state.loadingPage){
      content = <Layout.Section>
        
          <div className="Time-line-title">
            <LanguageSwitch Words="Timeline" /><div style={{fontSize:"15px",display:"inline-block",cursor:'pointer',color:'#006fbb',paddingLeft:'.5rem'}} className="total-tip" onClick={this.ControlShowBannerTip} > <LanguageSwitch Words="(How it works?)" /></div>
          </div>

          <div className="m-scrollable m-scroller">
            <div className="m-timeline-2">
              <div className="m-timeline-2__items  m--padding-top-25 m--padding-bottom-30">
                <div className="m-timeline-2__item">
                  <div className="m-timeline-2__item-cricle">1</div>
                  <div className="m-timeline-2__item-text  m--padding-top-5">
                    <div style={{ marginBottom:"10px" }}>
                      <Stack>
                        <Stack.Item fill>
                          <div className="webPush__Title"><LanguageSwitch Words="First Web Push" /></div>
                        </Stack.Item>
                        <Stack.Item>
                          {/* <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(1)}>disabled</Button> */}
                          <div style={{marginTop:'10px'}}>
                            <Switch onChange={()=>this.handleCheckChange('firstWebPushSwitch',!firstWebPushSwitch)} checked={firstWebPushSwitch} height={20} width={40} uncheckedIcon={true} checkedIcon={true} onColor="#6371c7"/>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </div>

                    {/* 开关控制显示 隐藏 */}
                    <div style={{display: firstWebPushSwitch?'block':'none'}}>
                    <Card>
                      <section className="webPush__SendTime" style={{padding:'2rem 2rem 0'}}>
                        <Stack>
                          <div className="Prefix-text">
                            <Heading><LanguageSwitch Words="Send" /></Heading>
                          </div>
                          <div className="Input-number">
                            <Tooltip content="time range : 5~59" light>
                              <TextField
                                label="set Time"
                                labelHidden
                                type="number"
                                value={Mins}
                                max="59"
                                min="1"
                                placeholder="5~59"
                                onChange={this.handleChange('Mins')}
                                onBlur={()=>this.updateAutomatedCartRecoveryTime(1)}
                                error={MinsErrorMsg}
                              />
                            </Tooltip>
                          </div>
                          <div className="Suffix-text">
                            <Heading><span style={{fontWeight:'500'}}><LanguageSwitch Words="Mins" /></span><LanguageSwitch Words=" after a cart is abandoned" /></Heading>
                          </div>
                          <Stack.Item fill>
                          </Stack.Item>
                          <EditDetailModal callBackFunc={this.callBackRefresh} sendTestImageUrl={this.state.productImageUrl} isWindows={this.state.isWindows} editType={1} />
                          {/* <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(1)}>Edit</Button> */}
                        </Stack>
                      </section>

                      <Card.Section>
                        <WebPushBox 
                            productImageUrl={API_DOMAIN + '/Untitled.jpg?v=123'} 
                            logo={this.state.UserLogoUrl} 
                            title={Title1} 
                            message={Message1} 
                            url={this.state.UserUrlName} 
                            button_title_1={Button1_Title_1} 
                            button_title_2={Button1_Title_2} 
                            type={true}
                            sendTest={false}
                        />
                      </Card.Section>


                      {/* <Card.Section>
                        <FormLayout>
                          <div>Do you wish to offer discount in your web push</div>
                          <FormLayout.Group>
                            <div className="Choose_DiscountCode_Select">
                            <Select
                              label="Do you wish to offer discount in your web push"
                              labelHidden
                              id='firstWebPushDiscountCodeSelect'
                              value={firstWebPushDiscountCodeSelect}
                              ariaControls="basic-collapsible"
                              onChange={this.handleSelectChange}
                              options={[{label:'Yes',value:'1'}, {label:'No',value:'2'}]}
                            />
                            </div>
                            <div></div>
                          </FormLayout.Group>
                        </FormLayout>
                      </Card.Section>

                      <Card.Section>
                        <div className="WebPush-Template-Thumbnail">
                          <Thumbnail
                            source="/WebPush__Logo_Template.png"
                            size="small"
                            alt="Abandoned Cart Recovery"
                          />
                        </div>

                        <div className="WebPush-Template-Action">
                          <Stack vertical={true}>
                            <div className="Abandoned-Cart-Recovery-title">
                              <TextField 
                                label="First Web Push Title" 
                                labelHidden
                                id="Title1"
                                value={Title1}
                                maxLength={50}
                                showCharacterCount
                                placeholder="Perfect length is 21 characters"
                                onChange={this.handleChangeInput}
                                error={Title1ErrorMsg}
                                helpText={Title1HelpText}
                              />
                            </div>
                            <div className="Abandoned-Cart-Recovery-subtitle">
                              <TextField 
                                label="First Web Push Message" 
                                labelHidden
                                id="Message1"
                                value={Message1} 
                                maxLength={100}
                                showCharacterCount
                                placeholder="Perfect length is 23 characters"
                                onChange={this.handleChangeInput}
                                error={Message1ErrorMsg}
                                helpText={Message1HelpText}
                              />
                            </div>
                            <div className="Abandoned-Cart-Recovery-url">
                              <TextField 
                                label="First Web Push URL" 
                                labelHidden
                                id="URL1"
                                value={URL1} 
                                onChange={this.handleChangeInput}
                                error={URL1ErrorMsg}
                                prefix={httpSelectValue1}
                              />
                            </div>

                            <div className="webPush__ButtonGroup">
                            <ButtonGroup>
                              <Button onClick={this.handleTemplateModal.bind(this,true,1)}>Select a Template</Button>
                              <Link url="/admin/abandoned-cart-recovery-v2/insert/1">Edit</Link>
                              <Link onClick={this.WebPushSeeExample.bind(this,'webpush',1)}>Send test</Link>
                            </ButtonGroup>
                            </div>
                          </Stack>
                        </div>

                        <div style={{clear:'both'}}></div>
                        
                      </Card.Section> */}

                    </Card>
                    
                    {/* <Card>
                      <section style={{padding:'2rem 2rem 1rem 2rem',display:firstWebPushDiscountCode?'block':'none'}}>
                      <Stack vertical>
                        
                        <Collapsible open={firstWebPushDiscountCode} id="basic-collapsible">
                          
                          <p style={{fontSize:'1.4rem',color:'#111',marginBottom:'2.5rem'}}>
                            Now that you’ve set your web push message. After users see and click on your web push, they will be taken to your your website Homepage or Cart. 
                            The discount widget will be at the bottom of the page. After they click “<b>Copy discount code</b>”, they will get your code and use them instantly.
                          </p>

                          <FormLayout>
                            <FormLayout.Group>
                              <TextField
                                  label="Please enter your discount code"
                                  onChange={this.handleChange('DiscountCode1')}
                                  value={DiscountCode1}
                                  error={DiscountCode1ErrorMsg}
                                  disabled={this.state.TextFieldDisabled}
                                  // onBlur={ () => this.updateAutomatedCartRecoveryCode(1)}
                                /> 
                                <div></div>
                            </FormLayout.Group>
                            
                            <div style={{fontSize:'1.4rem',fontWeight:'500'}}>
                              <Link url={`https://`+this.state.UserUrlName+"/admin/discounts"} external={true}>Create discount code</Link>
                            </div>

                            <TextField 
                              label="Title"
                              id="DiscountCode1_Title"
                              value={DiscountCode1_Title}
                              onChange={this.handleChangeInput}
                              maxLength={30}
                              showCharacterCount
                            />
                            <TextField
                              label="Message"
                              id="DiscountCode1_Message"
                              value={DiscountCode1_Message}
                              onChange={this.handleChangeInput}
                              maxLength={80}
                              showCharacterCount
                            />
                            <TextField 
                              label="Button"
                              id="DiscountCode1_Button"
                              value={DiscountCode1_Button}
                              onChange={this.handleChangeInput}
                              maxLength={30}
                              showCharacterCount
                            />
                            <Select
                              label="Position"
                              id="DiscountCode1_Position"
                              value={DiscountCode1_Position}
                              onChange={this.handleSelectChange}
                              options={[{label:'Left',value:'1'}, {label:'Center',value:'2'}, {label:'Right',value:'3'}]}
                            />

                            <Heading><Link onClick={this.WebPushSeeExample.bind(this,'discountCode',1)}>See example</Link></Heading>
                          </FormLayout>

                        </Collapsible>
                      </Stack>
                      </section>
                    </Card> */}


                    </div>

                  </div>
                </div>
                <div className="space"></div>
                <div className="m-timeline-2__item">
                  <div className="m-timeline-2__item-cricle">2</div>
                  <div className="m-timeline-2__item-text  m--padding-top-5">
                    <div style={{ marginBottom:"10px" }}>
                      <Stack>
                        <Stack.Item fill>
                          <div className="webPush__Title"><LanguageSwitch Words="Second Web Push" /></div>
                        </Stack.Item>
                        <Stack.Item>
                          <div style={{marginTop:'10px'}}>
                            <Switch onChange={()=>this.handleCheckChange('secondWebPushSwitch',!secondWebPushSwitch)} checked={secondWebPushSwitch} height={20} width={40} uncheckedIcon={true} checkedIcon={true} onColor="#6371c7"/>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </div>

                    {/* 开关控制显示 隐藏 */}
                    <div style={{display: secondWebPushSwitch?'block':'none'}}>
                    <Card>
                      <section className="webPush__SendTime" style={{padding:'2rem 2rem 0'}}>
                        <Stack>
                          <div className="Prefix-text">
                            <Heading><LanguageSwitch Words="Send" /></Heading>
                          </div>
                          <div className="Input-number">
                            <Tooltip content="time range : 1~24" light>
                            <TextField
                              label={<LanguageSwitch Words="set Time" />}
                              labelHidden
                              type="number"
                              value={Hours}
                              max="23"
                              min="1"
                              step={0.5}
                              placeholder="1~23"
                              onChange={this.handleChange('Hours')}
                              onBlur={()=>this.updateAutomatedCartRecoveryTime(2)}
                              error={HoursErrorMsg}
                            />
                            </Tooltip>
                          </div>
                          <div className="Suffix-text">
                            <Heading><span style={{fontWeight:'500'}}><LanguageSwitch Words="Hours" /></span><LanguageSwitch Words=" after a cart is abandoned" /></Heading>
                          </div>
                          <Stack.Item fill>
                          </Stack.Item>
                          <EditDetailModal callBackFunc={this.callBackRefresh} sendTestImageUrl={this.state.productImageUrl} isWindows={this.state.isWindows} editType={2} />
                          {/* <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(2)}>Save</Button> */}
                        </Stack>
                      </section>

                      <Card.Section>
                        <WebPushBox 
                              productImageUrl={API_DOMAIN + '/Untitled.jpg?v=123'} 
                              logo={this.state.UserLogoUrl} 
                              title={Title2} 
                              message={Message2} 
                              url={this.state.UserUrlName} 
                              button_title_1={Button2_Title_1} 
                              button_title_2={Button2_Title_2} 
                              type={true}
                              sendTest={false}
                          />
                      </Card.Section>

                      {/* <Card.Section>
                        <FormLayout>
                          <div>Do you wish to offer discount in your web push</div>
                          <FormLayout.Group>
                            <div className="Choose_DiscountCode_Select">
                            <Select
                              label="Do you wish to offer discount in your web push"
                              labelHidden
                              id='secondWebPushDiscountCodeSelect'
                              value={secondWebPushDiscountCodeSelect}
                              ariaControls="basic-collapsible-two"
                              onChange={this.handleSelectChange}
                              options={[{label:'Yes',value:'1'}, {label:'No',value:'2'}]}
                            />
                            </div>
                            <div></div>
                          </FormLayout.Group>
                        </FormLayout>
                      </Card.Section>

                      <Card.Section>
                        <div className="WebPush-Template-Thumbnail">
                          <Thumbnail
                            source="/WebPush__Logo_Template.png"
                            size="small"
                            alt="Abandoned Cart Recovery"
                          />
                        </div>

                        <div className="WebPush-Template-Action">
                          <Stack vertical={true}>
                            <div className="Abandoned-Cart-Recovery-title">
                              <TextField 
                                label="Second Web Push Title" 
                                labelHidden
                                id="Title2"
                                value={Title2}
                                maxLength={50}
                                showCharacterCount
                                placeholder="Perfect length is 21 characters"
                                onChange={this.handleChangeInput}
                                error={Title2ErrorMsg}
                                helpText={Title2HelpText}
                              />
                            </div>
                            <div className="Abandoned-Cart-Recovery-subtitle">
                              <TextField 
                                label="Second Web Push Message" 
                                labelHidden
                                id="Message2"
                                value={Message2} 
                                maxLength={100}
                                showCharacterCount
                                placeholder="Perfect length is 23 characters"
                                onChange={this.handleChangeInput}
                                error={Message2ErrorMsg}
                                helpText={Message2HelpText}
                              />
                            </div>
                            <div className="Abandoned-Cart-Recovery-url">
                              <TextField 
                                label="Second Web Push URL" 
                                labelHidden
                                id="URL2"
                                value={URL2} 
                                onChange={this.handleChangeInput}
                                error={URL2ErrorMsg}
                                prefix={httpSelectValue2}
                              />
                            </div>

                            <div className="webPush__ButtonGroup">
                            <ButtonGroup>
                              <Button onClick={this.handleTemplateModal.bind(this,true,2)}>Select a Template</Button>
                              <Link url="/admin/abandoned-cart-recovery-v2/insert/2">Edit</Link>
                              <Link onClick={this.WebPushSeeExample.bind(this,'webpush',2)}>Send test</Link>
                            </ButtonGroup>
                            </div>

                          </Stack>
                        </div>

                        <div style={{clear:'both'}}></div>
                      </Card.Section> */}
                    </Card>

                    {/*<Card>
                     <section style={{padding:'2rem 2rem 1rem 2rem',display:secondWebPushDiscountCode?'block':'none'}}>
                      <Stack vertical>
                        
                        <Collapsible open={secondWebPushDiscountCode} id="basic-collapsible-two">
                          
                          <p style={{fontSize:'1.4rem',color:'#111',marginBottom:'2.5rem'}}>
                            Now that you’ve set your web push message. After users see and click on your web push, they will be taken to your your website Homepage or Cart. 
                            The discount widget will be at the bottom of the page. After they click “<b>Copy discount code</b>”, they will get your code and use them instantly.
                          </p>

                          <FormLayout>
                            <FormLayout.Group>
                              <TextField
                                  label="Enter Discount Code"
                                  onChange={this.handleChange('DiscountCode2')}
                                  value={DiscountCode2}
                                  error={DiscountCode2ErrorMsg}
                                  disabled={this.state.TextFieldDisabled}
                                  // onBlur={ () => this.updateAutomatedCartRecoveryCode(2)}
                                /> 
                                <div></div>
                            </FormLayout.Group>
                            
                            <div style={{fontSize:'1.4rem'}}>
                              <Link url={`https://`+this.state.UserUrlName+"/admin/discounts"} external={true}>Create discount code</Link>
                            </div>

                            <TextField 
                              label="Title"
                              id="DiscountCode2_Title"
                              value={DiscountCode2_Title}
                              onChange={this.handleChangeInput}
                              maxLength={30}
                              showCharacterCount
                            />
                            <TextField
                              label="Message"
                              id="DiscountCode2_Message"
                              value={DiscountCode2_Message}
                              onChange={this.handleChangeInput}
                              maxLength={80}
                              showCharacterCount
                            />
                            <TextField 
                              label="Button"
                              id="DiscountCode2_Button"
                              value={DiscountCode2_Button}
                              onChange={this.handleChangeInput}
                              maxLength={30}
                              showCharacterCount
                            />
                            <Select
                              label="Position"
                              id="DiscountCode2_Position"
                              value={DiscountCode2_Position}
                              onChange={this.handleSelectChange}
                              options={[{label:'Left',value:'1'}, {label:'Center',value:'2'}, {label:'Right',value:'3'}]}
                            />

                            <Heading><Link onClick={this.WebPushSeeExample.bind(this,'discountCode',2)}>See example</Link></Heading>
                          </FormLayout>

                        </Collapsible>
                      </Stack>
                      </section>
                    </Card> */}
                    </div>

                  </div>
                </div>

                <div className="space"></div>
                <div className="m-timeline-2__item">
                  <div className="m-timeline-2__item-cricle">3</div>
                  <div className="m-timeline-2__item-text  m--padding-top-5">
                    <div style={{ marginBottom:"10px" }}>
                      <Stack>
                        <Stack.Item fill>
                          <div className="webPush__Title"><LanguageSwitch Words="Third Web Push" /></div>
                        </Stack.Item>
                        <Stack.Item>
                          <div style={{marginTop:'10px'}}>
                            <Switch onChange={()=>this.handleCheckChange('thirdWebPushSwitch',!thirdWebPushSwitch)} checked={thirdWebPushSwitch} height={20} width={40} uncheckedIcon={true} checkedIcon={true} onColor="#6371c7"/>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </div>

                    {/* 开关控制显示 隐藏 */}
                    <div style={{display: thirdWebPushSwitch?'block':'none'}}>
                    <Card>
                      <section className="webPush__SendTime" style={{padding:'2rem 2rem 0'}}>
                        <Stack>
                          <div className="Prefix-text">
                            <Heading><LanguageSwitch Words="Send" /></Heading>
                          </div>
                          <div className="Input-number">
                            <Tooltip content="time range : 1~7" light>
                            <TextField
                              label={<LanguageSwitch Words="set Time" />}
                              labelHidden
                              type="number"
                              value={Day}
                              max="7"
                              min="1"
                              placeholder="1~7"
                              onChange={this.handleChange('Day')}
                              onBlur={()=>this.updateAutomatedCartRecoveryTime(3)}
                              error={DayErrorMsg}
                            />
                            </Tooltip>
                          </div>
                          <div className="Suffix-text">
                            <Heading><span style={{fontWeight:'500'}}><LanguageSwitch Words="Day" /></span><LanguageSwitch Words=" after a cart is abandoned" /></Heading>
                          </div>
                          <Stack.Item fill>
                          </Stack.Item>
                          <EditDetailModal callBackFunc={this.callBackRefresh} sendTestImageUrl={this.state.productImageUrl} isWindows={this.state.isWindows} editType={3} />
                          {/* <Button primary loading={this.state.ButtonTypeLoading} onClick={ () => this.updateAutomatedCartRecoveryTime(3)}>Save</Button> */}
                        </Stack>
                      </section>

                      <Card.Section>
                        <WebPushBox 
                            productImageUrl={API_DOMAIN + '/Untitled.jpg?v=123'} 
                            logo={this.state.UserLogoUrl} 
                            title={Title3} 
                            message={Message3} 
                            url={this.state.UserUrlName} 
                            button_title_1={Button3_Title_1} 
                            button_title_2={Button3_Title_2} 
                            type={true}
                            sendTest={false}
                        />
                      </Card.Section>

                      {/* <Card.Section>
                        <FormLayout>
                          <div>Do you wish to offer discount in your web push</div>
                          <FormLayout.Group>
                            <div className="Choose_DiscountCode_Select">
                            <Select
                              label="Do you wish to offer discount in your web push"
                              labelHidden
                              id='thirdWebPushDiscountCodeSelect'
                              value={thirdWebPushDiscountCodeSelect}
                              ariaControls="basic-collapsible-third"
                              onChange={this.handleSelectChange}
                              options={[{label:'Yes',value:'1'}, {label:'No',value:'2'}]}
                            />
                            </div>
                            <div></div>
                          </FormLayout.Group>
                        </FormLayout>
                      </Card.Section>

                      <Card.Section>
                        <div className="WebPush-Template-Thumbnail">
                          <Thumbnail
                            source="/WebPush__Logo_Template.png"
                            size="small"
                            alt="Abandoned Cart Recovery"
                          />
                        </div>

                        <div className="WebPush-Template-Action">
                          <Stack vertical={true}>
                            <div className="Abandoned-Cart-Recovery-title">
                              <TextField 
                                label="Third Web Push Title" 
                                labelHidden
                                id="Title3"
                                value={Title3}
                                error={Title3ErrorMsg}
                                helpText={Title3HelpText}
                                onChange={this.handleChangeInput}
                                maxLength={50}
                                showCharacterCount
                                placeholder="Perfect length is 21 characters"
                              />
                            </div>
                            <div className="Abandoned-Cart-Recovery-subtitle">
                              <TextField 
                                label="Third Web Push Message" 
                                labelHidden
                                id="Message3"
                                value={Message3} 
                                maxLength={100}
                                showCharacterCount
                                placeholder="Perfect length is 23 characters"
                                onChange={this.handleChangeInput}
                                error={Message3ErrorMsg}
                                helpText={Message3HelpText}
                              />
                            </div>
                            <div className="Abandoned-Cart-Recovery-url">
                              <TextField 
                                label="Third Web Push URL" 
                                labelHidden
                                id="URL3"
                                value={URL3} 
                                onChange={this.handleChangeInput}
                                error={URL3ErrorMsg}
                                prefix={httpSelectValue3}
                              />
                            </div>

                            <div className="webPush__ButtonGroup">
                            <ButtonGroup>
                              <Button onClick={this.handleTemplateModal.bind(this,true,3)}>Select a Template</Button>
                              <Link url="/admin/abandoned-cart-recovery-v2/insert/3">Edit</Link>
                              <Link onClick={this.WebPushSeeExample.bind(this,'webpush',3)}>Send test</Link>
                            </ButtonGroup>
                            </div>
                          </Stack>
                        </div>

                        <div style={{clear:'both'}}></div>
                      </Card.Section> */}

                    </Card>

                    {/* <Card>
                    <section style={{padding:'2rem 2rem 1rem 2rem',display:thirdWebPushDiscountCode?'block':'none'}}>
                      <Stack vertical>
                        
                        <Collapsible open={thirdWebPushDiscountCode} id="basic-collapsible-third">
                          
                          <p style={{fontSize:'1.4rem',color:'#111',marginBottom:'2.5rem'}}>
                            Now that you’ve set your web push message. After users see and click on your web push, they will be taken to your your website Homepage or Cart. 
                            The discount widget will be at the bottom of the page. After they click “<b>Copy discount code</b>”, they will get your code and use them instantly.
                          </p>
                          <FormLayout>
                            <FormLayout.Group>
                              <TextField
                                  label="Enter Discount Code"
                                  onChange={this.handleChange('DiscountCode3')}
                                  value={DiscountCode3}
                                  error={DiscountCode3ErrorMsg}
                                  disabled={this.state.TextFieldDisabled}
                                  // onBlur={ () => this.updateAutomatedCartRecoveryCode(3)}
                                /> 
                                <div></div>
                            </FormLayout.Group>
                            
                            <div style={{fontSize:'1.4rem'}}>
                              <Link url={`https://`+this.state.UserUrlName+"/admin/discounts"} external={true}>Create discount code</Link>
                            </div>

                            <TextField 
                              label="Title"
                              id="DiscountCode3_Title"
                              value={DiscountCode3_Title}
                              onChange={this.handleChangeInput}
                              maxLength={30}
                              showCharacterCount
                            />
                            <TextField
                              label="Message"
                              id="DiscountCode3_Message"
                              value={DiscountCode3_Message}
                              onChange={this.handleChangeInput}
                              maxLength={80}
                              showCharacterCount
                            />
                            <TextField 
                              label="Button"
                              id="DiscountCode3_Button"
                              value={DiscountCode3_Button}
                              onChange={this.handleChangeInput}
                              maxLength={30}
                              showCharacterCount
                            />
                            <Select
                              label="Position"
                              id="DiscountCode3_Position"
                              value={DiscountCode3_Position}
                              onChange={this.handleSelectChange}
                              options={[{label:'Left',value:'1'}, {label:'Center',value:'2'}, {label:'Right',value:'3'}]}
                            />

                            <Heading><Link onClick={this.WebPushSeeExample.bind(this,'discountCode',3)}>See example</Link></Heading>
                          </FormLayout>

                        </Collapsible>
                      </Stack>
                      </section>
                    </Card> */}
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </Layout.Section>
        
    } else {
      content = ( <Layout.Section>
        <div className="space"></div>
        <Card>
          <Layout>
            <Layout.Section secondary>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
            </Layout.Section>
          </Layout>
        </Card>
        </Layout.Section>);
    }

    const { templateActive ,loadingPageSelect ,templateType,templateData , criticalWindow ,UserLogoUrl ,isWindows,
      sendTestTitle,sendTestMessage,sendTestUrl,seeExampleCode,seeExampleTitle,seeExampleMessage,seeExampleButton ,bannerTips} = this.state;

    const showTemplateList = loadingPageSelect ? <Layout>
      {templateData}
    </Layout> : <div className="loadingCenter"><Spinner size="large" color="teal" /></div>

    const templateModal = <Modal
        open={templateActive}
        onClose={this.handleTemplateModal.bind(this,false)}
        title={<LanguageSwitch Words="Select a Template" />}
        primaryAction={{
          content: <LanguageSwitch Words="Save" />,
          onAction: this.saveSelectedTemplate,
        }}
        secondaryActions={[
          {
            content: <LanguageSwitch Words="Edit" />,
            onAction: this.editSelectedTemplate,
          },
        ]}
        large={true}
      >
        <Modal.Section>
          <Select 
            value={templateType}
            label={<LanguageSwitch Words="Select a category" />}
            onChange={this.handleSelectTemplateType}
            options={[<LanguageSwitch Words="All" />, <LanguageSwitch Words="Regular" />,<LanguageSwitch Words="Discount" />]}
          />

          <div className="space"></div>

          {showTemplateList}

        </Modal.Section>
      </Modal>;

    const floatTopSaveBar = false ? <div>
      <ContextualSaveBar
        alignContentFlush
        message="Unsaved changes"
        saveAction={{
          onAction: this.updateToAll,
          loading: this.state.topSaveButtonLoading
        }}
        discardAction={{
          onAction: this.ControlDiscardModal,
        }}
      />
      </div>:'';

    const howItWorksModal = <Modal
      open={bannerTips}
      onClose={this.ControlShowBannerTip}
      title="Three sequential notifications"
      secondaryActions={[
        {
          content: 'Close',
          onAction: this.ControlShowBannerTip,
        },
      ]}
    >
      <Modal.Section>
        <section style={{marginBottom:'20px'}}>
          <Heading>
            <LanguageSwitch Words="First Web Push - Reminder" />
          </Heading>
          <p style={{marginBottom:'10px',marginTop: '5px'}}>
            <LanguageSwitch Words="Kindly remind your subscribers of their abandoned carts." />
          </p>
          <img src='/notifications-1.png' alt='' />
        </section>

        <section style={{marginBottom:'20px'}}>
          <Heading>
            <LanguageSwitch Words="Second Web Push - Discount with urgency" />
          </Heading>
          <p style={{marginBottom:'10px',marginTop: '5px'}}>
            <LanguageSwitch Words="Remind your subscribers of their abandoned carts again but with urgency, e.g. offer a discount. Let them know the offer only valid for a limited time or the abandoned items are selling out fast." />
          </p>
          <img src='/notifications-2.png' alt='' />
        </section>

        <section style={{marginBottom:'20px'}}>
          <Heading>
            <LanguageSwitch Words="Third Web Push - Deadline reminder" />
          </Heading>
          <p style={{marginBottom:'10px',marginTop: '5px'}}>
            <LanguageSwitch Words="Send the third web push with a discount with deadline to induce your subscribers to complete the purchase, in order to improve conversion rate." />
          </p>
          <img src='/notifications-3.png' alt='' />
        </section>

        <section style={{color: 'rgb(142, 142, 142)'}}>
          <p>
            * <LanguageSwitch Words="If customer completes the purchase in one of the web pushes, the next push will not be activated." />
          </p>

          <p style={{marginBottom:'10px'}}>
            * <LanguageSwitch Words="If you offer discount to your subscribers, please mention it in web push to increase CTR." />
          </p>
        </section>
      </Modal.Section>
    </Modal>

    const WebPushTestExample = this.state.WebPushExample && <WebPushBox productImageUrl={this.state.productImageUrl} logo={UserLogoUrl} title={sendTestTitle} message={sendTestMessage} url={sendTestUrl} type={isWindows} />
    const WebPushDiscountExample = this.state.DiscountCodeExample && <DiscountCodeBox code={seeExampleCode} title={seeExampleTitle} message={seeExampleMessage} button={seeExampleButton} />

    const {leavingModalActive,discardModalActive} = this.state;
    const UserLeavingModal = this.state.leavingModalActive ? <div>
    <Modal
      open={leavingModalActive}
      onClose={this.ControlUserLeavingModal}
      title="You have unsaved changes"
      primaryAction={{
        content: <LanguageSwitch Words="Leave edit" />,
        onAction: this.UserLeavesWithoutSave,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: <LanguageSwitch Words="Cancel" />,
          onAction: this.ControlUserLeavingModal,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            <LanguageSwitch Words="If you leave this page, all unsaved changes will be lost." />
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
    </div> : '';

    const UserDiscardModal = this.state.discardModalActive ? <div>
    <Modal
      open={discardModalActive}
      onClose={this.ControlDiscardModal}
      title={<LanguageSwitch Words="Discard all unsaved changes" />}
      primaryAction={{
        content: <LanguageSwitch Words="Discard changes" />,
        onAction: this.DiscardInitData,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: <LanguageSwitch Words="Continue editing" />,
          onAction: this.ControlDiscardModal,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            <LanguageSwitch Words="If you discard changes, you’ll delete any edits you made since you last saved." />
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
    </div> : '';

    const title = this.state.loadingPage?(<div><TextContainer>
      <LanguageSwitch Words="Abandoned Cart Recovery" />
      </TextContainer>
      <span style={{fontSize:"1.8rem",fontWeight:'400'}}>
        <LanguageSwitch Words="A series of notifications to remind the subscriber about the items they forgot in their cart" />
      </span>
      </div>):(
          <TextContainer>
            <SkeletonDisplayText size="small" />
          </TextContainer>
        );

    return (
      <div>
      <AppProvider>
        <Frame topBar={<OnPageSEOTabs
          callback={this.callback}
        /> } >
          <div className="WebPush__MobilePage">
          <Page 
            // breadcrumbs={[{content: 'Dashboard', onAction:this.isLeave}]}
            title={title}
          >
            { floatTopSaveBar }

            <div className="WebPush__Control__Button">
              {button}
            </div>
            
            {cardHeader}

            <div id='errorMsg' className="space"></div>
            { howItWorksModal }
            { criticalWindow }

            <Layout>
              {content}
              <Layout.Section secondary>
              </Layout.Section>

            </Layout>

            {toastMarkup}
            {templateModal}

            <div style={{ position:"fixed",right:"15px",bottom:"20px",zIndex:'600'}} className={this.state.showExample?'testWebPush_Show':''}>
              {WebPushTestExample}
              {WebPushDiscountExample}
            </div>

            {UserLeavingModal} 
            {UserDiscardModal} 

            <div className="space"></div>
            {/* <SheetExample /> */}
          </Page>
          </div>
        </Frame>
      </AppProvider>
      </div>
    );
  }
}

export default dashboard;
