import React, { Component } from "react";

import {
  AppProvider,
  Page,
  Frame,
  Card,
  TextContainer,
  Heading,
  Button,
  FormLayout
} from "@shopify/polaris";

// import SheetExample from '../../../../../src/components/ContactUsSheetExample/SheetExample';
import { reqGetFunctionalStatus,} from "../../../../api";
import "./index.css";
import OnPageSEOTabs from "../../Tabs";
import Base64Image from "../../../../../src/components/Base64Image";
import LanguageSwitch from "../../../../components/LanguageSwitch";
class dashboard extends Component {
  state = {
    welcomeStatus: '',
    webPushStatus: '',
    priceDropStatus: '',
    stockPushStatus: '',
    shippingPushStatus: '',
  };

  //进入页面调用接口
  componentDidMount() {
    this.getFunctionalStatus();
  }
  callback=()=>{
    this.setState({})
  }
  // 获取功能开启关闭状态
  getFunctionalStatus = async() =>{
    const params = {};
    const responseData = await reqGetFunctionalStatus(params);
    if (responseData.code === "200") {
      var result = responseData.data;
      this.setState({
        welcomeStatus: (result.welcomeStatus===1)?'features--active':'features--stop',
        webPushStatus: (result.webPushStatus===1)?'features--active':'features--stop',
        priceDropStatus: (result.priceDropStatus===1)?'features--active':'features--stop',
        stockPushStatus: (result.stockPushStatus===1)?'features--active':'features--stop',
        shippingPushStatus: (result.shippingPushStatus===1)?'features--active':'features--stop',
      });
    }
  }

  render() {
    const {
        state:{
          welcomeStatus,
          webPushStatus,
          priceDropStatus,
          stockPushStatus,
          shippingPushStatus
        }
      } = this;

    const WelcomeNotificationImg = <Base64Image type="welcome" />
    const WebPushImg = <Base64Image type="webpush" />
    const PriceDropImg = <Base64Image type="price" />
    const BackInStockImg = <Base64Image type="stock" />
    const ShippingNotificationImg = <Base64Image type="shipping" />

    return (
      <div>
        <AppProvider>
          <Frame topBar={<OnPageSEOTabs
            callback={this.callback}
          />}>
            <Page>
              <div className="bigspace"></div>
              <div className="center_text_align">
              <FormLayout>
                <FormLayout.Group>
                  <div className="features_item">
                    <div className={welcomeStatus}>
                      <Card sectioned>
                        <TextContainer>
                          <Heading><LanguageSwitch Words="Welcome Notification" /></Heading>
                        </TextContainer>
                        <div>
                          {WelcomeNotificationImg}
                          <Button primary url="/admin/automation/notification"><LanguageSwitch Words="Edit" /></Button>
                        </div>
                      </Card>
                    </div>
                  </div>

                  <div className="features_item">
                    <div className={webPushStatus}>
                      <Card sectioned>
                        <TextContainer>
                          <Heading><LanguageSwitch Words="Abandoned Cart Web Push" /></Heading>
                        </TextContainer>
                        <div>
                          {WebPushImg}
                          <Button primary url="/admin/abandoned-cart-recovery/index"><LanguageSwitch Words="Edit" /></Button>
                        </div>
                      </Card>
                    </div>
                  </div>

                  <div className="features_item">
                    <div className={priceDropStatus}>
                      <Card sectioned>
                        <TextContainer>
                          <Heading><LanguageSwitch Words="Price Drop Alert" /></Heading>
                        </TextContainer>
                        <div>
                          {PriceDropImg}
                          <Button primary url="/admin/automation/pricedrop"><LanguageSwitch Words="Edit" /></Button>
                        </div>
                      </Card>
                    </div>
                  </div>

                  <div className="features_item">
                    <div className={stockPushStatus}>
                      <Card sectioned>
                        <TextContainer>
                          <Heading><LanguageSwitch Words="Back In Stock Push" /></Heading>
                        </TextContainer>
                        <div>
                          {BackInStockImg}
                          <Button primary url="/admin/automation/stockpush"><LanguageSwitch Words="Edit" /></Button>
                        </div>
                      </Card>
                    </div>
                  </div>

                  <div className="features_item">
                    <div className={shippingPushStatus}>
                      <Card sectioned>
                        <TextContainer>
                          <Heading><LanguageSwitch Words="Shipping Notification" /></Heading>
                        </TextContainer>
                        <div>
                          {ShippingNotificationImg}
                          <Button primary url="/admin/automation/shipping"><LanguageSwitch Words="Edit" /></Button>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div></div>
                  {/* <Card sectioned>
                    <TextContainer>
                      <Heading>Promotion Push</Heading>
                    </TextContainer>
                    <div>
                      <img
                        src="/PromotionPush.png"
                        alt="Promotion Push"
                      />
                      <Button primary >Edit</Button>
                    </div>
                  </Card> */}
                </FormLayout.Group>
              </FormLayout>
              </div>
            </Page>
          </Frame>
        </AppProvider>
      </div>
    );
  }
}

export default dashboard;
