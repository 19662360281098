import React, { Component } from 'react';
import { Tabs , Button ,ButtonGroup, SkeletonDisplayText, Select } from '@shopify/polaris';
import { withRouter } from 'react-router-dom';
import { reqUpdateLanguage, reqGetSelectLanguage } from '../../api';
import LanguageSwitch from "../../components/LanguageSwitch";
import './Tab.css';

class OnPageSEOTabs extends Component {
    state = {
      selected: 0,
      selectedLanguage: localStorage.getItem('SELECT_LANGUAGE')?localStorage.getItem('SELECT_LANGUAGE'):'en'
    };
  
    componentDidMount () {

      var url = this.props.match.url;
      let arrayOne = ['/admin/dashboard'];
      for(var iOne = 0;iOne < arrayOne.length ; iOne ++){
        if(url.indexOf(arrayOne[iOne]) >= 0){
          this.setState({selected: 0});
          break;
        } 
      }
      let arrayTwo = ['/admin/create-campaign'];
      for(var iTwo = 0;iTwo < arrayTwo.length ; iTwo ++){
        if(url.indexOf(arrayTwo[iTwo]) >= 0){
          this.setState({selected: 1});
          break;
        }
      }
      let arrayThree = ['/admin/automation/all','/admin/automation/notification','/admin/automation/stockpush','/admin/automation/pricedrop','/admin/automation/shipping','/admin/abandoned-cart-recovery/index'];
      for(var iThree = 0;iThree < arrayThree.length ; iThree ++){
        if(url.indexOf(arrayThree[iThree]) >= 0){
          this.setState({selected: 2});
          break;
        }
      }

      let arrayFour = ['/admin/segments/all'];
      for(var iFour = 0;iFour < arrayFour.length ; iFour ++){
        if(url.indexOf(arrayFour[iFour]) >= 0){
          this.setState({selected: 3});
          break;
        }
      }

      let arrayFive = ['/admin/advanced-settings'];
      for(var iFive = 0;iFive < arrayFive.length ; iFive ++){
        if(url.indexOf(arrayFive[iFive]) >= 0){
          this.setState({selected: 4});
          break;
        }
      }
      let arraySix = ['/admin/pricing'];
      for(var iSix = 0;iSix < arraySix.length ; iSix ++){
        if(url.indexOf(arraySix[iSix]) >= 0){
          this.setState({selected: 5});
          break;
        }
      }
     

    };

    // handleTabChange = (selectedTabIndex) => {
    //   this.setState({selected: selectedTabIndex});
    // };

    saveThisData = ()=>{
      const {saveDataParams} = this.props;
      this.props.saveData(saveDataParams);
    }

  // 设置选择语言
  setSelectLanguage = async ()=>{
    let selectedLanguage = '';
    if(localStorage.getItem('SELECT_LANGUAGE')) {
      selectedLanguage = localStorage.getItem('SELECT_LANGUAGE');
    }else{
      selectedLanguage = await reqGetSelectLanguage();
    }
    this.setState({ selectedLanguage })

  }
  handleSelectChangeLanguage = async (selectedLanguage) => {
    this.setState({
      selectedLanguage
    });
    const params = {
      Language: selectedLanguage,
    };
    const response = await reqUpdateLanguage(params)
    if(response.code === 200) {
      window.localStorage.setItem('SELECT_LANGUAGE', selectedLanguage);
    }else{
      window.localStorage.setItem('SELECT_LANGUAGE', 'en');
    }
    this.props.callback();
  }
    render() {
      const { selected,selectedLanguage } = this.state;
      const tabs = [
        {
          id: '0',
          content: <LanguageSwitch Words="Dashboard" />,
          accessibilityLabel: 'Dashboard',
          panelID: 'dashboard',
          url:'/admin/dashboard',
        },
        // {
        //   id: '1',
        //   content: 'Abandoned Cart Recovery',
        //   panelID: 'Abandoned Cart Recovery',
        //   url:'/admin/abandoned-cart-recovery/index',
        // },
        {
          id: '1',
          content: <LanguageSwitch Words="Campaign" />,
          panelID: 'Campaign',
          url:'/admin/create-campaign/all',
        },
        {
          id: '2',
          content: <LanguageSwitch Words="Automation" />,
          panelID: 'Automation',
          url:'/admin/automation/all',
        },
        {
          id: '3',
          content: <LanguageSwitch Words="Segments" />,
          panelID: 'Segments',
          url:'/admin/segments/all',
        },
        {
          id: '4',
          content: <LanguageSwitch Words="Widgets" />,
          panelID: 'Widgets',
          url:'/admin/advanced-settings/index',
        },
        {
          id: '5',
          content: <LanguageSwitch Words="Pricing" />,
          panelID: 'Pricing',
          url:'/admin/pricing',
        },
      ];

      const { loading } = this.props;

      const discardButton = this.props.discardButton && <Button onClick={this.props.discardButton}> Discard </Button>
      const saveButton = this.props.saveData && <Button primary={true} onClick={this.saveThisData} loading={loading} > Save </Button>

      const tabButtonGroup = this.props.saveData?<div className="allHeaderSave"><ButtonGroup> {discardButton} {saveButton} </ButtonGroup></div>:'';


      const headerTabClassName = this.props.saveData?'headerTab':'';
      const options = [
        {label: '🇺🇸  English', value: 'en'},
        // {label: '🇫🇷  Française', value: 'fr'},
        // {label: '🇸🇵  Español', value: 'sp'},
        // {label: '🇬🇪  Deutsch', value: 'ge'},
        // {label: '🇵🇹  Português', value: 'pt'},
      ];
      const selectLanguage = this.state.loading ? <SkeletonDisplayText size="medium" /> : <div className="selectLanguage"><Select
          label=""
          options={options}
          onChange={this.handleSelectChangeLanguage}
          value={selectedLanguage}
      /></div>;

      return (
        <div className={headerTabClassName}>
        <div className="headerTabContent">
          <Tabs tabs={tabs} selected={selected}>
          {/* <Card.Section title={tabs[selected].content}>
              <p>Tab {selected} selected</p>
          </Card.Section> */}
            {selectLanguage}
          </Tabs>
        </div>

        {tabButtonGroup}

        </div>
       
      );
    }
  }


  export default withRouter(OnPageSEOTabs);