import React, { Component } from "react";

import {
  DisplayText,
  Heading,
  Select,
  AppProvider,
  SkeletonDisplayText,
  SkeletonBodyText,
  Page,
  Frame,
  FormLayout,
  Card,
  TextField,
  Button,
  Thumbnail,
  Layout,
  Stack,
  TextContainer,
  Toast,
  DataTable,
  Pagination,
  Link,
  Spinner,
  EmptyState
} from "@shopify/polaris";
// import {
//   SettingsMinor,CancelSmallMinor
// } from '@shopify/polaris-icons';
// import SheetExample from '../../../../../src/components/ContactUsSheetExample/SheetExample';
import WebPushBox from '../../../../../src/components/WebPushBox/index';

import {
  reqGetPriceDrop,
  reqSetPriceWidgetState,
  reqSetPriceWidgetContent,
  reqSetPriceState,
  reqGetPriceSubscribeProduct
} from "../../../../api";

import "./index.css";
import OnPageSEOTabs from "../../Tabs";

import "rc-time-picker/assets/index.css";

import EditDetailModal from './modal';

import { API_DOMAIN } from "../../../../utils/constants";
// import { Link } from "@shopify/polaris/types/components/AppProvider/utilities";
import LanguageSwitch from "../../../../components/LanguageSwitch";
class dashboard extends Component {
  state = {
    productImageUrl: '/template_Product_Image.jpg',  // sent test product image
    isWindows:true,
    //弹窗
    showToast: false, //弹窗显示
    showToastText: "", //弹窗文字
    showToastIserror: false, //弹窗是否错误

    selected: 0,
    //头部数据
    clicks: 0,
    sent: 0,
    subscribers: 0,
    revenue: 0,

    //页面动作
    loading: true,
    loadingPage: false,
    setUpdateImageBackground: "",

    setUpdateImageHeroBackground: "",
    //用户链接
    UserUrlName: "",

    //提交状态
    SubState: false,

    //保存按钮动作
    saveButton: false,

    // 页面详情所有内容 (用户选择还原数据 discard 时使用)
    initPageData: [],

    // 显示顶部保存栏
    showTopSaveBar: false,

    //总开关
    status: 0,
    Button2Loading: false,

    //底部widget --begin

    Button3Loading: false,
    widgetState: 0,
    new_widgetDelayTime: "10",
    new_widgetTitle: "Set Price Drop Alert",
    new_widgetDescription: "Get a notification when price drops below {{variant_price}}.",
    new_widgetAcceptButton: "Allow",
    new_widgetAfterText: "We will notify you when the price drops.",
    SaveWidgetLoading: false,

    //底部widget --end

    //头部加载状态
    loadingTable:false,

    //头部页面数据
    page: 1,
    size: 5,
    hasPrevious: false,
    hasNext: true,
    total: 0,
    products:[],
    tableIsEmpty:false
  };

  //进入页面调用接口
  componentDidMount() {
    this.GetCreateCampaign();
    this.getSubscribeProduct();
  }

  componentWillUnmount() {}

  callback = () => {
    this.setState({})
  }

  //获取
  getSubscribeProduct = async () => {

    this.setState({
      loadingTable:true
    });
    const params = { page: this.state.page, size: this.state.size };

    const response = await reqGetPriceSubscribeProduct(params);

    if (response.code === "200") {

      const maxPage = Math.ceil(response.count / this.state.size);

      var products = response.data.map((item,key) => {
        var name = (<Link url={item.url} external={true}>{item.title}</Link>);
          return [name,item.totalSubscribers];
      });

      if(products.length===0){
          this.setState({
            tableIsEmpty:true
          });
      }

      if (this.state.page > 1 && this.state.page < maxPage) {
        setTimeout(() => {
          this.setState({
            hasPrevious: true,
            hasNext: true,
            loadingTable: false,
            total:response.count,
            products:products
          });
        }, 1000);
        //如果当前页面小于等于1 且 小于总分页 下关闭 上开启
      } else if (this.state.page <= 1 && this.state.page < maxPage) {
        setTimeout(() => {
          this.setState({
            hasPrevious: false,
            hasNext: true,
            loadingTable: false,
            total:response.count,
            products:products
          });
        }, 1000);
        //如果当前页面大于1 且 大于等于 总分页   下开启 上关闭
      } else if (this.state.page > 1 && this.state.page >= maxPage) {
        setTimeout(() => {
          this.setState({
            hasPrevious: true,
            hasNext: false,
            loadingTable: false,
            total:response.count,
            products:products
          });
        }, 1000);
        // 否则都关闭
      } else if (this.state.page <= 1 && this.state.page >= maxPage) {
        setTimeout(() => {
          this.setState({
            hasPrevious: false,
            hasNext: false,
            loadingTable: false,
            total:response.count,
            products:products
          });
        }, 1000);
      }
    }
  }

  // 用户 编辑完成功保存后，将数据更新到父级页面
  callBackRefresh = (title,message,buttonTitle1,buttonTitle2) =>{
      this.setState({ 
        Title: title,
        Message: message,
        button1Title: buttonTitle1,
        button2Title: buttonTitle2,
      });
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror) => {
    this.setState({
      showToastText: showToastText,
      showToastIserror: showToastIserror
    });
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };
  // input 输入框变化
  handleChangeInput = (value, name) => {
    this.setState({
      [name]: value
    });
  };

  // 更新数据
  updatePageDetail() {
    let getData = this.state.initPageData;
    this.setState({
      status: getData.status,
      Title: getData.Title, //标题
      Message: getData.Message, //内容
      URL: getData.URL, //链接
      button1Title: getData.button1Title, //按钮1的标题
      button2Title: getData.button2Title, //按钮2的标题
      setUpdateImageBackground: getData.setUpdateImageBackground, //logo图
      setUpdateImageHeroBackground: getData.setUpdateImageHeroBackground, //大图
      sent: getData.sent,
      clicks: getData.clicks,
      revenue: getData.revenue,
      subscribers: getData.subscribers,

      //更新状态
      loadingPage: true,
      loading: false,
      UserUrlName: getData.userUrl,

      //通知状态
      widgetState: getData.widget_state,
      shopLogo: getData.setUpdateImageBackground
        ? getData.setUpdateImageBackground
        : API_DOMAIN + "/logo.jpg",
      new_widgetDelayTime: getData.delayTime,
      new_widgetTitle: getData.widgetTitle,
      new_widgetDescription: getData.widgetDescription,
      new_widgetAcceptButton: getData.widgetAcceptButton,
      new_widgetAfterText: getData.widgetAfterText,
    });
  }

  //获取数据
  GetCreateCampaign = async () => {
    const params = {};
    const responseData = await reqGetPriceDrop(params);
    if (responseData.code === "200") {
      var initData = responseData.data;
      this.setState({
        initPageData: initData
      });
      this.updatePageDetail();
    }
  };

  //输入框输入
  handleChange = (field, value) => {
      return value =>
      this.setState({
        [field]: value
      }); 
  };


  //通知开关切换
  SetWidgetState = async type => {
    const params = {};
    if (type === 2) {
      this.setState({
        Button2Loading: true
      });
      const responseData = await reqSetPriceState(params);
      if (responseData.code === "200") {
        this.setState({
          //开关状态
          status: responseData.data,
          Button2Loading: false
        });
        this.toggleToast(responseData.msg, false);
      }
    } else if (type === 3) {
      this.setState({
        Button3Loading: true
      });
      const responseData = await reqSetPriceWidgetState(params);
      if (responseData.code === "200") {
        this.setState({
          //开关状态
          widgetState: responseData.data,
          Button3Loading: false
        });
        this.toggleToast(responseData.msg, false);
      }
    }
  };

  // save new Widget 保存数据
  saveNewWidgetContent = async () => {
    this.setState({
      SaveWidgetLoading: true
    });
    const params = {
      new_widgetDelayTime: this.state.new_widgetDelayTime,
      new_widgetTitle: this.state.new_widgetTitle,
      new_widgetDescription: this.state.new_widgetDescription,
      new_widgetAcceptButton: this.state.new_widgetAcceptButton,
      new_widgetAfterText: this.state.new_widgetAfterText,
    };
    const responseData = await reqSetPriceWidgetContent(params);
    if (responseData.code === "200") {
      this.setState({
        //开关状态
        SaveWidgetLoading: false
      });
      this.toggleToast(responseData.msg, false);
    }
  };

  
  // See Example
  WebPushSeeExample =()=>{
    this.setState({
      showExample: '',
      WebPushExample:false,
    },()=>{
      this.setState({
        showExample:  this.props.isWindows ? 'testWebPush_Show' : 'testPush_Mac_Show',
        WebPushExample: true,
        sendTestTitle: this.state.Title,
        sendTestMessage: this.state.Message,
        sendTestUrl: this.state.UserUrlName,
      });
    });

    // 显示 5秒后 自动关闭
    setTimeout(this.CloseWebPushExample,5000);
  }

  // closed web push test
  CloseWebPushExample =()=>{
    this.setState({
      showExample:'',
      WebPushExample:false,
    });
  }

  render() {
    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={this.toggleToast}
        error={this.state.showToastIserror}
      />
    ) : null;
    const {
      state: {
        Title,
        Message,
        URL,
        button1Title,
        button2Title,
        status,
        // widgetState,
        shopLogo,
        new_widgetDelayTime,
        new_widgetTitle,
        new_widgetDescription,
        new_widgetAcceptButton,
        new_widgetAfterText,
      },
      handleChange,
      SetWidgetState,
      saveNewWidgetContent
    } = this;

    //判断开关3
    var button2 = "";
    if (status === 1) {
      button2 = (
        <Button
          loading={this.state.Button2Loading}
          onClick={() => SetWidgetState(2)}
          disabled={!this.state.loadingPage}
        >
          Disable
        </Button>
      );
    } else {
      button2 = (
        <Button
          loading={this.state.Button2Loading}
          primary
          onClick={() => SetWidgetState(2)}
          disabled={!this.state.loadingPage}
        >
          Enable
        </Button>
      );
    }

    const cardHeader = this.state.loading ? (
      <Stack>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
      </Stack>
    ) : (
      <FormLayout>
        <FormLayout.Group condensed>
          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.revenue}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="REVENUE" /></div>
              </TextContainer>
            </div>
          </Card>
          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.subscribers}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="SUBSCRIBERS" /></div>
              </TextContainer>
            </div>
          </Card>
          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.sent}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="IMPRESSIONS" /></div>
              </TextContainer>
            </div>
          </Card>
          <Card sectioned>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.clicks}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="CLICKS" /></div>
              </TextContainer>
            </div>
          </Card>
        </FormLayout.Group>
      </FormLayout>
    );

    const middleContent = this.state.loadingPage ? (
      <Card>
        <section
          className="webPush__SendTime"
          style={{ padding: "2rem 2rem 0" }}
        >
          {/* <div style={{float:"right"}}><Button primary>Edit</Button></div> */}
          <Stack>
          <Stack.Item fill>
          </Stack.Item>
          <EditDetailModal callBackFunc={this.callBackRefresh} sendTestImageUrl={this.state.productImageUrl} isWindows={this.state.isWindows} />
          </Stack>
        </section>
        <Card.Section>
          <WebPushBox
            productImageUrl={API_DOMAIN + "/Untitled.jpg?v=123"}
            logo={shopLogo}
            title={Title?Title:"Title"}
            message={Message?Message:"Message"}
            url={URL?URL:this.state.UserUrlName}
            button_title_1={button1Title?button1Title:""}
            button_title_2={button2Title?button2Title:""}
            type={true}
            sendTest={false}
          />
        </Card.Section>
 

      </Card>
    ) : (
      ""
    );

    const widgetContent = this.state.loadingPage ? (
      <Card>
        <div>
          <Card.Section>
            <Stack distribution="equalSpacing">
              <Heading><LanguageSwitch Words="Widget" /></Heading>
              <Button
                primary
                loading={this.state.SaveWidgetLoading}
                onClick={() => saveNewWidgetContent()}
              >
                <LanguageSwitch Words="Save" />
              </Button>
            </Stack>
          </Card.Section>
          <Card.Section>
            <Layout>
              <Layout.Section secondary>
                <FormLayout>
                  <Select
                    label={<LanguageSwitch Words="Widget Delay" />}
                    options={[
                      { label: "5 seconds", value: "5" },
                      { label: "8 seconds", value: "8" },
                      { label: "10 seconds", value: "10" },
                      { label: "15 seconds", value: "15" },
                      { label: "30 seconds", value: "30" }
                    ]}
                    onChange={handleChange("new_widgetDelayTime")}
                    value={new_widgetDelayTime}
                    helpText=<LanguageSwitch Words="When trigger the widget to show" />
                  />
                  <TextField
                    label={<LanguageSwitch Words="Title" />}
                    maxLength={255}
                    onChange={handleChange("new_widgetTitle")}
                    value={new_widgetTitle}
                  />
                  <TextField
                    label={<LanguageSwitch Words="Description" />}
                    maxLength={255}
                    onChange={handleChange("new_widgetDescription")}
                    value={new_widgetDescription}
                  />
                  <TextField
                    label={<LanguageSwitch Words="Message (After Subscription)" />}
                    maxLength={255}
                    onChange={handleChange("new_widgetAfterText")}
                    value={new_widgetAfterText}
                  />
                  <TextField
                    label={<LanguageSwitch Words="Accept Button" />}
                    maxLength={20}
                    onChange={handleChange("new_widgetAcceptButton")}
                    value={new_widgetAcceptButton}
                  />
                  
                  {/* <div style={{ float: "right", marginBottom: "2rem" }}>
                    <Button
                      primary
                      loading={this.state.SaveWidgetLoading}
                      onClick={() => saveNewWidgetContent()}
                    >
                      Save
                    </Button>
                  </div> */}
                </FormLayout>
              </Layout.Section>

              <Layout.Section secondary>
                <section>
                  <Card sectioned>
                    <Stack wrap={false}>
                      <section style={{ paddingTop: "1.5rem" }}>
                        <Thumbnail
                          source={shopLogo}
                          size="small"
                          alt="Black choker necklace"
                        />
                      </section>
                      <Stack vertical={true}>
                        <section
                          className="Message_Title"
                          style={{ paddingRight: "6rem" }}
                        >
                          <Heading>{new_widgetTitle}<span style={{float:"right", width:"15px",height:"3px",backgroundColor:"black",marginRight:"5px"}}></span></Heading>
                        </section>
                        <section
                          style={{
                            color: "#637381",
                            paddingRight: "7rem",
                            paddingTop: ".5px",
                            marginTop: "-5px",
                            lineHeight: "1.5"
                          }}
                        >
                          <p>{new_widgetDescription}</p>
                        </section>
                      </Stack>
                    </Stack>

                    <div style={{ marginTop: "1.5rem" }}>
                      <Stack>
                        <Stack.Item>
                          <div className="Preview_Accept">
                            <Button primary>{new_widgetAcceptButton}</Button>
                          </div>
                        </Stack.Item>
                        <Stack.Item fill></Stack.Item>
                      </Stack>
                    </div>
                  </Card>
                </section>
              </Layout.Section>
            </Layout>
          </Card.Section>
        </div>
      </Card>
    ) : (
      <div>
        <div className="space"></div>
        <Card>
          <Layout>
            <Layout.Section secondary>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
            </Layout.Section>
          </Layout>
        </Card>
      </div>
    );

    
    var footerContentMsg = "";
    if (this.state.total > 0) {
      const startNum = (this.state.page - 1) * this.state.size + 1;
      const endNum =
        this.state.page * this.state.size > this.state.total
          ? this.state.total
          : this.state.page * this.state.size;
      footerContentMsg =
        startNum + " - " + endNum + " of " + this.state.total + " results";
    }

    const productTable = this.state.loadingTable ? (
      <div className="loadingCenter">
        <Spinner size="large" color="teal" />
      </div>
    ):( this.state.tableIsEmpty ? (
      <div className="emptyWithImg">
            <EmptyState image="/empty.svg">
              <p>No data to show</p>
            </EmptyState>
          </div>
    ):(
      <div className="DataTable_Padding_1">
        <DataTable
            columnContentTypes={["text", "text"]}
            headings={["Product","Subscribers"]}
            rows={this.state.products}
            verticalAlign="middle"
          />
      </div>
    ));

    const head_p2 =(<p className="font_W_600 font_S_18"><LanguageSwitch Words="Subscription Product List" /></p>);

    const pageContent = this.state.loadingPage?(
      <Page>
        <div className="space"></div>
        <Layout>
          <Layout.Section>
            <DisplayText size="Large"><LanguageSwitch Words="Price Drop Alert" /></DisplayText>
            <DisplayText size="small">
              <span className="hello_sub" style={{fontSize:'1.8rem'}}>
                <LanguageSwitch Words="Notifies subscribers when the price of a product is dropped" />
              </span>
            </DisplayText>
            <div className="space"></div>
          </Layout.Section>
        </Layout>
        <div className="WebPush__Control__Button">
          {button2}
        </div>
        {cardHeader}
        <div className="space"></div>
        <Card title={head_p2}>
            <div className="Polaris-Card__Section Price">
                {productTable}
                <Pagination
                  label={footerContentMsg}
                  hasPrevious={this.state.hasPrevious}
                  onPrevious={() => {
                    this.setState({ page: this.state.page - 1 }, () => {
                      this.getSubscribeProduct();
                    });
                  }}
                  hasNext={this.state.hasNext}
                  onNext={() => {
                    this.setState({ page: this.state.page + 1 }, () => {
                      this.getSubscribeProduct();
                    });
                  }}
                />
            </div>
        </Card>
        {middleContent}
        {widgetContent}
        <div className="space"></div>
      </Page>
    ):(
      <Page>
        <div className="space"></div>
        <Layout>
          <Layout.Section>
          <TextContainer>
                  <SkeletonDisplayText size="small" />
          </TextContainer>
          </Layout.Section>
        </Layout>
        <div className="space"></div>
        <Stack>
          <Stack.Item fill>
            <Card sectioned>
              <div className="space"></div>
              <SkeletonBodyText lines={3} />
              <div className="space"></div>
            </Card>
          </Stack.Item>
          <Stack.Item fill>
            <Card sectioned>
              <div className="space"></div>
              <SkeletonBodyText lines={3} />
              <div className="space"></div>
            </Card>
          </Stack.Item>
          <Stack.Item fill>
            <Card sectioned>
              <div className="space"></div>
              <SkeletonBodyText lines={3} />
              <div className="space"></div>
            </Card>
          </Stack.Item>
          <Stack.Item fill>
            <Card sectioned>
              <div className="space"></div>
              <SkeletonBodyText lines={3} />
              <div className="space"></div>
            </Card>
          </Stack.Item>
        </Stack>

        <div>
          <div className="space"></div>
          <Card>
            <Layout>
              <Layout.Section secondary>
                <Card.Section>
                  <TextContainer>
                    <SkeletonDisplayText size="small" />
                  </TextContainer>
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={3} />
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={3} />
                </Card.Section>
                <Card.Section>
                  <SkeletonBodyText lines={3} />
                </Card.Section>
              </Layout.Section>
            </Layout>
          </Card>
      </div>
      </Page>
    );

    return (
      <div className="frame_page_padding">
        <AppProvider>
          <Frame
            topBar={
              <OnPageSEOTabs
                loading={this.state.saveButton}
                saveDataParams=""
                callback={this.callback}
              />
            }
          >
            {pageContent}
            {toastMarkup}
          </Frame>
        </AppProvider>
      </div>
    );
  }
}

export default dashboard;
