import React, { Component } from 'react';

import {AppProvider, Toast, Card, Layout  , Frame , Page,Button ,ProgressBar,List,Icon,SkeletonBodyText,SkeletonDisplayText,TextContainer} from '@shopify/polaris';
import {
    CirclePlusMajorMonotone,
    CircleMinusMajorMonotone,
} from '@shopify/polaris-icons';

import {
    reqGetPricingPlans,
    reqChangePlans
} from "../../../../api/index";

import OnPageSEOTabs from '../../Tabs';
import SheetExample from '../../../../../src/components/ContactUsSheetExample/SheetExample';
import './index.css';
import LanguageSwitch from "../../../../components/LanguageSwitch";



class Pricing extends Component {

  state = {
    //弹窗
    showToast: false,  //弹窗显示
    showToastText:'',  //弹窗文字
    showToastIserror:false, //弹窗是否错误

    selectedLevel:1, // 当前选中的套餐版本

    //页面动作
    loading: true,
    loadingPage: false,
  };

  callback =() => {
      this.setState({})
  }
  //获取到当前版本和所有套餐
  getPricingPlans = async () => {
    const response = await reqGetPricingPlans();
    if (response.code === '200') {
       
        Object.keys(response.data).forEach((key)=>{
            this.setState({[key]:response.data[key]});
        });

        this.setState({
            selectedLevel:response.data.level,
            loadingPage: true,
            loading: false,
        },()=>{
            this.minusOrPlusPlan();
        });

    }
  }


  //  点击加减号实现动态效果 
  minusOrPlusPlan = (acction) =>{

    const { selectedLevel , plans} =  this.state;

    var nextLevel = '';
    if(acction ==='minus'){
        nextLevel =  selectedLevel - 1;
    }else if(acction ==='plus'){
        nextLevel =  selectedLevel + 1;
    }else{
        nextLevel  =  selectedLevel;
    }
    if(selectedLevel === 0 ){
        nextLevel = nextLevel + 1;
    }
    
    if(nextLevel > 0){
        if(plans[nextLevel]){
            this.setState({
                selectedLevel:nextLevel
            },()=>{
                var pulsColor  = (nextLevel+1) < plans.length ?'inkLight':'skyDark';
                var minusColor = nextLevel > 1  ?'inkLight':'skyDark';
                this.setState({
                    pulsColor:pulsColor,
                    minusColor:minusColor
                });
            });
        }
    }

  }

  // 用户更改套餐 
  changePlans =  async (newLevel)=>{

    const {level ,selectedLevel} =  this.state;
    newLevel =  (newLevel === 0) ? 0 :  selectedLevel;

    if(newLevel  !== level){
        // console.log(newLevel);
        const response = await reqChangePlans({newLevel:newLevel});
        if (response.code === '200') {
            if(response.msg){
                this.toggleToast(response.msg, false);
            }
            if(response.url){
                window.top.location.href = response.url;
            }
            this.getPricingPlans(); // 重新获取客户当前套餐
        }else{
            this.toggleToast(response.msg, true);
        }

    }
    
  }


   //进入页面调用接口
  componentDidMount () {
    this.getPricingPlans();
  }

  //提示弹窗
  toggleToast = (showToastText,showToastIserror) => {
    this.setState({
      showToastText:showToastText,
      showToastIserror:showToastIserror
    });
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

    onRef = (ref) => {
        this.child = ref
    }
    handleToggleSheet = (e) => {
        this.child.handleToggleSheet()
    }

  render() {
    
    const { level,plans ,reset_time,impressions_use,impressions_all,precent, selectedLevel,pulsColor,minusColor} =  this.state;

    const FreeButton = level < 1 ? <Button disabled={true} fullWidth={true}><LanguageSwitch Words="CURRENT PLAN" /></Button> :<Button onClick={()=>this.changePlans(0)} primary fullWidth={true}><LanguageSwitch Words="DOWNGRADE" /></Button>;
    var  PaidButton   ='';
    if(level  < selectedLevel){
        PaidButton  =  <Button  onClick={()=>this.changePlans()} primary fullWidth={true}><LanguageSwitch Words="UPGRADE" /></Button>;
    }else  if(level  > selectedLevel){
        PaidButton  =  <Button  onClick={()=>this.changePlans()} primary fullWidth={true}><LanguageSwitch Words="DOWNGRADE" /></Button>;
    }else{
        PaidButton  =  <Button  disabled={true} fullWidth={true}><LanguageSwitch Words="CURRENT PLAN" /></Button>;
    }
    
    const loadingMarkup = this.state.loading ? (
        <div>
        <Layout>
          <Layout.Section>
            <Card>
                <Card.Section Section>
                    <SkeletonBodyText lines={4} />
                </Card.Section>
            </Card>
            <div className="bigspace"></div>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </div>
      ) :'';

    const title = plans?'Current Plan : $'+plans[level].price+'/Month (Your plan reset on '+ reset_time  + ')':''; 
    const desc  = 'Impressions : '+impressions_use+'/'+impressions_all+' ('+precent+'%)';
    const pageContent = this.state.loadingPage && (<div><Layout>
        <Layout.Section oneThird>
          <Card sectioned title= { title }>
              <div>
                  <label>{ desc }</label>
                  <ProgressBar progress={precent} size="small" />
              </div>
          </Card>
        

          <div className="bigspace"></div>
        

          </Layout.Section>
      </Layout>
      <div className="pricingContent"> 
      <div className="Polaris-Layout">
          <div className="Polaris-Layout__Section Polaris-Layout__Section--oneThird">
              <div className="Polaris-Card">
                  <div className="Polaris-Card__Section">
                      <h2 className="Polaris-Heading" style={{textAlign:'center'}}> { plans[0].name } </h2>
                      <div style={{textAlign:'center','marginTop':'2rem',color:'#47c1bf'}}>
                          <span style={{fontSize:'30px'}}>$</span>
                          <span style={{fontSize:'60px'}}> { plans[0].price } </span>
                      </div>
                      <div style={{opacity: '.54',width: '100px',margin: 'auto',color: '#343434',fontSize: '12px',lineHeight: '24px',textAlign: 'center',fontStyle: 'italic'}}><LanguageSwitch Words="Forever Free" /></div>
                      <h1 className="Polaris-Heading" style={{textAlign:'center','marginTop':'2rem','fontSize':'30px'}}> { plans[0].impressions } </h1>
                      <div style={{color: '#343434',fontSize: '12px',lineHeight: '24px',textAlign: 'center'}} ><LanguageSwitch Words="Impressions Per Month" /></div>
                      <div style={{color: '#343434',fontSize: '12px',margin: '3rem 0',textAlign: 'center'}} >
                          { FreeButton }
                      </div>
                      <div className="pricingList" >
                          <List type="bullet">
                            <List.Item><LanguageSwitch Words="Unlimited Subscribers" /></List.Item>
                            <List.Item><LanguageSwitch Words="Promotion Campaign" /></List.Item>
                            <List.Item><LanguageSwitch Words="Abandoned Cart Automation" /></List.Item>
                            <List.Item><LanguageSwitch Words="Welcome Notification Automation" /></List.Item>
                            <List.Item><LanguageSwitch Words="Price Drop Alert Automation" /></List.Item>
                            <List.Item><LanguageSwitch Words="Back In Stock Alert Automation" /></List.Item>
                            <List.Item><LanguageSwitch Words="Shipping Notification Automation" /></List.Item>
                            <List.Item><LanguageSwitch Words="Group subscribers" /></List.Item>
                            <List.Item><LanguageSwitch Words="Hero Image inside the push" /></List.Item>
                            <List.Item><LanguageSwitch Words="Discount code widget" /></List.Item>
                            <List.Item><LanguageSwitch Words="Custom Widgets" /></List.Item>
                            <List.Item><LanguageSwitch Words="Desktop + Mobile Web Push" /></List.Item>
                            <List.Item><LanguageSwitch Words="Web Push Report" /></List.Item>
                          </List>
                          <div style={{padding:'24px'}}></div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="Polaris-Layout__Section Polaris-Layout__Section--oneThird pricingCard" >
              <div className="Polaris-Card" >
                 
                  <div className="Polaris-Card__Section">
                      <div style={{position: 'relative'}}>
                          {/* <img style={{height: '121px',width: '97px',position: 'absolute',top: '15px',right: '10px'}} src="https://dashboard.pushowl.com/assets/images/home/pricing_most_popular.png" /> */}
                      </div>  
                      <h2 className="Polaris-Heading" style={{textAlign:'center'}}>{ plans[selectedLevel].name }</h2>
                      <div style={{textAlign:'center','marginTop':'2rem',color:'#47c1bf'}}>
                          <span style={{fontSize:'30px'}}>$</span>
                          <span style={{fontSize:'60px'}}>{ plans[selectedLevel].price }</span>
                      </div>
                      <div style={{opacity: '.54',width: '100px',margin: 'auto',color: '#343434',fontSize: '12px',lineHeight: '24px',textAlign: 'center',fontStyle: 'italic'}}>per Month</div>
                      <h1 className="Polaris-Heading" style={{textAlign:'center','marginTop':'2rem','fontSize':'30px'}}>
                          <span style={{display:'inline-block'}} className="activeIcon" onClick={()=>this.minusOrPlusPlan('minus')} > <Icon source={CircleMinusMajorMonotone} color={minusColor} /> </span>
                          <span style={{display:'inline-block',margin:'0 20px'}} > { plans[selectedLevel].impressions } </span>
                          <span style={{display:'inline-block'}} className="activeIcon" onClick={()=>this.minusOrPlusPlan('plus')} > <Icon source={CirclePlusMajorMonotone} color={pulsColor} /> </span>
                      </h1>
                      <div style={{color: '#343434',fontSize: '12px',lineHeight: '24px',textAlign: 'center'}} ><LanguageSwitch Words="Impressions Per Month" /></div>
                      <div style={{color: '#343434',fontSize: '12px',margin: '3rem 0',textAlign: 'center'}} >
                          { PaidButton  }
                      </div>
                      <div className="pricingList" >
                          {/* <p>
                              <span className="allfeatures">✔</span>
                              <span style={{fontSize:'18px',fontWeight:'700'}}>All Basic Features</span>
                          </p> */}
                          <List type="bullet">
                            <List.Item><LanguageSwitch Words="Unlimited Subscribers" /></List.Item>
                            <List.Item><LanguageSwitch Words="Promotion Campaign" /></List.Item>
                            <List.Item><LanguageSwitch Words="Abandoned Cart Automation" /></List.Item>
                            <List.Item><LanguageSwitch Words="Welcome Notification Automation" /></List.Item>
                            <List.Item><LanguageSwitch Words="Price Drop Alert Automation" /></List.Item>
                            <List.Item><LanguageSwitch Words="Back In Stock Alert Automation" /></List.Item>
                            <List.Item><LanguageSwitch Words="Shipping Notification Automation" /></List.Item>
                            <List.Item><LanguageSwitch Words="Group subscribers" /></List.Item>
                            <List.Item><LanguageSwitch Words="Hero Image inside the push" /></List.Item>
                            <List.Item><LanguageSwitch Words="Discount code widget" /></List.Item>
                            <List.Item><LanguageSwitch Words="Custom Widgets" /></List.Item>
                            <List.Item><LanguageSwitch Words="Desktop + Mobile Web Push" /></List.Item>
                            <List.Item><LanguageSwitch Words="Web Push Report" /></List.Item>
                            <List.Item><LanguageSwitch Words="Send Campaign at subscriber’s local time" /></List.Item>
                          </List>
                      </div>
                  </div>
              </div>
          </div>
          <div className="Polaris-Layout__Section Polaris-Layout__Section--oneThird pricingCard">
              <div className="Polaris-Card">
                  <div className="Polaris-Card__Section">
                      <h2 className="Polaris-Heading" style={{textAlign:'center'}}><LanguageSwitch Words="Enterprise" /></h2>
                      <div style={{fontWeight: 700,textAlign:'center',margin: '46px 0px 47px'}}>
                          {/* <img style={{height:'65px'}} src="https://dashboard.pushowl.com/assets/images/home/pricing_owl.png" /> */}
                          <span style={{color:'rgb(71, 193, 191)',display:'inline-block',textAlign:'left'}}>
                              {/* CUSTOM<br/>IMPRESSIONS */}
                              <LanguageSwitch Words="CUSTOM IMPRESSIONS" />
                          </span>
                      </div>
                      <div style={{textAlign:'center',margin: '31px 0px 30px'}}>
                          <div style={{maxWidth:'95%',display: 'inline-block'}}>
                              <LanguageSwitch Words="Contact us to discuss a custom pricing plan which suits your needs." />
                          </div>
                      </div>
                      <div style={{color: '#343434',fontSize: '12px',margin: '3rem 0',textAlign: 'center'}} >
                          <Button  onClick={this.handleToggleSheet} primary fullWidth={true}><LanguageSwitch Words="GET IN TOUCH" /></Button>
                      </div>
                      <div className="pricingList" >
                      {/* <p>
                          <span className="allfeatures">✔</span>
                          <span style={{fontSize:'18px',fontWeight:'700'}}>All Basic Features</span>
                      </p> */}
                      <List type="bullet">
                          <List.Item><LanguageSwitch Words="Unlimited Subscribers" /></List.Item>
                          <List.Item><LanguageSwitch Words="Promotion Campaign" /></List.Item>
                          <List.Item><LanguageSwitch Words="Abandoned Cart Automation" /></List.Item>
                          <List.Item><LanguageSwitch Words="Welcome Notification Automation" /></List.Item>
                          <List.Item><LanguageSwitch Words="Price Drop Alert Automation" /></List.Item>
                          <List.Item><LanguageSwitch Words="Back In Stock Alert Automation" /></List.Item>
                          <List.Item><LanguageSwitch Words="Shipping Notification Automation" /></List.Item>
                          <List.Item><LanguageSwitch Words="Group subscribers" /></List.Item>
                          <List.Item><LanguageSwitch Words="Hero Image inside the push" /></List.Item>
                          <List.Item><LanguageSwitch Words="Discount code widget" /></List.Item>
                          <List.Item><LanguageSwitch Words="Custom Widgets" /></List.Item>
                          <List.Item><LanguageSwitch Words="Desktop + Mobile Web Push" /></List.Item>
                          <List.Item><LanguageSwitch Words="Web Push Report" /></List.Item>
                          <List.Item><LanguageSwitch Words="Send Campaign at subscriber’s local time" /></List.Item>
                      </List>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      
      </div></div>);


    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={this.toggleToast}
        error={this.state.showToastIserror}
      />
    ) : null;

    const pageTitle = this.state.loading ?
    (
        <TextContainer>
            <SkeletonDisplayText size="small" />
        </TextContainer>

    ):(
            <LanguageSwitch Words="Pricing" />
    );

    return (
      <AppProvider>
        <Frame topBar={<OnPageSEOTabs
            callback={this.callback}
        /> } >
          <Page 
            title= {pageTitle}
          >
            {loadingMarkup}
            {pageContent}
            {toastMarkup}

            <div style={{display:'none'}}>
                <SheetExample onRef={this.onRef} />
            </div>
            <div className="space"></div>
          </Page>
        </Frame>
      </AppProvider>
      
    );
  }
}

export default Pricing;
