import React, { Component } from "react";

import {
  AppProvider,
  SkeletonDisplayText,
  SkeletonBodyText,
  Page,
  Frame,
  FormLayout,
  Card,
  TextField,
  TextStyle,
  Tabs,
  ChoiceList,
  Button,
  Thumbnail,
  DropZone,
  Layout,
  Stack,
  Banner,
  Spinner,
  List,
  Avatar,
  ContextualSaveBar,
  Modal,
  TextContainer,
  Toast,
  DisplayText
} from "@shopify/polaris";
// import {
//   SettingsMinor,CancelSmallMinor
// } from '@shopify/polaris-icons';
// import SheetExample from '../../../../../src/components/ContactUsSheetExample/SheetExample';
import LoadingGif from "../../../../components/LoadingGif";

import {
  reqSetWelcomeState,
  reqGetWelcomeNotification,
  reqInsertCreateCampaignImage,
  reqUpdateWelcomeNotification
} from "../../../../api";

import "./index.css";
import OnPageSEOTabs from "../../Tabs";

import "rc-time-picker/assets/index.css";

import { API_DOMAIN } from "../../../../utils/constants";
import LanguageSwitch from "../../../../components/LanguageSwitch";
class dashboard extends Component {
  state = {
    //弹窗
    showToast: false, //弹窗显示
    showToastText: "", //弹窗文字
    showToastIserror: false, //弹窗是否错误

    selected: 0,

    //多选框
    Multipleselected: [],

    //页面动作
    loading: true,
    loadingPage: false,
    //输入框变量
    sendingTo: "New subscribers",
    Title: "",
    TitleError: "",
    Message: "",
    MessageError: "",
    URL: "",
    URLError: "",

    //下拉框数据
    httpSelectValue: "https://",

    //图片1
    files: "",
    fileUpload: "",
    uploadedFiles: "",
    setUpdateImageWarning: "",
    setUpdateImageBackground: "",
    loadingLogo: false,
    //图片删除按钮
    imgButton: true,

    //按钮1
    httpSelectValueButton1: "https://",
    button1Title: "",
    button1TitleError: "",
    button1Url: "",
    button1UrlError: "",

    //按钮2
    httpSelectValueButton2: "https://",
    button2Title: "",
    button2TitleError: "",
    button2Url: "",
    button2UrlError: "",

    //用户链接
    UserUrlName: "",

    //提交状态
    SubState: false,

    //显示警告窗口
    criticalWindow: "",

    //保存按钮动作
    saveButton: false,

    // 页面详情所有内容 (用户选择还原数据 discard 时使用)
    initPageData: [],

    // 监听用户内容变化变量 (在用户未保存离开是做提示用)
    dataHasChange: false,
    leavingModalActive: false,

    // 用户还原回到 未编辑时内容版本 ( 弹窗提示 )
    discardModalActive: false,

    // 显示顶部保存栏
    showTopSaveBar: false,
    sent:0,
    clicks:0
  };
  callback=() =>{
    this.setState({})
  }
  //进入页面调用接口
  componentDidMount() {
    //开始调用获取网站
    this.GetCreateCampaign();
    // 挂载滚动监听
    // window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    // 移除滚动监听
    // window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    if (
      !navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      // 滚动的高度
      // const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);
      // 视窗高度
      // const clientHeight = (event.srcElement && event.srcElement.documentElement.clientHeight) || document.body.clientHeight;
      // 页面高度
      // const scrollHeight = (event.srcElement && event.srcElement.documentElement.scrollHeight) || document.body.scrollHeight;
      // 距离页面底部的高度
      // const height = scrollHeight - scrollTop - clientHeight;
      // var previewObj = document.getElementById('Preview-Auto');
      // if(scrollTop > 133){
      //   var fixedHeight = '';
      //   if(scrollTop > 2800){
      //     fixedHeight = 2800 + -130 + 'px';
      //   }else{
      //     fixedHeight = scrollTop + -130 + 'px';
      //   }
      //   previewObj.style.marginTop = fixedHeight;
      // } else{
      //   previewObj.style.marginTop = 'unset';
      // }
    }
  }

  // 保存数据时，报错滚动到页面错误提示顶部
  ScrollToTop() {
    if (
      !navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      var event = document.getElementById("errorContent");

      // 滚动的高度
      const scrollTop =
        (event.srcElement
          ? event.srcElement.documentElement.scrollTop
          : false) ||
        window.pageYOffset ||
        (event.srcElement ? event.srcElement.body.scrollTop : 0);
      // 视窗高度
      // const clientHeight = (event.srcElement && event.srcElement.documentElement.clientHeight) || document.body.clientHeight;
      // 页面高度
      // const scrollHeight = (event.srcElement && event.srcElement.documentElement.scrollHeight) || document.body.scrollHeight;

      if (scrollTop > 128) {
        window.scrollTo(0, 128);
      }
    }
  }

  //提示弹窗
  toggleToast = (showToastText, showToastIserror) => {
    this.setState({
      showToastText: showToastText,
      showToastIserror: showToastIserror
    });
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

  // 更新数据
  updatePageDetail(isDiscard) {
    let getData = this.state.initPageData;
    this.setState({
      Title: getData.Title, //标题
      Message: getData.Message, //内容
      httpSelectValue: getData.httpSelectValue, //https下拉框
      URL: getData.URL, //链接
      setUpdateImageBackground: getData.setUpdateImageBackground, //logo图
      Multipleselected: getData.Multipleselected, //所选中的按钮
      button1Title: getData.button1Title, //按钮1的标题
      httpSelectValueButton1: getData.httpSelectValueButton1, //按钮1https下拉框
      button1Url: getData.button1Url, //按钮1的链接
      button2Title: getData.button2Title, //按钮2的标题
      httpSelectValueButton2: getData.httpSelectValueButton2, //按钮2https下拉框
      button2Url: getData.button2Url, //按钮2的链接
      //更新状态
      loadingPage: true,
      loading: false,
      UserUrlName: getData.userUrl,
      sent:getData.sent,
      clicks:getData.clicks,
      status: getData.status,
    });
    this.setUpdateImage(0, "", getData.setUpdateImageBackground);

    if (isDiscard) {
      this.setState({
        TitleError: "",
        MessageError: "",
        URLError: "",
        button1TitleError: "",
        button1UrlError: "",
        button2TitleError: "",
        button2UrlError: "",
        criticalWindow: "",
        loadingLogo: false,
      });
    }
  }

  //获取数据
  GetCreateCampaign = async () => {
    const params = {};
    const responseData = await reqGetWelcomeNotification(params);
    if (responseData.code === "200") {
      var initData = responseData.data;
      this.setState({
        initPageData: initData
      });

      this.updatePageDetail(false);
    }
  };

  // 功能开关切换
  SetWelcomeState = async() => {
    this.setState({
      Button2Loading: true
    });

    const params = {};
    const responseData = await reqSetWelcomeState(params);
    if (responseData.code === "200") {
      this.setState({
        //开关状态
        status: responseData.data,
        Button2Loading: false
      });
      this.toggleToast(responseData.msg, false);
    }else{
      this.toggleToast(responseData.msg, true);
    }
  };

  //输入框输入
  handleChange = (field, value) => {
    return value =>
      this.setState(
        {
          [field]: value,
          TitleError: "",
          MessageError: "",
          URLError: "",
          button1TitleError: "",
          button1UrlError: "",
          button2TitleError: "",
          button2UrlError: "",
          criticalWindow: "",
          dataHasChange: true
        },
        () => {
          // 显示顶部保存栏
          // if(!this.state.showTopSaveBar){
          //   this.TopSaveBarAction();
          // }
        }
      );
  };
  //小时输入框
  handleChangeHour = (field, value) => {
    if (field > 23) {
      field = 0;
    }
    if (field < 0) {
      field = 23;
    }
    this.setState({
      [value]: field,
      dataHasChange: true
    });

    // 显示顶部保存栏
    // if(!this.state.showTopSaveBar){
    //   this.TopSaveBarAction();
    // }
  };

  //分钟输入框
  handleChangeMinute = (field, value) => {
    if (field > 59) {
      field = 0;
    }
    if (field < 0) {
      field = 59;
    }
    this.setState({
      [value]: field,
      dataHasChange: true
    });

    // 显示顶部保存栏
    // if(!this.state.showTopSaveBar){
    //   this.TopSaveBarAction();
    // }
  };
  //URL输入框
  URLHandleChange = (Value, id) => {
    this.setState({
      TitleError: "",
      MessageError: "",
      URLError: "",
      button1TitleError: "",
      button1UrlError: "",
      button2TitleError: "",
      button2UrlError: "",
      criticalWindow: ""
    });

    // 过滤 http https
    Value = Value.replace("http://", "");
    Value = Value.replace("https://", "");

    var reg = /[\w-]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/;

    if (id === "button1Url") {
      var button1UrlError = "";
      if (!reg.test(Value)) {
        if (Value) {
          button1UrlError = "Enter a valid website URL. eg: google.com";
        }
      }
      this.setState({
        [id]: Value,
        button1UrlError: button1UrlError
      });
    } else if (id === "button2Url") {
      var button2UrlError = "";
      if (!reg.test(Value)) {
        if (Value) {
          button2UrlError = "Enter a valid website URL. eg: google.com";
        }
      }
      this.setState({
        [id]: Value,
        button2UrlError: button2UrlError
      });
    } else {
      var URLError = "";
      if (!reg.test(Value)) {
        if (Value) {
          URLError = "Enter a valid website URL. eg: google.com";
        }
      }
      this.setState({
        [id]: Value,
        URLError: URLError
      });
    }

    this.setState({
      dataHasChange: true
    });
    // 显示顶部保存栏
    // if(!this.state.showTopSaveBar){
    //   this.TopSaveBarAction();
    // }
  };

  //多选框
  handleChangeMultipl = value => {
    this.setState({ Multipleselected: value });
  };

  //下拉框替换
  handleSelectChange = httpSelectValue => {
    this.setState({ httpSelectValue });
  };
  handleSelectChangebutton1 = httpSelectValueButton1 => {
    this.setState({ httpSelectValueButton1 });
  };
  handleSelectChangebutton2 = httpSelectValueButton2 => {
    this.setState({ httpSelectValueButton2 });
  };
  //切换卡片
  handleTabChange = selectedTabIndex => {
    this.setState({ selected: selectedTabIndex });
  };

  //上传图片
  updateImage = acceptedFiles => {
    if (!this.state.loadingLogo) {
      this.setState({
        files: acceptedFiles[0],
        loadingLogo: true
      });
      this.setUpdateImage(1, acceptedFiles[0]);
    } else {
      this.toggleToast("Image is uploading, please try again later.", false);
    }
  };

  //设置图片
  setUpdateImage = async (type, acceptedFiles, img) => {
    var fileUpload = "";
    var uploadedFiles = "";
    var setUpdateImageWarning = "";
    var setUpdateImageBackground = API_DOMAIN + "/logo.jpg";
    if (img) {
      fileUpload = !img && <DropZone.FileUpload />;
      uploadedFiles = img && (
        <div className="addImage">
          <Thumbnail size="small" alt="" source={img} />
        </div>
      );
      this.setState({
        imgButton: false,
        fileUpload: fileUpload,
        uploadedFiles: uploadedFiles
      });
    } else if (acceptedFiles) {
      const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
      const filesImg = acceptedFiles;
      if (validImageTypes.indexOf(filesImg.type) >= 0) {
        fileUpload = !filesImg && <DropZone.FileUpload />;

        //开启FileReader
        var reader = new FileReader();
        reader.readAsDataURL(filesImg); //读取文件保存在result中
        reader.onload = e => {
          var getRes = e.target.result; //读取的结果在result中
          const params = { img: getRes, type: filesImg.type };
          reqInsertCreateCampaignImage(params).then(response => {
            if (response.code === "200") {
              uploadedFiles = filesImg && (
                <div className="addImage">
                  <Thumbnail
                    size="small"
                    alt={filesImg.name}
                    source={response.data}
                  />
                </div>
              );
              setUpdateImageBackground = response.data;
            } else {
              fileUpload = <DropZone.FileUpload />;
              uploadedFiles = "";
            }

            this.setState({
              imgButton: false,
              fileUpload: fileUpload,
              uploadedFiles: uploadedFiles,
              setUpdateImageBackground: setUpdateImageBackground,
              loadingLogo: false
            });

            if (uploadedFiles) {
              this.setState({
                dataHasChange: true
              });
              // 显示顶部保存栏
              // if(!this.state.showTopSaveBar){
              //   this.TopSaveBarAction();
              // }
            }
          });
        };
      } else {
        fileUpload = <DropZone.FileUpload />;
        uploadedFiles = "";
        setUpdateImageWarning = (
          <Banner
            title="The following images couldn’t be uploaded:"
            status="warning"
          >
            <List>
              <List.Item><LanguageSwitch Words="File type must be .jpg .jpeg .png." /></List.Item>
            </List>
            <p></p>
          </Banner>
        );
        this.setState({
          fileUpload: fileUpload,
          uploadedFiles: uploadedFiles,
          setUpdateImageWarning: setUpdateImageWarning,
          setUpdateImageBackground: setUpdateImageBackground
        });
      }
    } else {
      fileUpload = <DropZone.FileUpload />;
      uploadedFiles = "";
      if (type === 1) {
        setUpdateImageWarning = (
          <Banner
            title="The following images couldn’t be uploaded:"
            status="warning"
          >
            <List>
              <List.Item><LanguageSwitch Words="File type must be .jpg .jpeg .png." /></List.Item>
            </List>
          </Banner>
        );
      }
      this.setState({
        fileUpload: fileUpload,
        uploadedFiles: uploadedFiles,
        setUpdateImageWarning: setUpdateImageWarning,
        setUpdateImageBackground: setUpdateImageBackground
      });
    }
  };

  //删除图片
  deleteImage = () => {
    this.setState({
      files: "",
      imgButton: true,
      dataHasChange: true
    });
    // 显示顶部保存栏
    // if(!this.state.showTopSaveBar){
    //   this.TopSaveBarAction();
    // }
    this.setUpdateImage();
  };

  //保存
  insertCreateCampaign = async () => {
    //判断是否允许提交
    var isAllow = 1;
    //错误字符提示 以及 个数
    var criticalCharacter1 = "";
    var criticalCharacter2 = "";
    var criticalCharacter3 = "";
    var criticalCharacter4 = "";
    var criticalCharacter5 = "";
    var criticalCharacter6 = "";
    var criticalCharacter7 = "";
    var criticalCharacter8 = "";
    var criticalCharacter9 = "";
    var criticalCharacter10 = "";
    var criticalCount = 0;
    console.log(this.state.Title);
    //判断必填项
    if (
      this.state.Title === "" ||
      this.state.Title === undefined ||
      this.state.Title === null
    ) {
      console.log(this.state.Title);
      criticalCharacter1 = <List.Item><LanguageSwitch Words="Content title is required" /></List.Item>;
      criticalCount = criticalCount + 1;
      isAllow = 0;
      this.setState({ TitleError: "Required" });
    }
    if (
      this.state.Message === "" ||
      this.state.Message === undefined ||
      this.state.Message === null
    ) {
      criticalCharacter2 = <List.Item><LanguageSwitch Words="Content body is required" /></List.Item>;
      criticalCount = criticalCount + 1;
      isAllow = 0;
      this.setState({ MessageError: "Required" });
    }
    if (
      this.state.URL === "" ||
      this.state.URL === undefined ||
      this.state.URL === null
    ) {
      criticalCharacter3 = <List.Item><LanguageSwitch Words="Content url is required" /></List.Item>;
      criticalCount = criticalCount + 1;
      isAllow = 0;
      this.setState({ URLError: "Required" });
    } else {
      if (
        this.state.URLError !== "" ||
        this.state.URLError === undefined ||
        this.state.URLError === null
      ) {
        criticalCharacter4 = (
          <List.Item>
            <LanguageSwitch Words="Content url: Enter a valid website URL. eg: yourstore.com" />
          </List.Item>
        );
        criticalCount = criticalCount + 1;
        isAllow = 0;
      }
    }

    //按钮1
    if (this.state.Multipleselected.indexOf("button1") >= 0) {
      if (
        this.state.button1Title === "" ||
        this.state.button1Title === undefined ||
        this.state.button1Title === null
      ) {
        criticalCharacter5 = (
          <List.Item><LanguageSwitch Words="Content action buttons 1 title is required" /></List.Item>
        );
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({ button1TitleError: "Required" });
      }
      if (
        this.state.button1Url === "" ||
        this.state.button1Url === undefined ||
        this.state.button1Url === null
      ) {
        criticalCharacter6 = (
          <List.Item><LanguageSwitch Words="Content action buttons 1 url is required" /></List.Item>
        );
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({ button1UrlError: "Required" });
      } else {
        if (
          this.state.button1UrlError !== "" ||
          this.state.button1UrlError === undefined ||
          this.state.button1UrlError === null
        ) {
          criticalCharacter7 = (
            <List.Item>
              <LanguageSwitch Words="Content action buttons 1 url: Enter a valid website URL. eg:" />
              yourstore.com
            </List.Item>
          );
          criticalCount = criticalCount + 1;
          isAllow = 0;
        }
      }
    }

    //按钮2
    if (this.state.Multipleselected.indexOf("button2") >= 0) {
      if (
        this.state.button2Title === "" ||
        this.state.button2Title === undefined ||
        this.state.button2Title === null
      ) {
        criticalCharacter8 = (
          <List.Item><LanguageSwitch Words="Content action buttons 2 title is required" /></List.Item>
        );
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({ button2TitleError: "Required" });
      }
      if (
        this.state.button2Url === "" ||
        this.state.button2Url === undefined ||
        this.state.button2Url === null
      ) {
        criticalCharacter9 = (
          <List.Item><LanguageSwitch Words="Content action buttons 2 url is required" /></List.Item>
        );
        criticalCount = criticalCount + 1;
        isAllow = 0;
        this.setState({ button2UrlError: "Required" });
      } else {
        if (
          this.state.button2UrlError !== "" ||
          this.state.button2UrlError === undefined ||
          this.state.button2UrlError === null
        ) {
          criticalCharacter10 = (
            <List.Item>
              <LanguageSwitch Words="Content action buttons 2 url: Enter a valid website URL. eg:" />
              yourstore.com
            </List.Item>
          );
          criticalCount = criticalCount + 1;
          isAllow = 0;
        }
      }
    }

    //判断是否允许提交
    if (isAllow === 1) {
      this.setState({
        saveButton: true
      });
      const params = {
        Title: this.state.Title, //标题
        Message: this.state.Message, //内容
        URL: this.state.URL, //链接
        httpSelectValue: this.state.httpSelectValue, //https下拉框
        Multipleselected: this.state.Multipleselected, //所选中的按钮
        button1Title: this.state.button1Title, //按钮1的标题
        button1Url: this.state.button1Url, //按钮1的链接
        httpSelectValueButton1: this.state.httpSelectValueButton1, //按钮1https下拉框
        button2Title: this.state.button2Title, //按钮2的标题
        button2Url: this.state.button2Url, //按钮2的链接
        httpSelectValueButton2: this.state.httpSelectValueButton2, //按钮2https下拉框
        setUpdateImageBackground: this.state.setUpdateImageBackground, //logo图
      };
      this.setState({ SubState: true });

      const responseData = await reqUpdateWelcomeNotification(params);
      if (responseData.code === "200") {
        this.setState({
          saveButton: false,
          dataHasChange: false,
          showTopSaveBar: false
        });
        this.toggleToast(responseData.msg, false);
      }
    } else {
      var criticalWindow = (
        <div style={{ marginBottom: "2rem" }}>
          <Banner
            title={
              `To add this message, ` + criticalCount + ` fields need changes`
            }
            status="critical"
          >
            <List>
              {criticalCharacter1}
              {criticalCharacter2}
              {criticalCharacter3}
              {criticalCharacter4}
              {criticalCharacter5}
              {criticalCharacter6}
              {criticalCharacter7}
              {criticalCharacter8}
              {criticalCharacter9}
              {criticalCharacter10}
            </List>
          </Banner>
        </div>
      );

      this.ScrollToTop();

      this.setState({
        criticalWindow: criticalWindow
      });
    }
  };

  //获取周几
  GetDate() {
    var todays = new Date(),
      date =
        todays.getFullYear() +
        "-" +
        (todays.getMonth() + 1) +
        "-" +
        todays.getDate();
    const day = new Date(Date.parse(date.replace(/-/g, "/")));
    const today = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return today[day.getDay()];
  }

  //获取时间
  formatDate() {
    var todays = new Date(),
      date =
        todays.getFullYear() +
        "-" +
        (todays.getMonth() + 1) +
        "-" +
        todays.getDate() +
        " " +
        todays.getHours() +
        ":" +
        todays.getMinutes();

    var d = new Date(date);
    var hh = d.getHours();
    var m = d.getMinutes();
    var dd = "AM";
    var h = hh;
    if (h > 12) {
      h = hh - 12;
      dd = "PM";
    }

    if (h === 0) {
      h = 12;
    }
    m = m < 10 ? "0" + m : m;
    h = h < 10 ? "0" + h : h;

    return h + ":" + m + " " + dd;
  }

  //获取日期
  GetTime() {
    var todays = new Date(),
      date =
        todays.getFullYear() +
        "-" +
        (todays.getMonth() + 1) +
        "-" +
        todays.getDate() +
        " " +
        todays.getHours() +
        ":" +
        todays.getMinutes();

    var dd = new Date(date);
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = dd.getDate();
    d = d < 10 ? "0" + d : d;
    date = y + "-" + m + "-" + d;
    return date;
  }


  // 用户编辑内容 变化后，显示全局顶部保存导航
  TopSaveBarAction = () => {
    this.setState(({ showTopSaveBar }) => ({
      showTopSaveBar: !showTopSaveBar
    }));
  };

  // 监听用户返回 导航方法
  isLeave = () => {
    if (this.state.dataHasChange) {
      this.ControlUserLeavingModal();
      return false;
    } else {
      window.location.href = "/admin/automation/notification"; // 直接跳转
    }
  };

  // 用户未保存编辑内容，直接确认离开
  UserLeavesWithoutSave = () => {
    window.location.href = "/admin/automation/notification"; // 直接跳转
  };

  // UserLeavesListening modal 开关控制
  ControlUserLeavingModal = async () => {
    this.setState(({ leavingModalActive }) => ({
      leavingModalActive: !leavingModalActive
    }));
  };

  // DiscardModal 开关控制
  ControlDiscardModal = async () => {
    this.setState(({ discardModalActive }) => ({
      discardModalActive: !discardModalActive
    }));
  };

  // Discard 返回页面原始数据
  DiscardInitData = () => {
    // 恢复原始数据
    this.setState(
      {
        dataHasChange: false, // 数据编辑参数
        showTopSaveBar: false // 顶部保存导航
      },
      () => {
        this.updatePageDetail(true);
      }
    );

    // 关闭弹窗 隐藏顶部保存栏
    this.setState(({ discardModalActive }) => ({
      discardModalActive: !discardModalActive
    }));
  };

  render() {
    const toastMarkup = this.state.showToast ? (
      <Toast
        content={this.state.showToastText}
        onDismiss={this.toggleToast}
        error={this.state.showToastIserror}
      />
    ) : null;
    const loadingMarkup = this.state.loading ? (
      <div className="loadingCenter">
        <Spinner size="large" color="teal" />
      </div>
    ) : (
      ""
    );
    const {
      state: {
        status,
        fileUpload,
        uploadedFiles,
        sendingTo,
        Title,
        Message,
        URL,
        Multipleselected,
        button1Title,
        button1Url,
        button2Title,
        button2Url,
        httpSelectValue,
        httpSelectValueButton1,
        httpSelectValueButton2,
        selected,
        setUpdateImageWarning,
        setUpdateImageBackground,
        TitleError,
        MessageError,
        URLError,
        button1TitleError,
        button1UrlError,
        button2TitleError,
        button2UrlError,
        criticalWindow
      },
      handleChange,
      updateImage,
      deleteImage,
    } = this;

    // 判断功能开关
    var button2 = "";
    if (status === 1) {
      button2 = (
        <Button
          loading={this.state.Button2Loading}
          onClick={() => this.SetWelcomeState()}
        >
          Disable
        </Button>
      );
    } else {
      button2 = (
        <Button
          loading={this.state.Button2Loading}
          primary
          onClick={() => this.SetWelcomeState()}
        >
          Enable
        </Button>
      );
    }

    var button1Content = "";
    //判断按钮1
    if (Multipleselected.indexOf("button1") >= 0) {
      button1Content = (
        <div>
          <FormLayout>
            <TextField
              label={<LanguageSwitch Words="Title" />}
              onChange={handleChange("button1Title")}
              value={button1Title}
              maxLength={50}
              showCharacterCount
              helpText={<LanguageSwitch Words="Perfect length is 15 characters"/>}
              error={button1TitleError}
            />
            <TextField
              label={<LanguageSwitch Words="Url" />}
              id="button1Url"
              onChange={this.URLHandleChange}
              value={button1Url}
              error={button1UrlError}
              prefix={httpSelectValueButton1}
              // connectedLeft={
              //   <Select
              //     value={httpSelectValueButton1}
              //     label="Weight unit"
              //     onChange={this.handleSelectChangebutton1}
              //     labelHidden
              //     options={['https://', 'http://']}
              //   />
              // }
            />
          </FormLayout>
        </div>
      );
    }

    var button2Content = "";
    //判断按钮2
    if (Multipleselected.indexOf("button2") >= 0) {
      button2Content = (
        <div>
          <FormLayout>
            <TextField
              label={<LanguageSwitch Words="Title" />}
              onChange={handleChange("button2Title")}
              value={button2Title}
              maxLength={50}
              showCharacterCount
              helpText={<LanguageSwitch Words="Perfect length is 15 characters" />}
              error={button2TitleError}
            />
            <TextField
              label={<LanguageSwitch Words="Url" />}
              id="button2Url"
              onChange={this.URLHandleChange}
              error={button2UrlError}
              value={button2Url}
              prefix={httpSelectValueButton2}
              // connectedLeft={
              //   <Select
              //     value={httpSelectValueButton2}
              //     label="Weight unit"
              //     onChange={this.handleSelectChangebutton2}
              //     labelHidden
              //     options={['https://', 'http://']}
              //   />
              // }
            />
          </FormLayout>
        </div>
      );
    }

    const tabs = [
      {
        id: "0",
        content: <LanguageSwitch Words="Windows" />,
        panelID: "Windows"
      },
      {
        id: "1",
        content: <LanguageSwitch Words="Android" />,
        panelID: "Android"
      },
      {
        id: "2",
        content: <LanguageSwitch Words="Mac" />,
        panelID: "Mac"
      }
    ];

    //Preview 切换卡片
    var tabsContent = "";
    if (this.state.loadingPage === true) {
      if (selected === 0) {
        //设置按钮显示
        var buttonShowBool = false;
        var button1ShowBool = false;
        var button2ShowBool = false;
        var buttonShow = "";

        if (
          Multipleselected.indexOf("button1") >= 0 &&
          Multipleselected.indexOf("button2") >= 0
        ) {
          if (button1Title !== "" && button2Title !== "") {
            button1ShowBool = true;
            button2ShowBool = true;
          } else if (button1Title !== "" && button2Title === "") {
            buttonShowBool = true;
            button1ShowBool = true;
          } else if (button1Title === "" && button2Title !== "") {
            buttonShowBool = true;
            button2ShowBool = true;
          }
        } else if (
          Multipleselected.indexOf("button1") >= 0 &&
          Multipleselected.indexOf("button2") === -1
        ) {
          if (button1Title) {
            buttonShowBool = true;
            button1ShowBool = true;
          }
        } else if (
          Multipleselected.indexOf("button2") >= 0 &&
          Multipleselected.indexOf("button1") === -1
        ) {
          if (button2Title) {
            buttonShowBool = true;
            button2ShowBool = true;
          }
        }

        if (button1ShowBool && button2ShowBool) {
          buttonShow = (
            <section
              style={{
                display: "flex",
                textAlign: "center",
                marginTop: "-1.5rem",
                paddingLeft: "1rem"
              }}
            >
              <div
                style={{
                  backgroundColor: "#6b6b6b",
                  marginRight: "5px",
                  width: "50%",
                  padding: "3px 5px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "clip"
                }}
              >
                {button1Title}
              </div>
              <div
                style={{
                  backgroundColor: "#6b6b6b",
                  width: "50%",
                  padding: "3px 5px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "clip"
                }}
              >
                {button2Title}
              </div>
            </section>
          );
        }
        if (buttonShowBool && button1ShowBool) {
          buttonShow = (
            <section
              style={{
                display: "flex",
                textAlign: "center",
                marginTop: "-1.5rem",
                paddingLeft: "1rem"
              }}
            >
              <div
                style={{
                  backgroundColor: "#6b6b6b",
                  width: "100%",
                  padding: "3px 5px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "clip"
                }}
              >
                {button1Title}
              </div>
            </section>
          );
        }

        if (buttonShowBool && button2ShowBool) {
          buttonShow = (
            <section
              style={{
                display: "flex",
                textAlign: "center",
                marginTop: "-1.5rem",
                paddingLeft: "1rem"
              }}
            >
              <div
                style={{
                  backgroundColor: "#6b6b6b",
                  width: "100%",
                  padding: "3px 5px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "clip"
                }}
              >
                {button2Title}
              </div>
            </section>
          );
        }

        tabsContent = (
          <Card.Section>
            <div
              className=""
              style={{
                background: "url('/winback.jpg')  no-repeat",
                height: "550px",
                width: "100%",
                overflow: "hidden",
                backgroundSize: "cover",
                backgroundPosition: "right bottom",
                position: "relative"
              }}
            >
              <div className="ht-preview--windows__time">
                <div>{this.formatDate()}</div>
                <div>{this.GetTime()}</div>
              </div>
              <section
                className="testPush_Win_Preview"
                style={{
                  display: "block",
                  width: "85%",
                  maxWidth: "370px",
                  position: "absolute",
                  right: "10px",
                  bottom: "60px",
                  backgroundColor: "white"
                }}
              >
                <Card>
                  <div
                    style={{
                      padding: "1rem",
                      backgroundColor: "#333",
                      color: "#fff"
                    }}
                  >
                    <Stack vertical={true}>
                      <section style={{ padding: "1rem 1rem" }}>
                        <Stack wrap={false}>
                          <section style={{ paddingTop: ".5rem" }}>
                            <Thumbnail
                              source={setUpdateImageBackground}
                              size="small"
                              alt="Black choker necklace"
                            />
                          </section>
                          <Stack vertical={true}>
                            <section
                              className="Message_Title"
                              style={{ paddingRight: "6rem" }}
                            >
                              <span className="">
                                {this.state.Title ? this.state.Title : "Title"}
                              </span>
                            </section>
                            <section
                              className="Message_Content"
                              style={{
                                paddingRight: "7rem",
                                paddingTop: ".5px",
                                marginTop: "-5px",
                                lineHeight: "1.5"
                              }}
                            >
                              <div className="Polaris-Stack__Itemclass">
                                <Stack vertical={true}>
                                  <span>
                                    {this.state.Message
                                      ? this.state.Message
                                      : "Message"}
                                  </span>
                                </Stack>
                              </div>
                              <div className="Polaris-Stack__Itemclass">
                                <div
                                  style={{ fontSize: "13px", color: "#999" }}
                                >
                                  {" "}
                                  {"Google Chrome · " + this.state.UserUrlName}
                                </div>
                              </div>
                            </section>
                          </Stack>
                        </Stack>
                      </section>

                      {buttonShow}
                    </Stack>
                  </div>
                </Card>
              </section>
            </div>
          </Card.Section>
        );
      } else if (selected === 1) {
        //   //设置按钮显示
        // var b2buttonShow = '';
        // if(Multipleselected.indexOf('button1') >= 0 || Multipleselected.indexOf('button2') >= 0){
        //   if(Multipleselected.indexOf('button1') >= 0){
        //     var b2button1Show = '';
        //     if(button1Title){
        //       b2button1Show = <Link>{button1Title}</Link>
        //     }
        //   }
        //   if(Multipleselected.indexOf('button2') >= 0){
        //     var b2button2Show = '';
        //     if(button2Title){
        //       b2button2Show = <Link>{button2Title}</Link>
        //     }
        //   }
        //   b2buttonShow = <div style={{ marginLeft:"70px" }}><Stack>
        //     {b2button1Show}

        //     {b2button2Show}
        //   </Stack></div>
        // }
        tabsContent = (
          <Card.Section>
            <div
              className="design-mobile4"
              style={{
                background: "url('/mobile5.png')  no-repeat top",
                backgroundSize: "100% auto"
              }}
            >
              <div className="Mobile__Time">{this.formatDate()}</div>
              <section className="Preview">
                <Card>
                  <Stack vertical={true}>
                    <Stack wrap={false}>
                      <Stack.Item fill>
                        <div className="Webpush_Url_Icon">
                          <Stack wrap={false}>
                            <Avatar source="/Google_Chrome_Icon.svg" />
                            <div
                              style={{
                                paddingBottom: ".5rem",
                                paddingLeft: ".5rem"
                              }}
                            >
                              Chrome · {this.state.UserUrlName}
                            </div>
                          </Stack>
                        </div>
                      </Stack.Item>
                      {/* <Stack.Item>
                  <div className="testWebPush_Win_Icon">
                          <Stack>
                              <Icon source={SettingsMinor} />
                              <Icon source={CancelSmallMinor} />
                          </Stack>
                      </div>
                  </Stack.Item> */}
                    </Stack>

                    <Stack distribution="fill" wrap={false}>
                      <Stack vertical={true}>
                        <section
                          className="Message_Title"
                          style={{ marginTop: "-0.6rem" }}
                        >
                          <div className="">
                            {this.state.Title ? this.state.Title : "Title"}
                          </div>
                        </section>
                        <section
                          className="Message_Content"
                          style={{ paddingRight: "1rem" }}
                        >
                          <div className="Polaris-Stack__Itemclass">
                            <Stack vertical={true}>
                              <div>
                                {this.state.Message
                                  ? this.state.Message
                                  : "Message"}
                              </div>
                            </Stack>
                          </div>
                        </section>
                      </Stack>

                      <section style={{ float: "right", marginTop: "-0.6rem" }}>
                        <Thumbnail
                          source={setUpdateImageBackground}
                          size="small"
                          alt="Black choker necklace"
                        />
                      </section>
                    </Stack>
                    <div style={{ clear: "both" }}></div>
                  </Stack>
                </Card>
              </section>
            </div>
          </Card.Section>
        );
      } else if (selected === 2) {
        tabsContent = (
          <Card.Section>
            <div
              className=""
              style={{
                background: "url('/macback.jpg')  no-repeat",
                height: "415px",
                width: "100%",
                overflow: "hidden",
                backgroundSize: "cover",
                backgroundPosition: "right top",
                position: "relative"
              }}
            >
              <div className="ht-preview--mac__time">
                {this.GetDate()} {this.formatDate()}
              </div>
              <section
                className="MacPreview"
                style={{ width: "94%", margin: "10% auto" }}
              >
                <section className="MacPreview_Card">
                  <Card>
                    <Stack alignment="center">
                      <section style={{ marginLeft: "2rem" }}>
                        <Avatar
                          customer
                          name="Farrah"
                          source="/mac_chrome.iso.f3b6c54c.svg"
                        />
                      </section>
                      <Stack.Item fill>
                        <Stack vertical={true} spacing="extraTight">
                          <div className="Mac_Message_Title">
                            {this.state.Title
                              ? this.state.Title.substring(0, 30)
                              : "Title"}
                            {this.state.Title
                              ? this.state.Title.length > 31
                                ? "..."
                                : ""
                              : ""}
                          </div>
                          <span>{this.state.UserUrlName}</span>
                          <div className="Mac_Message_Content">
                            {this.state.Message
                              ? this.state.Message.substring(0, 34)
                              : "Message"}
                            {this.state.Message
                              ? this.state.Message.length > 35
                                ? "..."
                                : ""
                              : ""}
                          </div>
                        </Stack>
                      </Stack.Item>
                      <Card>
                        <Card.Section>Close</Card.Section>
                        <Card.Section>Settings</Card.Section>
                      </Card>
                    </Stack>
                  </Card>
                </section>
              </section>
            </div>
          </Card.Section>
        );
      }
    }

    const { leavingModalActive, discardModalActive } = this.state;

    const floatTopSaveBar = false ? (
      <div>
        <ContextualSaveBar
          alignContentFlush
          message="Unsaved changes"
          saveAction={{
            onAction: this.insertCreateCampaign,
            loading: this.state.saveButton
          }}
          discardAction={{
            onAction: this.ControlDiscardModal
          }}
        />
      </div>
    ) : (
      ""
    );

    const UserLeavingModal = this.state.leavingModalActive ? (
      <div>
        <Modal
          open={leavingModalActive}
          onClose={this.ControlUserLeavingModal}
          title={<LanguageSwitch Words="You have unsaved changes" />}
          primaryAction={{
            content: <LanguageSwitch Words="Leave edit" />,
            onAction: this.UserLeavesWithoutSave,
            destructive: true
          }}
          secondaryActions={[
            {
              content: <LanguageSwitch Words="Cancel" />,
              onAction: this.ControlUserLeavingModal
            }
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p><LanguageSwitch Words="If you leave this page, all unsaved changes will be lost." /></p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </div>
    ) : (
      ""
    );

    const UserDiscardModal = this.state.discardModalActive ? (
      <div>
        <Modal
          open={discardModalActive}
          onClose={this.ControlDiscardModal}
          title={<LanguageSwitch Words="Discard all unsaved changes" />}
          primaryAction={{
            content: <LanguageSwitch Words="Discard changes" />,
            onAction: this.DiscardInitData,
            destructive: true
          }}
          secondaryActions={[
            {
              content: <LanguageSwitch Words="Continue editing" />,
              onAction: this.ControlDiscardModal
            }
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                <LanguageSwitch Words="If you discard changes, you’ll delete any edits you made since you last saved." />
              </p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </div>
    ) : (
      ""
    );
    const cardHeader = this.state.loading ? (
      <Stack>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
        <Stack.Item fill>
          <Card sectioned>
            <div className="space"></div>
            <SkeletonBodyText lines={3} />
            <div className="space"></div>
          </Card>
        </Stack.Item>
      </Stack>
    ) : (
      <FormLayout>
        <FormLayout.Group condensed>

          <div style={{padding:'2rem',boxShadow: '0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)'}}>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.sent}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="Total Impressions" /></div>
              </TextContainer>
            </div>
          </div>

          <div style={{padding:'2rem',boxShadow: '0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)'}}>
            <div style={{ textAlign: "center" }}>
              <TextContainer>
                <div className="Total_Number_Show">{this.state.clicks}</div>
                <div className="Total_Number_Desc"><LanguageSwitch Words="Total Clicks" /></div>
              </TextContainer>
            </div>
          </div>
        </FormLayout.Group>
      </FormLayout>
    );



    const title = this.state.loadingPage ? (
      123
    ) : (
      <div>
        <SkeletonDisplayText size="small" />
      </div>
    );

    const pageContent = this.state.loadingPage ? (
      <Page>
        <div className="space"></div>
        <Layout>
          <Layout.Section>
            <DisplayText size="Large"><LanguageSwitch Words="Welcome Notification" /></DisplayText>
            <DisplayText size="small">
              <span className="hello_sub" style={{fontSize:'1.8rem'}}><LanguageSwitch Words="A notification is sent to the new subscribers" /></span>
            </DisplayText>
            <div className="space"></div>
          </Layout.Section>
        </Layout>

        <div className="WebPush__Control__Button">
            {button2}
        </div>

        {cardHeader}
        <div className="space"></div>

        {floatTopSaveBar}

        <div id="errorContent"></div>

        {criticalWindow}
        <Layout>
          <Layout.Section secondary>
            <Card title={<LanguageSwitch Words="CONTENT" />}>
              <Card.Section>
                <FormLayout>
                  <TextField
                    disabled
                    label={<LanguageSwitch Words="Sending to" />}
                    onChange={handleChange("sendingTo")}
                    value={sendingTo}
                  />
                  <TextField
                    label={<LanguageSwitch Words="Title" />}
                    onChange={handleChange("Title")}
                    value={Title}
                    maxLength={50}
                    showCharacterCount
                    helpText={<LanguageSwitch Words="Perfect length is 21 characters" />}
                    error={TitleError}
                  />
                  <TextField
                    label={<LanguageSwitch Words="Message" />}
                    onChange={handleChange("Message")}
                    value={Message}
                    maxLength={100}
                    showCharacterCount
                    helpText={<LanguageSwitch Words="Perfect length is 23 characters" />}
                    error={MessageError}
                  />
                  <TextField
                    label={<LanguageSwitch Words="URL" />}
                    id="URL"
                    onChange={this.URLHandleChange}
                    value={URL}
                    helpText={<LanguageSwitch Words="Your customers will see this web page when they tap on the content or hero image." />}
                    error={URLError}
                    prefix={httpSelectValue}
                    // connectedLeft={
                    //   <Select
                    //     value={httpSelectValue}
                    //     label="Weight unit"
                    //     onChange={this.handleSelectChange}
                    //     labelHidden
                    //     options={['https://', 'http://']}
                    //   />
                    // }
                  />
                </FormLayout>
              </Card.Section>
              <Card.Section title={<LanguageSwitch Words="BRAND LOGO" />}>
                <Stack vertical={true}>
                  <TextStyle variation="subdued">
                    <LanguageSwitch Words="64px x 64px; Less than 1MB; Accepts jpg, jpeg, png." />
                  </TextStyle>
                  {setUpdateImageWarning}
                  <Stack spacing="loose" distribution="center">
                    <div style={{ width: 134, height: 134, margin: "0 auto" }}>
                      <DropZone
                        accept="image/*"
                        type="image"
                        onDrop={(files, acceptedFiles, rejectedFiles) => {
                          updateImage(acceptedFiles);
                        }}
                      >
                        <div className="Webpush_UploadImage">
                          {uploadedFiles}
                          {fileUpload}
                          <div
                            style={{
                              textAlign: "center",
                              margin: "-10px 0 10px"
                            }}
                          >
                            {" "}
                            {this.state.loadingLogo ? <LoadingGif /> : ""}
                          </div>
                        </div>
                      </DropZone>
                    </div>
                    <div
                      style={{
                        display: this.state.imgButton ? "none" : "block"
                      }}
                    >
                      <Button
                        disabled={this.state.imgButton}
                        onClick={() => deleteImage()}
                      >
                        <LanguageSwitch Words="Remove" />
                      </Button>
                    </div>
                  </Stack>
                </Stack>
              </Card.Section>
            </Card>

            <Card>
              <Card.Section title={<LanguageSwitch Words="ACTION BUTTONS" />}>
                <FormLayout>
                  <ChoiceList
                    allowMultiple
                    choices={[
                      {
                        label: <LanguageSwitch Words="1st action button" />,
                        value: "button1",
                        helpText: button1Content
                      }
                    ]}
                    selected={Multipleselected}
                    onChange={this.handleChangeMultipl}
                  />
                  <ChoiceList
                    allowMultiple
                    choices={[
                      {
                        label: <LanguageSwitch Words="2nd action button" />,
                        value: "button2",
                        helpText: button2Content
                      }
                    ]}
                    selected={Multipleselected}
                    onChange={this.handleChangeMultipl}
                  />
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <div id="Preview-Auto">
              <Card title="">
                <Tabs
                  tabs={tabs}
                  selected={selected}
                  onSelect={this.handleTabChange}
                  fitted
                >
                  {loadingMarkup}
                  {tabsContent}
                </Tabs>
              </Card>
            </div>
          </Layout.Section>
        </Layout>

        {UserLeavingModal}
        {UserDiscardModal}
        {toastMarkup}

        <div className="space"></div>
        {/* <SheetExample /> */}
      </Page>
    ) : (
      <Page title={title}>
        <Layout>
          <Layout.Section secondary>
            <Card>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
            </Card>
            <Card>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
            </Card>
            <Card>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={3} />
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card>
              <Card.Section>
                <SkeletonBodyText lines={2} />
              </Card.Section>
              <Card.Section>
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "100%" }}
                    src="/mimg.png"
                    alt="Mobile example"
                    className="_2PsLf"
                  />
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    );
    return (
      <div className="frame_page_padding">
        <AppProvider>
          <Frame
            topBar={
              <OnPageSEOTabs
                loading={this.state.saveButton}
                discardButton={
                  this.state.dataHasChange && this.ControlDiscardModal
                }
                saveData={this.insertCreateCampaign}
                saveDataParams=""
                callback={this.callback}
              />
            }
          >
            <div className="MobilePage_Has_Save">
              {pageContent}
            </div>
          </Frame>
        </AppProvider>
      </div>
    );
  }
}

export default dashboard;
