import React from 'react'
import './index.css'

export default function LoadingGif(props) {
    const text = props.text;

    if(text === undefined){
        return <img className="Loading" src="/loading.gif" alt="loading"/>
    }else{
        return <div><span className="Loading-Card-title">{text}</span><img className="Loading" src="/loading.gif" alt="loading"/></div>
    }
}